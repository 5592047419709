import { Modules } from "../common/constants/sidebar";
import {
  clientAdminImg,
  dashboardImg,
  projectAdminImg,
  projectAllocationImg,
  projectManagementImg,
  timesheetImg,
  vendorAdminImg,
  vendorSowAdminImg,
  clientAdminBlueIcon,
  projectAdminBlueIcon,
  projectAllocationBlueIcon,
  projectManagementBlueIcon,
  timeSheetBlueIcon,
  vendorAdminBlueIcon,
  vendorSowAdminBlueIcon,  
  dashboardBlueIcon,
  leaveTracker,
  whiteLeaveTracker,
  engineeringIcon,
  whiteEngineeringIcon
} from "../common/icons";

export const ModuleList = [
  // { id: "1",key: 'dashboard', name: "Dashboard", img: dashboardImg, imgHover:dashboardBlueIcon },
  { id: "2",key:'projectAllocation', name: "Project Allocation", img: projectAllocationImg, imgHover:projectAllocationBlueIcon },
  { id: "3",key:'timeSheet', name: Modules.TIMESHEET, img: timesheetImg, imgHover:timeSheetBlueIcon },
  { id: "4",key:'projectManagement', name: "Project Management", img: projectManagementImg, imgHover:projectManagementBlueIcon },
  { id: "5",key:'clientAdmin' ,name: "Client Admin", img: clientAdminImg, imgHover:clientAdminBlueIcon },
  { id: "6",key:'projectAdmin' , name: "Project Admin", img: projectAdminImg, imgHover:projectAdminBlueIcon },
  { id: "7",key:'vendorAdmin' , name: "Vendor Admin", img: vendorAdminImg, imgHover:vendorAdminBlueIcon },
  { id: "8",key:'vendorSOWAdmin' , name: "Vendor SOW Admin", img: vendorSowAdminImg, imgHover:vendorSowAdminBlueIcon },
  { id: "9",key:'settings' , name: "Settings", img: "", imgHover:"" },
  { id: "10", key: 'reporting', name: "Reporting", img: vendorSowAdminImg, imgHover:vendorSowAdminBlueIcon},
  { id: "11", key: "invoiceTracker", name: "Invoice Tracker", img: vendorSowAdminImg, imgHover: vendorSowAdminBlueIcon},
  { id: "12", key: 'skillTracker', name: "Skill Tracker", img: vendorSowAdminImg, imgHover:vendorSowAdminBlueIcon},
  { id: "13", key: 'viewAttendance', name: "View Attendance", img: whiteLeaveTracker, imgHover: leaveTracker},
  { id: "14", key: 'attendanceFileUpload', name: "Attendance File Upload", img: timesheetImg, imgHover: timeSheetBlueIcon},
  { id: "15", key: 'trainingAndSession',name:"Training and Session",img:whiteEngineeringIcon,imgHover:engineeringIcon},
  { id: "16", key: 'sessionFeedback', name: "Session Feedback", img: projectManagementImg, imgHover:projectManagementBlueIcon },
];
