import React, { useState, useEffect} from 'react';
import './setupWFODays.css';
import {useSelector } from 'react-redux';
import TableContainer from "@mui/material/TableContainer";
import CalendarIcon from '@mui/icons-material/CalendarToday';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper";
import {Tooltip,ButtonBase} from "@mui/material";
import { AddEnableIcon, editIcon, crossIcon} from '../../common/icons';
// import DatePicker from 'react-datepicker' ;
// import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getCustomWfo,
  getWfoList, setVtrackLoader,deleteCustomWfoDay
} from "../../redux/actions";
import { useDispatch } from "react-redux";
import { updateWfoDays} from '../../redux/actions';
import { addCustomWfo, cloneWfoDays} from '../../redux/actions';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { toastOptions } from '../../common/utils/toasterOptions';
import CircularLoader from
"../Loaders/CircularLoader"
;

import DialogBox from "../DialogBox/dialogBox";

export const SetupWFODays = () => {
  const [tableData, setTableData] = useState(Array(12).fill([]).map(() => [false, false, false, false, false]));
  const [isEditing, setIsEditing] = useState(Array(12).fill(false));
  const [customDates, setCustomDates] = useState(Array(12).fill(''));
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const[selectedDate, setSelectedDate] =useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [selectedYear, setSelectedYear] = useState(currentYear);
  // const [startDate, setStartDate] = useState(new Date());
  const[pickedDate, setPickedDate]= useState();
  const [showCancelIcon, setShowCancelIcon] = useState(false);
  const [showClonePopup, setShowClonePopup] = useState(false);
  const [sourceYear, setSourceYear] = useState(false);
  const [targetYear, setTargetYear] = useState(false);
  const[updatedTableData, setUpdatedTableData] = useState(Array(12).fill([]).map(() => [false, false, false, false, false]));
  const [checkboxChanges, setCheckboxChanges] = useState(false);

  const [showDialogBox, setShowDialogBox] = useState(false);
  const [dialogDeleteButtonClicked, setDialogDeleteButtonClicked] = useState(false);
  const [deleteCustomWfoDayId, setDeleteCustomWfoDayId]= useState(null);

  
  const dispatch = useDispatch();

  const {
    wfoList, customWfoDays
  } = useSelector(({ MODULES }) => MODULES);

  const { vTrackLoader } = useSelector(({ APP_STATE }) => APP_STATE);
  // const toggleEditMode = () => {
  //   setIsEditing(!isEditing);
  // };

  const toggleEditMode = (monthIndex) => {
    const updatedEditingState = [...isEditing];
    updatedEditingState[monthIndex] = !updatedEditingState[monthIndex];
    setIsEditing(updatedEditingState);
    setShowCancelIcon(true);
    setCheckboxChanges(false);
    
  };

  const handleCheckboxChange = (monthIndex, dayIndex) => {
    const newData = [...tableData];
    newData[monthIndex][dayIndex] = !newData[monthIndex][dayIndex];
     setTableData(newData);
    setSelectedMonth(monthIndex);

    setCheckboxChanges(true);
  };

  const handleSaveRow = (monthIndex) => {
    toggleEditMode(monthIndex);
  };

  const handleAddCustomDates = (monthIndex) => {
    setPickedDate(null);
    setOpenDialog(true);
    setSelectedMonth(monthIndex);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveCustomDates = () => {
      const monthName = new Date(2024, selectedMonth).toLocaleString('en-US', { month: 'long' });

      let formattedPickedDate;
    if (!pickedDate) {
        formattedPickedDate = `${selectedYear}-${(selectedMonth + 1).toString().padStart(2, '0')}-01`;
    } else {
        formattedPickedDate = pickedDate
    }
      
       dispatch(addCustomWfo({
        year: selectedYear,
        month: monthName,
        date: formattedPickedDate,
      }));

      handleCloseDialog();
  };
  useEffect(() => {
    const updatedTableData1 = Array(12).fill([]).map(() => [false, false, false, false, false]);

    if (wfoList && wfoList.length > 0) {
      wfoList.forEach((item, index) => {
        const monthIndex = Math.floor(index / 5);
        const dayIndex = index % 5;

        if (item && item.status==="true") {
          updatedTableData1[monthIndex][dayIndex] = true;
        }
      });
    }

    setTableData(updatedTableData1);
  }, [wfoList]);

  useEffect(() => {
    const updatedTableData2 = tableData;
    if (customWfoDays && customWfoDays.length) {
      customWfoDays.forEach((customWfo) => {
        const monthIndex = new Date(customWfo.date).getMonth();
        const dayIndex = new Date(customWfo.date).getDate() - 1;

        if (monthIndex >= 0 && monthIndex < updatedTableData.length && dayIndex >= 0 && dayIndex < updatedTableData[monthIndex].length) {
          updatedTableData[monthIndex][dayIndex] = true;
        }
      });
    }

    setTableData(updatedTableData2);
  }, [customWfoDays]);


  useEffect(()=>{
    setVtrackLoader(true);
    dispatch(getWfoList({year:selectedYear}));
    dispatch(getCustomWfo({year:selectedYear}));
    setVtrackLoader(false);
  },[selectedYear])

  useEffect(() => {
    if (dialogDeleteButtonClicked) {
      dispatch(deleteCustomWfoDay({ id: deleteCustomWfoDayId, year: selectedYear }));
      setDialogDeleteButtonClicked(false);
      setDeleteCustomWfoDayId(false);
    }
  }, [dialogDeleteButtonClicked]);

  const years = []
  let i
  for (i=1;i<=5;i++){
    years.push(currentYear-2 +i)
  }
  const handleUpdate = () => {

    if (!checkboxChanges) {
      return;
    }
      const checkedDays = tableData[selectedMonth].reduce((acc, isChecked, dayIndex) => {
        if (isChecked) {
          const dayName = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"][dayIndex];
          acc.push(dayName);
        }
        return acc;
      }, []);
    
      const monthName = new Date(2024, selectedMonth).toLocaleString('en-US', { month: 'long' });
    

  
      const checkedDaysString = checkedDays.join(',');
  
      dispatch(updateWfoDays({
        year:selectedYear,
        month:monthName,
        day:checkedDaysString
      }))
      toast.success('WFO updated successfully', toastOptions);
  
      setIsEditing(Array(12).fill(false));
  
    
  };

  const handleCancel= ()=>{
    dispatch(getWfoList({ year: selectedYear }));

    setIsEditing(Array(12).fill(false));
    setShowCancelIcon(false);
  }

  const yearDropdown = (yearType) =>{
    return(<>
    <FormControl sx={{ minWidth: 100, marginTop:1}} size="small">
              <InputLabel id="demo-select-small-label" sx={{ minwidth: 100 }}>
                Year<span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={yearType==="selectedYear"?selectedYear:(yearType==="sourceYear"?sourceYear:targetYear)}
                label="Year"
              >
                {years.map((year) => (
                  <MenuItem
                    key={year}
                    onClick={() => {
                      if(yearType==="selectedYear")
                      setSelectedYear(year)
                      else if(yearType==="sourceYear")
                      setSourceYear(year)
                      else
                      setTargetYear(year)
                    }}
                    value={year}
                  >
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
    </>)
   
  }
  const handleClone = () => {
    setVtrackLoader(true);
    dispatch(cloneWfoDays({
      sourceYear:sourceYear,
      targetYear:targetYear
    }))
    setVtrackLoader(false);
    setTargetYear(false)
    setSourceYear(false)
    setShowClonePopup(false)
  };

  const ClonePopUp = () => {
   
    return (
      <Dialog open={showClonePopup} onClose={() => setShowClonePopup(false)} className = "WFODialog" sx={{border:"2px solid #1773bc"}}>
        <DialogTitle sx={{color:"#1773bc",fontFamily:"Montserrat", fontWeight:600,fontSize:"2rem", margin:2,textAlign:"center"}}>SET UP WFO DAYS FOR NEW YEAR</DialogTitle>
        <DialogContent sx={{minHeight:150}} >
          <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{color:"#1773bc",fontSize:12}}>Select Source</div>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10  }}>{yearDropdown("sourceYear")}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: '#1773bc', fontSize: '1.5rem' }}>TO</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{color:"#1773bc",fontSize:12}}>Select Target</div>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10  }}>{yearDropdown("targetYear")}</div>
            </div>
          </div>
       
        </DialogContent>
        <div style={{ display: "flex", justifyContent: "flex-end", padding: 10 }} className="popup-actions">
          <Button onClick={() => { setShowClonePopup(false); setTargetYear(false); setSourceYear(false) }} autoFocus style={{ marginLeft: "0.5rem" }} variant="outlined" size="large">
            Cancel
          </Button>
          <Button onClick={() => sourceYear && targetYear && handleClone()} autoFocus style={{ marginLeft: "0.5rem" }} variant="contained" size="large">
            Clone
          </Button>
        </div>
      </Dialog>
    );
  };
  const handleDeleteCustomDate = (customDayID) => {

    const customWfoDay = customWfoDays.find(day => day.customDayID === customDayID);
  
    if(customWfoDay) {
      const actualCustomDayID = customWfoDay.customDayID;
     
      setDeleteCustomWfoDayId(actualCustomDayID);
      setShowDialogBox(true);
    }
  };

  return (
    <div className="settingsWrapper" style={{ maxWidth: "100%" }}>
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 600, "&::-webkit-scrollbar": { width: 10 }, "&::-webkit-scrollbar-thumb": { backgroundColor: "white" }, "&::-webkit-scrollbar-thumb": { backgroundColor: "#1773bc", borderRadius: 2 } }}>
        <FormControl sx={{  minWidth: 100, margin:1  }} size="small">
            <InputLabel  id="demo-select-small-label" sx={{minwidth:100}} >Year</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedYear}
              label="Year"
              
            >
              {years.map((year) => (
              <MenuItem key={year} onClick={() => {setSelectedYear(year)}} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        <div className="table-container">
          {/* <button onClick={toggleEditMode}>{isEditing ? 'Save' : 'Edit'}</button> */}
          {vTrackLoader && <CircularLoader/>}
          {!vTrackLoader &&<table className="custom-table">
            <thead style={{ backgroundColor: 'rgba(23, 115, 188, 0.05)' }}>
              <tr>
                <th>Month</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th style={{ width: '300px' }}>WFO Dates</th>
                <th style={{ width: '80px' }}>Actions</th>
                {/* <th>Custom WFO Dates</th> */}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, monthIndex) => (
                <tr key={monthIndex}>
                  <td>{new Date(2024, monthIndex).toLocaleString('en-US', { month: 'long' })}</td>
                  {row.map((isChecked, dayIndex) => (
                    <td key={dayIndex}>
                      {isEditing[monthIndex] ? (
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={() => handleCheckboxChange(monthIndex, dayIndex)}
                        />
                      ) : isChecked ? (
                        <span>&#10004;</span>
                      ) : null}
                    </td>
                  ))}
                  <td > 
                  {customWfoDays &&
          customWfoDays.length &&
          customWfoDays.some(customWfo => new Date(customWfo.date).getMonth() === monthIndex) ? (
            customWfoDays
              .filter((customWfo) => new Date(customWfo.date).getMonth() === monthIndex)
              .map((customWfo) => {
                const formattedDate = new Date(customWfo.date).toLocaleDateString('en-GB');
                return (
                  <div key={customWfo.customDayID} style={{ position: 'relative', display: 'inline-block', padding: '0 5px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>{formattedDate}</div>
                      <img
                        src={crossIcon}
                        className="deleteIcon cursorPointer"
                        onClick={() => handleDeleteCustomDate(customWfo.customDayID)}
                        alt="Delete"
                        style={{
                          marginLeft: '5px',
                          width: 'i.3rem', 
                          height: '1rem', 
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  </div>
                );
              })
          ) : null}
                  </td>
                  <td>
                    {isEditing[monthIndex] ? (

                      <div className="actions">
                          <Tooltip title="update" className={`tooltip${!checkboxChanges ? ' disabled' : ''}`}>
                            <img
                            disabled={!checkboxChanges}
                              src={AddEnableIcon}
                              className="editDeleteIcon cursorPointer${!checkboxChanges ? ' disabled' : ''}`}"
                              style={{ marginRight: "2.5rem"}}
                              onClick={() => handleUpdate()}
                              alt=""
                            />
                          </Tooltip>
                          {showCancelIcon && ( 
                            <Tooltip title="cancel">
                              <img
                                src={crossIcon}
                                className="editDeleteIcon"
                                onClick={handleCancel}
                              />
                            </Tooltip>
                          )}
                        </div>
                    ) : (
                      <div className="actions">
                      <Tooltip title="edit" className="tooltip">
                                                <img
                                                    src={editIcon}
                                                    className="editDeleteIcon cursorPointer"
                                                    
                                                    onClick={() => toggleEditMode(monthIndex)}
                                                    alt=""
                                                />    
                                              </Tooltip>
                                              <Tooltip title="Add Custom Dates" className="tooltip">
                                                <img
                                                    src={AddEnableIcon}
                                                    className="editDeleteIcon cursorPointer"
                                                    
                                                    onClick={() => handleAddCustomDates(monthIndex)}
                                                    alt=""
                                                />    
                                              </Tooltip>

                                      
                      </div>
                        
                    
                    )}
                  </td>
                  
                </tr>
              ))}

            </tbody>
          </table>}
          <div style={{paddingTop:"2rem"}}>
              <ButtonBase
                className={"holidayCloneBtn cursorPointer custom-button"}
                autoFocus
                variant="contained"
                onClick={() => {
                  setShowClonePopup(true);
                }}
              >
                Set Up WFO Days For New Year
              </ButtonBase>{ClonePopUp()}
          </div>
          </div>
          

          <Dialog open={openDialog} onClose={handleCloseDialog}sx={{ "& .MuiDialog-paper": { minWidth: '40rem',display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderColor: '#1773bc',
          maxWidth:'40rem',
          maxHeight:'25rem' }
          }}>
            <DialogTitle style={{ fontWeight: 'bold', fontSize: '1.6rem', color:'#1773bc' }}>Add Custom Dates</DialogTitle>
            <DialogContent>
            <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
            <div style={{display:'flex', flexDirection:'column', paddingRight:'5rem'}}>
              <div style={{ paddingTop:'1rem'}}>
                <label style={{ fontWeight: 'bold',fontSize: '10px' }}>Month: {new Date(2024, selectedMonth).toLocaleString('en-US', { month: 'long' })}</label>
              </div>
              <div style={{paddingTop:'0.5rem'}}>
                <label style={{fontWeight: 'bold',fontSize: '10px' }}>Year: {selectedYear}</label>
              </div>
            </div>
            <div className="datepicker-container">
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                      sx={{
                        "& label": {
                          lineHeight: "0.8rem",
                        },
                      }}>
                      <DatePicker
                        label={
                          <Typography sx={{ fontSize: '12px', fontWeight: 'bold', color: '#1773bc' }}>
                            Select Date
                          </Typography>
                        }
                        value={pickedDate? pickedDate : new Date(selectedYear,selectedMonth,1) }
                        onChange={(newValue) => {
                          const formattedDate = newValue ? newValue.format("YYYY-MM-DD") : null;
                          setPickedDate(formattedDate);
                        }}
                        renderInput={(params) => (
                          <TextField
              style={{
                width: "15rem",
              }}
              {...params}
              sx={{
                "& input": {
                  fontSize: '12px',
                  fontWeight: 'bold',
                },
              }}
            />
                        )}
                      />
                  </LocalizationProvider>
              </div>
            </div>
            
              
              
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} autoFocus style={{marginLeft:"15rem"}} variant="outlined"  size="large">Cancel</Button>
              <Button onClick={handleSaveCustomDates} autoFocus style={{margin:"2.5rem"}} variant="contained"  size="large">Save</Button>
            </DialogActions>
          </Dialog>
          {showDialogBox && deleteCustomWfoDayId=== deleteCustomWfoDayId && (
                                  <DialogBox
                                    setShowDialogBox={setShowDialogBox}
                                    setDialogDeleteButtonClicked={
                                      setDialogDeleteButtonClicked
                                    }
                                    header="Are you sure you want to Delete?"
                                  />
                                )}

        </TableContainer>
      </Paper>
    </div>
  );
};
