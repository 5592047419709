import { call, put, takeLatest } from "redux-saga/effects";
import { getResourcingData,getBillableLeaves } from "../http/requests/project-admin";
import {
  setResourcingData,
  ProjectAdminType,
  setVtrackLoader,
  setBillableLeaves
} from "../redux/actions";

function* workerGetResourcingDataSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const resourcingData = yield call(getResourcingData,payload.projectId);
    const billableLeaves = yield call(getBillableLeaves,payload.projectId);
    yield put(setResourcingData(resourcingData));
    yield put(setBillableLeaves(billableLeaves));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* getResourcingDataSaga() {
  yield takeLatest(
    ProjectAdminType.GET_RESOURCING_DATA,
    workerGetResourcingDataSaga
  );
};
