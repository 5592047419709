import { call, put, takeLatest } from "redux-saga/effects";
import { getUserDetailsForTrainingAndSession } from "../http/requests/session";
import {
  setVtrackLoader
} from "../redux/actions";
import { SessionType,setUserDetailsForTrainingAndSession } from "../redux/actions/session";

function* workerGetUserDetailsForTrainingAndSessionSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const userDetail = yield call(getUserDetailsForTrainingAndSession,payload.sessionId);
    yield put(setUserDetailsForTrainingAndSession(userDetail));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* getUserDetailsForTrainingAndSessionSaga() {
  yield takeLatest(
    SessionType.GET_USER_DETAILS_FOR_TRAINING_AND_SESSION,
    workerGetUserDetailsForTrainingAndSessionSaga
  );
};