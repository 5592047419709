import { call, put, takeLatest } from "redux-saga/effects";
import {getWFODays} from "../http/requests/setupWFODays";
import {
    setVtrackLoader,
  } from "../redux/actions";

  import { SettingDataType } from "../redux/actions";
  import{setWfoList} from "../redux/actions/settings"

  function* workerSetupWFODaysSaga({payload}) {
    try {
      yield put(setVtrackLoader(true));
      const wfoList = yield call(getWFODays,payload.year)
      yield put(setWfoList(wfoList));
      yield put(setVtrackLoader(false));
    } catch (err) {
      console.log(err);
      yield put(setVtrackLoader(false));
    }
  };
  
  export function* setupWFODaysSaga() {
    yield takeLatest(
      SettingDataType.GET_WFO_LIST,
      workerSetupWFODaysSaga
    );
  };
  
