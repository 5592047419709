import { call, put, takeLatest } from "redux-saga/effects";
import {
  InvoiceTrackerType,
  setInvoiceTrackerData,
  setVtrackLoader
} from "../redux/actions";
import { getAllClientsInvoice } from "../http/requests/invoice-tracker";
import { toast } from "react-toastify";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerGetAllClientsInvoiceSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const invoiceTrackerData = yield call(
      getAllClientsInvoice,
      payload.clientId,
      payload.projectId,
      payload.startDate,
      payload.endDate    
    );
    
    yield put(setInvoiceTrackerData(invoiceTrackerData));
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* getAllClientsInvoiceSaga() {
  yield takeLatest(
    InvoiceTrackerType.GET_INVOICE_TRACKER_DATA,
    workerGetAllClientsInvoiceSaga
  );
};
