import { HttpMethod } from "../../common/constants/http-requests";
import { httpRequest } from "../../common/utils/http-request";
import { VIEW_ATTENDANCE_API } from "../api";

// export const getViewAttendanceData = (month,year,employeeID) => 
//   httpRequest({
//     url: `${VIEW_ATTENDANCE_API}/get-view-attendance-data?month=${month}&year=${year} &employeeID=`,
//     method: HttpMethod.GET,
//   });
export const getViewAttendanceData = (month, year, employeeID) => {
  const url = `${VIEW_ATTENDANCE_API}/get-view-attendance-data?month=${month}&year=${year}`;
  const finalUrl = employeeID ? `${url}&employeeID=${employeeID}` : url;
  return httpRequest({
    url: finalUrl,
    method: HttpMethod.GET,
  });
};

export const getLeaveDetails = (leaveId)=>
  httpRequest({
    url: `${VIEW_ATTENDANCE_API}/get-leave-details?leaveId=${leaveId}`,
    method: HttpMethod.GET,
  });

export const getAttendanceForExcel = (month, year)=>
  httpRequest({
    url:`${VIEW_ATTENDANCE_API}/get-attendance-for-excel?month=${month}&year=${year}`,
    method: HttpMethod.GET,
  });