import { call, put, takeLatest } from "redux-saga/effects";
import {
  HistoryType,
  setChangesHistory,
  setProjectAllocationData,
  setVtrackLoader,
} from "../redux/actions";
import { getChangesHistory } from "../http/requests/history";

function* workerGetChangesHistorySaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    const historyResponse = yield call(
      getChangesHistory,{
        tableName: payload.tableName,
        tableIdentifierKey: payload.tableIdentifierKey
      }
    );
    yield put(setChangesHistory(historyResponse));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
}

export function* getChangesHistorySaga() {
  yield takeLatest(
    HistoryType.GET_CHANGES_HISTORY,
    workerGetChangesHistorySaga
  );
}
