// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empName-period {
    display: flex;
    gap: 5rem;
} 

.namePeriodDiv {
    display: flex;
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: 'Montserrat';
    padding: 1rem;
}

.namePeriodLabel {
    color: grey;
    margin-right: 1rem;

}

.namePeriodField {
    color: #1773bc;
}

.contentNotFound{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/components/DetailView/DetailView.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,iBAAiB;IACjB,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,kBAAkB;;AAEtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,6BAA6B;AACjC","sourcesContent":[".empName-period {\r\n    display: flex;\r\n    gap: 5rem;\r\n} \r\n\r\n.namePeriodDiv {\r\n    display: flex;\r\n    font-size: 1.5rem;\r\n    line-height: 2rem;\r\n    font-family: 'Montserrat';\r\n    padding: 1rem;\r\n}\r\n\r\n.namePeriodLabel {\r\n    color: grey;\r\n    margin-right: 1rem;\r\n\r\n}\r\n\r\n.namePeriodField {\r\n    color: #1773bc;\r\n}\r\n\r\n.contentNotFound{\r\n    display: flex;\r\n    width: 100%;\r\n    flex-direction: row;\r\n    justify-content: space-around;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
