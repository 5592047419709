import { call, put, takeLatest } from "redux-saga/effects";
import { getSessionUserHistory } from "../http/requests/session";
import {
  setVtrackLoader
} from "../redux/actions";
import { SessionType,setSessionUserHistory } from "../redux/actions/session";

function* workerSessionUserHistorySaga() {
  try {
    yield put(setVtrackLoader(true));
    const sessionHistory = yield call(getSessionUserHistory);
    yield put(setSessionUserHistory(sessionHistory));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* sessionUserHistorySaga() {
  yield takeLatest(
    SessionType.GET_SESSIONS_USER_HISTORY,
    workerSessionUserHistorySaga
  );
};
