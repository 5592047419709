import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { updateProjectAdminDetails } from "../http/requests/project-admin";
import { getProjectAdminData, ProjectAdminType, setVtrackLoader } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerUpdateProjectAdminSaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    yield call(updateProjectAdminDetails, payload.data);
    let page=payload.data.pageNo;page+=1;
    let rows=payload.data.rows;
    toast.success("Data Saved", toastOptions)
    payload.data.pageNo += 1
    yield put(
      getProjectAdminData({
        pageNo: page,
        pageSize: rows,
        sortDir: "ASC",
        sortBy: "projectName",
        searchData: '',
        expiredAllocations: payload.data.expiredAllocations
      })
    );
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* updateProjectAdminSaga() {
  yield takeLatest(
    ProjectAdminType.UPDATE_PROJECT_ADMIN_DATA,
    workerUpdateProjectAdminSaga
  );
};