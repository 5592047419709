import { call, put, takeLatest } from "redux-saga/effects";
import {
  InvoiceTrackerType,
  getInvoiceTrackerData,
  setVtrackLoader
} from "../redux/actions";
import { deleteInvoice } from "../http/requests/invoice-tracker";
import { toast } from "react-toastify";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerDeleteInvoiceSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    yield call(deleteInvoice,payload);
    toast.success("Data deleted Successfully", toastOptions);

    yield put(getInvoiceTrackerData());
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions);
    yield put(setVtrackLoader(false));
  }
};

export function* deleteInvoiceSaga() {
  yield takeLatest(
    InvoiceTrackerType.DELETE_INVOICE,
    workerDeleteInvoiceSaga
  );
};
