export const MultipleFileViewType = {
    GET_MULTIPLE_FILE_VIEW: 'GET_MULTIPLE_FILE_VIEW',
    SET_MULTIPLE_FILE_VIEW_DATA: 'SET_MULTIPLE_FILE_VIEW_DATA',
    DOWNLOAD_MULTIPLE_FILE_VIEW: 'DOWNLOAD_MULTIPLE_FILE_VIEW',
    DELETE_MULTIPLE_FILE_VIEW: 'DELETE_MULTIPLE_FILE_VIEW',
  }

export const getMultipleFileView = (data)=>
({
  type: MultipleFileViewType.GET_MULTIPLE_FILE_VIEW,
  payload: data,
})

export const setMultipleFileViewData = (data)=>
({
  type: MultipleFileViewType.SET_MULTIPLE_FILE_VIEW_DATA,
  payload: data,
})

export const downloadMultipleFileView = (data)=>
({
  type: MultipleFileViewType.DOWNLOAD_MULTIPLE_FILE_VIEW,
  payload: data,
})

export const deleteMultipleFileView = (data)=>
({
  type: MultipleFileViewType.DELETE_MULTIPLE_FILE_VIEW,
  payload: data,
})