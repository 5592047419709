import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { updateResourcingData } from "../http/requests/project-admin";
import { getResourcingData, ProjectAdminType, setVtrackLoader } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerUpdateResourcingDataSaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    yield call(updateResourcingData, payload.data);
    toast.success("Data Saved", toastOptions)
    yield put(
        getResourcingData({projectId: payload.data[0].projectId})
    );
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* updateResourcingDataSaga() {
  yield takeLatest(
    ProjectAdminType.UPDATE_RESOURCING_DATA,
    workerUpdateResourcingDataSaga
  );
};