import { call, put, takeLatest } from "redux-saga/effects";
import { postHoliday } from "../http/requests/holiday-list";
import { SettingDataType,setVtrackLoader } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workerAddHolidaySaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    yield call(postHoliday, payload);
    toast.success("Data Saved", toastOptions)
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* addHolidaySaga() {
  yield takeLatest(
    SettingDataType.ADD_HOLIDAY,
    workerAddHolidaySaga
  );
};
