import { Component } from 'react';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getMultipleFileView } from "../../redux/actions/multiple-file-view";
import { useSelector } from "react-redux";
import { Modules } from "../../common/constants/sidebar";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    AddDisableIcon,
    AddEnableIcon, approveIcon, crossIcon, deleteIcon, downloadIcon, editIcon, rejectIcon, TableArrows
} from "../../common/icons";
import { deleteMultipleFileView } from "../../redux/actions/multiple-file-view";
import { downloadMultipleFileView } from "../../redux/actions/multiple-file-view";
import { Padding } from '@mui/icons-material';
import CircularLoader from "../Loaders/CircularLoader";

const MultipleFileViewPopup = (props) => {
    const dispatch = useDispatch();
    let multipleFileViewData = useSelector(({ MODULES }) => MODULES.multipleFileViewData)

    const handleDownload = (fileId) => {
        dispatch(downloadMultipleFileView({
            ID: fileId
        }))
    }
    const handleDelete = (fileId) => {
        dispatch(deleteMultipleFileView({
            ID: fileId,
            type: props.type,
            Id: props.Id
        }))
        props.onResponse(true)
    }
    const handleClose = () => {
        props.onResponse(true);
      }
    const { vTrackLoader } = useSelector(({ APP_STATE }) => APP_STATE);
    
    return (
        <React.Fragment>
            <DialogContent>
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 600 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{bgcolor:'#f3f8fc'}}>
                                <TableCell align="left"sx={{color:'#3a75c2',fontSize:'1.5rem'}}>File Name</TableCell>
                                <TableCell align="left"sx={{color:'#3a75c2',fontSize:'1.5rem'}}>Upload Date</TableCell>
                                <TableCell align="right"sx={{color:'#3a75c2',fontSize:'1.5rem',paddingRight:'2rem'}}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        {!vTrackLoader && <TableBody>
                            {multipleFileViewData&&multipleFileViewData.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left" sx={{fontSize:'1.5rem'}}>{row.docName.split('/').pop()}</TableCell>
                                    <TableCell align="left" sx={{ fontSize: '1.5rem' }}>
                                        {(() => {
                                            const options = { day: 'numeric', month: 'short', year: 'numeric' };
                                            const formattedDate = new Date(row.createdDT).toLocaleDateString('en-US', options);
                                            return `${formattedDate} `;
                                        })()}
                                    </TableCell>
                                    <TableCell align="right"  >
                                        
                                        <Tooltip sx={{paddingRight:'2rem'}} title={<h2>Download</h2>}>
                                            <img src={downloadIcon} className="editDownloadIcon cursorPointer downloadIcon"
                                                onClick={() =>
                                                    handleDownload(row.id)
                                                }
                                                alt="" />
                                        </Tooltip>
                                        <Tooltip title={<h2>Delete</h2>} sx={{marginLeft:'2rem'}}>
                                            <img
                                                src={deleteIcon}
                                                className="editDeleteIcon cursorPointer deleteIcon"
                                                onClick={() =>
                                                    handleDelete(row.id)
                                                }
                                                alt=""
                                            />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>}
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
            <Button variant="contained" style={{ maxWidth: '12rem' }} onClick={handleClose}>Close</Button>
            </DialogActions>
        </React.Fragment>

    );
}

export default MultipleFileViewPopup;