import { call, put, takeLatest } from "redux-saga/effects";
import {
  InvoiceTrackerType,
  setInvoicedMonthsData,
  setVtrackLoader
} from "../redux/actions";
import { getInvoiceByInvoiceId, getInvoicedMonths } from "../http/requests/invoice-tracker";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workeGetInvoicedMonthsSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const invoicedMonthsData = yield call(getInvoicedMonths,payload.projectId);
    yield put(setInvoicedMonthsData(invoicedMonthsData));
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions);
    yield put(setVtrackLoader(false));
  }
};

export function* getInvoicedMonthsSaga() {
  yield takeLatest(
    InvoiceTrackerType.GET_INVOICED_MONTHS,
    workeGetInvoicedMonthsSaga
  );
};
