
export const ProjectAdminType = {
    GET_PROJECT_ADMIN_DATA: 'GET_PROJECT_ADMIN_DATA',
    SET_PROJECT_ADMIN_DATA: 'SET_PROJECT_ADMIN_DATA',
    SAVE_PROJECT_ADMIN_DATA: 'SAVE_PROJECT_ADMIN_DATA',
    SAVE_PROJECT_ADMIN_FILE_DATA: 'SAVE_PROJECT_ADMIN_FILE_DATA',
    UPDATE_PROJECT_ADMIN_DATA: 'UPDATE_PROJECT_ADMIN_DATA',
    DELETE_PROJECT_ADMIN_DATA: 'DELETE_PROJECT_ADMIN_DATA',
    SET_SELECTED_PROJECT_DATA: 'SET_SELECTED_PROJECT_DATA',
    GET_PROJECT_DATA: 'GET_PROJECT_DATA',
    DOWNLOAD_PROJECT_ADMIN_DATA: 'DOWNLOAD_CLIENT_ADMIN_DATA',
  }
  
  export const getProjectAdminData = (data) => 
  ({
    type: ProjectAdminType.GET_PROJECT_ADMIN_DATA,
    payload: data,
  });
  
  export const deleteProjectAdminData = (data) => 
  ({
    type: ProjectAdminType.DELETE_PROJECT_ADMIN_DATA,
    payload: data,
  });
  
  export const setProjectAdminData = (data) => 
  ({
    type: ProjectAdminType.SET_PROJECT_ADMIN_DATA,
    payload: data,
  });
  
  export const saveProjectAdminData = (data) => 
  ({
    type: ProjectAdminType.SAVE_PROJECT_ADMIN_DATA,
    payload: { data },
  });
  
  export const updateProjectAdminData = (data) => 
  ({
    type: ProjectAdminType.UPDATE_PROJECT_ADMIN_DATA,
    payload: { data },
  });

  export const postProjectAdminFile = (data) => 
  ({
    type: ProjectAdminType.SAVE_PROJECT_ADMIN_FILE_DATA,
    payload: { data },
  });
  
  export const setSelectedProjectData = (data) =>
  ({
    type: ProjectAdminType.SET_SELECTED_PROJECT_DATA,
    payload: data,
  });

  export const getProjectById = (data) => 
  ({
    type: ProjectAdminType.GET_PROJECT_DATA,
    payload: data,
  });

export const downloadProjectAdminFile = (projectId) =>
({
  type: ProjectAdminType.DOWNLOAD_PROJECT_ADMIN_DATA, 
  payload: { projectId },
}); 

