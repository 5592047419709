// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formDivs {
  display: flex;
  align-items: center;
}
.lineDivs{
  display: flex;padding-bottom: 2rem;
  justify-content: space-between;
  align-items: center;
}
.textareaInput {
  height: 5rem!important;
  font-size: 1.5rem !important;
}

.sessionDescription{
  font-size: 1.5rem !important;
}

#date-picker-inline {
  height: 2rem!important;
}

.special {
  height: 5rem!important;
}

.custom-datepicker .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 5rem!important;
  font-size: 1.5rem;
}

.approveDownloadIcon{
  margin: 0 2rem 0 1rem;
}

.cursorPointer {
  cursor: pointer!important;
}
.disabledButton {
  cursor: not-allowed!important;
}`, "",{"version":3,"sources":["webpack://./src/components/Session/SessionCreation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa,CAAC,oBAAoB;EAClC,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".formDivs {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.lineDivs{\r\n  display: flex;padding-bottom: 2rem;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n.textareaInput {\r\n  height: 5rem!important;\r\n  font-size: 1.5rem !important;\r\n}\r\n\r\n.sessionDescription{\r\n  font-size: 1.5rem !important;\r\n}\r\n\r\n#date-picker-inline {\r\n  height: 2rem!important;\r\n}\r\n\r\n.special {\r\n  height: 5rem!important;\r\n}\r\n\r\n.custom-datepicker .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {\r\n  height: 5rem!important;\r\n  font-size: 1.5rem;\r\n}\r\n\r\n.approveDownloadIcon{\r\n  margin: 0 2rem 0 1rem;\r\n}\r\n\r\n.cursorPointer {\r\n  cursor: pointer!important;\r\n}\r\n.disabledButton {\r\n  cursor: not-allowed!important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
