import { ClientAdminType, DropdownType, HistoryType, InvoiceTrackerType, ProjectAdminType, ProjectAllocationType, SessionType, SettingDataType } from "../actions"
import { ProjectManagementType } from "../actions/project-management";
import { ReportingType } from "../actions/reporting";
import { TimeSheetType } from "../actions/timesheet";
import { SkillsType } from "../actions/skills";
import { MultipleFileViewType } from "../actions/multiple-file-view";
import { ViewAttendanceType } from "../actions/view-attendance";
import { SignalCellularNullSharp } from "@material-ui/icons";

export const modulesState = {
  clientAdminData: null,
  projectAdminData: null,
  projectAllocationData: [],
  employeeViewData:null,
  timeSheetData: null,
  timesheetPeriodId: 0,
  timesheetPeriodWeek: "",
  clientsData: null,
  projectManagers: null,
  listItems: null,
  allTasks: null,
  allUsers: null,
  allProjectsData: null,
  userRole: null,
  allProjectManagementData:[],
  projectManagementData: null,
  settingTableData:null,
  mappedProjectManagementData:null,
  assignedProjects: null,
  reportees: null,
  selectedEmployeeId: null,
  selectedProjectId: null,
  detailedTimeSheetData: null,
  timeSheetProjects: null,
  reportingData: null,
  selectedProjectData: null,
  invoiceTrackerData: null,
  invoiceDetails: null,
  historyDetails: null,
  invoicedMonths: null,
  zohoPeopleDetails: null,
  exportProjectManagementData: [],
  skillsList: null, 
  allocationChangeReportData: [],
  multipleFileViewData:[],
  holidayList: null,
  wfoList: null,
  customWfoDays: null,
  allocationChangeReportDataForExcel:[],
  exportData: [],
  projectAllocationDataForExcel:[],
  viewAttendanceData:[],
  leaveDetails:[],
  allocationChangeReportDataByEmployee:[],
  allocationChangeReportByEmployeeDataForExcel:[],
  sessionDetails: null,
  participantsData: null ,
  userDetailsForTrainingAndSession:null,
  sessionUserHistoryData: null,
  sessionsDataForExcel: null,
  sessionFeedbackQuestions:null,
  sessionFeedbackHeaderData:null,
  sessionFeedbackURL:null,
  attendanceForExcel:null,
  syncDetailsData:[],
}

export const modulesReducer = (state = modulesState, action) => {
  switch(action.type) {
    case ClientAdminType.SET_CLIENT_ADMIN_DATA:
      return {
        ...state,
        clientAdminData: action.payload,
      };
    case SettingDataType.SET_SYNC_DETAILS:
      return {
        ...state,
        syncDetailsData: action.payload
      };
    case TimeSheetType.SET_TIMESHEET_DATA:
      return {
        ...state,
        timeSheetData: action.payload,
      };
    case ProjectAdminType.SET_PROJECT_ADMIN_DATA:
      return {
        ...state,
        projectAdminData: action.payload,
      }
    case ProjectAllocationType.SET_PROJECT_ALLOCATION_DATA:
      return {
        ...state,
        projectAllocationData: action.payload,
      }
    case ProjectAllocationType.SET_EMPLOYEE_VIEW_DATA:
      return {
        ...state,
        employeeViewData : action.payload,
      }
    case DropdownType.SET_ALL_CLIENTS_DATA:
      return {
        ...state,
        clientsData: action.payload,
      }
    case DropdownType.SET_PROJECT_MANAGERS_DATA:
      return {
        ...state,
        projectManagers: action.payload,
      }
    case DropdownType.SET_LIST_ITEMS:
      return {
        ...state,
        listItems: action.payload,
      }
    case DropdownType.SET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      }
    case DropdownType.SET_ALL_PROJECTS_DATA:
      return {
        ...state,
        allProjectsData: action.payload,
      }
    case SettingDataType.SET_USER_ROLE_DATA:
      return {
        ...state,
        userRole: action.payload
      }
    case ProjectManagementType.SET_PROJECT_MANAGEMENT_DATA:
      return {
        ...state,
        projectManagementData: action.payload
      }
    case ProjectManagementType.SET_ALL_PROJECT_MANAGEMENT_DATA:
      return {
        ...state,
        allProjectManagementData: action.payload
      }
    case SettingDataType.SET_SETTING_TABLE_DATA:
      return {
        ...state,
        settingTableData: action.payload
      }
    case TimeSheetType.SET_TIMESHEET_PERIOD_ID:
      return {
        ...state,
        timesheetPeriodId: action.payload
      }
    case TimeSheetType.SET_TIMESHEET_PERIOD_WEEK:
      return {
        ...state,
        timesheetPeriodWeek: action.payload
      }
    case ProjectManagementType.SET_MAPPED_PROJECT_MANAGEMENT_DATA:
      return{
        ...state,
        mappedProjectManagementData: action.payload
      }  
    case TimeSheetType.SET_PROJECT_TASKS_DATA:
      return {
        ...state,
        allTasks: action.payload
      }
    case TimeSheetType.SET_SELECTED_PROJECT_ID:
      return {
        ...state,
        selectedProjectId: action.payload
      }
    case TimeSheetType.SET_SELECTED_EMPLOYEE_ID:
      return {
        ...state,
        selectedEmployeeId: action.payload
      }
    case DropdownType.SET_ASSIGNED_PROJECTS_DATA:
      return {
        ...state,
        assignedProjects: action.payload
      }
    case DropdownType.SET_REPORTEES:
      return {
        ...state,
        reportees: action.payload
      }
    case DropdownType.SET_TIMESHEET_PROJECTS:
      return {
        ...state,
        timeSheetProjects: action.payload
      }
    case TimeSheetType.SET_DETAILED_TIMESHEET_DATA:
      return {
        ...state,
        detailedTimeSheetData: action.payload
      }
    case ReportingType.SET_REPORTING_DATA:
      return{
        ...state,
        reportingData: action.payload
      }

    case ReportingType.SET_ALLOCATION_CHANGE_REPORT_DATA:
      return{
        ...state,
        allocationChangeReportData: action.payload
      }
    case MultipleFileViewType.SET_MULTIPLE_FILE_VIEW_DATA:
      return{
        ...state,
        multipleFileViewData: action.payload
      }
   
    case ProjectAdminType.SET_SELECTED_PROJECT_DATA:
      return{
        ...state,
        selectedProjectData: action.payload
      }
    case InvoiceTrackerType.SET_INVOICE_TRACKER_DATA:
      return{
        ...state,
        invoiceTrackerData: action.payload
      }
    case InvoiceTrackerType.SET_INVOICE_DETAILS:
      return{
        ...state,
        invoiceDetails: action.payload
      }
    case HistoryType.SAVE_CHANGES_HISTORY:
      return{
        ...state,
        historyDetails: action.payload
      }
    case InvoiceTrackerType.SAVE_INVOICED_MONTHS_DATA:
      return{
        ...state,
        invoicedMonths: action.payload
      }
    case SettingDataType.SET_ZOHO_PEOPLE_DETAILS:
      return{
        ...state,
        zohoPeopleDetails: action.payload
      }
    case ProjectManagementType.SET_EXPORT_PROJECT_MANAGEMENT_DATA:
      return{
        ...state,
        exportProjectManagementData: action.payload
      }
    case SkillsType.SET_SKILLS_DATA:
      return {
        ...state,
        skillsList: action.payload
      }
    case SettingDataType.SET_HOLIDAY_LIST:
      return{
        ...state,
        holidayList: action.payload
      }
    case SettingDataType.SET_WFO_LIST:
      return{
        ...state,
        wfoList: action.payload
      }
    case SettingDataType.SET_CUSTOM_WFO:
      return{
        ...state,
        customWfoDays: action.payload
      }
    case ReportingType.SET_EXPORT_DATA:
      return {
        ...state,
        exportData: action.payload
      }
    case ReportingType.SET_ALLOCATION_CHANGE_REPORT_DATA_FOR_EXCEL:
      return{
        ...state,
        allocationChangeReportDataForExcel:action.payload,
      }
    case ProjectManagementType.RESET_EXPORT_PROJECT_MANAGEMENT_DATA:
      return{
        ...state,
        exportProjectManagementData: [],
      }
    case ProjectAllocationType.SET_PROJECT_ALLOCATION_DATA_FOR_EXCEL:
      return{
        ...state,
        projectAllocationDataForExcel:[],
      }
    case ViewAttendanceType.SET_VIEW_ATTENDANCE_DATA:
      return{
        ...state,
        viewAttendanceData:action.payload
      }
    case ViewAttendanceType.SET_LEAVE_DETAILS:
      return{
        ...state,
        leaveDetails:action.payload
      }
    case ReportingType.SET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE:
      return{
        ...state,
        allocationChangeReportDataByEmployee: action.payload
      }
    case ReportingType.SET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE_DATA_FOR_EXCEL:
      return{
        ...state,
        allocationChangeReportByEmployeeDataForExcel: action.payload
      }
    case SessionType.SET_ALL_SESSIONS_DATA:
      return{
        ...state,
        sessionDetails: action.payload
      }
    case SessionType.SET_SESSION_FEEDBACK_QUESTIONS_LIST:
      return{
        ...state,
        sessionFeedbackQuestions:action.payload
      }
    case SessionType.SET_PARTICIPANTS_DATA:
      return{
        ...state,
        participantsData: action.payload
      }
    case SessionType.SET_USER_DETAILS_FOR_TRAINING_AND_SESSION:
      return{
        ...state,
        userDetailsForTrainingAndSession: action.payload
      }
    case SessionType.SET_SESSIONS_USER_HISTORY:
      return{
        ...state,
        sessionUserHistoryData : action.payload
      }
    case SessionType.SET_SESSION_FEEDBACK_HEADER:
      return {
        ...state,  
        sessionFeedbackHeaderData: action.payload  
      }; 
    case SessionType.SET_SESSION_FOR_EXCEL:
      return {
        ...state,  
        sessionsDataForExcel: action.payload  
      };    
    case SessionType.SET_SESSION_FEEDBACK_URL:
      return {
        ...state,  
        sessionFeedbackURL: action.payload  
      };
    case ViewAttendanceType.SET_ATTENDANCE_FOR_EXCEL:
      return {
        ...state,  
        attendanceForExcel: action.payload  
      };
    default: return state;
  }
};
