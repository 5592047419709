import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useDispatch } from 'react-redux';
import { updateParticipantRequest } from '../../redux/actions';

 const UserRegistrationRejectReason= (props) => {
    const [inputValue, setInputValue] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = () => {
        // dispatch(updateParticipantRequest({
        //     updateData: {
        //         registrationId: props.registrationId,
        //         status: "Rejected",
        //         reason: inputValue
        //     },
        //     sessionId : props.sessionId
        //  }))
      props.onResponse(true,inputValue)
      setInputValue("");
    };

    const handleCancel = () => {
      props.onResponse(true);
    }
    return (
      <React.Fragment>
          <DialogContent>
            <DialogContentText>
              Reason for Rejection
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Reason"
              type="text"
              variant="standard"
              fullWidth
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </DialogActions>
      </React.Fragment>
    );
}

export default UserRegistrationRejectReason;