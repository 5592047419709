import { call, put, takeLatest } from "redux-saga/effects";
import { getSessionFeedbackHeader,getSessionFeedbackQuestionsList } from "../http/requests/session";
import {
  setVtrackLoader
} from "../redux/actions";
import { SessionType,setSessionFeedbackHeader,setSessionFeedbackquestionsList } from "../redux/actions/session";

function* workerGetSessionFeedbackHeaderSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const feedbackDetail = yield call(getSessionFeedbackHeader,payload);
    yield put(setSessionFeedbackHeader(feedbackDetail));
    const sessionQuestionList = yield call(getSessionFeedbackQuestionsList);
    yield put(setSessionFeedbackquestionsList(sessionQuestionList));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }

};

export function* GetSessionFeedbackHeaderSaga() {
  yield takeLatest(
    SessionType.GET_SESSION_FEEDBACK_HEADER,
    workerGetSessionFeedbackHeaderSaga
  );
};