import React, { useEffect, useState } from 'react';
import {
    Button,
  } from "@mui/material";
  import { useDispatch, useSelector } from "react-redux";
  import {DataTable} from "../DataTable/DataTable"
  import DialogActions from '@mui/material/DialogActions';
  import DialogContent from '@mui/material/DialogContent';
  import Table from '@mui/material/Table';
  import TableBody from '@mui/material/TableBody';
  import TableCell from '@mui/material/TableCell';
  import TableContainer from '@mui/material/TableContainer';
  import TableHead from '@mui/material/TableHead';
  import TableRow from '@mui/material/TableRow';
  import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
  import Paper from '@mui/material/Paper';
  import Dialog from "@mui/material/Dialog";
  import { toast } from "react-toastify";
  import { toastOptions } from "../../common/utils/toasterOptions";
  import {approveIcon,rejectIcon} from "../../common/icons";
import { updateParticipantRequest, updateAttendance } from '../../redux/actions';
import UserRegistrationRejectReason from '../ReasonDialogBox/UserRegistrationRejectReason';
import moment from 'moment';
 
  export const ParticipantListPopUp = (props) =>{
    const [rowIDRejectTimesheetPopup, setRowIDRejectTimesheetPopup] = useState(-1);
    const [openDialogBox, setOpenDialogBox] = useState(false);
    const [reason,setReason] = useState(null);
    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
    // const [presentUserRegistrationIds,setPresentUserRegistrationIds] = useState('');
    // const [absentUserRegistrationIds,setAbsentUserRegistrationIds] = useState('');
    const [presentRows, setPresentRows] = useState([]);
    const [absentRows, setAbsentRows] = useState([]);
   
    const handleDialogBox = (res,reason) => {
      setReason(reason);
      setOpenDialogBox(!res);
      // setRowIDRejectTimesheetPopup(-1);
    };
    // const onResponse = (reason,isSubmitButtonClicked) => {
    //   setReason(reason);
    // };
 
   
    const dispatch = useDispatch();
    const participantsList = useSelector(({ MODULES }) => MODULES.participantsData)
    const { vTrackLoader } = useSelector(({ APP_STATE }) => APP_STATE);
    const [seatsFilled , setSeatsFilled] = useState('');
    
    useEffect(() => {
      if (participantsList) {
          const acceptedCount = participantsList.filter(obj => obj.status === 'Accepted').length;
          setSeatsFilled(acceptedCount);
      }
    }, [participantsList]);
 
    
    const handleClose = () => {
      setSeatsFilled('')
      props.onResponse(true);
    }
 
    const handleCloseForReject = () => {
      setSeatsFilled('')
      setOpenDialogBox(false);
      // setRowIDRejectTimesheetPopup(-1);
      // setRowIDMultipleViewPopup(-1);
    };

    const handlePresentCheckboxChange = (row, checked) => {
    if (checked) {
        setPresentRows(prevPresentRows => [...prevPresentRows, row.registrationId]);
        setAbsentRows(prevAbsentRows => prevAbsentRows.filter(id => id !== row.registrationId));
    } else {
        setPresentRows(prevPresentRows => prevPresentRows.filter(id => id !== row.registrationId));
    }
    }

    const handleAbsentCheckboxChange = (row, checked) => {
    //   setAbsentUserRegistrationIds(prevIds => {
    //     const updatedIds = prevIds.split(',');
    //     if (checked && !updatedIds.includes(row.registrationId.toString())) {
    //         updatedIds.push(row.registrationId);
    //     } else if (!checked) {
    //         return updatedIds.filter(id => id !== row.registrationId.toString()).join(',');
    //     }
    //     return updatedIds.filter(id => id !== '').join(',');
    // });
    if (checked) {
        setAbsentRows(prevAbsentRows => [...prevAbsentRows, row.registrationId]);
        setPresentRows(prevPresentRows => prevPresentRows.filter(id => id !== row.registrationId));
    } else {
        setAbsentRows(prevAbsentRows => prevAbsentRows.filter(id => id !== row.registrationId));
    }
    }

    useEffect(() => {
    }, [presentRows,absentRows]);

    const initializeRows = () => {

      if(participantsList){
      const presentIds = participantsList.filter(row => row.attendance === 'Present'&& row.status === "Accepted").map(row => row.registrationId);
      const absentIds = participantsList.filter(row => row.attendance === 'Absent'&& row.status === "Accepted").map(row => row.registrationId);
      setPresentRows(presentIds);
      setAbsentRows(absentIds);

      }
    };

  useEffect(() => {
    initializeRows();
  }, [participantsList]);
 
    // console.log(rowIDRejectTimesheetPopup)
    useEffect(()=>{
      if(reason){
        dispatch(updateParticipantRequest({
          updateData: {
              registrationId: rowIDRejectTimesheetPopup,
              status: "Rejected",
              reason: reason
          },
          sessionId : props.sessionId
      }))
      setRowIDRejectTimesheetPopup(-1)
      }},[reason]);
 
    return (
      <React.Fragment>
        <div className="heading">Participant List </div>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div style={{ display: 'flex' }}>
            <div className='heading' style={{fontSize:"1.6rem"}}>Session Name: &nbsp;</div>
            <div className='heading coloured' style={{fontSize:"1.6rem"}}>{props.sessionName}</div>
          </div>
 
          <div style={{ display: 'flex' }}>
            <div className='heading' style={{fontSize:"1.6rem"}}>Seats Filled :&nbsp;</div>
            <div className='heading coloured' style={{fontSize:"1.6rem"}}>{seatsFilled} / {props.totalSeats}</div>
          </div>
        </div>
       
        <DialogContent style={{padding:"1rem 0.1rem",width:"100rem",}}>
          <TableContainer component={Paper} style={{maxHeight:"50rem",overflowY:"auto"}}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell align="left"sx={{bgcolor:'#f3f8fc',color:'#3a75c2',fontSize:'1.5rem'}}>Participant Name</TableCell>
                  {/* {console.log(props.sessionDate)} */}
                  {props.sessionStatus === "Completed" ?
                              (<>
                                <TableCell align="left"sx={{bgcolor:'#f3f8fc',color:'#3a75c2',fontSize:'1.5rem'}}> Mark Present</TableCell>
                                <TableCell align="left"sx={{bgcolor:'#f3f8fc',color:'#3a75c2',fontSize:'1.5rem'}}>Mark Absent</TableCell>
                              </>)
                              :
                              (<>
                  <TableCell align="left"sx={{bgcolor:'#f3f8fc',color:'#3a75c2',fontSize:'1.5rem'}}>Registration Type</TableCell>
                  <TableCell align="left"sx={{bgcolor:'#f3f8fc',color:'#3a75c2',fontSize:'1.5rem',paddingRight:'2rem'}}>Actions</TableCell>
                  <TableCell align="left"sx={{bgcolor:'#f3f8fc',color:'#3a75c2',fontSize:'1.5rem'}}>Reason</TableCell>
                  </>)}
                </TableRow>
              </TableHead>
              {!vTrackLoader && <TableBody>
                {props.sessionStatus === "Completed" &&participantsList&&participantsList.filter(row => row.status === "Accepted").map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left" sx={{fontSize:'1.5rem'}}>{row.employeeName}</TableCell>
                    <TableCell align="left" sx={{fontSize:'1.5rem'}}>
                        <input 
                            type="checkbox"
                            style={{marginLeft:"3.8rem", transform: "scale(1.5)"}}
                            checked={presentRows.includes(row.registrationId)}
                            onChange={(event) => handlePresentCheckboxChange(row, event.target.checked)}
                        />
                    </TableCell>
                              <TableCell align='left' sx={{fontSize:'1.5rem'}}>
                                  <input 
                                   type="checkbox"
                                   style={{marginLeft:"3.8rem", transform: "scale(1.5)"}}
                                   checked ={absentRows.includes(row.registrationId)}
                                   onChange={(event) => handleAbsentCheckboxChange(row, event.target.checked)}
                                   />
                      </TableCell>
                    </TableRow>
                    ))}
                    {(props.sessionStatus === "Published" || props.sessionStatus === "Cancelled")&&participantsList&&participantsList.map((row) => (
                      <TableRow
                      key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                    <TableCell align="left" sx={{fontSize:'1.5rem'}}>{row.employeeName}</TableCell>
                    <TableCell align="left" sx={{ fontSize: '1.5rem' }}>
                      {row.registrationType === "Nominated" ? (
                        <Tooltip  title= {`By: ${row.nomineeName}`}sx={{ fontSize:"2rem" }}>
                          <span>{row.registrationType}</span>
                        </Tooltip>
                      ):<span>{row.registrationType}</span>}
                    </TableCell>
                    {/* <TableCell align="left" sx={{ fontSize: '1.5rem' }}>{row.nomineeName}</TableCell> */}
                    {(row.status==="Accepted" || row.status==="Rejected" || row.status ==="Revoked") ? <TableCell align="left" sx={{justifyContent:"end"}}>
                      {/* <TableCell align="right" sx={{fontSize:'1.5rem'}}>{row.Status}</TableCell> */}
                    <h2>{row.status}</h2>
                      </TableCell>
                        :
                        <TableCell align="left" sx={{justifyContent:"end"}} >
                         
                          <Tooltip sx={{paddingRight:'6rem'}} title={<h2>Accept</h2>}>
                              <img src={approveIcon}
                                className={`approveDownloadIcon ${
                                row.status === "Accepted"
                                  ? "disabledButton"
                                  : "cursorPointer"
                                }`}
                                onClick={() =>{
                                  if(props.totalSeats>seatsFilled){
                                    dispatch(updateParticipantRequest({
                                      updateData: {
                                          registrationId: row.registrationId,
                                          status: "Accepted",
                                          reason: null
                                      },
                                      sessionId : props.sessionId
                                    }))
                                  }
                                  else
                                  toast.info(`Seats are full`,toastOptions);
                                }}
                              alt="" />
                          </Tooltip>
 
                          {row.registrationId === rowIDRejectTimesheetPopup &&
                              openDialogBox && (<Dialog
                                  open={openDialogBox}
                                  onClose={handleCloseForReject}
                                  fullWidth={true} maxWidth={"sm"}>
                                  <UserRegistrationRejectReason
                                    onResponse={handleDialogBox}
                                  />
                                </Dialog>
                              )}
                          <Tooltip title={<h2>Reject</h2>} sx={{marginLeft:'2rem'}}>
                              <img
                                  src={rejectIcon}
                                  className="editDeleteIcon cursorPointer deleteIcon"
                                  onClick={
                                      () =>{
                                      setOpenDialogBox(true);
                                    setRowIDRejectTimesheetPopup(row.registrationId);
                                      }
                                }
                                  alt=""
                              />
                          </Tooltip>
                      </TableCell>}
                      <TableCell align="left" sx={{fontSize:'1.5rem'}}>{(row.status==="Rejected"||row.status==="Revoked")&&row.reason}</TableCell>
                         
                     
                  </TableRow>
                ))}
              </TableBody>}
            </Table>
          </TableContainer>
        </DialogContent>
        
        <DialogActions style={{padding:"1rem 0.1rem"}}>
          {props.sessionStatus === "Completed" &&
          <Button variant="contained" style={{maxWidth:'12rem',margin:'12px'}} onClick={() => {
            dispatch(updateAttendance({
              updateData: {
                presentList: presentRows.join(','),
                absentList: absentRows.join(','),
                sessionId: props.sessionId
            },
            sessionId : props.sessionId
            }));
            setPresentRows([]);
            setAbsentRows([]);
            handleClose()
          }}>
              Submit
          </Button>
         }
          <Button variant="contained" style={{backgroundColor:"#cfcfd4",color:"black",width:"15rem",marginLeft:"1rem"  }} onClick={() => {handleClose()}}>Close</Button>
        </DialogActions>
      </React.Fragment>
    );
  }