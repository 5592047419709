export const DATE_FORMAT = "DD MMM YYYY";
export const DATE_TIME_FORMAT = "YYYY-MM-DDT00:00:00";
export const EMPLOYEE_USER_MANUAL_LINK = 'https://veersalabs.sharepoint.com/:b:/s/VeersaLabsPvt.LTD/EXmGznt8T95IkeTSudNo4nMBDHVkn5MUsy5ZCp5NN0cceA?e=F4hiwS'
export const PROJECT_MANAGER_USER_MANUAL_LINK = 'https://veersalabs.sharepoint.com/:b:/s/VeersaLabsPvt.LTD/EXmGznt8T95IkeTSudNo4nMBDHVkn5MUsy5ZCp5NN0cceA?e=F4hiwS'
export const DATE_FIELD_FORMAT = "MM/DD/YYYY";
export const DATE_TIME_READABLE_FORMAT = "DD-MMM-YYYY HH:mm:ss";
export const ROLES = {
    ADMIN:"Admin",
    PROJECT_MANAGER:"Project Manager",
    EMPLOYEE:"EMPLOYEE"
}