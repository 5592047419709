import { HttpMethod } from "../../common/constants/http-requests";
import { httpRequest } from "../../common/utils/http-request";
import { SKILLS_API } from "../api";


export const getSkillsData = (payload) =>
  httpRequest({
    url: `${SKILLS_API}/users-by-skills`,
    method: HttpMethod.POST,
    data: payload
  })