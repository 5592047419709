import {toast} from "react-toastify";
import {call, put, takeLatest} from "redux-saga/effects";
import { getSyncDetails } from "../http/requests/settings";
import { SettingDataType ,setVtrackLoader} from "../redux/actions";
import { setSyncDetails} from "../redux/actions/settings"
import {toastOptions} from "../common/utils/toasterOptions";

function* workerGetSyncDetailsSaga() {    
    try {
        yield put(setVtrackLoader(true));
        console.log("Ravi");
        const syncDetails = yield call(getSyncDetails);
        yield put(setSyncDetails(syncDetails));
        yield put(setVtrackLoader(false));
    } catch (err) {
        toast.error(err.data.errorMessage, toastOptions);
        yield put(setVtrackLoader(false));
        toast.error(err.data.errorMessage, toastOptions);
    }
}

export function* getSyncDetailsSaga() {
    yield takeLatest(
        SettingDataType.GET_SYNC_DETAILS,
        workerGetSyncDetailsSaga
    );
}