import { HttpMethod } from "../../common/constants/http-requests";
import { httpRequest } from "../../common/utils/http-request";
import { SETUP_WFO_DAYS_API } from "../api";

export const getWFODays = (year) =>
  httpRequest({
    url: `${SETUP_WFO_DAYS_API}/get-wfo-list?year=${year}`,
    method: HttpMethod.GET,
  });

export const updateWfoDays =(data)=>
  httpRequest({
    url:`${SETUP_WFO_DAYS_API}/update-wfo-days`,
    method: HttpMethod.PUT,
    data:data
  })

  export const addCustomWfo = (data)=>
  httpRequest({
    url: `${SETUP_WFO_DAYS_API}/add-custom-wfo`,
    method: HttpMethod.POST,
    data:data
  })
  export const getCustomWfo = (year)=>
  httpRequest({
    url:`${SETUP_WFO_DAYS_API}/get-custom-wfo-days?year=${year} `,
    method: HttpMethod.GET,
  })

  export const cloneWfoDays =(data)=>
  httpRequest({
    url:`${SETUP_WFO_DAYS_API}/clone-wfo-days`,
    method: HttpMethod.PUT,
    data:data
  })

  export const deleteCustomWfoDay = (data) =>
  httpRequest({
    url: `${SETUP_WFO_DAYS_API}/${data.id}/delete-custom-wfo-day`,
    method: HttpMethod.DELETE,
  });