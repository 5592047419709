export const SkillsType = {
    GET_SKILLS_DATA: 'GET_SKILLS_DATA',
    SET_SKILLS_DATA: 'SET_SKILLS_DATA'
}
 
export const getSkillDataGrid = (data) => 
  ({
    type: SkillsType.GET_SKILLS_DATA,
    payload: data,
  })

export const setSkillDataGrid = (data) => 
  ({
    type: SkillsType.SET_SKILLS_DATA,
    payload: data,
  })