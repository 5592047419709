import { call, put, takeLatest } from "redux-saga/effects";
import { getAllocationChangeReport } from "../http/requests/reporting";
import { setVtrackLoader } from "../redux/actions";
import { ReportingType, setAllocationChangeReportData, setReportingData,setExportData } from "../redux/actions/reporting";

function* workerGetAllocationChangeReport({ payload }){
  try{
    yield put(setVtrackLoader(true));
    
    const allocationChangeReport = yield call(
      getAllocationChangeReport,
      payload.startDate,
      payload.endDate,
      payload.projectId);
      
      if(payload.isExport){
        yield put(setExportData(allocationChangeReport));
       }
      else{
       yield put(setAllocationChangeReportData(allocationChangeReport));
      }
      yield put(setVtrackLoader(false));
  } catch(err){
    console.log(err);
    yield put(setVtrackLoader(false));
  }
}

export function* getAllocationChangeReportSaga() {
  yield takeLatest(
    ReportingType.GET_ALLOCATION_CHANGE_REPORT,
    workerGetAllocationChangeReport
  );
};