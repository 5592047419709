import { call, put, takeLatest } from "redux-saga/effects";
import { getAllSessionsData } from "../http/requests/session";
import {
  setVtrackLoader
} from "../redux/actions";
import { SessionType,setAllSessionsData } from "../redux/actions/session";

function* workerAllSessionsSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const sessionDetail = yield call(getAllSessionsData,payload.searchData);
    yield put(setAllSessionsData(sessionDetail));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* allSessionsSaga() {
  yield takeLatest(
    SessionType.GET_ALL_SESSIONS_DATA,
    workerAllSessionsSaga
  );
};
