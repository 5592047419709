import { call, put, takeLatest } from "redux-saga/effects";
import { updateUserRegistration,getUserDetailsForTrainingAndSession,getAllSessionsData } from "../http/requests/session";
import { SessionType,setVtrackLoader,setUserDetailsForTrainingAndSession,setAllSessionsData } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workerUpdateUserRegistrationSaga({ payload }) {
    try {
        yield put(setVtrackLoader(true));
        yield call(updateUserRegistration, payload.data);
        toast.success("Registration Cancelled", toastOptions)
        let sessionId=payload.data.sessionId;
        let userSessionDetail = yield call(getUserDetailsForTrainingAndSession,sessionId)
        yield put(setUserDetailsForTrainingAndSession(userSessionDetail));
        const sessionDetail = yield call(getAllSessionsData,"");
        yield put(setAllSessionsData(sessionDetail));
        yield put(setVtrackLoader(false));
    } catch (err) {
        toast.error(err.data.errorMessage, toastOptions)
        yield put(setVtrackLoader(false));
    }
}

export function* updateUserRegistrationSaga() {
    yield takeLatest(
        SessionType.UPDATE_USER_REGISTRATION,
        workerUpdateUserRegistrationSaga
    );
};