export const InvoiceTrackerType = {
  GET_INVOICE_TRACKER_DATA: 'GET_INVOICE_TRACKER_DATA',
  SET_INVOICE_TRACKER_DATA: 'SET_INVOICE_TRACKER_DATA',
  SAVE_INVOICE_TRACKER_DATA: 'SAVE_INVOICE_TRACKER_DATA',
  GET_INVOICE_DETAILS: 'GET_INVOICE_DETAILS', 
  SET_INVOICE_DETAILS: 'SET_INVOICE_DETAILS',
  UPDATE_INVOICE: 'UPDATE_INVOICE',
  DELETE_INVOICE: 'DELETE_INVOICE',
  GET_INVOICED_MONTHS: 'GET_INVOICED_MONTHS',
  SAVE_INVOICED_MONTHS_DATA: 'SAVE_INVOICED_MONTHS_DATA',
}

export const getInvoiceTrackerData = () =>
  ({
    type: InvoiceTrackerType.GET_INVOICE_TRACKER_DATA,
  });

export const setInvoiceTrackerData = (data) =>
  ({
    type: InvoiceTrackerType.SET_INVOICE_TRACKER_DATA,
    payload: data,
  });

export const saveInvoiceTrackerData = (data) =>
({
  type: InvoiceTrackerType.SAVE_INVOICE_TRACKER_DATA,
  payload: data,
})

export const getInvoiceDetails = (data) =>
({
  type: InvoiceTrackerType.GET_INVOICE_DETAILS,
  payload: data,
})

export const setInvoiceDetails = (data) =>
({
  type: InvoiceTrackerType.SET_INVOICE_DETAILS,
  payload: data,
})

export const updateInvoice = (payload) =>
({
  type: InvoiceTrackerType.UPDATE_INVOICE,
  payload: payload
})

export const deleteInvoice = (payload) => 
({
  type: InvoiceTrackerType.DELETE_INVOICE,
  payload: payload
})

export const getInvoicedMonths = (payload) =>
({
  type: InvoiceTrackerType.GET_INVOICED_MONTHS,
  payload: payload
})

export const setInvoicedMonthsData = (payload) =>
({
  type: InvoiceTrackerType.SAVE_INVOICED_MONTHS_DATA,
  payload: payload
})