export const HistoryType = {
  GET_CHANGES_HISTORY: 'GET_CHANGES_HISTORY',
  SAVE_CHANGES_HISTORY: 'SAVE_CHANGES_HISTORY',
}

export const getChangesHistory = (data) =>
({
  type: HistoryType.GET_CHANGES_HISTORY,
  payload: data
})

export const setChangesHistory = (data) =>
({
  type: HistoryType.SAVE_CHANGES_HISTORY,
  payload: data
})