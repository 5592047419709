// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataContainer{
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.fontSize-2rem{
  font-size: 2rem;
}

.box{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}`, "",{"version":3,"sources":["webpack://./src/components/EmployeeView/employeeView.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,qCAAqC;AACvC","sourcesContent":[".dataContainer{\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin: 1rem;\r\n}\r\n\r\n.fontSize-2rem{\r\n  font-size: 2rem;\r\n}\r\n\r\n.box{\r\n  display: grid;\r\n  grid-template-columns: repeat(2, 1fr);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
