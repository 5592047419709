import { call, put, takeLatest } from "redux-saga/effects";
import { addSession,getAllSessionsData } from "../http/requests/session";
import { SessionType,setVtrackLoader,setAllSessionsData } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workerAddSessionSaga({ payload }) {
    try {
        yield put(setVtrackLoader(true));
        yield call(addSession, payload.data);
        toast.success("Data Saved", toastOptions)
        const sessionDetail = yield call(getAllSessionsData,"");
        yield put(setAllSessionsData(sessionDetail));
        yield put(setVtrackLoader(false));
    } catch (err) {
        toast.error(err.data.errorMessage, toastOptions)
        yield put(setVtrackLoader(false));
    }
}

export function* addSessionSaga() {
    yield takeLatest(
        SessionType.ADD_SESSION,
        workerAddSessionSaga
    );
};