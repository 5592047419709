import { call, put, takeLatest } from "redux-saga/effects";
import { submitSessionFeedbackDetails,getSessionFeedbackHeader } from "../http/requests/session";
import { SessionType,setVtrackLoader,setSessionFeedbackHeader } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workerSubmitSessionFeedbackDetailsSaga({ payload }) {
    try {
        yield put(setVtrackLoader(true));
        yield call(submitSessionFeedbackDetails, payload.data);
        toast.success("Feedback Submitted", toastOptions)
        const feedbackDetail = yield call(getSessionFeedbackHeader,payload.data.SessionId);
        yield put(setSessionFeedbackHeader(feedbackDetail)); 
        yield put(setVtrackLoader(false));
    } catch (err) {
        toast.error(err.data.errorMessage, toastOptions)
        yield put(setVtrackLoader(false));
    }
}


export function* submitSessionFeedbackDetailsSaga() {
    yield takeLatest(
        SessionType.SUBMIT_SESSION_FEEDBACK_DETAILS,
        workerSubmitSessionFeedbackDetailsSaga
    );
};