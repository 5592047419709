import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Paper, Slide } from "@mui/material";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectAllocationData, getZohoPeopleDetails, setSelectedEmployeeId } from "../../redux/actions";
import { useEffect } from "react";
import { tableColumnsData } from "../../common/utils/datatable";
import './employeeView.css'
import { DATE_FORMAT } from "../../common/constants/extra-constants";
import moment from "moment";
import { Modules } from "../../common/constants/sidebar";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const EmployeeView = ({viewEmployeeDetails, setViewEmployeeDetails, selectedEmployeeId}) => {
  const {zohoPeopleDetails, projectAllocationData,employeeViewData} = useSelector(({ MODULES }) => MODULES);
  const dispatch = useDispatch();
  const handleClose = () => {
    setViewEmployeeDetails(false);
  }

  useEffect(() =>{
    if(selectedEmployeeId && viewEmployeeDetails){
      dispatch(
        getZohoPeopleDetails({
          employeeId: selectedEmployeeId
        })
      );

      dispatch(
        getProjectAllocationData({
          pageNo: 1,
          pageSize: 100,
          sortBy: "projectName",
          sortDir: "ASC",
          searchData: "",
          status: "ACTIVE",
          employeeID: selectedEmployeeId,
          employeeView: true
        })
      );
    }
  }, [selectedEmployeeId, viewEmployeeDetails])

  const insertData = (data, type) => {
     const response = type === "date" ? moment(data).format(DATE_FORMAT) : (data?.length === 0 || data === ", ") ? "-" : data;
     return response;
  }

  const createDataContainer = (col, key, data) => {
    if(key !== Modules.PROJECT_ALLOCATION){
      return (
        <div className="dataContainer">
          <div className="fontSize-2rem" style={{color: "#1773bc"}}>
            {col.label}
          </div>
          <div className="fontSize-2rem">
            {zohoPeopleDetails && insertData(zohoPeopleDetails[col.id], col.type)}
          </div>
        </div>
      )
    }
    else{
      return (
         <div className="dataContainer">
          <div className="fontSize-2rem" style={{color: "#1773bc"}}>
            {col.label}
          </div>
          <div className="fontSize-2rem">
            {data && insertData(data[col.id], col.type)}
          </div>
        </div>
      )
    }
  }

  return (
    <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={viewEmployeeDetails}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogContent>
          {
            Object.keys(tableColumnsData["ZohoDetails"]).map((key)=>{
              return(
                <Paper elevation={3} style={{margin: "2rem 0", padding: "1rem"}}>
                  <DialogTitle style={{color:"#1773bc", fontSize: "3rem"}}>{key}</DialogTitle>
                    <Divider variant="middle"  style={{marginBottom: "1rem", borderWidth: "1px", borderColor: "#1773bc"}}/>
                  <Paper elevation={key !== Modules.PROJECT_ALLOCATION ? 6 : 0} className={key !== Modules.PROJECT_ALLOCATION ? "box" : ""}>
                    {key !== Modules.PROJECT_ALLOCATION ? (zohoPeopleDetails && tableColumnsData["ZohoDetails"][key].map((col) => {
                      return createDataContainer(col, key);
                    })) : employeeViewData && employeeViewData.data.map((data) => {
                          return <Paper elevation={6} className="box" style={{margin: "1rem"}}>
                            {tableColumnsData["ZohoDetails"][key].map((col) => {
                              return createDataContainer(col, key, data);
                            })}
                        </Paper>
                      })}         
                  </Paper>
                </Paper>
              )
            })
          }
          
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
  )
}