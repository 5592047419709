import { call, put, takeLatest } from "redux-saga/effects";

import { getViewAttendanceData } from "../http/requests/view-attendance";
import {
  setVtrackLoader
} from "../redux/actions";

import { ViewAttendanceType, setViewAttendanceData } from "../redux/actions/view-attendance";

function* workerViewAttendanceSaga({ payload }) {
    try {
      yield put(setVtrackLoader(true));
      const viewAttendanceDetails = yield call(
        getViewAttendanceData,
        payload.month,
        payload.year,
        payload.employeeId
      );
        yield put(setViewAttendanceData(viewAttendanceDetails));
        yield put(setVtrackLoader(false));
    } catch (err) {
      console.log(err);
      yield put(setVtrackLoader(false));
    }
  };
  
  export function* viewAttendanceSaga() {
    yield takeLatest(
      ViewAttendanceType.GET_VIEW_ATTENDANCE_DATA,
      workerViewAttendanceSaga
    );
  };