import { call, put, takeLatest } from "redux-saga/effects";
import { getSkillsData } from "../http/requests/skills";
import {
  SkillsType,
  setVtrackLoader,
  setSkillDataGrid
} from "../redux/actions";

function* workerSkillsDataSaga(payload) {
  try {
    yield put(setVtrackLoader(true));
    const skillsData = yield call(getSkillsData, payload.payload);
    yield put(setSkillDataGrid(skillsData));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* skillsDataSaga() {
  yield takeLatest(
    SkillsType.GET_SKILLS_DATA,
    workerSkillsDataSaga
  );
};
