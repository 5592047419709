import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modules } from "../../common/constants/sidebar";
import { searchIcon } from "../../common/icons";
import {differenceInDays} from 'date-fns';
import {parseISO} from "date-fns";
import Dialog from "@mui/material/Dialog";

import Autocomplete from '@mui/material/Autocomplete';


import {
  getFullName,
  getLabel,
  getMinWidth,
  getTotalHrs,
  tableColumnsData,
  titleCase,
  UniqueIds
} from "../../common/utils/datatable";
import {
  getAllSessionsData,
  getUserDetailsForTrainingAndSession,
  getInvoicedMonths,
  getInvoiceTrackerData,
  getProjectById,
  getUsersProjectAllocation,
  setListItems,
  updateTimeSheetStatus,
  getNotifications,
  getResourcingData
} from "../../redux/actions";

import {
  getAllUserDetails,
  getAssignedProjects,
  getClientAdminData,
  getClientsData,
  getListItems,
  clonePreviousWeekTimesheet,
  getMyTimeSheetData,
  getProjectAdminData,
  getProjectAllocationData,
  getProjectTasks,
  getReportees,
  getTimeSheetData,
  getTimesheetProjects,
  getTimeSheetReportee,
  saveTimeSheetPeriodData,
  setSelectedEmployeeId,
  setSelectedProjectId,
  setTimeSheetPeriodWeek,
  submitPeriodForApproval,
  getSessionUserHistory,
  getSessionFeedbackQuestionsList
} from "../../redux/actions";
import {
  getMappedProjectManagementData,
  getProjectManagementData,
} from "../../redux/actions/project-management";
import {getViewAttendanceData, setViewAttendanceData} from "../../redux/actions/view-attendance";
import { DataTable } from "../DataTable/DataTable";
import { toastOptions } from "../../common/utils/toasterOptions";
import "./tabsTable.css";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  ListSubheader,
  OutlinedInput,
  Select,
  Switch,
} from "@mui/material";
import { CalendarMonthRounded } from "@mui/icons-material";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
} from "../../common/constants/extra-constants";
import { handleSetRows } from "../../common/utils/tabsTable";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  getMissingTimesheet,
  getTimesheetReports,
  getAllocationChangeReport,
  getAllocationChangeReportByEmployee,
  setReportingData,
  resetReportingDataState,
  setAllocationChangeReportData,
  setAllocationChangeReportByEmployee
} from "../../redux/actions/reporting";
import { Link } from "react-router-dom";
import { getSkillDataGrid } from "../../redux/actions/skills";
import { CreateCalendar } from "../Calendar/Calendar";
import { SessionCreation } from "../Session/SessionCreation";
import SessionFeedback from "../Session/SessionFeedback";
import {excelAllIcon } from '../../common/icons';
import { Tooltip } from "@mui/material";
import MonthYearPopup from "../ReasonDialogBox/MonthYearPopup";

const getPeriods = () => {
  let date = moment().subtract(42, "days");
  const periods = [];
  for (let i = 0; i < 13; i++) {
    periods.push({
      startDate: moment(date.startOf("isoweek")),
      endDate: moment(date.add(7, "days").startOf("week")),
    });
    date.add(7, "days");
  }
  return periods;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const TabsTable = ({
  headingName,
  tabName,
  status,
  projectId,
  queryPeriodWeek,
}) => {
  const {
    clientAdminData,
    projectAdminData,
    projectAllocationData,
    timeSheetData,
    projectManagementData,
    mappedProjectManagementData,
    selectedProjectId,
    reportees,
    reportingData,
    invoiceTrackerData,
    selectedProjectData,
    invoicedMonths,
    allocationChangeReportData,
    viewAttendanceData,
    allocationChangeReportDataByEmployee,
    sessionDetails,
    sessionUserHistoryData,
  } = useSelector(({ MODULES }) => MODULES);
  const { allUserDetails, userData } = useSelector(({ USER }) => USER);
  const { allTasks, listItems, clientsData, timeSheetProjects, skillsList } = useSelector(({ MODULES }) => MODULES);
  const dispatch = useDispatch();

  const { vTrackLoader } = useSelector(({ APP_STATE }) => APP_STATE);

  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
  const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [searchData, setSearchData] = useState("");
  const periodWeeks = getPeriods();
  const [selectedPeriodWeek, setSelectedPriodWeek] = useState(
    queryPeriodWeek !== null
      ? queryPeriodWeek
      : {
        startDate: moment().startOf("isoweek"),
        endDate: moment().endOf("isoweek"),
      }
  );
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [rowToBeUpdated, setRowToBeUpdated] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [disableDate, setDisableDate] = useState(false);
  const [disablePeriodWeek, setDisablePeriodWeek] = useState(false);
  const [filterProjectDropdownData, setFilterProjectDropdownData] = useState(null);
  const [filterClientDropdownData, setFilterClientDropdownData] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientProject, setSelectedClientProject] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedPriSecSkills, setSelectedPriSecSkills] = useState([]);
  const [selectedStreams, setSelectedStreams] = useState([]);


  const [isHistory, setIsHistory] = useState(false);
  const [billStatus, setBillStatus] = useState(null);
  const[expiredAllocations, setExpiredAllocations] = useState(false);

  const [selectedUserIds, setSelectedUserIds] = useState("");
  const[selectedUserID, setSelectedUserID] = useState("");
  const[viewAttendanceClicked, setViewAttendanceClicked]= useState(false);

  const [selectedSkillsIds, setSelectedSkillsIds] = useState("");
  const [selectedPriSecSkillsIds, setSelectedPriSecSkillsIds] = useState("");
  const [selectedStreamsIds, setSelectedStreamsIds] = useState("");

  const [userSkills, setUserSkills] = useState(
    listItems ? listItems.Skills : []
  );
  const [userStreams, setUserStreams] = useState(listItems ? listItems.Streams : [])
  const [userPriSecSkills, setUserPriSecSkills] = useState(listItems ? listItems.PriSecSkills : [])

  const [userMembers, setUserMembers] = useState(allUserDetails);
  const [selectAll, setSelectAll] = useState(false);

  const [selectAllPriSecSkill, setSelectAllPriSecSkill] = useState(false);
  const [selectAllStreams, setSelectAllStreams] = useState(false);
  const [missingReportData,setMissingReportData] = useState(0);
  const [selectedMissingProjects, setSelectedMissingProjects] = useState([]);
  const [selectedTimesheetProjects, setSelectedTimesheetProjects] = useState([]);
  const [selectMissingProjectsIds, setSelectMissingProjectsIds] = useState("");
  const [selectTimesheetProjectsIds, setSelectTimesheetProjectsIds] = useState("");
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [skillsLength, setSkillsLength] = useState(0);
  const [opencreateSessionPopup,setOpencreateSessionPopup] = useState(false);
  const [timesheetIds,setTimeSheetDetailIDs] = useState([]);
  const [selectedTimesheetRows,setSelectedTimesheetRows] = useState([]);
  const [selectedClientInvoiceDetail, setSelectedClientInvoiceDetail] = useState(null);
  const [selectedProjectInvoiceDetail, setSelectedProjectInvoiceDetail] = useState(null);
  const [projectsForInvoiceDropdown, setProjectsForInvoiceDropdown] =  useState(null);
  const [clientsForInvoiceDropdown, setClientsForInvoiceDropdown] =  useState(null);
  const [startDateInvoice, setStartDateInvoice] = useState(null);
  const [endDateInvoice, setEndDateInvoice] = useState(null);

  const [openDialogBox, setOpenDialogBox] = useState(false);

  const inputRef = useRef("");

  const handleDialogBox = (res) => {
    setOpenDialogBox(!res);
  };

  useEffect(() => {
    if(startDate===null&&endDate===null){
      setDisablePeriodWeek(false);
    }
  }, [startDate,endDate]);


  const handleUserChangeForViewAttendance = (event) => {
     setSelectedUserID(event.target.value.id);
  
    setSelectedUser(event.target.value);
  };

  const handleClose = () => {
    setOpencreateSessionPopup(false);
    setOpenDialogBox(false);
  };

  const handleMultipleFileView = (res) => {
    setOpencreateSessionPopup(!res);
  };

  const getData = (event) => {
    if (headingName === Modules.REPORTING) {
      if(tabName === "ALLOCATION CHANGE REPORT BY PROJECT"){
        if(!selectedProjectId || (!startDate || !endDate)){
          toast.info(
            "Please Select Project ID , Start and End Date",
            toastOptions
          );
          return;
        }
      }
      if(tabName=== "ALLOCATION CHANGE REPORT BY EMPLOYEE"){
        if(!selectedUserIds ||(!startDate || !endDate)){
          toast.info(
            "Please Select User , Start and End Date",
            toastOptions
          )
        }
      }
      if (!disableDate && !disablePeriodWeek) {
        toast.info(
          "Please Select Period Week OR Start and End Date",
          toastOptions
        );
        return;
      }
      if (tabName === "TIMESHEET REPORTS" && !selectTimesheetProjectsIds) {
        toast.info("Please select projects", toastOptions);
        return;
      }
      if(tabName === "ALLOCATION CHANGE REPORT BY PROJECT" && !selectedProjectId) {
        toast.info("Please select projects", toastOptions);
        return;
      }
      if(tabName === "ALLOCATION CHANGE REPORT BY EMPLOYEE" && !selectedUserIds){
        toast.info("Please select user",toastOptions);
        return;
      }
      if(tabName === "ALLOCATION CHANGE REPORT BY PROJECT"){
      }
      if (!disableDate) {
        if (!startDate) {
          toast.info("Please Enter Start Date", toastOptions);
          return;
        } else if (!endDate) {
          toast.info("Please Enter End Date", toastOptions);
          return;
        } else {
          if (startDate.isValid() && endDate.isValid()) {
            if(tabName === "MISSING TIMESHEET"){
               dispatch(
                getMissingTimesheet({
                  startDate: startDate.format(DATE_TIME_FORMAT),
                  endDate: endDate.format(DATE_TIME_FORMAT),
                  projectId: selectMissingProjectsIds,
                })
              )
            }
            else if(tabName === "ALLOCATION CHANGE REPORT BY PROJECT"){
               dispatch(
                getAllocationChangeReport({
                  startDate: startDate.format(DATE_TIME_FORMAT),
                  endDate: endDate.format(DATE_TIME_FORMAT),
                  projectId: selectedProjectId,
                })
              )
            }
            else if(tabName === "ALLOCATION CHANGE REPORT BY EMPLOYEE"){
              dispatch(
                getAllocationChangeReportByEmployee({
                  employeeIds: selectedUserIds,
                  endDate: endDate.format(DATE_TIME_FORMAT),
                  startDate: startDate.format(DATE_TIME_FORMAT),
              })
              )
            }
            else{
              dispatch(
                getTimesheetReports({
                  startDate: startDate.format(DATE_TIME_FORMAT),
                  endDate: endDate.format(DATE_TIME_FORMAT),
                  projectId: selectTimesheetProjectsIds,
                })
              );
            }
          } else {
            toast.info("Please Enter Valid Date", toastOptions);
            return;
          }
        }
      }
      if (!disablePeriodWeek) {
        tabName === "MISSING TIMESHEET"
          ? dispatch(
            getMissingTimesheet({
              startDate: moment(selectedPeriodWeek.startDate).format(
                DATE_TIME_FORMAT
              ),
              endDate: moment(selectedPeriodWeek.endDate).format(
                DATE_TIME_FORMAT
              ),
              projectId: selectMissingProjectsIds,
            })
          )
          : dispatch(
            getTimesheetReports({
              startDate: moment(selectedPeriodWeek.startDate).format(
                DATE_TIME_FORMAT
              ),
              endDate: moment(selectedPeriodWeek.endDate).format(
                DATE_TIME_FORMAT
              ),
              projectId: selectTimesheetProjectsIds,
            })
          );
      }
    }
  };

  const getInvoiceDetailsData = (event) => {
      if(startDateInvoice && !endDateInvoice){
        toast.info(
          "Please Select End Date",
          toastOptions
        );
        return;
      }
      if(endDateInvoice && !startDateInvoice){
        toast.info(
          "Please Select Start Date",
          toastOptions
        );
        return;
      }
      dispatch(getInvoiceTrackerData({
        clientId: selectedClientInvoiceDetail?selectedClientInvoiceDetail.id: "",
        projectId: selectedProjectInvoiceDetail?selectedProjectInvoiceDetail.id: "",
        startDate: startDateInvoice? startDateInvoice.format(DATE_FORMAT): "",
        endDate: endDateInvoice?endDateInvoice.format(DATE_FORMAT): ""
      }));
  };

  const getSkillData = (event) => {
    if (headingName === Modules.SKILLS) {
      dispatch(
        getSkillDataGrid({
          "Streams": selectedStreamsIds,
          "SkillBuckets": selectedSkillsIds,
          "PrimarySecondarySkills": selectedPriSecSkillsIds
        })
      )
    }
  };

  const projectsTimesheetDropdown = () => {
    let projects = [];
    if (mappedProjectManagementData) {
      mappedProjectManagementData.forEach((client) => {
        client.projects.forEach((project) => {
          projects.push({
            projectName: project.projectName,
            projectId: project.projectId,
          });
        });
      });

      projects = projects.sort(function (a, b) {
        return a.projectName.localeCompare(b.projectName);
      });
    }
   
    return (
      <>
        <TextField
          select
          label={"Select Projects"}
          value={selectedTimesheetProjects}
          required
          sx={{
            minWidth: "20rem",
            marginRight: "1rem",
            "& label": {
              lineHeight: "1rem",
            },
          }}
          SelectProps={{
            multiple: true,  // Enable multiple selection
            onChange: (event) => {
              const selectedValues = event.target.value;
              setSelectedTimesheetProjects(selectedValues);
            },
          }}
        >
          {projects &&
            projects.map((option) => (
              <MenuItem
                key={option.projectId}
                value={option.projectName}
                required
                onClick={() => {
                  if(!selectTimesheetProjectsIds.includes(option.projectId)){
                  setSelectedTimesheetProjects((prev)=>[...prev,{
                    projectId: option.projectId,
                    projectName: option.projectName,
                  }])
                  setSelectTimesheetProjectsIds((prev)=>[...prev,option.projectId]);
                  // dispatch(setSelProjectsIds(option.projectId));
                  }else{
                    const newProjectIds=selectTimesheetProjectsIds.filter((id)=> id!==option.projectId);
                    setSelectTimesheetProjectsIds(newProjectIds);
                    const newProjects = selectedTimesheetProjects.filter((obj)=> obj.projectId!==option.projectId);
                    setSelectedTimesheetProjects(newProjects);
                 }
                }}
              >
            {option.projectName}
          </MenuItem>
            ))}
        </TextField>
      </>
    );
  };
  const projectsMissingDropdown = () => {
    let projects = [];
    if (mappedProjectManagementData) {
      mappedProjectManagementData.forEach((client) => {
        client.projects.forEach((project) => {
          projects.push({
            projectName: project.projectName,
            projectId: project.projectId,
          });
        });
      });

      projects = projects.sort(function (a, b) {
        return a.projectName.localeCompare(b.projectName);
      });
    }
   
    return (
      <>
        <TextField
          select
          label={"Select Projects"}
          value={selectedMissingProjects}
          required
          sx={{
            minWidth: "20rem",
            marginRight: "1rem",
            "& label": {
              lineHeight: "1rem",
            },
          }}
          SelectProps={{
            multiple: true,  // Enable multiple selection
            onChange: (event) => {
              const selectedValues = event.target.value;
              setSelectedMissingProjects(selectedValues);
            },
          }}
        >
          {projects &&
            projects.map((option) => (
              <MenuItem
                key={option.projectId}
                value={option.projectName}
                required
                onClick={() => {
                  if(!selectMissingProjectsIds.includes(option.projectId)){
                  setSelectedMissingProjects((prev)=>[...prev,{
                    projectId: option.projectId,
                    projectName: option.projectName,
                  }])
                  setSelectMissingProjectsIds((prev)=>[...prev,option.projectId]);
                  // dispatch(setSelProjectsIds(option.projectId));
                  }else{
                    const newProjectIds=selectMissingProjectsIds.filter((id)=> id!==option.projectId);
                    setSelectMissingProjectsIds(newProjectIds);
                    const newProjects = selectedMissingProjects.filter((obj)=> obj.projectId!==option.projectId);
                    setSelectedMissingProjects(newProjects);
                 }
                }}
              >
            {option.projectName}
          </MenuItem>
            ))}
        </TextField>
      </>
    );
  };
  const projectsDropdownAllocationReport = () => {
    let projects = [];
    if (mappedProjectManagementData) {
      mappedProjectManagementData.forEach((client) => {
        client.projects.forEach((project) => {
          projects.push({
            projectName: project.projectName,
            projectId: project.projectId,
          });
        });
      });

      projects = projects.sort(function (a, b) {
        return a.projectName.localeCompare(b.projectName);
      });
    }

    return (
      <>
        <TextField
          select
          label={"Projects"}
          value={selectedProject.projectName ? selectedProject.projectName : ""}
          required
          sx={{
            minWidth: "20rem",
            marginRight: "1rem",
            "& label": {
              lineHeight: "1rem",
            },
          }}
        >
          {projects &&
            projects.map((option) => (
              <MenuItem
                key={option.projectId}
                value={option.projectName}
                required
                onClick={() => {
                  setSelectedProject({
                    projectId: option.projectId,
                    projectName: option.projectName,
                  });
                  dispatch(setSelectedProjectId(option.projectId));
                }}
              >
            {option.projectName}
          </MenuItem>
            ))}
        </TextField>
      </>
    );
  };

const employeeDropdownAllocationChangeReport = () => {
  return(
    <>
      <div className="dropdownsBox">
              <label style={{marginRight: "1rem", marginTop:"-0.5rem"}}>Select Users</label>
              <FormControl sx={{width:200,marginTop:"-0.8rem", minWidth:"30rem", marginRight: "1rem", "& label":{lineHeight:"1rem"} }}>
                <InputLabel required id="demo-multiple-checkbox-label">
                  Select User
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedUsers}
                  onChange={handleUserChange}
                  input={<OutlinedInput label="Select User" />}
                  renderValue={getUsersNames}
                  MenuProps={MenuProps}
                >
                  {allUserDetails && (
                    <ListSubheader className="subheader">
                      <TextField
                        placeholder="Search Here..."
                        className="subheader-field"
                        onKeyDown={(e) => {
                          e.stopPropagation();
                        }}
                        autoFocus={true}
                        onChange={(e) => {
                          setUserMembers({
                            ...allUserDetails,
                            data: allUserDetails.data.filter(
                              (i) =>
                                i.firstName
                                  ?.toLowerCase()
                                  .includes(e.target.value.toLowerCase()) ||
                                i.lastName
                                  ?.toLowerCase()
                                  .includes(e.target.value.toLowerCase()) ||
                                i.email
                                  ?.toLowerCase()
                                  .includes(e.target.value.toLowerCase())
                            ),
                          });
                        }}
                      />
                    </ListSubheader>
                  )}
                  <div
                    disabled={!selectedUserIds.length}
                    style={{
                      textAlign: "right",
                      cursor: "pointer",
                      height: "2rem",
                    }}
                  >
                    <Link
                      onClick={() => {
                        setSelectedUsers([]);
                        setSelectedUserIds([]);
                        setSelectAll(false);
                      }}
                      style={{ color: "#1773bc", paddingRight: "1rem" }}
                    >
                      Clear Selection
                    </Link>
                  </div>
                  <MenuItem
                    key={`select-all`}
                    value={"Select All"}
                    className="no-left-margin"
                  >
                    <Checkbox checked={selectAll} />
                    <ListItemText
                      primary={selectAll ? "Select None" : "Select All"}
                    />
                  </MenuItem>
                  {userMembers &&
                    userMembers.data.map((user) => (
                      <MenuItem
                        key={user.id}
                        value={user}
                        className="no-left-margin"
                      >
                        <Checkbox
                          checked={
                            selectedUsers.findIndex(
                              (person) => person.id === user.id
                            ) > -1
                          }
                        />
                        <ListItemText
                          primary={
                            getFullName(user.firstName, user.lastName) +
                            " (" +
                            user.email +
                            ")"
                          }
                        />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
    </>
  )
}




  const createStartDateEndDateContainer = () => {
    return (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        sx={{
          "& label": {
            lineHeight: "0.8rem",
          },
        }}
      >

        <DatePicker
            style={{
              height:"3rem"
            }}
              
          label="Start Date"
          value={startDate}
          onChange={(newValue) => {
            setDisablePeriodWeek(true);
            setDisableDate(false);
            setStartDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              style={{
                width: "15rem",
              }}
              {...params}
            />
          )}
         // disabled={disableDate}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => {
            setDisablePeriodWeek(true);
            setDisableDate(false);
            setEndDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              style={{
                width: "15rem",
              }}
              {...params}
            />
          )}
          //disabled={disableDate}
        />
      </LocalizationProvider>
    );
  };

  const createStartDateEndDateContainerInvoiceDetails = () => {
    return (
      <div style={{display: 'flex'}}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        sx={{
          "& label": {
            lineHeight: "0.8rem",
          },
        }}
      >
        <div>
          <DatePicker
              style={{
                height:"3rem"
              }}
                
            label="Start Date"
            value={startDateInvoice}
            onChange={(newValue) => {
              setStartDateInvoice(newValue);
            }}
            renderInput={(params) => (
              <TextField
                style={{
                  width: "15rem",
                }}
                {...params}
              />
            )}
          />
         </div>
          <div style={{marginLeft: '1rem'}}>
            <DatePicker
              label="End Date"
              value={endDateInvoice}
              onChange={(newValue) => {
                setEndDateInvoice(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  style={{
                    width: "15rem",
                  }}
                  {...params}
                />
              )}
              //disabled={disableDate}
            />
          </div>
      </LocalizationProvider>
      </div>
    );
  };

  const createClearAndShowButton = () => {
    return (
      <>
        <button
          onClick={(event) => getData()}
          className={
            tabName === "TIMESHEET REPORTS" ? selectTimesheetProjectsIds ? "showDataBtn" : "disableAddButton" : "showDataBtn"
          }
        >
          Show Data
        </button>

        <button
          style={{ marginLeft: 0 }}
          className={
            disableDate || disablePeriodWeek
              ? "addBtn showDataBtn"
              : "disableAddButton"
          }
          onClick={() => {
            setDisableDate(false);
            setStartDate(null);
            setEndDate(null);
            setDisablePeriodWeek(false);
            dispatch(setSelectedProjectId(null));
            setSelectMissingProjectsIds("");
            setSelectTimesheetProjectsIds("");
            setSelectedMissingProjects([]);
            setSelectedTimesheetProjects([]);
            setSelectedProject({
              projectName: null,
              projectId: null,
            });
            // setSelectedPriodWeek({
            //   startDate : moment().startOf('isoweek'),
            //   endDate : moment().day() === 0 ? moment().startOf('week') : moment().add(7,'days').startOf('week')
            // });
          }}
        >
          Clear Selection
        </button>
      </>
    );
  };

  const createClearAndShowButtonForSkills = () => {
    return (
      <div className="skills-btns">
        <button
          style={{width: '16rem',marginleft: '0rem'}}
          onClick={(event) => getSkillData()}
          className={
            tabName === "SKILLS REPORT" ? selectedStreamsIds.length ||selectedSkillsIds.length || selectedPriSecSkillsIds.length ? "showDataBtn" : "disableAddButton" : "showDataBtn"
          }
        >
          Show Data
        </button>
        <button
          style={{ marginLeft: '0rem', width: '16rem' }}
          className={"addBtn showDataBtn"}
          onClick={() => {
            setSelectedStreams([]);
            setSelectedStreamsIds("");
            setSelectAllStreams(false);
            setSelectedSkills([]);
            setSelectedSkillsIds("");
            setSelectAll(false);
            setSelectedPriSecSkills([]);
            setSelectedPriSecSkillsIds("");
            setSelectAllPriSecSkill(false);
          }}
        >
          Clear Selection
        </button>
      </div>
    );
  };

  const createClearAndShowButtonForInvoiceTracker = () => {
    return (
      <div className="skills-btns">
        <button
          style={{width: '16rem'}}
          onClick={(event) => getInvoiceDetailsData()}
          className={
            selectedProjectInvoiceDetail||selectedClientInvoiceDetail ||startDateInvoice || endDateInvoice ? "showDataBtn" : "disableShowInvoiceDetailButton"
          }
        >
          Show Data
        </button>
        <button
          style={{ marginLeft: '0rem', width: '16rem' }}
          className={
            selectedProjectInvoiceDetail||selectedClientInvoiceDetail ||startDateInvoice || endDateInvoice ? "showDataBtn" : "disableShowInvoiceDetailButton"
          }
          onClick={() => {
            setStartDateInvoice(null);
            setEndDateInvoice(null);
            setSelectedClientInvoiceDetail(null);
            setSelectedProjectInvoiceDetail(null);
            dispatch(getInvoiceTrackerData({
              clientId: "",
              projectId: "", 
              startDate: "",
              endDate: ""
            }));
          }}
        >
          Clear Selection
        </button>
      </div>
    );
  };

  const FilterData = () => {
    return (
      <>
        <div className="filter-container">
          {tabName === "TIMESHEET REPORTS" && projectsTimesheetDropdown()}
          {tabName === "MISSING TIMESHEET" && projectsMissingDropdown()}
          {tabName === "ALLOCATION CHANGE REPORT BY PROJECT" && projectsDropdownAllocationReport()}
          {tabName ==="ALLOCATION CHANGE REPORT BY EMPLOYEE" && employeeDropdownAllocationChangeReport()}
          {periodWeek(true)}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
          {tabName !== "ALLOCATION CHANGE REPORT BY PROJECT" && tabName !== "ALLOCATION CHANGE REPORT BY EMPLOYEE" && (
  <span style={{ fontSize: "1.5rem", fontWeight: "600", color: "gray" }}>
    OR
  </span>
)}
          </div>
          {createStartDateEndDateContainer()}
          {/* {tabName === 'MISSING TIMESHEET' } */}
          {createClearAndShowButton()}
        </div>

        {tabName === "MISSING TIMESHEET" ? createSearchBoxforMissingTimesheet("Search") : <></>}
      </>
    );
  };

  const periodWeek = (flag) => {
    if(tabName === "ALLOCATION CHANGE REPORT BY PROJECT" || tabName=== "ALLOCATION CHANGE REPORT BY EMPLOYEE"){
    }else
    return (
      <Select
        IconComponent={CalendarMonthRounded}
        defaultValue={
          queryPeriodWeek !== null
            ? queryPeriodWeek.startDate.format(DATE_FORMAT) +
            " - " +
            queryPeriodWeek.endDate.format(DATE_FORMAT)
            : moment().startOf("isoweek").format(DATE_FORMAT) +
            " - " +
            moment().endOf("isoweek").format(DATE_FORMAT)
        }
        sx={{ minWidth: "15rem", maxHeight: "3rem"}}
        className={"select-date"}
        disabled={headingName === Modules.REPORTING ? disablePeriodWeek : false}
      >
        {getDateItems(flag)}
      </Select>
    );
  };

  const pendingApprovalReportessTab = () => {
    let timesheetDetailIDs;
    if(tabName === "PENDING APPROVAL"){
     timesheetDetailIDs = timeSheetData && handleSetRows(timeSheetData,"PENDING APPROVAL").map(row =>
      row[UniqueIds[headingName.replace(" ", "")]]
    ).join(',');
    
  }
  // setTimeSheetDetailIDs(timesheetDetailIDs);
    return (
      <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
      <div className="pendingApprovalDiv">
        <div className="filter-container">
        <TextField
          select
          label={"Projects"}
          value={selectedProject.projectName ? selectedProject.projectName : ""}
          sx={{
            minWidth: "15rem",
            "& label": {
              lineHeight: "0.8rem",
            },
          }}
        >
          {timeSheetProjects &&
            timeSheetProjects.map(
              (option) => (
                // data.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.name}
                  required
                  onClick={() => {
                    setSelectedProject({
                      projectId: option.id,
                      projectName: option.name,
                    });
                    dispatch(setSelectedProjectId(option.id));
                  }}
                >
                  {option.name}
                </MenuItem>
              )
              // ))
            )}
        </TextField>
        <TextField
          select
          label={"Employee Name"}
          value={
            selectedEmployee.employeeName ? selectedEmployee.employeeName : ""
          }
          sx={{
            minWidth: "15rem",
            "& label": {
              lineHeight: "1rem",
              marginTop: "-0.1rem",
            },
          }}
        >
          {reportees &&
            reportees.map((option) => (
              <MenuItem
                key={option.id}
                value={getFullName(option.firstName, option.lastName)}
                onClick={() => {
                  setSelectedEmployee({
                    employeeId: option.id,
                    employeeName: getFullName(
                      option.firstName,
                      option.lastName
                    ),
                  });
                  dispatch(setSelectedEmployeeId(option.id));
                }}
              >
                {getFullName(option.firstName, option.lastName)}
              </MenuItem>
            ))}
        </TextField>
        {tabName !== "PENDING APPROVAL" && periodWeek(false)}
        <button
          className={"addBtn showDataBtn"}
          onClick={() => {
            if (tabName === "REPORTEES") {
              dispatch(
                getTimeSheetReportee({
                  periodWeek:
                    selectedPeriodWeek.startDate.format(DATE_FORMAT) +
                    " - " +
                    selectedPeriodWeek.endDate.format(DATE_FORMAT),
                  projectId: selectedProject.projectId
                    ? selectedProject.projectId
                    : "",
                  employeeId: selectedEmployee.employeeId
                    ? selectedEmployee.employeeId
                    : "",
                })
              );
            } else {
              setSelectedTimesheetRows([]);
              dispatch(
                getTimeSheetData({
                  periodWeek: tabName === "PENDING APPROVAL"?"0":
                    (selectedPeriodWeek.startDate.format(DATE_FORMAT) +
                    " - " +
                    selectedPeriodWeek.endDate.format(DATE_FORMAT)),
                  employeeId: selectedEmployee.employeeId,
                  projectId: selectedProject.projectId,
                })
              );
            }
          }}
        >
          Show Data
        </button>
        <button
          className={
            selectedEmployee.employeeId || selectedProject.projectId
              ? "addBtn showDataBtn"
              : "disableAddButton"
          }
          onClick={() => {
            setSelectedEmployee({});
            dispatch(setSelectedEmployeeId(null));
            setSelectedProject({});
            dispatch(setSelectedProjectId(null));
          }}
        >
          Clear Selection
        </button>
        </div>
        {(tabName==="PENDING APPROVAL")&&
        <div className="c">
          <button 
           className={timesheetDetailIDs&&selectedTimesheetRows.length?"addBtn showDataBtn":"disableAddButton"}
            onClick={() => {
              if(selectedTimesheetRows){

                dispatch(updateTimeSheetStatus({
                  timesheetDetailIDs:selectedTimesheetRows.join(","),
                  timesheetstatus:"Approved"
                }))
                setSelectedTimesheetRows([]);
              }
            }}
            disabled={!timesheetDetailIDs||!selectedTimesheetRows}
          >
            Approve selected
          </button>
        </div>}
      </div>
      {tabName === "PENDING APPROVAL"&&timeSheetData&&rows&&
      <div className="bottomSpan">
        <span style={{color:"#808080"}}> Number of Pending Approvals :</span>
        <span style={{color:"#1773bc"}}>&nbsp;{rows.length}</span>
      </div>}
      </div>
    );
  };
  const myTimeSheetTab = () => {
    return (
      <div className="myTimeSheetTopBar">
        {timeSheetData && timeSheetData.length ? (
          <div className="searchWrapperText">
            <span className="searchWrapperSpan">
              Timesheet Period Status :{" "}
            </span>
            {timeSheetData[0].periodStatus}
          </div>
        ) : null}
        <div className="button-flex">
          {periodWeek(true)} 
          <button
          
            disabled={
              isAddButtonClicked ||
              isEditButtonClicked ||
              (timeSheetData &&
                timeSheetData.length &&
                timeSheetData[0].periodStatus !== "Open")
            }
            className={
              isAddButtonClicked ||
                isEditButtonClicked ||
                (timeSheetData &&
                  timeSheetData.length &&
                  timeSheetData[0].periodStatus !== "Open")
                ? "disableAddButton"
                : "addBtn"
            }
            onClick={() => setIsAddButtonClicked(true)}
          >
            Add
          </button>
          <button
            disabled={
              isAddButtonClicked ||
              isEditButtonClicked ||
              (timeSheetData &&
                timeSheetData.length &&
                getTimesheetStatus(timeSheetData))
            }
            className={
              isAddButtonClicked ||
                isEditButtonClicked ||
                (timeSheetData &&
                  timeSheetData.length &&
                  getTimesheetStatus(timeSheetData))
                ? "disableAddButtonSubmit"
                : "addBtnSubmit"
            }
            onClick={() => {
              let sum = 0;
              rows.forEach((row) => {
                if (row.status !== "Rejected") sum += parseFloat(row.totalHrs);
              });
              if (sum.toFixed(2) >= 40) dispatch(submitPeriodForApproval());
              else{
                if(timeSheetData && timeSheetData[0] && userData.data.joinDate>=timeSheetData[0].dateHours[0].date && userData.data.joinDate<=timeSheetData[0].dateHours[4].date){
                  const daysDifference = differenceInDays(parseISO(timeSheetData[0].dateHours[4].date), parseISO(userData.data.joinDate))
                  if (sum.toFixed(2) >= (daysDifference+1)*8){dispatch(submitPeriodForApproval());} 
                  else
                  toast.info(
                    `Total Hours must be greater than or equal to ${((daysDifference+1)*8)}`,
                    toastOptions
                  );
                }
                else
                toast.info(
                  "Total Hours must be greater than or equal to 40",
                  toastOptions
                );
              }
            }}
          >
            
          </button>
        </div>
      </div>
    );
  };
  const handleSetColumnsData = (data) => {
    const temp = [];
    let totalHrs = {};
    let status = {};
    if(headingName===Modules.TRAINING_AND_SESSION&&tabName==='TRAINING AND SESSION'){
      setColumns(tableColumnsData["TrainingAndSession"]);
    }
    else if (
      headingName !== Modules.TIMESHEET &&
      headingName !== Modules.REPORTING &&
      headingName !== Modules.PROJECT_ALLOCATION
    ) {
      if (headingName === Modules.PROJECT_ADMIN && userData.data.roleName === "Project Manager") {
        setColumns(tableColumnsData["ProjectAdminManager"]);
      }
      else {
        setColumns(tableColumnsData[headingName.replace(" ", "")]);
      }
    }
    else if (headingName === Modules.REPORTING) {
      setColumns(tableColumnsData[titleCase(tabName).replace(" ", "")]);
    } else {
      Object.keys(data).forEach((col) => {
        
        if (col === "dateHours" && tabName !== "REPORTEES" && tabName!== "PENDING APPROVAL") {
          data[col].forEach((dateHour, index) => {
            let month = {
              isDate: true,
            };
            const trimmedDate = dateHour.date.split('T')[0];
            if (index === 0 || moment(trimmedDate).format("DD") === "01")
              month["day"] = moment(trimmedDate).format("MMM");
            temp.push({
              id: moment(trimmedDate).format("ddd DD"),
              label: moment(trimmedDate).format("ddd DD"),
              date: moment(trimmedDate).format(),
              minWidth: 55,
              sortDir: "",
              align: "left",
              ...month,
            });
          });
        }else if (col === "periodWeek" && tabName === "PENDING APPROVAL"){
          temp.push({
            id: col,
            label: getLabel(col, 'PendingApproval'),
            minWidth:250,
            maxWidth:300,
            sortDir: "DESC",
            align: "left",
            isRequired: col.isRequired,
          })
        } else if(col === "dayHours" && tabName=== "PENDING APPROVAL"){
          data[col].forEach((dayHour, index) => {
            temp.push({
              id: dayHour.day,
              label: dayHour.day,
              minWidth: 55,
              sortDir: "",
              align: "left",
            });
          });
        } else if (col === "totalHrs") {
          totalHrs = {
            id: col,
            label: getLabel(col, headingName),
            minWidth: getMinWidth(col, headingName),
            sortDir: "DESC",
            align: "left",
          };
        } else if (
          col === "status" &&
          headingName === Modules.PROJECT_ALLOCATION &&
          tabName === "RESOURCE ALLOCATION"
        ) {
          temp.push({
            id: col,
            label: "Status",
            minWidth: 110,
            align: "left",
          });
        } else if (col === "status" && headingName === Modules.TIMESHEET) {
          if (tabName === "MY TIMESHEET" || tabName === "REPORTEES") {
            status = {
              id: col,
              label: getLabel(col, headingName),
              minWidth: getMinWidth(col, headingName),
              sortDir: "DESC",
              align: "left",
            };
          }
        } else if (!col.includes("Id") && getLabel(col, headingName) !== "") {
          temp.push({
            id: col,
            label: getLabel(col, headingName),
            minWidth: getMinWidth(col, headingName),
            sortDir: "DESC",
            align: "left",
            isRequired: col.isRequired,
          });
        } else if (col === "employeeId" && tabName !== "MY TIMESHEET") {
          temp.push({
            id: "employeeName",
            label:
              tabName === "PENDING APPROVAL" || tabName === "REPORTEES"
                ? "Employee Name"
                : getLabel("employeeName", headingName),
            minWidth:
              tabName === "PENDING APPROVAL" || tabName === "REPORTEES"
                ? "12rem"
                : getMinWidth("employeeName", headingName),
            sortDir: "DESC",
            align: "left",
          });
        } 
      });
      if (headingName === Modules.PROJECT_ALLOCATION) {
        if (tabName === "RESOURCE ALLOCATION") {
          temp.push({
            id: "actions",
            label: "View History",
            minWidth: 100,
            sortDir: "",
            align: "left",
          });
        }
        setColumns([...temp]);
      } else {
        if (totalHrs) {
          var removeByAttr = function (arr, attr, value) {
            var i = arr.length;
            while (i--) {
              if (
                arr[i] &&
                arr[i].hasOwnProperty(attr) &&
                arguments.length > 2 &&
                arr[i][attr] === value
              ) {
                arr.splice(i, 1);
              }
            }
            return arr;
          };
          if (tabName === "REPORTEES") {
            removeByAttr(temp, "id", "task");
            removeByAttr(temp, "id", "projectName");
            setColumns([
              ...temp,
              totalHrs,
              status,
              {
                id: "viewDetails",
                label: "View Details",
                minWidth: 80,
                type: "action",
              },
            ]);
          } else {
            setColumns([
              ...temp,
              totalHrs,
              {
                id: "actions",
                label: "Actions",
                minWidth: 60,
                sortDir: "",
                align: "left",
              },
              status,
            ]);
          }
        } else {
          setColumns([
            ...temp,
            {
              id: "actions",
              label: "Actions",
              minWidth: 100,
              sortDir: "",
              align: "left",
            },
          ]);
        }
      }
    }
  };

  const createSearchBox = (placeHolder) => {
    return (
      <div className="searchWrapper">
        <img src={searchIcon} className="searchIcon" alt="" />
        <input
          className="searchBox"
          type="search"
          placeholder={placeHolder}
          ref={inputRef}
          onChange={setSearchDataHelper}
        />
      </div>
    );
  };

  const createTopBarForSessionAndTraining = (placeHolder) => {
    if(userData?.data?.roleName === "Admin"){
    return(
      <div style={{ display:"flex", justifyContent:"space-between", width:"100%" }}>
        <div className="searchWrapper">
          <img src={searchIcon} className="searchIcon" alt="" />
          <input
            className="searchBox"
            type="search"
            placeholder={placeHolder}
            ref={inputRef}
            onChange={setSearchDataHelper}
          />
        </div>
        <div style={{ fontSize:'1.5rem',color:'#1773BC' }}>
          <button className="addBtn" onClick={() => {
              setOpencreateSessionPopup(true);
            }} 
          >
            Create Session
          </button>
        </div>
      </div>
    )}else {return(
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="searchWrapper">
          <img src={searchIcon} className="searchIcon" alt="" />
          <input
            className="searchBox"
            type="search"
            placeholder={placeHolder}
            ref={inputRef}
            onChange={setSearchDataHelper}
          />
        </div>
      </div>
    )
    }
  }

  const createSearchBoxforMissingTimesheet = (placeHolder) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="searchWrapper">
          <img src={searchIcon} className="searchIcon" alt="" />
          <input
            className="searchBox"
            type="search"
            placeholder={placeHolder}
            ref={inputRef}
            onChange={setSearchDataHelper}
          />
        </div>
        <div style={{ fontSize:'1.5rem', marginLeft:'90rem',color:'#1773BC' }}>Missing Timesheets : {missingReportData} </div>
      </div>
    );
  };

  const setTableData = (tableData) => {
    handleSetColumnsData(tableData.data[0]);
    setTotalRecord(tableData.totalCount);
  };

  const resetSearchData = () => {
    setSearchData("");
    if (inputRef && inputRef.current) inputRef.current.value = "";
  };

  const setSearchDataHelper = (e) => {
    if (headingName === Modules.REPORTING) {
      let value = e.target.value.toLowerCase();
      let searchedData = reportingData.filter((data) => {
        if (
          data.employeeName.toLowerCase().includes(value) ||
          data.periodWeek.toLowerCase().includes(value)
        ) {
          return data;
        }
      });
      setRows(searchedData);
      // setMissingReportData(searchedData.length);
      return;
    }
    if (
      (headingName !== Modules.REPORTING && e.target.value.length > 2) ||
      e.target.value.length === 0
    )
      {
        setSearchData(e.target.value);}
  };

  const periodWeekClickHandler = (fromMyTimeSheet, periodWeek) => {
    setSelectedPriodWeek(periodWeek);
    if (headingName === Modules.TIMESHEET) {
      if (fromMyTimeSheet) {
        dispatch(
          saveTimeSheetPeriodData({
            periodWeek:
              periodWeek.startDate.format(DATE_FORMAT) +
              " - " +
              periodWeek.endDate.format(DATE_FORMAT),
            startDT: periodWeek.startDate.format(),
            endDT: periodWeek.endDate.endOf("isoweek").format(),
          })
        );
        setRowToBeUpdated({});
        setIsAddButtonClicked(false);
        setIsEditButtonClicked(false);
      }
      let startDate = moment(periodWeek.startDate);
      let endDate = moment(periodWeek.endDate);
      if (tabName === "MY TIMESHEET")
        dispatch(
          getAssignedProjects({
            startDate: moment(startDate).format(DATE_TIME_FORMAT),
            endDate: moment(endDate).format(DATE_TIME_FORMAT),
          })
        );
      dispatch(
        setTimeSheetPeriodWeek(
          periodWeek.startDate.format(DATE_FORMAT) +
          " - " +
          periodWeek.endDate.format(DATE_FORMAT)
        )
      );
    } else if (headingName === Modules.REPORTING) {
      setDisableDate(true);
    }
  };
  const getDateItems = (fromMyTimeSheet) => {
    return periodWeeks.map((periodWeek) => (
      <MenuItem
        value={
          periodWeek.startDate.format(DATE_FORMAT) +
          " - " +
          periodWeek.endDate.format(DATE_FORMAT)
        }
        onClick={() => periodWeekClickHandler(fromMyTimeSheet, periodWeek)}
      >
        {periodWeek.startDate.format(DATE_FORMAT) +
          " - " +
          periodWeek.endDate.format(DATE_FORMAT)}
      </MenuItem>
    ));
  };

  const getTimesheetStatus = (timeSheetData) => {
    for (let i = 0; i < timeSheetData.length; i++) {
      if (timeSheetData[i].status === "Open") return false;
    }
    return true;
  };

  useEffect(() => {
    dispatch(setListItems(null))
    
    dispatch(
      setTimeSheetPeriodWeek(
        `${selectedPeriodWeek.startDate.format(
          DATE_FORMAT
        )} - ${selectedPeriodWeek.endDate.format(DATE_FORMAT)}`
      )
    );
    if (
      timeSheetData === null &&
      headingName === Modules.TIMESHEET &&
      tabName === "MY TIMESHEET" &&
      queryPeriodWeek === null
    ) {
      dispatch(
        saveTimeSheetPeriodData({
          periodWeek:
            moment().startOf("isoweek").format(DATE_FORMAT) +
            " - " +
            moment().endOf("isoweek").format(DATE_FORMAT),
          startDT: moment().startOf("isoweek").format(),
          endDT: moment().endOf("isoweek").format(),
        })
      );
      dispatch(
        setTimeSheetPeriodWeek(
          moment().startOf("isoweek").format(DATE_FORMAT) +
          " - " +
          moment().endOf("isoweek").format(DATE_FORMAT)
        )
      );
      setRowToBeUpdated({});
      setIsAddButtonClicked(false);
      setIsEditButtonClicked(false);
    }
    if (headingName === Modules.TIMESHEET && tabName === "MY TIMESHEET") {
      dispatch(
        getMyTimeSheetData({
          periodWeek:
            selectedPeriodWeek.startDate.format(DATE_FORMAT) +
            " - " +
            selectedPeriodWeek.endDate.format(DATE_FORMAT),
        })
      );
    } else if (headingName === Modules.TIMESHEET) {
      if (tabName === "REPORTEES") {
        dispatch(
          getTimeSheetReportee({
            periodWeek:
              selectedPeriodWeek.startDate.format(DATE_FORMAT) +
              " - " +
              selectedPeriodWeek.endDate.format(DATE_FORMAT),
            projectId: "",
            employeeId: "",
          })
        );
      } else {
        dispatch(
          getTimeSheetData({
            periodWeek: tabName === "PENDING APPROVAL"?"0":
              selectedPeriodWeek.startDate.format(DATE_FORMAT) +
              " - " +
              selectedPeriodWeek.endDate.format(DATE_FORMAT),
            employeeId: null,
            projectId: null,
          })
        );
      }
    }

    if (headingName === Modules.REPORTING) {
      if (tabName === "MISSING TIMESHEET") {
        dispatch(getNotifications());
      }
      else if (tabName === "TIMESHEET REPORTS") {
        if (selectTimesheetProjectsIds)
          dispatch(
            getTimesheetReports({
              startDate: moment(selectedPeriodWeek.endDate).format(
                DATE_TIME_FORMAT
              ),
              endDate: moment(selectedPeriodWeek.endDate).format(
                DATE_TIME_FORMAT
              ),
              projectId: selectTimesheetProjectsIds,
            })
          );
        dispatch(getMappedProjectManagementData());
        setDisableDate(true);
        setDisablePeriodWeek(false);
      }else if(tabName === "ALLOCATION CHANGE REPORT BY PROJECT"){
        dispatch(
          setAllocationChangeReportData([])
        )
      }else if(tabName === "ALLOCATION CHANGE REPORT BY EMPLOYEE"){
        dispatch(
          setAllocationChangeReportByEmployee([])
        );
      }
    }
    if (
      headingName === Modules.PROJECT_ALLOCATION &&
      tabName === "RESOURCE ALLOCATION"
    ) {
      setRows([]);
    }
    if(headingName === Modules.VIEW_ATTENDANCE){
      dispatch(setViewAttendanceData([]))
    }
  }, [tabName]);

  useEffect(()=>{
    if(headingName === Modules.REPORTING){
     dispatch(setReportingData(null));
    }
    // if(headingName === Modules.TRAINING_AND_SESSION && tabName === 'TRAINING AND SESSION'){
    //   dispatch(getAllSessionsData())
    // }
    if(headingName === Modules.TRAINING_AND_SESSION && tabName ==='SESSION HISTORY'){
      dispatch(getSessionUserHistory())
    }
    if(headingName === Modules.TRAINING_AND_SESSION){
      dispatch(getSessionFeedbackQuestionsList())
    }
  },[tabName]);

  useEffect(() => {
    if (
      headingName === Modules.CLIENT_ADMIN &&
      clientAdminData &&
      clientAdminData.totalCount
    ) {
      setTableData(clientAdminData);
      setRows(clientAdminData.data);
    }
    else if (
      headingName === Modules.TRAINING_AND_SESSION&&tabName==="TRAINING AND SESSION"&&
      sessionDetails&&sessionDetails.totalCount
    ) {
      setTableData(sessionDetails);
      setRows(sessionDetails.data);
    }
    else if (
      headingName === Modules.TRAINING_AND_SESSION && tabName === "SESSION HISTORY"
    ) {
      if(userData.data.roleName==="Admin")
        setColumns(tableColumnsData["SessionHistoryAdmin"]);
      else
        setColumns(tableColumnsData["SessionHistory"]);
        setRows(sessionUserHistoryData);
    }
    else if (
      headingName === Modules.PROJECT_ADMIN &&
      projectAdminData &&
      projectAdminData.totalCount
    ) {
      setTableData(projectAdminData);
      setRows(projectAdminData.data);
    } else if (
      headingName === Modules.PROJECT_ALLOCATION &&
      projectAllocationData &&
      projectAllocationData.totalCount
    ) {
      setTableData(projectAllocationData);
      setRows(projectAllocationData.data);
    } else if (
      headingName === Modules.TIMESHEET &&
      timeSheetData &&
      timeSheetData.length
    ) {
      handleSetColumnsData(timeSheetData[0]);
      setTotalRecord(timeSheetData.length);
      setRows(handleSetRows(timeSheetData, tabName));
      setIsAddButtonClicked(false);
    } else if (
      headingName === Modules.PROJECT_MANAGEMENT &&
      projectManagementData &&
      projectManagementData.totalCount
    ) {
      setTableData(projectManagementData);
      setRows(projectManagementData.data);
    } 
      else if (headingName === Modules.PROJECT_MANAGEMENT && (!projectManagementData || projectManagementData.totalCount === 0) ) { 
      setColumns(tableColumnsData[headingName.replace(" ", "")])
      setRows(null)
    } else if (
      headingName === Modules.REPORTING )
      {
      if(reportingData &&reportingData.length){
        setColumns(tableColumnsData[titleCase(tabName).replace(" ", "")]);
        setRows(reportingData);
        setMissingReportData(reportingData.length);
      }else{
        setRows("");
        setMissingReportData(0);
      }
      
    } else if (
      headingName === Modules.SKILLS &&
      skillsList &&
      skillsList.length
    ) {
      setColumns(tableColumnsData[titleCase(tabName).replace(" ", "")]);
      setRows(skillsList);
      setSkillsLength(skillsList.length);
    } else if (
      headingName === Modules.INVOICE_TRACKER &&
      invoiceTrackerData &&
      invoiceTrackerData.totalCount
    ) {
      setTableData(invoiceTrackerData);
      setRows(invoiceTrackerData.data);
    } else {
      if (tableColumnsData[headingName.replace(" ", "")]) {
        let temp = [...tableColumnsData[headingName.replace(" ", "")]];
        if (headingName === Modules.TIMESHEET && tabName === "MY TIMESHEET") {
          status = temp.pop();
        }
        if (headingName === Modules.TIMESHEET) {
          let totalHrs = temp.pop();
          let date = moment(selectedPeriodWeek.startDate);
          if (tabName !== "REPORTEES" && tabName!== "PENDING APPROVAL"){
            for (let i = 0; i < 7; i++) {
              if (i === 0 || moment(date).format("DD") == "01") {
                let tempDate = moment(date).format();
                let month = {
                  isDate: true,
                };
                month["day"] = moment(tempDate).format("MMM");
                temp.push({
                  id: moment(date).format("ddd DD"),
                  label: moment(date).format("ddd DD"),
                  date: moment(date).format(),
                  minWidth: 55,
                  sortDir: "",
                  align: "left",
                  isDate: true,
                  ...month,
                });
              } else {
                temp.push({
                  id: moment(date).format("ddd DD"),
                  label: moment(date).format("ddd DD"),
                  date: moment(date).format(),
                  minWidth: 55,
                  sortDir: "",
                  align: "left",
                  isDate: true,
                });
              }
              date.add(1, "days");
            }
          temp.push(totalHrs);
          }
          if(tabName==="PENDING APPROVAL"){
            let temp1 = [...tableColumnsData["PendingApproval"]];
            let totalHrs=temp1.pop();
            const weekDay=["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];
            for(let i=0;i<7;i++){
              temp1.push({
                id:weekDay[i],
                label:weekDay[i],
                minWidth:55,
                sortDir: "",
                align: "left"
              })
            }
            temp1.push(totalHrs);
            setColumns([
              ...temp1
            ]);
        }
        
        if (headingName === Modules.TIMESHEET) {
          if(tabName!=="PENDING APPROVAL"){
            setColumns([
              ...temp,
              {
                id: "actions",
                label: "Actions",
                minWidth: 100,
                sortDir: "",
                align: "left",
                isRequired: true,
              },
            ]);
          }
        } else {
          setColumns([...temp]);
        }
      } else if (tableColumnsData[titleCase(tabName).replace(" ", "")]) {
        setColumns(tableColumnsData[titleCase(tabName).replace(" ", "")]);
      } else {
        setColumns([]);
      }
      setRows([]);
      setTotalRecord(0);
      }
    }
  }, [
    clientAdminData,
    projectAdminData,
    projectAllocationData,
    projectManagementData,
    timeSheetData,
    reportingData,
    missingReportData,
    invoiceTrackerData,
    headingName,
    skillsList,
    sessionDetails,
    sessionUserHistoryData,
  ]);
  // useEffect(()=>{
  //   if(tabName==='TRAINING AND SESSION'&&sessionDetails){
  //     console.log(2);
  //     setTableData(sessionDetails);
  //     setRows(sessionDetails.data);
  //     console.log(sessionDetails.data);
  //     for(let i=0;i<sessionDetails.data.length;i++){
  //       rows.push(sessionDetails.data[i]);
  //     }
  //     console.log(rows);
  //     // setColumns(sessionDetails);
  //   }
  // },[headingName,tabName]);

  useEffect(() => {
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    setCurrentMonth(month);
    setCurrentYear(year);
  }, [headingName]);

  useEffect(() => {
    switch (headingName) {
      case Modules.CLIENT_ADMIN:
        dispatch(
          getClientAdminData({
            pageNo: 1,
            pageSize: 10,
            sortBy: "clientName",
            sortDir: "ASC",
            searchData: searchData,
          })
        );
        break;
      case Modules.PROJECT_ADMIN:
        dispatch(
          getProjectAdminData({
            pageNo: 1,
            pageSize: 10,
            sortBy: "projectName",
            sortDir: "ASC",
            searchData: searchData,
            expiredAllocations: expiredAllocations
          })
        );
        break;
      case Modules.PROJECT_ALLOCATION:
        if (tabName !== "RESOURCE ALLOCATION") {
          dispatch(
            getProjectAllocationData({
              pageNo: 1,
              pageSize: 10,
              sortBy: "projectName",
              sortDir: "ASC",
              searchData: searchData,
              status: status,
              employeeID: "",
            })
          );
        }
        break;
      case Modules.PROJECT_MANAGEMENT:
        dispatch(
          getProjectManagementData({
            projectId: projectId,
            pageNo: 1,
            pageSize: 10,
            sortBy: "projectName",
            sortDir: "ASC",
            searchData: searchData,
            billStatus: billStatus,
            expiredAllocations: expiredAllocations
          })
        );
        dispatch(
          getResourcingData({projectId:projectId})
        );
        break;
      case Modules.REPORTING:
        if (tabName === "MISSING TIMESHEET") {
          
          dispatch(
            getMissingTimesheet({
              startDate: moment(moment().startOf("isoweek")).format(
                DATE_TIME_FORMAT
              ),
              endDate: moment(moment().endOf("isoweek")).format(
                DATE_TIME_FORMAT
              ),
              projectId: selectMissingProjectsIds,
            })
          );
          dispatch(getMappedProjectManagementData());
          setDisableDate(true);
        }
        break;
      case Modules.TIMESHEET:
        if (tabName !== "MY TIMESHEET") dispatch(getTimesheetProjects());
        break;
      case Modules.INVOICE_TRACKER:
        if (tabName === "INVOICE DETAILS") {
          dispatch(getInvoiceTrackerData({
            clientId: selectedClientInvoiceDetail?selectedClientInvoiceDetail.id: "",
            projectId: selectedProjectInvoiceDetail?selectedProjectInvoiceDetail.id: "",
            startDate: startDateInvoice? startDateInvoice.format(DATE_FORMAT): "",
            endDate: endDateInvoice?endDateInvoice.format(DATE_FORMAT): ""
          }));
        }
        break;
      case Modules.VIEW_ATTENDANCE:
        if(tabName === "VIEW ATTENDANCE")
        dispatch(
          getViewAttendanceData({
            month: currentMonth + 1,
            year: currentYear
          })
        );
        break;
      case Modules.TRAINING_AND_SESSION:
        if(tabName === "TRAINING AND SESSION")
        dispatch(getAllSessionsData({searchData: searchData}))
      default:
        break;
    }
  }, [headingName, searchData]);

 useEffect(() => {
    if(headingName === Modules.SKILLS){
      if (listItems && listItems.Streams && listItems.Streams.length) {
        setUserStreams(listItems.Streams);
      }
      if (listItems && listItems.Skills && listItems.Skills.length) {
        setUserSkills(listItems.Skills);
      }
      if (listItems && listItems.PriSecSkills && listItems.PriSecSkills.length) {
        setUserPriSecSkills(listItems.PriSecSkills);
      }
    }
  }, [listItems]);

  useEffect(() => {
    setRows([]);
    if (clientsData === null && headingName === Modules.PROJECT_ADMIN) {
      dispatch(getClientsData());
    }
    if (
      headingName === Modules.PROJECT_ADMIN ||
      headingName === Modules.CLIENT_ADMIN ||
      headingName === Modules.SKILLS
    ) {
      dispatch(
        getListItems({
          headingName: headingName,
        })
      );
    }
    if (headingName === Modules.TRAINING_AND_SESSION && tabName === 'TRAINING AND SESSION' && userData.data.roleName==='Admin') {
      dispatch(
        getListItems({
          headingName: Modules.SKILLS,
        }));
    }
    if (
      (headingName === Modules.TIMESHEET && tabName === "MY TIMESHEET") ||
      (headingName === Modules.PROJECT_MANAGEMENT &&
        projectManagementData === null)
    ) {
      let startDate = moment(selectedPeriodWeek.startDate);
      let endDate = moment(selectedPeriodWeek.endDate);
      dispatch(
        getAssignedProjects({
          startDate: moment(startDate).format(DATE_TIME_FORMAT),
          endDate: moment(endDate).format(DATE_TIME_FORMAT),
        })
      );
    }
    if (headingName === Modules.TIMESHEET && tabName !== "MY TIMESHEET") {
      if (selectedProjectId) {
        dispatch(getReportees(selectedProjectId));
      } else {
        dispatch(getReportees(null));
      }
    }
    if (
      headingName === Modules.INVOICE_TRACKER
    ) {
      dispatch(getMappedProjectManagementData());
    }
    resetSearchData();
    setIsAddButtonClicked(false);
    setIsEditButtonClicked(false);
    if (headingName !== Modules.TIMESHEET) {
      dispatch(setSelectedEmployeeId(null));
      dispatch(setSelectedProjectId(null));
    }

    if (headingName === Modules.PROJECT_MANAGEMENT) {
      dispatch(
        getListItems({
          headingName: Modules.PROJECT_MANAGEMENT,
        })
      );
    }
    if (headingName === Modules.INVOICE_TRACKER) {
      dispatch(
        getListItems({
          headingName: Modules.INVOICE_TRACKER,
        })
      );
    }
    setSelectedPriSecSkills([]);
    setSelectedSkills([]);
    setSelectedStreams([]);
    setSelectedPriodWeek(
      queryPeriodWeek !== null
      ? queryPeriodWeek
      : {
        startDate: moment().startOf("isoweek"),
        endDate: moment().endOf("isoweek"),
      });
  }, [headingName]);

  useEffect(() => {
    if (allTasks === null) dispatch(getProjectTasks());
    if (allUserDetails === null) dispatch(getAllUserDetails());
  }, []);

  useEffect(() => {
    if (
      selectedProjectData &&
      headingName === Modules.INVOICE_TRACKER &&
      tabName === "CREATE INVOICE" &&
      isAddButtonClicked
    ) {
      let rowsCount = Math.round(
        moment(selectedProjectData.endDate).diff(
          selectedProjectData.startDate,
          "months",
          true
        )
      );
      let startDate = moment(selectedProjectData.startDate);
      let rowsCountList = [];
      let rows = [];
      let invoicedMonthsList = invoicedMonths?.split(",");
      let invoicedDict = {};
      for (let i = 0; i < invoicedMonthsList.length; i++) {
        invoicedDict[invoicedMonthsList[i]] = true;
      }
      for (let i = 0; i < rowsCount; i++) {
        if (!invoicedDict[moment(startDate).format("MMM")]) {
          let row = {
            clientId: selectedProjectData?.clientId,
            projectId: selectedProjectData?.projectId,
            clientName: selectedProjectData?.clientName,
            projectName: selectedProjectData?.projectName,
            billStartDate: startDate.format(DATE_FORMAT),
            billEndDate: moment
              .min([
                moment(startDate).endOf("months"),
                moment(selectedProjectData.endDate),
              ])
              .format(DATE_FORMAT),
            billMonth: moment(startDate).format("MMM"),
            billAmount: "",
            invoiceStatus: "Created",
          };
          rows.push(row);
          rowsCountList.push({ id: "done" });
        }
        startDate = moment(startDate).startOf("months").add(1, "months");
      }
      setRows(rows);
    }
  }, [selectedProjectData, isAddButtonClicked]);

  useEffect(() => {
    if(skillsList && skillsList.length){
    setSkillsLength(skillsList.length);
    }
    else{
      setSkillsLength(0);
    }
  }, [skillsList]);

  useEffect(() => {
    if (mappedProjectManagementData && mappedProjectManagementData.length) {
      const dropdownClients = mappedProjectManagementData.map((data) => {
        return {
          name: data.clientName,
          id: data.clientId,
        };
      });

      setFilterClientDropdownData(dropdownClients);
      setClientsForInvoiceDropdown(dropdownClients);

      const dropdownProjects = mappedProjectManagementData.flatMap((data) => 
        data.projects.map((project) => ({
          name: project.projectName,
          id: project.projectId,
        }))
      );
      setProjectsForInvoiceDropdown(dropdownProjects);
    }
  }, [mappedProjectManagementData]);

  useEffect(() => {
    if (selectedClient) {
      const dropdownProjects = mappedProjectManagementData
        .filter((data) => data.clientId === selectedClient.id)[0]
        .projects?.map((project) => {
          return {
            name: project.projectName,
            id: project.projectId,
          };
        });
      setFilterProjectDropdownData(dropdownProjects);
    }
  }, [selectedClient]);

  useEffect(() => {
    if(mappedProjectManagementData){
      if (selectedClientInvoiceDetail) {
        const dropdownProjects = mappedProjectManagementData
          .filter((data) => data.clientId === selectedClientInvoiceDetail.id)[0]
          .projects?.map((project) => {
            return {
              name: project.projectName,
              id: project.projectId,
            };
          });
        setProjectsForInvoiceDropdown(dropdownProjects);
      }else{
        const dropdownProjects = mappedProjectManagementData.flatMap((data) => 
          data.projects.map((project) => ({
            name: project.projectName,
            id: project.projectId,
          }))
        );
        setProjectsForInvoiceDropdown(dropdownProjects);
      }
    }
  }, [selectedClientInvoiceDetail]);

  useEffect(() => {
    if (selectedClientProject) {
      dispatch(getProjectById({ projectId: selectedClientProject?.id }));
      dispatch(
        getInvoicedMonths({
          projectId: selectedClientProject?.id,
        })
      );
    }
  }, [selectedClientProject]);

  const createTopBarForInvoice = () => {
    return (
      <div className="invoiceTopBar">
        <div className="invoiceTrackerDropdownBox">
          <label>Please Select Client</label>
          <FormControl style={{ minWidth: 400 }}>
            <InputLabel
              required
              className="select-label"
              id={`label-for-Client`}
            >
              Client Name
            </InputLabel>
            <Select
              id="outlined-select-currency"
              labelId={`label-for-Client`}
              label="Client Name"
              value={selectedClient?.name ? selectedClient.name : ""}
              required
              className="select-input"
              MenuProps={{
                autoFocus: false,
                PaperProps: { sx: { maxHeight: 300 } },
              }}
            >
              {filterClientDropdownData &&
                filterClientDropdownData.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.name}
                    onClick={() => {
                      setSelectedClient(option);
                      setSelectedClientProject(null);
                      // setIsAddButtonClicked(true);
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="invoiceTrackerDropdownBox">
          <label>Please Select Project</label>
          <FormControl style={{ minWidth: 400 }}>
            <InputLabel required id="demo-multiple-checkbox-label">
              Projects
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              label="Projects"
              value={
                selectedClientProject?.name ? selectedClientProject.name : ""
              }
              disabled={selectedClient === null}
              className="select-input"
            >
              {filterProjectDropdownData &&
                filterProjectDropdownData.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.name}
                    onClick={() => {
                      setSelectedClientProject({
                        id: option.id,
                        name: option.name,
                      });
                      setIsAddButtonClicked(false);
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div>{createAddButton("Create Invoice")}</div>
      </div>
    );
  };

  const createTopBarForInvoice_Details = () => {
    return (
      <div className="invoice_DetailsTopBar" >
        <div className="invoiceTrackerDropdownBox">
          <label>Select Client</label>
          <FormControl style={{ minWidth: '17.5rem' }}>
            <InputLabel
              required
              className="select-label"
              id={`label-for-Client`}
            >
              Client Name
            </InputLabel>
            <Select
              id="outlined-select-currency"
              labelId={`label-for-Client`}
              label="Client Name"
              value={selectedClientInvoiceDetail?.name ? selectedClientInvoiceDetail.name : ""}
              required
              className="select-input"
              MenuProps={{
                autoFocus: false,
                PaperProps: { sx: { maxHeight: 300 } },
              }}
            >
              {clientsForInvoiceDropdown &&
                clientsForInvoiceDropdown.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.name}
                    onClick={() => {
                      setSelectedClientInvoiceDetail(option);
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="invoiceTrackerDropdownBox">
          <label>Select Project</label>
          <FormControl style={{ minWidth: '17.5rem' }}>
            <InputLabel required id="demo-multiple-checkbox-label">
              Projects
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              label="Projects"
              value={
                selectedProjectInvoiceDetail?.name ? selectedProjectInvoiceDetail.name : ""
              }
              className="select-input"
            >
              {projectsForInvoiceDropdown &&
                projectsForInvoiceDropdown.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.name}
                    onClick={() => {
                      setSelectedProjectInvoiceDetail({
                        id: option.id,
                        name: option.name,
                      });
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <label style={{marginLeft: '0.5rem',marginTop : '0.85rem'}}>Select Period</label>
        <div style={{marginLeft:'2 rem'}}>{createStartDateEndDateContainerInvoiceDetails()}</div>
        <div className="invoiceDetailsClearAndShowButton">
          {createClearAndShowButtonForInvoiceTracker()}
        </div>
      </div>
    );
  };

  const handleUserChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.findIndex((val) => val === "Select All") > -1) {
      if (selectAll) {
        setSelectedUsers([]);
        setSelectedUserIds([]);
      } else {
        setSelectedUsers(allUserDetails.data);
        let ids = allUserDetails.data.map((user) => {
          return user.id;
        });
        setSelectedUserIds(ids.join(","));
      }
      setSelectAll(!selectAll);
    } else {
      setSelectedUsers(value);
      let ids = value.map((user) => {
        return user.id;
      });
      setSelectedUserIds(ids.join(","));
    }
  };

  const handleUserChangeSkills = (event) => {
    const {
      target: { value },
    } = event;
    if (value.findIndex((val) => val === "Select All") > -1) {
      if (selectAll) {
        setSelectedSkills([]);
        setSelectedSkillsIds("");
      } else {
        setSelectedSkills(listItems.Skills);
        let ids = listItems.Skills?.map((user) => {
          return user.longCodeValue;
        });
        setSelectedSkillsIds(ids.join(","));
      }
      setSelectAll(!selectAll);
    } else {
      setSelectedSkills(value);
      let ids = value.map((user) => {
        return user.longCodeValue;
      });
      setSelectedSkillsIds(ids.join(","));
    }
  };

  const handleUserChangePriSecSkills = (event) => {
    const {
      target: { value },
    } = event;
    if (value.findIndex((val) => val === "Select All") > -1) {
      if (selectAllPriSecSkill) {
        setSelectedPriSecSkills([]);
        setSelectedPriSecSkillsIds("");
      } else {
        setSelectedPriSecSkills(listItems.PriSecSkills);
        let ids = listItems.PriSecSkills?.map((user) => {
          return user.longCodeValue;
        });
        setSelectedPriSecSkillsIds(ids.join(","));
      }
      setSelectAllPriSecSkill(!selectAllPriSecSkill);
    } else {
      setSelectedPriSecSkills(value);
      let ids = value.map((user) => {
        return user.longCodeValue;
      });
      setSelectedPriSecSkillsIds(ids.join(","));
    }
  };
  const handleUserChangeStreams = (event) => {
    const {
      target: { value },
    } = event;
    if (value.findIndex((val) => val === "Select All") > -1) {
      if (selectAllStreams) {
        setSelectedStreams([]);
        setSelectedStreamsIds("");
      } else {
        setSelectedStreams(listItems.Skills);
        let ids = listItems.Skills?.map((user) => {
          return user.longCodeValue;
        });
        setSelectedStreamsIds(ids.join(","));
      }
      setSelectAllStreams(!selectAllStreams);
    } else {
      setSelectedStreams(value);
      let ids = value.map((user) => {
        return user.longCodeValue;
      });
      setSelectedStreamsIds(ids.join(","));
    }
  };
  const getStreamsName = () => {
    let selectedStreamsName = "";
    if (selectedStreams && selectedStreams.length) {
      for (let i = 0; i < selectedStreams.length; i++) {
        selectedStreamsName += `${selectedStreams[i].longCodeValue}`;
        if (i !== selectedStreams.length - 1) selectedStreamsName += ", ";
      }
    }
    return selectedStreamsName;
  };

  const getPriSecSkillsName = () => {
    let selectedPriSecSkillsName = "";
    if (selectedPriSecSkills && selectedPriSecSkills.length) {
      for (let i = 0; i < selectedPriSecSkills.length; i++) {
        selectedPriSecSkillsName += `${selectedPriSecSkills[i].longCodeValue}`;
        if (i !== selectedPriSecSkills.length - 1) selectedPriSecSkillsName += ", ";
      }
    }
    return selectedPriSecSkillsName;
  };

  const getSkillsName = () => {
    let selectedSkillsName = "";
    if (selectedSkills && selectedSkills.length) {
      for (let i = 0; i < selectedSkills.length; i++) {
        selectedSkillsName += `${selectedSkills[i].longCodeValue}`;
        if (i !== selectedSkills.length - 1) selectedSkillsName += ", ";
      }
    }
    return selectedSkillsName;
  };

  const getUsersNames = () => {
    let selectedNames = "";
    if (selectedUsers && selectedUsers.length) {
      for (let i = 0; i < selectedUsers.length; i++) {
        if (selectedUsers[i].lastName) {
          selectedNames += `${selectedUsers[i].firstName} ${selectedUsers[i].lastName}`;
        } else {
          selectedNames += selectedUsers[i].firstName;
        }
        if (i !== selectedUsers.length - 1) selectedNames += ", ";
      }
    }
    return selectedNames;
  };

  const handleChangeForHistoryInProjectManagement = (value)=>{
    setExpiredAllocations(value=== "false");
  }

  const handleChangeForHistoryInProjectAdmin = (value)=>{
    setExpiredAllocations(value=== "false");
  }

  const handleChangeForHistory = (value) => {
    setIsHistory(value === "false");
  };

  const getUserProjectAllocations = () => {
    dispatch(
      getUsersProjectAllocation({
        employeeIds: selectedUserIds,
        isHistory: isHistory,
      })
    );
  };

  useEffect(() => {
    if (allUserDetails && allUserDetails.length) {
      setUserMembers({ ...allUserDetails, data: allUserDetails.data });
    }
  }, [allUserDetails]);

  const createTopBarForProjectAllocation = () => {
    return (
      <>
        <div className="allocationTopBar">
          <div>
            <div className="dropdownsBox">
              <label>Select Resources for Allocations</label>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel required id="demo-multiple-checkbox-label">
                  Select User
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedUsers}
                  onChange={handleUserChange}
                  input={<OutlinedInput label="Select User" />}
                  renderValue={getUsersNames}
                  MenuProps={MenuProps}
                >
                  {allUserDetails && (
                    <ListSubheader className="subheader">
                      <TextField
                        placeholder="Search Here..."
                        className="subheader-field"
                        onKeyDown={(e) => {
                          e.stopPropagation();
                        }}
                        autoFocus={true}
                        onChange={(e) => {
                          setUserMembers({
                            ...allUserDetails,
                            data: allUserDetails.data.filter(
                              (i) =>
                                i.firstName
                                  ?.toLowerCase()
                                  .includes(e.target.value.toLowerCase()) ||
                                i.lastName
                                  ?.toLowerCase()
                                  .includes(e.target.value.toLowerCase()) ||
                                i.email
                                  ?.toLowerCase()
                                  .includes(e.target.value.toLowerCase())
                            ),
                          });
                        }}
                      />
                    </ListSubheader>
                  )}
                  <div
                    disabled={!selectedUserIds.length}
                    style={{
                      textAlign: "right",
                      cursor: "pointer",
                      height: "2rem",
                    }}
                  >
                    <Link
                      onClick={() => {
                        setSelectedUsers([]);
                        setSelectedUserIds([]);
                        setSelectAll(false);
                      }}
                      style={{ color: "#1773bc", paddingRight: "1rem" }}
                    >
                      Clear Selection
                    </Link>
                  </div>
                  <MenuItem
                    key={`select-all`}
                    value={"Select All"}
                    className="no-left-margin"
                  >
                    <Checkbox checked={selectAll} />
                    <ListItemText
                      primary={selectAll ? "Select None" : "Select All"}
                    />
                  </MenuItem>
                  {userMembers &&
                    userMembers.data.map((user) => (
                      <MenuItem
                        key={user.id}
                        value={user}
                        className="no-left-margin"
                      >
                        <Checkbox
                          checked={
                            selectedUsers.findIndex(
                              (person) => person.id === user.id
                            ) > -1
                          }
                        />
                        <ListItemText
                          primary={
                            getFullName(user.firstName, user.lastName) +
                            " (" +
                            user.email +
                            ")"
                          }
                        />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div>
              <label>Include Expired Allocation</label>
              <Switch
                checked={isHistory}
                onChange={(e) => handleChangeForHistory(e.target.value)}
                value={isHistory}
                inputProps={{ "aria-label": "controlled" }}
                id="history"
              />
            </div>
            <div>
              <button
                className={
                  !selectedUserIds || selectedUserIds.length === 0
                    ? "disableAddButton"
                    : "addBtn"
                }
                disabled={!selectedUserIds || selectedUserIds.length === 0}
                onClick={() => getUserProjectAllocations()}
              >
                Get Project Allocations
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

const createClearAndViewButtonForAttendance = () => {
  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
 
<button
  style={{
    padding: "0.875rem 2.375rem",
    background: "#1773bc",
    border: "0.0625rem solid #1773bc",
    borderRadius: "0.625rem",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.25rem",
    color: "#ffffff",
    cursor: selectedUser ? "pointer" : "not-allowed"
  }}
  className={
    disableDate || disablePeriodWeek
      ? "addBtn showDataBtn"
      : "disableAddButton"
  }
  onClick={() => {
    setSelectedUserID(null);
    setSelectedUser(null);
    setViewAttendanceClicked(false);
    setUserMembers(allUserDetails);
  }}
>
  Clear Selection
</button>
</div>
    </>
  );
};

const handleUserChangeForAttendanceDropDown = (event) => {
  const user = event.target.value
  setSelectedUser(user);
  setSelectedUserID(user.id);
  setUserMembers(allUserDetails);
  setViewAttendanceClicked(true)
};




const createTopBarForViewAttendance = () => {
  return (
    <>
    <div style={{ display: 'flex', width:'100%', alignItems: 'center',justifyContent:'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{fontSize: "1.25rem",fontWeight: "600",color: "grey",marginRight: "1rem",whiteSpace: "nowrap",fontFamily:"Montserrat"}}>Select User to View Attendance : </span>
        <FormControl sx={{ m: 1, width: 250 }}>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={selectedUser}
              onChange={handleUserChangeForAttendanceDropDown}
              input={<OutlinedInput/>}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    width: 250,
                  },
                },
              }}
            >
              
              {userData.data.roleName === "Admin" && allUserDetails && (
                <ListSubheader className="subheader" >
                  <TextField
                    placeholder="Search Here..."
                    className="subheader-field"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    // autoFocus={true}
                    onChange={(e) => {
                      setUserMembers({
                        ...allUserDetails,
                        data: allUserDetails.data.filter(
                          (i) =>
                            i.firstName?.toLowerCase().includes(e.target.value.toLowerCase()) ||
                            i.lastName?.toLowerCase().includes(e.target.value.toLowerCase()) ||
                            i.email?.toLowerCase().includes(e.target.value.toLowerCase())
                        ),
                      });
                    }}
                  />
                </ListSubheader>
              )}
              {userData.data.roleName === "Admin" && userMembers &&
                userMembers.data.map((user) => (
                  <MenuItem
                    value={user}
                  >
                    {getFullName(user.firstName, user.lastName)} ({user.email})
                  </MenuItem>
                ))}
              {userData.data.roleName === "Project Manager" && reportees && (
                reportees.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option}
                    
                  >
                    {getFullName(option.firstName, option.lastName)} ({option.email})
                  </MenuItem>
                ))
              )}
              
            </Select>
          </FormControl>
        <div>{createClearAndViewButtonForAttendance()}</div>
      </div>
      <a onClick={(e) => setOpenDialogBox(true)} style={{cursor: "pointer"}}>      
          <Tooltip title={<h1>Export All Reportee's Attendance xls</h1>}>
            <img src={excelAllIcon} height={'30rem'} />
          </Tooltip>
      </a>
    </div>
    {openDialogBox && (
      <Dialog
        open={openDialogBox}
        onClose={handleClose}
      >
        <MonthYearPopup onResponse={handleDialogBox}/>
      </Dialog>
    )}
    </>
      
  );
};


  const createTopBarForSkills = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: '1rem', fontWeight:'bold', fontSize: '1.5rem',color:'#1773BC',display:'flex',justifyContent:'space-between'}}>
            <label>SKILLS REPORT FILTER BY FOLLOWING</label>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }} className="allocationTopBar">
            
            <div className="dropdownsBox">
              <label style={{fontSize:'1.25rem'}}> Streams </label>
              <FormControl sx={{ m: '2rem', width: '20rem' }}>
                <InputLabel required id="demo-multiple-checkbox-label">
                  Select Streams
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedStreams}
                  onChange={handleUserChangeStreams}
                  input={<OutlinedInput label="Select User" />}
                  renderValue={getStreamsName}
                  MenuProps={MenuProps}
                >
                  {listItems && (
                    <ListSubheader className="subheader">
                      <TextField
                        placeholder="Search Here..."
                        className="subheader-field"
                        onKeyDown={(e) => {
                          e.stopPropagation();
                        }}
                        autoFocus={true}
                        onChange={(e) => {
                          setUserStreams(
                            listItems.Streams.filter((i) =>
                                i.longCodeValue
                                ?.toLowerCase()
                                .includes(e.target.value.toLowerCase())
                            )
                          );
                        }}
                      />
                    </ListSubheader>
                  )}
                  <div
                    disabled={!selectedStreamsIds.length}
                    style={{
                      textAlign: "right",
                      cursor: "pointer",
                      height: "2rem",
                    }}
                  >
                    <Link
                      onClick={() => {
                        setSelectedStreams([]);
                        setSelectedStreamsIds("");
                        setSelectAllStreams(false);
                      }}
                      style={{ color: "#1773bc", paddingRight: "1rem" }}
                    >
                      Clear Selection
                    </Link>
                  </div>
                  {/* <MenuItem
                    key={`select-all`}
                    value={"Select All"}
                    className="no-left-margin"
                  >
                    <Checkbox checked={selectAllStreams} />
                    <ListItemText
                      primary={selectAllStreams ? "Select None" : "Select All"}
                    />
                  </MenuItem> */}

                  {userStreams &&
                    userStreams?.map((user) => (
                      <MenuItem
                        key={user}
                        value={user}
                        className="no-left-margin"
                      >
                        <Checkbox
                          checked={
                            selectedStreams.findIndex(
                              (person) => person.id === user.id
                            ) > -1
                          }
                        />
                        <ListItemText primary={user.longCodeValue} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginLeft:'1.5rem',marginRight:'1.5rem' }}>
              <span style={{ fontSize: '1.5rem', fontWeight: 600, color: 'gray' }}> OR</span>
            </div>
            <div className="dropdownsBox">
              <label style={{fontSize:'1.25rem'}}> Skills Bucket</label>
              <FormControl sx={{ m: '2rem', width: '20rem' }}>
                <InputLabel required id="demo-multiple-checkbox-label">
                  Select Skills
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedSkills}
                  onChange={handleUserChangeSkills}
                  input={<OutlinedInput label="Select User" />}
                  renderValue={getSkillsName}
                  MenuProps={MenuProps}
                >
                  {listItems && (
                    <ListSubheader className="subheader">
                      <TextField
                        placeholder="Search Here..."
                        className="subheader-field"
                        onKeyDown={(e) => {
                          e.stopPropagation();
                        }}
                        autoFocus={true}
                        onChange={(e) => {
                          setUserSkills(
                            listItems.Skills.filter((i) =>
                              i.longCodeValue
                                ?.toLowerCase()
                                .includes(e.target.value.toLowerCase())
                            )
                          );
                        }}
                      />
                    </ListSubheader>
                  )}
                  <div
                    disabled={!selectedSkillsIds.length}
                    style={{
                      textAlign: "right",
                      cursor: "pointer",
                      height: "2rem",
                    }}
                  >
                    <Link
                      onClick={() => {
                        setSelectedSkills([]);
                        setSelectedSkillsIds("");
                        setSelectAll(false);
                      }}
                      style={{ color: "#1773bc", paddingRight: "1rem" }}
                    >
                      Clear Selection
                    </Link>
                  </div>

                  {/* <MenuItem
                    key={`select-all`}
                    value={"Select All"}
                    className="no-left-margin"
                  >
                    <Checkbox checked={selectAll} />
                    <ListItemText
                      primary={selectAll ? "Select None" : "Select All"}
                    />
                  </MenuItem> */}

                  {userSkills &&
                    userSkills?.map((user) => (
                      <MenuItem
                        key={user}
                        value={user}
                        className="no-left-margin"
                      >
                        <Checkbox
                          checked={
                            selectedSkills.findIndex(
                              (person) => person.id === user.id
                            ) > -1
                          }
                        />
                        <ListItemText primary={user.longCodeValue} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around',marginLeft:'1.5rem',marginRight:'1.5rem' }}>
              <span style={{ fontSize: '1.5rem', fontWeight: 600, color: 'gray' }}> OR</span>
            </div>
            <div className="dropdownsBox">
              <label style={{fontSize:'1.25rem'}}> Primary/Secondary Skill</label>
              <FormControl sx={{ m: '2rem', width: '20rem' }}>
                <InputLabel required id="demo-multiple-checkbox-label">
                  Select Skills
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedPriSecSkills}
                  onChange={handleUserChangePriSecSkills}
                  input={<OutlinedInput label="Select User" />}
                  renderValue={getPriSecSkillsName}
                  MenuProps={MenuProps}
                >
                  {listItems && (
                    <ListSubheader className="subheader">
                      <TextField placeholder="Search Here..." className="subheader-field" onKeyDown={(e) => { e.stopPropagation();
                      }}
                        autoFocus={true}
                        onChange={(e) => {
                          setUserPriSecSkills(
                            listItems.PriSecSkills.filter((i) =>
                              i.longCodeValue?.toLowerCase().includes(e.target.value.toLowerCase())
                            )
                          );
                        }}
                      />
                    </ListSubheader>
                  )}
                  <div
                    disabled={!selectedPriSecSkillsIds.length}
                    style={{
                      textAlign: "right",
                      cursor: "pointer",
                      height: "2rem",
                    }}
                  >
                    <Link
                      onClick={() => {
                        setSelectedPriSecSkills([]);
                        setSelectedPriSecSkillsIds("");
                        setSelectAllPriSecSkill(false);
                      }}
                      style={{ color: "#1773bc", paddingRight: "1rem" }}
                    >
                      Clear Selection
                    </Link>
                  </div>

                  {/* <MenuItem
                    key={`select-all`}
                    value={"Select All"}
                    className="no-left-margin"
                  >
                    <Checkbox checked={selectAllPriSecSkill} />
                    <ListItemText
                      primary={selectAllPriSecSkill ? "Select None" : "Select All"}
                    />
                  </MenuItem> */}

                  {userPriSecSkills && userPriSecSkills?.map((user) => (
                    <MenuItem key={user} value={user} className="no-left-margin">
                      <Checkbox checked={selectedPriSecSkills.findIndex((person) => person.id === user.id ) > -1}
                      />
                      <ListItemText primary={user.longCodeValue} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft:'5rem'}}>
              {createClearAndShowButtonForSkills()}
            </div>
          </div>
          <div style={{textAlign:'right',fontSize:'1.5rem',marginRight:'5rem',color:'#1773bc'}}>SkillsList Count: {skillsLength}</div>

          </div>
      </>
    );
  };

  const createAddButton = (buttonLabel) => {
    if (headingName === Modules.PROJECT_ADMIN && userData.data.roleName === "Project Manager") { }
    else {
      return (
        <>
          <button
            disabled={
              isAddButtonClicked ||
              isEditButtonClicked ||
              (headingName === Modules.INVOICE_TRACKER && !selectedClientProject)
            }
            className={
              isAddButtonClicked ||
                isEditButtonClicked ||
                (headingName === Modules.INVOICE_TRACKER && !selectedClientProject)
                ? "disableAddButton"
                : "addBtn"
            }
            onClick={() => setIsAddButtonClicked(true)}
          >
            <div
              className={
                isAddButtonClicked ||
                  isEditButtonClicked ||
                  (headingName === Modules.INVOICE_TRACKER &&
                    !selectedClientProject)
                  ? "disableAddButtonText"
                  : "btnText"
              }
            >
              {buttonLabel}
            </div>
          </button>
        </>
      );
    }
  };

  const excelData = () => {
    if(headingName === Modules.PROJECT_MANAGEMENT){
      return projectManagementData
    }else if(headingName === Modules.SKILLS){
      return skillsList;
    }else if(headingName === Modules.REPORTING && tabName === "ALLOCATION CHANGE REPORT BY PROJECT"){
      return allocationChangeReportData
    }else if(headingName === Modules.REPORTING && tabName ==="ALLOCATION CHANGE REPORT BY EMPLOYEE"){
      return allocationChangeReportDataByEmployee
    }
    else if(headingName === Modules.REPORTING && tabName === "MISSING TIMESHEET"){
      return reportingData
    }
    else{
      return projectAllocationData;
    }
  }

  const createExpiredAllocation = ()=>{
    return(
      <>
        <div>
          <label>Expired Allocations</label>
          <Switch
            checked={expiredAllocations}
            onChange={(e) => handleChangeForHistoryInProjectManagement(e.target.value)}
            value={expiredAllocations}
            inputProps={{ "aria-label": "controlled" }}
            id="expiredAllocation"
          />
        </div>
      </>
    )
  }

  const createExpiredAllocationforProjectAdmin = ()=>{
    return(
      <>
        <div>
          <label>Expired Projects</label>
          <Switch
            checked={expiredAllocations}
            onChange={(e) => handleChangeForHistoryInProjectAdmin(e.target.value)}
            value={expiredAllocations}
            inputProps={{ "aria-label": "controlled" }}
            id="expiredAllocationForProjectAdmin"
          />
        </div>
      </>
    )
  }

  //useEffect for dispatching the method for filtering by bill status
  useEffect(() => {
    if(headingName=== Modules.PROJECT_MANAGEMENT){
      dispatch(
        getProjectManagementData({
          projectId: projectId,
          pageNo: 1,
          pageSize: 10,
          sortBy: "projectName",
          sortDir: "ASC",
          searchData: searchData,
          billStatus: billStatus,
          expiredAllocations:expiredAllocations
        })
      );
    }
  }, [billStatus,expiredAllocations]);



  useEffect(() => {
    if(headingName=== Modules.PROJECT_ADMIN)
    {
      dispatch(
        getProjectAdminData({
          projectId: projectId,
          pageNo: 1,
          pageSize: 10,
          sortBy: "projectName",
          sortDir: "ASC",
          searchData: searchData,
          billStatus: billStatus,
          expiredAllocations:expiredAllocations


        })
      );
    }
  }, [expiredAllocations]);

  // useEffect(() => {
  //   if(headingName=== Modules.PROJECT_MANAGEMENT)
  //   {
  //     dispatch(
  //       getProjectManagementData({
  //         projectId: projectId,
  //         pageNo: 1,
  //         pageSize: 10,
  //         sortBy: "projectName",
  //         sortDir: "ASC",
  //         searchData: searchData,
  //         billStatus: billStatus,
  //         expiredAllocations:expiredAllocations


  //       })
  //     );
  //   }
  // }, [expiredAllocations]);




  // function to handle the state of filtering by bill status
  const handleSelectChange = (event) => {
    setBillStatus(event.target.value);
  };


  const createFilterByBillStatusDropDown= ()=>{
    return(
      <FormControl  >
          <InputLabel id="demo-simple-select-label">
            Filter By Bill Status
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={billStatus}
            label="Filter By Bill Status"
            onChange={handleSelectChange}
            sx={{minWidth:"15rem",height:"3.5rem"}}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"Billable"}>Billable</MenuItem>
            <MenuItem value={"Shadow"}>Shadow</MenuItem>
            <MenuItem value={"Non Billable"}>Non Billable</MenuItem>
          </Select>
        </FormControl>
    )

  }
  useEffect(() => {
    if(headingName=== Modules.VIEW_ATTENDANCE && tabName==="VIEW EMPLOYEE ATTENDANCE"){
      dispatch(getReportees(null))
    }
  }, [tabName]);

  return (
    <div
      className={tabName === "VIEW EMPLOYEE ATTENDANCE" ? `tableDivViewAttendance`
        :(`tableDiv ${
        tabName === "MY TIMESHEET" ? "timesheetTable" : ""
        }`)}
      // style={tabName === "VIEW EMPLOYEE ATTENDANCE" ? { padding: "0.5rem!important" } : {}}
    >
      <div
        className={
          headingName === Modules.REPORTING
            ? "searchHeaderFlex"
            : "searchHeader"
        }
      >
        {headingName === Modules.PROJECT_ALLOCATION &&
          tabName === "RESOURCE ALLOCATION" &&
          createTopBarForProjectAllocation()}
        {headingName !== Modules.TIMESHEET &&
          headingName !== Modules.REPORTING &&
          headingName !== Modules.VIEW_ATTENDANCE &&
          headingName !== Modules.TRAINING_AND_SESSION &&
          headingName !== Modules.SESSION_FEEDBACK &&
          headingName !== Modules.INVOICE_TRACKER &&
          tabName !== "RESOURCE ALLOCATION" &&
          headingName !== Modules.SKILLS &&
          tabName !== "SKILLS REPORT" &&
          createSearchBox("Search")}
        {headingName===Modules.TRAINING_AND_SESSION && tabName === "TRAINING AND SESSION" && createTopBarForSessionAndTraining("Search") }
        {headingName=== Modules.PROJECT_MANAGEMENT && createFilterByBillStatusDropDown()}
        {headingName=== Modules.PROJECT_MANAGEMENT && createExpiredAllocation() }
        {headingName=== Modules.PROJECT_ADMIN && createExpiredAllocationforProjectAdmin()}       
        {headingName === Modules.INVOICE_TRACKER && tabName === "CREATE INVOICE" && createTopBarForInvoice()}
        {headingName === Modules.INVOICE_TRACKER && tabName === "INVOICE DETAILS" && createTopBarForInvoice_Details()}
        {headingName === Modules.TIMESHEET
          ? tabName === "MY TIMESHEET"
            ? myTimeSheetTab()
            : pendingApprovalReportessTab()
          : headingName === Modules.REPORTING
            ? FilterData()
            : (headingName !== Modules.PROJECT_ALLOCATION) &
              (headingName !== Modules.INVOICE_TRACKER) &
              (headingName !== Modules.SKILLS) &
              (headingName !== Modules.VIEW_ATTENDANCE)&
              (headingName !== Modules.TRAINING_AND_SESSION && tabName !== "SESSION CREATION") &
              (headingName !== Modules.SESSION_FEEDBACK)
              ? createAddButton("Add")
              : null}
        {/* {headingName !== Modules.SKILLS && tabName !== 'SKILLS REPORT' &&  createSearchBox("Search")} */}
        {/* {headingName===Modules.SESSION_FEEDBACK&&(<SessionFeedback/>)} */}
        {headingName === Modules.SKILLS &&
          tabName === "SKILLS REPORT" &&
          createTopBarForSkills()}
        {headingName === Modules.VIEW_ATTENDANCE && tabName === "VIEW EMPLOYEE ATTENDANCE" && createTopBarForViewAttendance()}
      </div>
      {opencreateSessionPopup && (<Dialog open={opencreateSessionPopup} onClose={handleClose} maxWidth={"false"} PaperProps={{ style: { backgroundColor: "white", padding: "2.3rem",marginLeft:"20rem" } }} >
      {headingName === Modules.TRAINING_AND_SESSION && ( <SessionCreation
            onResponse={
              handleMultipleFileView
            }
          />)}
      </Dialog>
      )}
      
      <DataTable
        rows={rows}
        columns={columns}
        headingName={headingName}
        tabName={tabName}
        setColumns={setColumns}
        setRows={setRows}
        totalRecord={totalRecord}
        isAddButtonClicked={isAddButtonClicked}
        setIsAddButtonClicked={setIsAddButtonClicked}
        isEditButtonClicked={isEditButtonClicked}
        setIsEditButtonClicked={setIsEditButtonClicked}
        searchData={searchData}
        resetSearchData={resetSearchData}
        projectStatus={status}
        selectedPeriodWeek={selectedPeriodWeek}
        projectId={projectId}
        rowToBeUpdated={rowToBeUpdated}
        setRowToBeUpdated={setRowToBeUpdated}
        billStatus={billStatus}
        expiredAllocations={expiredAllocations}
        dataForExcel={excelData()}
        selectedUserIds={
           headingName === Modules.REPORTING ? selectedUserIds : (headingName === Modules.SKILLS ? selectedSkillsIds : selectedUserIds)
        }
        isHistory={isHistory}
        startDate={startDate}
        endDate={endDate}
        selectedUserID ={selectedUserID}
        viewAttendanceClicked={viewAttendanceClicked}
        selectedTimesheetRows = {selectedTimesheetRows}
        setSelectedTimesheetRows = {setSelectedTimesheetRows}
      />
      {headingName === Modules.TIMESHEET &&
        tabName === "MY TIMESHEET" &&
        timeSheetData &&
        timeSheetData.length &&
        !vTrackLoader &&
        rows.length ? (
        <div className="totalWorkingHrs">
          {`Total Hours: ${getTotalHrs(timeSheetData)}`}
        </div>
      ) : null}
      {/* {headingName===Modules.REPORTING && <ExportExcel data={headingName===Modules.REPORTING ? projectManagementData : []} headingName={headingName} projectId={projectId}/>} */}
      {(headingName=== Modules.VIEW_ATTENDANCE && viewAttendanceData && rows.length>0) ? <div></div> : <div></div>}
    </div>
  );
};
