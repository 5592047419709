import { useEffect, useState, useRef } from "react";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  ListSubheader,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  addSession,
  updateSession
} from "../../redux/actions";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DialogActions from '@mui/material/DialogActions';
import { Link } from "react-router-dom";
import { getFullName } from "../../common/utils/datatable";
import "./SessionCreation.css";
import moment from "moment";
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { toastOptions } from '../../common/utils/toasterOptions';
// import CustomDatePicker from "./CustomeDatePicker";


const durations = [
  { value: '30 min', label: '30 min' },
  { value: '1 hour', label: '1 hour' },
  { value: '1 hour 30 min', label: '1 hour 30 min' },
  { value: '2 hours', label: '2 hours' },
  { value: '2 hours 30 min', label: '2 hours 30 min' },
  { value: '3 hours', label: '3 hours' },
];


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "30rem",
      width: "10rem",
    },
  },
};

export const SessionUpdate = (props) => {
  const {sessionDetails} = useSelector(({ MODULES }) => MODULES);
  const index = sessionDetails.data.findIndex(item => item.sessionId === props.sessionId);
  const [sessionName, setSessionName] = useState(sessionDetails.data[index].sessionName);
  const [duration, setDuration] = useState(sessionDetails.data[index].duration);
  const [status, setStatus] = useState(sessionDetails.data[index].status);
  const [sessionDate, setSessionDate] = useState(sessionDetails.data[index].sessionDate);
  const [description, setDescription] = useState(sessionDetails.data[index].description);
  const [selectAll, setSelectAll] = useState(false);

  const { allUserDetails, userData } = useSelector(({ USER }) => USER);
  const { listItems } = useSelector(({ MODULES }) => MODULES);
  const [userMembers, setUserMembers] = useState(allUserDetails);
  const [userSkills, setUserSkills] = useState(listItems ? listItems.Skills : []);
  const [totalSeats, setTotalSeats] = useState(sessionDetails.data[index].seatsAlloted);
  const [regStartDate, setRegStartDate] = useState(sessionDetails.data[index].registrationStartDate);
  const [regEndDate, setRegEndDate] = useState(sessionDetails.data[index].registrationEndDate);
  const selectedHosts = sessionDetails.data[index].hosts;
  const [selectedUsers, setSelectedUsers] = useState();
  const selectedSessionSkills = sessionDetails.data[index].targetedSkill.split(',');
  const [selectedSkills, setSelectedSkills] = useState();
  const [otherSkills, setOtherSkills] = useState();
  const [selectedUserIds, setSelectedUserIds] = useState("");
  const [selectedSkillsIds, setSelectedSkillsIds] = useState("");
  


  const dispatch = useDispatch();


  useEffect(() => {
    if (allUserDetails) {
      setUserMembers(allUserDetails);
      const filteredAllUserDetails = allUserDetails.data.filter(user =>selectedHosts.map(selectedHosts => selectedHosts.employeeId).includes(user.id));
      setSelectedUsers(filteredAllUserDetails)
    }
    
  }, [allUserDetails]);
  useEffect(() => {
    if (selectedUsers) {
      setSelectedUserIds(selectedUsers.map(user => user.id).join(','));
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (listItems && listItems.Skills && listItems.Skills.length) {
      setUserSkills(listItems.Skills);
    }
  }, [listItems]);

  useEffect(() => {
    if (userSkills &&  userSkills.length) {
      let filteredUserSkills = userSkills.filter(skill=>selectedSessionSkills.includes(skill.longCodeValue));
      const restSkills = selectedSessionSkills.filter(skill =>!userSkills.some(userSkill => userSkill.longCodeValue === skill));

      if(restSkills[0]&&restSkills[0].length){
        const othersSkill = userSkills.find(skill => skill.longCodeValue === "Others");
        filteredUserSkills.push(othersSkill);
      }
      setSelectedSkills(filteredUserSkills)
      setOtherSkills(restSkills.join(', '))
      
    }
  }, [userSkills]);

  useEffect(() => {
    if (selectedSkills && selectedSkills.length) {
      setSelectedSkillsIds(selectedSkills.map(skill=> skill.longCodeValue).join(','))
    }
  }, [selectedSkills]);

  const handleClose = () => {
    setSelectedUserIds("")
    props.onResponse(true);
  }

  const handleUserChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.findIndex((val) => val === "Select All") > -1) {
      if (selectAll) {
        setSelectedUsers([]);
        setSelectedUserIds([]);
      } else {
        setSelectedUsers(allUserDetails.data);
        let ids = allUserDetails.data.map((user) => {
          return user.id;
        });
        setSelectedUserIds(ids.join(","));
      }
      setSelectAll(!selectAll);
    } else {
      setSelectedUsers(value);
      let ids = value.map((user) => {
        return user.id;
      });
      setSelectedUserIds(ids.join(","));
    }
  };
  const handleUserChangeSkills = (event) => {
    const {
      target: { value },
    } = event;
    if (value.findIndex((val) => val === "Select All") > -1) {
      if (selectAll) {
        setSelectedSkills([]);
        setSelectedSkillsIds("");
      } else {
        setSelectedSkills(listItems.Skills);
        let ids = listItems.Skills?.map((user) => {
          return user.longCodeValue;
        });
        setSelectedSkillsIds(ids.join(","));
      }
      setSelectAll(!selectAll);
    } else {
      setSelectedSkills(value);
      let ids = value.map((user) => {
        return user.longCodeValue;
      });
      setSelectedSkillsIds(ids.join(","));
    }
  };
  const getUsersNames = () => {
    let selectedNames = "";
    if (selectedUsers && selectedUsers.length) {
      for (let i = 0; i < selectedUsers.length; i++) {
        selectedNames +=  selectedUsers[i].firstName+" "+selectedUsers[i].lastName;
        if (i !== selectedUsers.length - 1) selectedNames += ", ";
      }
    }
    
    return selectedNames;
  };
  const getSkillsName = () => {
    let selectedSkillsName = "";
    if (selectedSkills && selectedSkills.length) {
      for (let i = 0; i < selectedSkills.length; i++) {
        selectedSkillsName += `${selectedSkills[i].longCodeValue}`;
        if (i !== selectedSkills.length - 1) selectedSkillsName += ", ";
      }
    }
    return selectedSkillsName;
  };

  return (
    <div style={{ width: "140rem" }}>

      <div className="heading" style={{ marginBottom: "2rem" }}>
        Edit Session
      </div>

      <div className="lineDivs">
        <div className="formDivs">
          <label style={{ marginRight: "6.6rem", fontFamily: "Montserrat", fontSize: "1.2rem" }}>Session Name</label>
          <TextField
            label="Enter Name"
            required
            value={sessionName}
            onChange={(e) => setSessionName(e.target.value)}
            variant="outlined"
            margin="normal"
            InputProps={{ className: 'textareaInput' }}
            style={{ width: "50rem", margin: "0rem" }}
          />
        </div>

        <div className="formDivs">
          <label style={{ marginRight: "5.6rem", fontFamily: "Montserrat", fontSize: "1.2rem" }}>Session Host(s)</label>
          <FormControl sx={{ width: "50rem" }}>
            <InputLabel required id="demo-multiple-checkbox-label">
              Select Host(s)
            </InputLabel>
            <Select
              labelId="host-multiple-checkbox-label"
              id="host-multiple-checkbox"
              multiple
              value={selectedUsers?selectedUsers:[]}
              onChange={handleUserChange}
              input={<OutlinedInput label="Select Host" />}
              renderValue={getUsersNames}
              MenuProps={MenuProps}
              style={{ height: "5rem", fontSize:"1.5rem" }}
            >

              {allUserDetails && (
                <ListSubheader className="subheader">
                  <TextField
                    placeholder="Search Here..."
                    className="subheader-field"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    autoFocus={true}
                    onChange={(e) => {
                      setUserMembers({
                        ...allUserDetails,
                        data: allUserDetails.data.filter(
                          (i) =>
                            i.firstName
                              ?.toLowerCase()
                              .includes(e.target.value.toLowerCase()) ||
                            i.lastName
                              ?.toLowerCase()
                              .includes(e.target.value.toLowerCase()) ||
                            i.email
                              ?.toLowerCase()
                              .includes(e.target.value.toLowerCase())
                        ),
                      });
                    }}
                  />
                </ListSubheader>
              )}
              <div
                disabled={selectedUserIds===""}
                style={{
                  textAlign: "right",
                  cursor: "pointer",
                  height: "2rem",
                }}
              >
                <Link
                  onClick={() => {
                    setSelectedUsers([]);
                    setSelectedUserIds("");
                    setSelectAll(false);
                  }}
                  style={{ color: "#1773bc", paddingRight: "1rem" }}
                >
                  Clear Selection
                </Link>
              </div>
              {/* <MenuItem
                key={`select-all`}
                value={"Select All"}
                className="no-left-margin"
              >
                <Checkbox checked={selectAll} />
                <ListItemText
                  primary={selectAll ? "Select None" : "Select All"}
                />
              </MenuItem> */}
              {userMembers &&
                userMembers.data.map((user) => (
                  <MenuItem
                    key={user.id}
                    value={user}
                    className="no-left-margin"
                  >
                    <Checkbox
                      checked={
                        selectedUsers&&selectedUsers.findIndex(
                          (person) => person.id === user.id
                        ) > -1
                      }
                    />
                    <ListItemText
                      primary={
                        getFullName(user.firstName, user.lastName) +
                        " (" +
                        user.email +
                        ")"
                      }
                    />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="lineDivs" style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="formDivs" style={{ marginRight: "2rem" }}>
          <label style={{ marginRight: "5.2rem", fontFamily: "Montserrat", fontSize: "1.2rem" }}>Skill(s) Targetted</label>
          <FormControl sx={{ width: '50rem' }}>
            <InputLabel required >Select Skill(s)</InputLabel>
            {/* {console.log(selectedSkills)} */}
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedSkills?selectedSkills:[]}
              onChange={handleUserChangeSkills}
              input={<OutlinedInput label="Select User" />}
              renderValue={getSkillsName}
              MenuProps={MenuProps}
              style={{ height: "5rem", fontSize:"1.5rem" }}
            >
              {listItems && (
                <ListSubheader className="subheader">
                  <TextField
                    placeholder="Search Here..."
                    className="subheader-field"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    autoFocus={true}
                    onChange={(e) => {
                      setUserSkills(
                        listItems.Skills.filter((i) =>
                          i.longCodeValue
                            ?.toLowerCase()
                            .includes(e.target.value.toLowerCase())
                        )
                      );
                    }}
                  />
                  {selectedSkillsIds&&selectedSkillsIds.includes("Others") ? (
                    <TextField
                      placeholder="Enter Other Skills"
                      required
                      value={otherSkills}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                      }}
                      InputProps={{
                        endAdornment: (
                          <span style={{ color: 'red' }}>*</span>
                        ),
                      }}
                      autoFocus={true}
                      onChange={(e) => setOtherSkills(e.target.value)}
                      sx={{paddingTop:"1rem", width: "43.4rem"}}
                    />
                  ):''}
                </ListSubheader>
              )}
              

              <div
                disabled={selectedSkillsIds===""}
                style={{
                  textAlign: "right",
                  cursor: "pointer",
                  height: "2rem",
                }}
              >
                <Link
                  onClick={() => {
                    setSelectedSkills([]);
                    setSelectedSkillsIds("");
                    setSelectAll(false);
                  }}
                  style={{ color: "#1773bc", paddingRight: "1rem" }}
                >
                  Clear Selection
                </Link>
              </div>

              {/* <MenuItem
                key={`select-all`}
                value={"Select All"}
                className="no-left-margin"
              >
                <Checkbox checked={selectAll} />
                <ListItemText
                  primary={selectAll ? "Select None" : "Select All"}
                />
              </MenuItem> */}

              {userSkills && userSkills.length &&
                userSkills?.map((user) => (
                  <MenuItem
                    key={user.id}
                    value={user}
                    className="no-left-margin"
                  >
                    <Checkbox
                      checked={
                        selectedSkills&&selectedSkills.findIndex(
                          (person) => person.id === user.id
                        ) > -1
                      }
                    />
                    <ListItemText primary={user.longCodeValue} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div style={{display:"flex"}}>
          <div className="formDivs" style={{marginRight:"4.8rem"}}>
            <label style={{ marginRight: "6.9rem", marginTop: "-0.5rem", fontFamily: "Montserrat", fontSize: "1.2rem" }}>Session Date</label>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{
                "& label": {
                  lineHeight: "0.8rem",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <DatePicker
                className="custom-datepicker"
                style={{ height: "4rem" }}
                required
                label={
                  <div>
                    Select Date{" "}
                    <span style={{ color: "red" }}>*</span>
                  </div>
                }
                value={
                  sessionDate
                }
                onChange={(newValue) => {
                  setSessionDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    InputProps={{ style: { height: '5rem' } }}
                    style={{
                      width: "15rem",
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="formDivs" >
            <label style={{ marginRight: "5rem", fontFamily: "Montserrat", fontSize: "1.2rem" }}>Session Duration</label>
            <FormControl sx={{ width: "15rem" }}>
              <InputLabel >Select Duration</InputLabel>
              <Select
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                label="Duration"
                style={{ height: "5rem", fontSize:"1.5rem" }}
              >
                {durations.map((dur) => (
                  <MenuItem key={dur.value} value={dur.value}>
                    {dur.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div className="lineDivs">
        <div style={{display:"flex"}}>
          <div className="formDivs">
            <label style={{ marginRight: "1.5rem", fontFamily: "Montserrat", fontSize: "1.2rem" }}>Registration Start Date</label>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{
                "& label": {
                  lineHeight: "0.8rem",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <DatePicker
                className="custom-datepicker"
                style={{ height: "4rem" }}
                required
                label={
                  <div>
                    Select Date{" "}
                    <span style={{ color: "red" }}>*</span>
                  </div>
                }
                value={
                  regStartDate
                }
                onChange={(newValue) => {
                  setRegStartDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    InputProps={{ style: { height: '5rem' } }}
                    style={{
                      width: "15rem",
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="formDivs">
            <label style={{ marginLeft:"5.5rem",marginRight: "1.1rem", fontFamily: "Montserrat", fontSize: "1.2rem" }}>Registration End Date</label>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{
                "& label": {
                  lineHeight: "0.8rem",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <DatePicker
                className="custom-datepicker"
                style={{ height: "4rem" }}
                required
                label={
                  <div>
                    Select Date{" "}
                    <span style={{ color: "red" }}>*</span>
                  </div>
                }
                value={
                  regEndDate
                }
                onChange={(newValue) => {
                  setRegEndDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    InputProps={{ style: { height: '5rem' } }}
                    style={{
                      width: "15rem",
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className="formDivs" >
            <label style={{ marginRight: "6rem", fontFamily: "Montserrat", fontSize: "1.2rem"}}>Session Status</label>
            <FormControl sx={{ width: "15rem" }}>
              <InputLabel required >Select Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Duration"
                style={{ height: "5rem", fontSize:"1.5rem" }}
              >
                <MenuItem value="Draft">Draft</MenuItem>
                <MenuItem value="Published">Published</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="formDivs">
            <label style={{ marginLeft:"5rem",marginRight: "8.5rem", fontFamily: "Montserrat", fontSize: "1.2rem" }}>Total Seats</label>
            <TextField
              label="Enter Number of Seats"
              required
              value={totalSeats}
              onChange={(e) => setTotalSeats(e.target.value)}
              variant="outlined"
              margin="normal"
              InputProps={{ className: 'textareaInput' }}
              style={{ width: "15rem", margin: "0rem" }}
            />
          </div>
        </div>       
      </div>
 
      

      <div className="formDivs">
        <label style={{ marginRight: "8.2rem", fontFamily: "Montserrat", fontSize: "1.2rem" }}>Description</label>
        <TextField
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          inputProps={{
            style: {
              maxHeight: "10rem",
              overflowY: "scroll",
              display: "block",
            },
            className: "sessionDescription",
          }}
          multiline
          fullWidth
        />
      </div>

      <div style={{ display: "flex", paddingTop: "4rem", justifyContent: "flex-end" }}>
        <Button
          onClick={
            () => {
              if(selectedSkillsIds.includes("Others"))
                if(!otherSkills){
                  toast.error("Please Fill Other Skills",toastOptions);
                  return
                }
              let data={
                sessionId : props.sessionId,
                updateData:  {sessionName: sessionName,
                hosts: selectedUserIds,
                targetedSkill: selectedSkillsIds.replace(/Others/, otherSkills),
                duration: duration,
                sessionDate: sessionDate&&dayjs(sessionDate).format("DD MMM YYYY"),
                description: description,
                SaveOrPublish: status==="Draft"? 0 : 1,
                seatsAlloted: totalSeats,
                registrationStartDate: regStartDate&&dayjs(regStartDate).format("DD MMM YYYY"),
                registrationEndDate: regEndDate&&dayjs(regEndDate).format("DD MMM YYYY"),
                status:status}
              }
              for (const key in data.updateData) {
                if (key!= "duration" && key!= "SaveOrPublish" && key!= "description" && !data.updateData[key]) {
                  let errMsg = key.replace(/([A-Z])/g, ' $1')
                  errMsg = errMsg.charAt(0).toUpperCase() + errMsg.slice(1)
                  errMsg = "Please Fill " + errMsg
                  toast.error(
                    errMsg,
                    toastOptions
                  );                  
                  return
                }
              }
              dispatch(
                updateSession(data)
              );
            }
          }
          type="submit" variant="contained" color="primary" style={{ marginRight: "1rem", height: "3rem",width:"22.2rem" }}>
          UPDATE
        </Button>
        {/* <Button
          onClick={
            () => {
              dispatch(
                addSession({
                  sessionName: sessionName,
                  host: selectedUserIds,
                  targetedSkill: selectedSkillsIds.replace(/Others/, otherSkills),
                  duration: duration,
                  sessionDate: sessionDate.format("DD MMM YYYY"),
                  description: description,
                  SaveOrPublish: 1,
                  seatsAlloted: totalSeats,
                  registrationStartDate: dayjs(regStartDate).format("DD MMM YYYY"),
                  registrationEndDate: dayjs(regEndDate).format("DD MMM YYYY")
                })
              );
            }
          }
          type="submit" variant="contained" color="primary" style={{ marginRight: "1rem", height: "3rem" }}>
          Save and Publish
        </Button> */}
        <Button onClick={
          () => {
            setSessionName('')
            setSelectedUsers([])
            setSelectedUserIds("")
            setSelectedSkills([])
            setSelectedSkillsIds("")
            setSessionDate(new Date())
            setRegStartDate(new Date())
            setRegEndDate(new Date())
            setTotalSeats(25)
            setDescription('')
            setDuration('')
            handleClose()
          }
        }
          variant="contained" style={{ marginRight: "1rem", height: "2.9rem",background:"lightgray",color:"black" }} >Close</Button>

      </div>

    </div>
  );
};