import { useEffect, useState, useRef } from "react";
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, ListSubheader,} from "@mui/material";
import "../Tabs/tabsTable.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { registerUserSessionData,getUserDetailsForTrainingAndSession, updateUserRegistration} from "../../redux/actions";
import { Link } from "react-router-dom";
import { getFullName } from "../../common/utils/datatable";
import Loader from "../Loaders/Loader";
import moment from "moment";
import { Modules } from "../../common/constants/sidebar";


// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: "30rem",
//       width: "10rem",
//     },
//   },
// };

const ViewAndNominate = (props) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { allUserDetails, userData } = useSelector(({ USER }) => USER);
  const { listItems,userDetailsForTrainingAndSession,reportees } = useSelector(({ MODULES }) => MODULES);
  const [userMembers, setUserMembers] = useState(null);
  const [userMembersForAdmin,setUserMembersForAdmin] = useState(allUserDetails);
  const [isRegistered,setIsRegistered] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState("");
  const [reason,setReason] = useState('');
  const [openReasonTextbox,setOpenReasonTextbox] = useState(false);
  const { vTrackLoader } = useSelector(({ APP_STATE }) => APP_STATE);

  const [userSkills, setUserSkills] = useState(
    listItems ? listItems.Skills : []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if(userData.data.roleName === "Admin"){
      if (allUserDetails && allUserDetails.length) {
        setUserMembersForAdmin(allUserDetails);
      }
    }
  }, [allUserDetails]);

  useEffect(()=>{
    if(userData.data.roleName === "Project Manager"){
      if (reportees && reportees.length) {
        setUserMembers(prevState => ({
          ...prevState,
          data: reportees
        }));
      }
    }
  },[reportees]);

  useEffect(() => {
    if(userDetailsForTrainingAndSession&&userDetailsForTrainingAndSession.sessionID===props.sessionData.sessionId&&(userDetailsForTrainingAndSession.status==="Registered"||userDetailsForTrainingAndSession.status==="Accepted")){
      setIsRegistered(true);
    }else{
      setIsRegistered(false);
    }
  },[userDetailsForTrainingAndSession]);

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };
  const handleSubmit = () => {
    dispatch(updateUserRegistration({sessionId:props.sessionData.sessionId,reason:reason}));
    setOpenReasonTextbox(false);
    setSelectedUsers([]); setSelectedUserIds(""); handleClose();
  };

  const handleCancel = () => {
    setOpenReasonTextbox(false);
  }

  const handleClose = () => {
    props.onResponse(true);
  };
  const handleUserChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.findIndex((val) => val === "Select All") > -1) {
      if (selectAll) {
        setSelectedUsers([]);
        setSelectedUserIds([]);
      } else {
        if(userData.data.roleName==="Admin"){
          setSelectedUsers(allUserDetails.data);
          let ids = allUserDetails.data.map((user) => {
            return user.id;
          });
          setSelectedUserIds(ids.join(","));
        }else if(userData.data.roleName==="Project Manager"){
          setSelectedUsers(reportees);
          let ids = reportees.map((user) => {
            return user.id;
          });
          setSelectedUserIds(ids.join(","));
        }
      }
      setSelectAll(!selectAll);
    } else {
      setSelectedUsers(value);
      let ids = value.map((user) => {
        return user.id;
      });
      setSelectedUserIds(ids.join(","));
      if(userData.data.roleName==="Project Manager"){
        setUserMembers(prevState => ({
          ...prevState,
          data: reportees
        }));        
      }else if(userData.data.roleName==="Admin"){
        setUserMembersForAdmin(allUserDetails);
      }
    }
  };
  
  const getUsersNames = () => {
    let selectedNames = "";
    if (selectedUsers && selectedUsers.length) {
      for (let i = 0; i < selectedUsers.length; i++) {
        if (selectedUsers[i].lastName) {
          selectedNames += `${selectedUsers[i].firstName} ${selectedUsers[i].lastName}`;
        } else {
          selectedNames += selectedUsers[i].firstName;
        }
        if (i !== selectedUsers.length - 1) selectedNames += ", ";
      }
    }
    return selectedNames;
  };

  const createTopBarForViewAndNominate = () => {
    return (
      <>
      {vTrackLoader && <Loader/>}
      <div >
        <div>
        <div >
        <FormControl sx={{ s: 1, width: "35rem" }}>
        <InputLabel requred id="demo-multiple-checkbox-label">
          Nominate Employee
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedUsers}
          onChange={handleUserChange}
          input={<OutlinedInput label="Nominate Employee"/>}
          renderValue={getUsersNames}
          MenuProps={{ PaperProps: { style: { maxHeight: "20rem", width: "30rem", }, }, }}>
          
          {userData.data.roleName === "Admin" && allUserDetails && (
            <ListSubheader className="subheader" >
              <TextField
                placeholder="Search Here..."
                className="subheader-field"
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                autoFocus={true}
                onChange={(e) => {
                  setUserMembersForAdmin({
                    ...allUserDetails,
                    data: allUserDetails.data.filter(
                      (i) =>
                        i.firstName?.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        i.lastName?.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        i.email?.toLowerCase().includes(e.target.value.toLowerCase())
                    ),
                  });
                }}
              />
            </ListSubheader>
          )}
          
          {userData.data.roleName === "Project Manager" && reportees && (
            
            <ListSubheader className="subheader" >
              <TextField
                placeholder="Search Here..."
                className="subheader-field"
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                autoFocus={true}
                onChange={(e) => {
                  setUserMembers({
                    ...reportees,
                    data: reportees.filter(
                      (i) =>
                        i.firstName?.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        i.lastName?.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        i.email?.toLowerCase().includes(e.target.value.toLowerCase())
                    ),
                  });
                }}
              />
            </ListSubheader>
          )}
          <div disabled = {!selectedUserIds.length||allUserDetails!==userMembersForAdmin||(reportees&&userMembers&&reportees!==userMembers.data)} style={{textAlign:"right",cursor: "pointer",height: "2rem"}}>
            <Link onClick = {() => {setSelectedUsers([]);setSelectedUserIds([]);setSelectAll(false);}} style = {{color: "#1773bc",paddingRight:"1rem"}}>
              Clear Selection
            </Link>
          </div>
          <MenuItem key={`select-all`} value={"Select All"} className = "no-left-margin">
            <Checkbox checked={selectAll} />
            <ListItemText primary = {selectAll? "Select None": "Select All"}/>
            
          </MenuItem>
          {userData.data.roleName === "Admin" && userMembersForAdmin &&
            userMembersForAdmin.data.map((user) => (
              <MenuItem key={user.id} value={user} className="no-left-margin">
              <Checkbox checked={selectedUsers.findIndex((person)=> person.id === user.id)>-1} />
              <ListItemText primary={getFullName(user.firstName,user.lastName)+"("+user.email+")"}/>
              </MenuItem>
            ))}
          {userData.data.roleName === "Project Manager" && userMembers &&
            userMembers.data.map((user) => (
              <MenuItem key={user.id} value={user} className="no-left-margin">
              <Checkbox checked={selectedUsers.findIndex((person)=> person.id === user.id)>-1} />
              <ListItemText primary={getFullName(user.firstName,user.lastName)+"("+user.email+")"}/>
              </MenuItem>
            ))
          }
          
        </Select>
      </FormControl>
    
  </div>
  </div>
  </div>
      </>
    );
  };

  const getHosts = (renderValues) => {
    let hostList = '';
    for (let i = 0; i < renderValues.length; i++) {
      hostList += renderValues[i].hostName;
      if (i !== renderValues.length - 1) {
        hostList += ', ';
      }
    }
    return hostList;
  }
  
  return (
    <div>
      <div style={{ marginBottom: '1rem',marginTop:"-1rem", fontWeight:'bold', fontSize: '1.8rem', color:'#1773BC', display:'flex', justifyContent:'space-between'}}>
          <label>Session Details </label>
      </div>
      <div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ flex: "1", marginBottom: "1rem" }}>
          <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
            <label style={{  marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem",color: "rgb(23, 115, 188)" }}>
              Session Name 
            </label>
            <label style={{ fontFamily: "Montserrat", fontSize: "1.7rem" }}>
              {props.sessionData.sessionName}
            </label>
            </div>
          </div>

          <div style={{ flex: "1", marginBottom: "1rem" }}>
          <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
            <label style={{ marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem" ,color: "rgb(23, 115, 188)"}}>
              Session Hosts 
            </label>
            <label style={{  fontFamily: "Montserrat", fontSize: "1.7rem"}}>
              {getHosts(props.sessionData.hosts)}
            </label>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ flex: "1", marginBottom: "1rem" }}>
            <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
            <label style={{ marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem" ,color: "rgb(23, 115, 188)"}}>
              Skills Targeted 
            </label>
            <label style={{ fontFamily: "Montserrat", fontSize: "1.7rem" }}>
              {props.sessionData.targetedSkill}
            </label>
            </div>
          </div>
          <div style={{ flex: "1", marginBottom: "1rem" }}>
            <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
            <label style={{  marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem",color: "rgb(23, 115, 188)"}}>
              Session Date 
            </label>
            
            <label style={{  fontFamily: "Montserrat", fontSize: "1.7rem"}}>
              {props.sessionData.sessionDate}
            </label>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ flex: "1", marginBottom: "1rem" }}>
          <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
            <label style={{ marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem",color: "rgb(23, 115, 188)" }}>
              Registration Start Date
            </label>
            <label style={{  fontFamily: "Montserrat", fontSize: "1.7rem" }}>
              {props.sessionData.registrationStartDate}
            </label>
            </div>
          </div>
          <div style={{ flex: "1", marginBottom: "1rem" }}>
            <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
            <label style={{ marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem",color: "rgb(23, 115, 188)"}}>
              Registration End Date 
            </label>
            
            <label style={{ fontFamily: "Montserrat", fontSize: "1.7rem" }}>
              {props.sessionData.registrationEndDate}
            </label>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
        {userDetailsForTrainingAndSession&&userDetailsForTrainingAndSession.registationType==="Nominated"&&(<div style={{ flex: "1", marginBottom: "1rem" }}>
          <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
            <label style={{  marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem",color: "rgb(23, 115, 188)" }}>
              You have been Nominated by 
            </label>
            <label style={{  fontFamily: "Montserrat", fontSize: "1.7rem" }}>
              {userDetailsForTrainingAndSession.nominationManagerID}
            </label>
            </div>
          </div>)}
          {userDetailsForTrainingAndSession&&userDetailsForTrainingAndSession.status&&(<div style={{ flex: "1", marginBottom: "1rem" }}>
          <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
            <label style={{  marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem" ,color: "rgb(23, 115, 188)"}}>
              Status 
            </label>
            <label style={{  fontFamily: "Montserrat", fontSize: "1.7rem" }}>
              {userDetailsForTrainingAndSession.status}
            </label>
            </div>
          </div>)}
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
         <div style={{ flex: "1"}}>
          <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
            <label style={{ marginRight:"1rem", fontFamily: "Montserrat", fontSize: "1.5rem",color: "rgb(23, 115, 188)"}}>
              Description 
            </label>
            <label style={{  fontFamily: "Montserrat", fontSize: "1.7rem"}}>
              {props.sessionData.description}
            </label>
            </div>
          </div>
        </div>
      </div>

      {openReasonTextbox&&(<div style={{ display: "flex",paddingTop: "2rem",justifyContent: "flex-end",width:'100%'}}>
      <TextField id="outlined-size-small" label="Reason" size="small" variant="outlined" value={reason} onChange={handleReasonChange} fullWidth />
      <Button onClick={handleSubmit} variant="contained"  color="primary" style={{ marginLeft:"1rem",width:"8.6rem",height:"2.5rem",marginTop:"0.2rem"  }}>Submit</Button>
      <Button onClick={handleCancel}  variant="contained" style={{marginLeft:"1rem",backgroundColor:"#cfcfd4",color:"black",width:"8.6rem" ,height:"2.5rem",marginTop:"0.2rem" }}>Cancel</Button>
      </div>)}
      <div
        style={{
          display: "flex",
          paddingTop: "2rem",
          justifyContent: "flex-end",
        }}
      >
       
        
        {!isRegistered&&(props.sessionData.status==="Published")&&moment().isSameOrBefore(moment(props.sessionData.registrationEndDate),'day')&&moment().isSameOrAfter(moment(props.sessionData.registrationStartDate),'day')&&(
          <Button
          onClick={() => {
            dispatch(registerUserSessionData({
              sessionId:props.sessionData.sessionId,
            }))
            setSelectedUsers([]); setSelectedUserIds(""); handleClose();
          }}
          type="submit"
          variant="contained"
          color="primary"
          style={{  width:"17rem" }}
        >
          {(userData.data.roleName==="Admin" || userData.data.roleName==="Project Manager") ? "Register Yourself" : "Register"}
        </Button>)}
        {isRegistered&&(props.sessionData.status==="Published")&&moment().isSameOrAfter(moment(props.sessionData.registrationStartDate),'day')&&(
          <Button
          onClick={() => {
            setOpenReasonTextbox(true);
          }}
          type="submit"
          variant="contained"
          color="primary"
          disabled={openReasonTextbox}
          style={{ width:"17rem" }}
        >
          Revoke Registration
        </Button>)}
        
        {userData.data.roleName === "Employee"&&(
          <Button
          onClick={() => { setSelectedUsers([]); setSelectedUserIds(""); handleClose(); }} variant="contained" style={{backgroundColor:"#cfcfd4",color:"black",width:"17rem",marginLeft:"1rem"  }} >
          Close
          </Button>)}
        
      </div>
      
      {(props.sessionData.status==='Published')&&moment().isSameOrBefore(moment(props.sessionData.registrationEndDate),'day')&&moment().isSameOrAfter(moment(props.sessionData.registrationStartDate),'day')&&(userData.data.roleName === "Admin"||userData.data.roleName === "Project Manager") &&<svg width="100%" height="1" style={{marginTop:"2rem"}}>
      <line x1="0" y1="0" x2="100%" y2="0" stroke="rgb(23, 115, 188)" strokeWidth="1" /></svg>}


      <div style={{display:"flex",justifyContent:"flex-end"}}>
      {(props.sessionData.status==='Published')&&moment().isSameOrBefore(moment(props.sessionData.registrationEndDate),'day')&&moment().isSameOrAfter(moment(props.sessionData.registrationStartDate),'day')&&(userData.data.roleName === "Admin"||userData.data.roleName === "Project Manager")&&(
      <div style={{ display: "flex",paddingTop: "2rem",width:'100%'}}>
        <span style={{marginTop:"0.5rem",fontSize: "1.5rem",fontWeight: "600",color: "grey",marginRight: "1.5rem",whiteSpace: "nowrap",fontFamily:"Montserrat"}}>Select Your Team Members To Nominate For This Training :</span>
        { createTopBarForViewAndNominate()}
        
        <Button
          onClick={() => {
            dispatch(registerUserSessionData({
              sessionId:props.sessionData.sessionId,
              EmployeeIDs:selectedUserIds
            }))
            setSelectedUserIds("");
            setSelectAll(false);
            setSelectedUsers([]);
          }}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!selectedUserIds.length}
          style={{marginLeft:"2rem", width:"17rem" }}
        >
          Nominate
        </Button>
        </div>)}
        {(userData.data.roleName === "Admin"||userData.data.roleName === "Project Manager")&&<div style={{display:"flex",justifyContent:"flex-end",paddingTop:"2rem"}}>
        <Button
          onClick={() => { setSelectedUsers([]); setSelectedUserIds(""); handleClose(); }} variant="contained" 
          style={{backgroundColor:"#cfcfd4",color:"black",marginLeft:"4.25rem",width:"17rem"}} >
          Close
        </Button>
        </div>
        }
        </div>
    </div>
  );
};
export default ViewAndNominate;