import { call, put, takeLatest } from "redux-saga/effects";
import { sendMissingTimesheetReminderManually } from "../http/requests/reporting";
import { setVtrackLoader } from "../redux/actions";
import { ReportingType} from "../redux/actions/reporting";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workerSendMissingTimesheetReminderManually({ payload }){
  try{
    yield put(setVtrackLoader(true));
    yield call(
      sendMissingTimesheetReminderManually,
      payload.employeeName,
      payload.employeeId,
      payload.periodWeek);
      toast.success("Reminder Sent SuccessFully", toastOptions)
    yield put(setVtrackLoader(false));
  } catch(err){
    console.log(err);
    yield put(setVtrackLoader(false));
  }
}

export function* sendMissingTimesheetReminderManuallySaga() {
  yield takeLatest(
    ReportingType.SEND_MISSING_TIMESHEET_REMINDER_MANUALLY,
    workerSendMissingTimesheetReminderManually
  );
};