import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { updateBillableLeaves } from "../http/requests/project-admin";
import { getBillableLeaves, ProjectAdminType, setVtrackLoader } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerUpdateBillableLeavesSaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    yield call(updateBillableLeaves, payload.data);
    toast.success("Data Updated", toastOptions)
    yield put(
        getBillableLeaves({projectId: payload.data.projectId})
    );
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* updateBillableLeavesSaga() {
  yield takeLatest(
    ProjectAdminType.UPDATE_BILLABLE_LEAVES,
    workerUpdateBillableLeavesSaga
  );
};