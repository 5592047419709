import set from "date-fns/fp/set/index";
import { call, put, takeLatest } from "redux-saga/effects";
import { getAllProjectManagementDetails } from "../http/requests/project-management";
import {
  setVtrackLoader
} from "../redux/actions";
import { ProjectManagementType, setAllProjectManagementData } from "../redux/actions/project-management";

function* workerAllProjectManagementSaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    const allProjectManagementDetails = yield call(getAllProjectManagementDetails);
    yield put(setAllProjectManagementData(allProjectManagementDetails));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* allProjectManagementSaga() {
  yield takeLatest(
    ProjectManagementType.GET_ALL_PROJECT_MANAGEMENT_DATA,
    workerAllProjectManagementSaga
  );
};
