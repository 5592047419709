import { useEffect, useState, useRef } from "react";
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, ListSubheader,} from "@mui/material";
import "../Tabs/tabsTable.css";
import { ToastContainer, toast } from 'react-toastify';
import { toastOptions } from "../../common/utils/toasterOptions";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import {  updateUserRegistration, submitSessionFeedbackDetails, getSessionFeedbackQuestionsList, getSessionFeedbackHeader,getUserDetails} from "../../redux/actions";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import { getFullName } from "../../common/utils/datatable";
import Loader from "../Loaders/Loader";
import moment from "moment";
import { Modules } from "../../common/constants/sidebar";
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";


// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: "30rem",
//       width: "10rem",
//     },
//   },
// };

const SessionFeedback = (props) => {
  const navigate = useNavigate();
  const { allUserDetails, userData } = useSelector(({ USER }) => USER);
  const { sessionFeedbackQuestions,sessionFeedbackHeaderData } = useSelector(({ MODULES }) => MODULES);

  const [decodedSessionId,setDecodedSessionId] = useState(-1);
  const [states, setStates] = useState(Array.from({ length: sessionFeedbackQuestions&&sessionFeedbackQuestions.length }, () => ''));
  const { vTrackLoader } = useSelector(({ APP_STATE }) => APP_STATE);
  const [sessionId,setSessionId] = useState(props.id);
  const [countdown, setCountdown] = useState(5);
  const [timer, setTimer] = useState(false);
  const dispatch = useDispatch();

  const Options = [
    { value: 'Yes', label: 'YES' },
    { value: 'No', label: 'NO' },
    { value: 'Maybe', label: 'MAY BE' }
  ];

  useEffect( () =>{
    let base64Encoded = props.id;
    let bytesDecoded = atob(base64Encoded).split('').map(char => char.charCodeAt(0));
    let decodedInt = bytesDecoded[0] | (bytesDecoded[1] << 8) | (bytesDecoded[2] << 16) | (bytesDecoded[3] << 24);
    setDecodedSessionId(decodedInt)
    if(sessionId!==null){
        dispatch(getSessionFeedbackHeader(decodedInt));
    }
    if(sessionFeedbackHeaderData&&sessionFeedbackHeaderData.isFeedbackSubmitted===1){
        setTimer(true);
    }
   
  },[]);
  useEffect(()=>{
    if(sessionFeedbackHeaderData&&sessionFeedbackHeaderData.isFeedbackSubmitted===1){
        setTimer(true);
    }else{
        setTimer(false);;
    }
  },[sessionFeedbackHeaderData])

  useEffect(() => {
    
    if(timer){
        const redirectTimer = setTimeout(() => {
            if (countdown === 0) {
              setTimer(false);
              navigate('/vTrack/TrainingandSession')
              dispatch(getUserDetails());
            } else {
              setCountdown(countdown - 1); 
            }
          }, 1000); 
          return () => clearTimeout(redirectTimer);
    }
    
  }, [countdown,timer]);


  const handleSubmit = () => {
    const feedbackData = [];

    for (let i = 0; sessionFeedbackQuestions && i<sessionFeedbackQuestions.length; i++) {
        feedbackData.push({
            QuestionId: sessionFeedbackQuestions[i].questionId,
            Response: states[i],
        });
    }
    const requiredFieldsFilled = states.every((state, index) => {
        if (sessionFeedbackQuestions[index].required === 1) {
          return state !== '' && state !== null && state !== undefined;
        } else {
          return true; // If not required, consider it filled
        }
      });

    if (requiredFieldsFilled) {
        dispatch(submitSessionFeedbackDetails({
        SessionId:decodedSessionId,
        Data:feedbackData
        }
        ));
    } else {
        toast.error("Please fill all the fields",toastOptions)
    }
    
  };

  const handleClose = () => {
    navigate('/vTrack/TrainingandSession')
    dispatch(getUserDetails());
    setStates([]);
    setSessionId(null);
  };



const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#197ecd',
    },
    '& .MuiRating-iconHover': {
      color: '#1773bc',
    },
  });
  const handleStateChange = (index, value) => {
    setStates(prevStates => {
      const updatedStates = [...prevStates];
      updatedStates[index] = value; 
      return updatedStates; 
    });
  };

  const createInvoiceEditForm = (type, index) => {
    switch(type){
      case 'Select':
        return(
            <div className="formDivs">
                <FormControl component="fieldset" >
                    <RadioGroup
                    row
                    aria-label="response"
                    name="response"
                    value={states[index]}
                    onChange={(e) => handleStateChange(index, e.target.value)}
                    >
                    {Options.map((opt) => (
                        <FormControlLabel
                        key={opt.value}
                        value={opt.value}
                        control={<Radio color="primary" />}
                        label={opt.label}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: "2.2rem", // Adjust the fontSize to increase the size
                            },
                            '& .MuiFormControlLabel-label': {
                                    fontSize: '1.2rem', // Adjust the font size as needed
                                },
                        }}
                        />
                    ))}
                    </RadioGroup>
                </FormControl>
            </div>
        )  
      case 'Descriptive':
        return(
            <TextField
            placeholder="Type"
            variant="standard"
            value={states[index]}
            onChange={(e) => handleStateChange(index,e.target.value)}
            
            inputProps={{
                style: { maxHeight: "10rem",display:"flex", overflow: "scroll",overflowX:"hidden", fontSize:"1.8rem",border:"none" },
                required: true
            }}
            multiline
        />
        )
      case 'Rating':
        return(
            <div>
                <StyledRating name="size-large" size="large" value={states[index]} onChange={(event, newValue) => { handleStateChange(index,newValue); }}sx={{fontSize:"3rem"}} />
            </div>
        )
    }
  }
  
  return (
    <>
    {vTrackLoader && <Loader />}
    {!vTrackLoader && (
    sessionId===null?(
        <div style={{display:"flex",justifyContent:"Center", alignItems: 'center', height: '50rem',flexDirection:"column"}}>
            <h1 style={{fontSize:"3rem",color:"#333"}}>404 - Session Not Found</h1>
            <p style={{fontSize:"2rem",color:"#666"}}>Oops! It seems like the Session Feedback Form you are looking for does not exist.</p>
            <p style={{fontSize:"2rem",color:"#666"}}>You can Check Your Email or Submit Feedback from Session History Tab</p>
        </div>):(sessionFeedbackHeaderData&&sessionFeedbackHeaderData.isFeedbackSubmitted===1?(
            <div style={{display:"flex",justifyContent:"Center", alignItems: 'center', height: '50rem',flexDirection:"column"}}>
            <h1 style={{fontSize:"2.5rem",color:"#1773BC"}}>You Have Successfully Submitted {sessionFeedbackHeaderData.sessionName} Feedback Form</h1>
            <h2 style={{fontSize:"2.5rem",color:"black"}}>
                You will be redirected  in {countdown} seconds
            </h2>
        </div>
        ):(
        <div style={{display:"flex",flexDirection :"column",maxHeight: "70rem", overflowY: "auto",paddingLeft:"6rem",paddingRight:"6rem",boxShadow: "-0.5rem 0 0.5rem -0.5rem rgba(0, 0, 0, 0.5),0.5rem 0 0.5rem -0.5rem rgba(0, 0, 0, 0.5),0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.5)"}}>
            <div style={{margin:"4rem 0 4rem 0",minHeight:"13rem",fontWeight:'bold', fontSize: '2.5rem', color:'#1773BC', display:'flex', flexDirection:"column",boxShadow: '0 0.25rem 0.2rem 0 rgb(0 0 0 / 20%)',backgroundColor:"#fafcfd",borderBottom: '0.5rem solid #1773BC'}}>
                {sessionFeedbackHeaderData?(<label style={{padding:"2rem"}}> {sessionFeedbackHeaderData.sessionName} Feedback Form</label>):<label> Feedback Form</label>}
                <label style={{padding:"0 2rem 0 2rem", fontSize:"1.5rem", color:"red",fontWeight:"lighter"}}>* Required</label>
            </div>
            <div style={{ display: "flex",flexDirection:"column",paddingBottom:"2rem" }}>
                {
                    sessionFeedbackQuestions&&sessionFeedbackQuestions.map((question,index) => (
                        <div style={{display:"flex",flexWrap:"wrap",padding:"3rem",marginBottom:"2rem",flexDirection:"column",boxShadow: '0 0.25rem 0.2rem 0 rgb(0 0 0 / 20%)',backgroundColor:"#fafcfd"}}>
                        <label style={{ paddingBottom:"3rem",fontFamily: "Montserrat", fontSize: "1.8rem",color:"black"}}>
                        {question.question}
                        {question.required===1?(<span style={{color:"red"}}> *</span>):(null)}
                        </label>
                        {createInvoiceEditForm(question.responseType,index)}
                        </div>
                    ))
                }
            </div>
            <div style={{display:"flex",justifyContent:"flex-end", padding:"2rem 0 2rem 0"}}>
                <Button onClick={handleSubmit} variant="contained"  color="primary" style={{ width:"20rem",height:"3.5rem",marginTop:"0.2rem",marginBottom:"1rem"  }}>Submit</Button>
                <Button onClick={handleClose}  variant="contained" style={{marginLeft:"2rem",backgroundColor:"#cfcfd4",color:"black",width:"20rem" ,height:"3.5rem",marginTop:"0.2rem",marginBottom:"1rem" }}>Close</Button>
            </div>
        </div>
     )))}
    </>
  );
};
export default SessionFeedback;