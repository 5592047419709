// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title{
    font-weight: 600 !important;
    font-size: 2rem !important;
    line-height: 2.5rem !important;
    text-align: center;
}
.dialogActions{
    justify-content: flex-end !important;
    display: flex  !important;
    flex-direction: row  !important;
    gap: 1rem  !important;
    align-items: center  !important;
}
.wrapper{
    padding:3rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/DialogBox/dialogBox.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,0BAA0B;IAC1B,8BAA8B;IAC9B,kBAAkB;AACtB;AACA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,+BAA+B;IAC/B,qBAAqB;IACrB,+BAA+B;AACnC;AACA;IACI,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":[".title{\r\n    font-weight: 600 !important;\r\n    font-size: 2rem !important;\r\n    line-height: 2.5rem !important;\r\n    text-align: center;\r\n}\r\n.dialogActions{\r\n    justify-content: flex-end !important;\r\n    display: flex  !important;\r\n    flex-direction: row  !important;\r\n    gap: 1rem  !important;\r\n    align-items: center  !important;\r\n}\r\n.wrapper{\r\n    padding:3rem 2rem 2rem 2rem;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 3rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
