import { call, put, takeLatest } from "redux-saga/effects";
import { deleteMultipleFileView } from "../http/requests/multiple-file-view";
import { getProjectAdminData, ProjectAdminType, setVtrackLoader } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";
import { getMultipleFileView } from "../http/requests/multiple-file-view";
import { MultipleFileViewType } from "../redux/actions/multiple-file-view";

function* workerDeleteMultipleFileViewSaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    yield call(deleteMultipleFileView,payload.type, payload.ID);
    toast.success("Data Deleted", toastOptions)
    //yield call(getMultipleFileView, payload.type,payload.Id);
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* deleteMultipleFileViewSaga() {
  yield takeLatest(
    MultipleFileViewType.DELETE_MULTIPLE_FILE_VIEW,
    workerDeleteMultipleFileViewSaga
  );
};
