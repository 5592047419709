import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  OutlinedInput,
  Select,
  styled,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Button} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import GroupsIcon from '@mui/icons-material/Groups';
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MyPopupComponent from "../ReasonDialogBox/MyPopupComponent";
import MultipleFileViewPopup from "../ReasonDialogBox/MultipleFileViewPopup";
import ResourcingDetailsPopup from "../ReasonDialogBox/ResourcingDetailsPopup";
import ViewAndNominate from "../Session/ViewAndNominate"
import SessionFeedback from "../Session/SessionFeedback"
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs  from "dayjs";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modules } from "../../common/constants/sidebar";
import Dialog from "@mui/material/Dialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { connect } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PropTypes from "prop-types";
import { parse, subDays } from 'date-fns';
import { makeStyles } from "@material-ui/core/styles";
import {
  AddDisableIcon,
  AddEnableIcon,
  approveIcon,
  crossIcon,
  deleteIcon,
  downloadIcon,
  editIcon,
  hrSelfService,
  rejectIcon,
  TableArrows,
} from "../../common/icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  convertDateToDDMYYYY,
  fileHandler,
  getApprovers,
  getApproversIds,
  getApproversWithIds,
  getHosts,
  getHostsWithIds,
  getFullName,
  getLabel,
  getisRequiredofColumn,
  getTypeofColumn,
  initialSort,
  UniqueIds,
  dateCalc,
  getSkillsForTooltip,
} from "../../common/utils/datatable";
import {
  createData,
  dropDownMockData,
  initialData,
} from "../../mock-data/TableData";
import {
  deleteInvoice,
  deleteProjectAdminData,
  getProjectAdminData,
  getProjectAllocationData,
  getProjectById,
  postProjectAdminFile,
  saveInvoiceTrackerData,
  saveProjectAdminData,
  setVtrackLoader,
  updateProjectAdminData,
  getUserDetailsForTrainingAndSession,
  getSkillDataGrid,
  setReportingData,
  getLeaveDetails,
  getViewAttendanceData,
  deleteSessionData,
  getParticipantsData,
  getReportees,
  getSessionFeedbackQuestionsList,
  getUserDetails,
  sendMissingTimesheetReminderManually,
  clonePreviousWeekTimesheet,
} from "../../redux/actions";
import {
  deleteClientAdminData,
  getClientAdminData,
  postClientAdminFile,
  saveClientAdminData,
  updateClientAdminData
} from "../../redux/actions/client-admin";
import { downloadProjectAdminFile, getResourcingData } from "../../redux/actions/project-admin";
import {
  deleteProjectManagementData,
  getProjectManagementData,
  saveProjectManagementData,
  updateProjectManagementData,
} from "../../redux/actions/project-management";
import {
  deleteTimeSheetData,
  saveTimeSheetData,
  updateTimeSheetData,
  updateTimeSheetStatus
} from "../../redux/actions/timesheet";
import DialogBox from "../DialogBox/dialogBox";
import Loader from "../Loaders/Loader";
import "./DataTable.css";
import { DetailView } from "../DetailView/DetailView";
import { toast } from "react-toastify";
import { useEffect } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getCircularProgressColor } from "../../common/utils/circular-progress-color";
import ExportExcel from "../ExportExcel";
import { FormView } from "../FormView/formView";
import { SessionUpdate } from "../Session/SessionUpdate";
import {ParticipantListPopUp} from "../Session/SessionParticipants";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
} from "../../common/constants/extra-constants";
import { ROLES } from "../../common/constants/extra-constants";
import { EmployeeView } from "../EmployeeView/employeeView";
import { listItemsSaga } from "../../saga/get-list-items-saga";
import { set } from "date-fns";
import { getAllocationChangeReport } from "../../redux/actions";
import { modulesState } from "../../redux/reducers/modules";
import { create } from "@mui/material/styles/createTransitions";
import { getMultipleFileView } from "../../redux/actions/multiple-file-view";
import { CreateCalendar } from "../Calendar/Calendar";
import WarningPopup from "../ReasonDialogBox/WarningPopup";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

export const DataTable = ({
  headingName,
  tabName,
  rows,
  columns,
  setColumns,
  setRows,
  totalRecord,
  isAddButtonClicked,
  setIsAddButtonClicked,
  isEditButtonClicked,
  setIsEditButtonClicked,
  searchData,
  resetSearchData,
  projectStatus,
  selectedPeriodWeek,
  projectId,
  rowToBeUpdated,
  setRowToBeUpdated,
  dataForExcel,
  billStatus,
  expiredAllocations,
  selectedUserIds,
  isHistory,
  startDate,
  endDate,
  selectedUserID,
  viewAttendanceClicked,
  setSelectedTimesheetRows,
  selectedTimesheetRows,
}) => {
  const {
    clientsData,
    allTasks,
    listItems,
    assignedProjects,
    clientAdminData,
    projectAdminData,
    mappedProjectManagementData,
    selectedProjectData,
    exportProjectManagementData,
    allProjectManagementData,
    allocationChangeReportDataForExcel,
    projectAllocationData,
    reportingData,
    skillsList,
    viewAttendanceData,
    leaveDetails,
    allocationChangeReportByEmployeeDataForExcel,
    sessionsDataForExcel,
    timeSheetData
  } = useSelector(({ MODULES }) => MODULES);

  const allocationChangeReportData = useSelector(
    ({ MODULES }) => MODULES.allocationChangeReportData
  );
  const allocationChangeReportDataByEmployee = useSelector(
    ({ MODULES }) => MODULES.allocationChangeReportDataByEmployee
  );
  const multipleFileViewData = useSelector(
    ({ MODULES }) => MODULES.multipleFileViewData
  );
  const { userData, allUserDetails, allUserDetailsDict,notificationsList } = useSelector(
    ({ USER }) => USER
  );
  const { vTrackLoader } = useSelector(({ APP_STATE }) => APP_STATE);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [newRowAdded, setNewRowAdded] = useState(
    initialData(headingName, selectedPeriodWeek)
  );
  const [sortBy, setSortBy] = useState(initialSort[headingName]);
  const [fileState, setFileState] = useState("");
  const [showDialogBox, setShowDialogBox] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const [dialogDeleteButtonClicked, setDialogDeleteButtonClicked] =
    useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [selectedEmpId, setSelectedEmpId] = useState("");
  const [teamMembers, setTeamMembers] = useState(allUserDetails);
  const [managerTeam, setManagerTeam] = useState(allUserDetails);
  const [ownerTeam, setOwnerTeam] = useState(allUserDetails);
  const [deliveryOfficerTeam, setDeliveryOfficerTeam] =
    useState(allUserDetails);
  const [approversTeam, setApproversTeam] = useState(allUserDetails);
  const [filterProjectDropdownData, setFilterProjectDropdownData] =
    useState(null);
  const [filterClientDropdownData, setFilterClientDropdownData] =
    useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState("");
  const [formViewDetails, setFormViewDetails] = useState(false);
  const [selectedProjectAllocationId, setSelectedProjectAllocationId] =
    useState("");
  const [billRateDisable, setBillRateDisable] = useState(false);
  const [viewEmployeeDetails, setViewEmployeeDetails] = useState(false);
  const [sortAsc, setSortAsc] = useState(true);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [openMultipleFileView, setMultipleFileView] = useState(false);
  const [openResourcingDetailsPopup,setOpenResourcingDetailsPopup] = useState(false);
  const [rowIDMultipleViewPopup, setRowIDMultipleViewPopup] = useState(-1);
  const [rowIdResourcingDetailsPopup, setRowIdResourcingDetailsPopup] = useState(-1);
  const [rowIDRejectTimesheetPopup, setRowIDRejectTimesheetPopup] = useState(-1);
    const [openViewAndNominateSession, setViewAndNominateSession] = useState(false);
  const [rowIDViewAndNominateSession, setRowIDViewAndNominateSession] = useState(-1);
  const [openEditSessionPopup, setOpenEditSessionPopup] = useState(false);
  const [rowIDEditSession, setRowIDEditSession] = useState();
  const [openParticipantsListView, setParticipantsListView] = useState(false);
  const [openSessionFeedbackPopup, setOpenSessionFeedbackPopup] = useState(false);
  const [rowIDSessionFeedbackPopup,setRowIDSessionFeedbackPopup]= useState(-1);
  const [key,setKey] = useState(0);
  const [warningPopup, setWarningPopup] = useState(false);
  const [warningPopupType, setWarningPopupType] = useState(null);
  const projectManagementData = useSelector(({ MODULES }) => MODULES.projectManagementData)
  const resourcingData = useSelector(({ MODULES }) => MODULES.resourcingData)
  let navigate = useNavigate(); 
  const routeChange = (Id) =>{
    let byteArray = new Uint8Array([
      (Id & 0xff),
      ((Id >> 8) & 0xff),
      ((Id >> 16) & 0xff),
      ((Id >> 24) & 0xff)
    ]);
    
    // Encode the byte array to Base64
    let base64Encoded = btoa(String.fromCharCode.apply(null, byteArray));
    
    let path = `/vTrack/SessionFeedback?id=${base64Encoded}`; 
    // console.log(Id);

    navigate(path);
  }
const handleOpenEditSessionPopup = (res) => {
  setOpenEditSessionPopup(!res)
};
const handleCloseEditSessionPopup = () => {
  setOpenEditSessionPopup(false)
};
const handleOpenParticipantsListViewPopup = (res) => {
  setParticipantsListView(!res)
};
const handleCloseParticipantsListViewPopup = () => {
  setParticipantsListView(false)
};  

  const handleDialogBox = (res) => {
    setOpenDialogBox(!res);
    setRowIDRejectTimesheetPopup(-1);
  };
  const handleClose = () => {
    setOpenDialogBox(false);
    setMultipleFileView(false);
    setViewAndNominateSession(false);
    setOpenSessionFeedbackPopup(false);
    setRowIDRejectTimesheetPopup(-1);
    setRowIDMultipleViewPopup(-1);
    setRowIDViewAndNominateSession(-1);
    setRowIDSessionFeedbackPopup(-1);
    setRowIdResourcingDetailsPopup(-1);
    setOpenResourcingDetailsPopup(false);
    setWarningPopup(false);
  };
  const handleclonePreviousWeekTimesheet = () => {

    const currentPeriodweek=selectedPeriodWeek.startDate.format(DATE_FORMAT) +" - " +selectedPeriodWeek.endDate.format(DATE_FORMAT);
    const previousStartDate = moment(selectedPeriodWeek.startDate).subtract(7, 'days').format(DATE_FORMAT);
    const previousEndDate = moment(selectedPeriodWeek.endDate).subtract(7, 'days').format(DATE_FORMAT);
    const previousPeriodWeek=previousStartDate+" - "+previousEndDate;
    dispatch(clonePreviousWeekTimesheet({selectedPeriodWeek:currentPeriodweek,previousPeriodWeek:previousPeriodWeek}));
  }
  const handleMultipleFileView = (res) => {
    setMultipleFileView(!res);
    setRowIDMultipleViewPopup(-1);
  };
  const handleResourcingDetails = (res) => {
    setOpenResourcingDetailsPopup(!res);
    setRowIdResourcingDetailsPopup(-1);
  }
  const handleSessionFeedbackView = (res) => {
    setOpenSessionFeedbackPopup(!res);
    setRowIDSessionFeedbackPopup(-1);
  };

  const handleViewAndNominateSessionPopup = (res) => {
    setViewAndNominateSession(!res);
    setRowIDViewAndNominateSession(-1);
  };

  const handleWarningPopup = (res) => {
    if(res){
      if(warningPopupType==="save")
        dispatch(
          saveProjectManagementData({
            ...newRowAdded,
            projectId: projectId,
            pageNo: page,
            rows: rowsPerPage,
          })
        );
      else if(warningPopupType==="update")
        dispatch(
          updateProjectManagementData({
            ...newRowAdded,
            projectId: projectId,
            pageNo: page,
            rows: rowsPerPage,
          })
        );
    }
    
    setWarningPopupType(null)
    setWarningPopup(false);
  }

  const validateProjectAllocation = (data) =>{
    if(data.billStatus === "Billable"){
      let flag = true
      const startDate = new Date(data.startDate);
      let currYear = startDate.getFullYear();
      let currMonth = startDate.getMonth(); // Months are zero-indexed (0 = January, 11 = December
          
      let currentMonthPACount = 0.0
      projectManagementData && projectManagementData.data && projectManagementData.data.length && 
      projectManagementData.data.forEach((paData) => {
          const paStartDate = new Date(paData.startDate);
          const paEndDate = new Date(paData.endDate);
          console.log(paData)
          if(paData.billStatus==="Billable"&&startDate<=paEndDate && startDate>=paStartDate&&paData.employeeId!==data.employeeId[0]){
              currentMonthPACount += (Number(paData.billAllocation)/100.0);
          }
      });
      currentMonthPACount+=Number(data.billAllocation)/100.0;

      // Find the matching resourcingData entry for the current month and year
      const currentResourcingData = resourcingData.find(
          (resData) => 
              resData.year === currYear.toString() && 
              resData.month === new Date(currYear, currMonth).toLocaleString('default', { month: 'long' })
      );
      if(currentResourcingData&&currentResourcingData.billableResourceCount<currentMonthPACount){
        return false;
      }
      else return true;
    }
    else
    return true;
  }

  const setDialogBoxText = () => {
    if (headingName === Modules.PROJECT_ADMIN) {
      return "Delete action on Project  will impact all active allocations for this project.  Are you sure you want to delete?";
    } else if (headingName === Modules.CLIENT_ADMIN) {
      return "Delete action on Client data will delete all active projects for this client. Are you sure you want to delete?";
    }
    return "Are you sure you want to Delete?";
  };

  const extractDatesForTimesheetReminder = (period) => {
    const [startDateStr, endDateStr] = period.split('-').map(date => date.trim());
    const startDate = parse(startDateStr, 'dd MMM yyyy', new Date());
    const endDate = parse(endDateStr, 'dd MMM yyyy', new Date());
    const fridayDate = subDays(endDate, 2);
    const currentDate = new Date();
    if(currentDate>=fridayDate){
      return true;
    }else{
      return false;
    }
  };
  
  const getClientAdminORProjectAdminData = (file, id, name, headingName) => {
    if (file) {
      const formData = new FormData();
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
      if (headingName === Modules.CLIENT_ADMIN) {
        dispatch(
          postClientAdminFile({
            id: id,
            name: name,
            data: formData,
            pageNo: page,
            rows: rowsPerPage,
          })
        );
      } else if (headingName === Modules.PROJECT_ADMIN) {
        dispatch(
          postProjectAdminFile({
            id: id,
            name: name,
            data: formData,
            pageNo: page,
            rows: rowsPerPage,
          })
        );
      }
    }
  };

  const handleSelectAllForPendingApproval = () => {
    if (rows&&rows.length&&selectedTimesheetRows.length===rows.length) {
      setSelectedTimesheetRows([]);
    } else if(rows){
      const allRowIds = rows.map(row => row.timesheetDetailID);
      setSelectedTimesheetRows(allRowIds);
    }
  };

  const getValueOfSelect = (id) => {
    if (id === "projectManagerName") {
      return getNameAndEmail(newRowAdded["projectManagerId"]);
    } else if (id === "deliveryOfficer") {
      return getNameAndEmail(newRowAdded["deliveryOfficerId"]);
    } else if (id === "businessOwner") {
      return getNameAndEmail(newRowAdded["businessOwnerId"]);
    } else {
      return null;
    }
  };

  const filterNamesHandler = (e, col) => {
    if (col === "employeeName") {
      setTeamMembers({
        ...allUserDetails,
        data: allUserDetails.data.filter(
          (i) =>
            i.firstName?.toLowerCase().includes(e.toLowerCase()) ||
            i.lastName?.toLowerCase().includes(e.toLowerCase()) ||
            i.email?.toLowerCase().includes(e.toLowerCase())
        ),
      });
    } else if (col === "projectManagerName") {
      setManagerTeam({
        ...allUserDetails,
        data: allUserDetails.data.filter(
          (i) =>
            i.firstName?.toLowerCase().includes(e.toLowerCase()) ||
            i.lastName?.toLowerCase().includes(e.toLowerCase()) ||
            i.email?.toLowerCase().includes(e.toLowerCase())
        ),
      });
    } else if (col === "businessOwner") {
      setOwnerTeam({
        ...allUserDetails,
        data: allUserDetails.data.filter(
          (i) =>
            i.firstName?.toLowerCase().includes(e.toLowerCase()) ||
            i.lastName?.toLowerCase().includes(e.toLowerCase()) ||
            i.email?.toLowerCase().includes(e.toLowerCase())
        ),
      });
    } else if (col === "deliveryOfficer") {
      setDeliveryOfficerTeam({
        ...allUserDetails,
        data: allUserDetails.data.filter(
          (i) =>
            i.firstName?.toLowerCase().includes(e.toLowerCase()) ||
            i.lastName?.toLowerCase().includes(e.toLowerCase()) ||
            i.email?.toLowerCase().includes(e.toLowerCase())
        ),
      });
    } else if (col === "approvers") {
      setApproversTeam({
        ...allUserDetails,
        data: allUserDetails.data.filter(
          (i) =>
            i.firstName?.toLowerCase().includes(e.toLowerCase()) ||
            i.lastName?.toLowerCase().includes(e.toLowerCase()) ||
            i.email?.toLowerCase().includes(e.toLowerCase())
        ),
      });
    }
  };

  const saveDataHandler = () => {
    // setbillRateAmount(0);
    let validProjectAllocationData;
    if (!isEditButtonClicked) {
      if (headingName === Modules.CLIENT_ADMIN) {
        dispatch(
          saveClientAdminData({
            ...newRowAdded,
            pageNo: page,
            rows: rowsPerPage,
          })
        );
      } else if (headingName === Modules.PROJECT_ADMIN) {
        let approverIds = getApproversIds(newRowAdded.approvers);
        dispatch(
          saveProjectAdminData({
            ...newRowAdded,
            projectApprovers: approverIds,
            pageNo: page,
            rows: rowsPerPage,
          })
        );
      } else if (headingName === Modules.PROJECT_MANAGEMENT) {
        validProjectAllocationData = validateProjectAllocation(newRowAdded)
        if (validProjectAllocationData) {
          dispatch(
            saveProjectManagementData({
              ...newRowAdded,
              projectId: projectId,
              pageNo: page,
              rows: rowsPerPage,
            })
          );
        }
        else {
          setWarningPopupType("save");
          setWarningPopup(true);
        }
      } else if (headingName === Modules.INVOICE_TRACKER) {
        dispatch(saveInvoiceTrackerData({ ...newRowAdded }));
      }
    } else {
      if (headingName === Modules.CLIENT_ADMIN) {
        if (fileState && newRowAdded.clientId && newRowAdded.clientName) {
          fileHandler(
            fileState,
            newRowAdded.clientId,
            newRowAdded.clientName,
            headingName
          );
        }
        dispatch(
          updateClientAdminData({
            ...newRowAdded,
            pageNo: page,
            rows: rowsPerPage,
          })
        );
      } else if (headingName === Modules.PROJECT_ADMIN) {
        if (fileState && newRowAdded.projectId && newRowAdded.projectName) {
          fileHandler(
            fileState,
            newRowAdded.projectId,
            newRowAdded.projectName,
            headingName
          );
        }
        let approverIds = getApproversIds(newRowAdded.approvers);
        dispatch(
          updateProjectAdminData({
            ...newRowAdded,
            projectApprovers: approverIds,
            pageNo: page,
            rows: rowsPerPage,
            expiredAllocations: expiredAllocations,
          })
        );
      } else if (headingName === Modules.PROJECT_MANAGEMENT) {
        validProjectAllocationData = validateProjectAllocation(newRowAdded)
        if(validProjectAllocationData)
          {dispatch(
            updateProjectManagementData({
              ...newRowAdded,
              projectId: projectId,
              pageNo: page,
              rows: rowsPerPage,
            })
          );}
          else {
            setWarningPopupType("update");
            setWarningPopup(true);
          }
      } else if (headingName === Modules.INVOICE_TRACKER) {
        dispatch(saveInvoiceTrackerData({ ...newRowAdded, pageNo: page }));
        let filteredRows = rows.filter((row) => {
          return row.billMonth !== newRowAdded.billMonth;
        });
        setRows(filteredRows);
      }
      setIsEditButtonClicked(false);
    }
    if (headingName === Modules.TIMESHEET) {
      const dateHours = [];
      const restProps = {};
      let totalHrs = 0;
      Object.keys(newRowAdded).forEach((key) => {
        if (moment(key).isValid()) {
          dateHours.push({
            date: key,
            hours: newRowAdded[key],
          });
          if (newRowAdded[key] !== "") totalHrs += parseFloat(newRowAdded[key]);
        } else {
          restProps[key] = newRowAdded[key];
        }
      });
      restProps["dateHours"] = [...dateHours];
      let finalHours = totalHrs.toFixed(2);
      if (finalHours.split(".")[1] === "00") finalHours = parseInt(totalHrs);
      restProps["totalHrs"] = finalHours;
      isEditButtonClicked
        ? dispatch(updateTimeSheetData(restProps))
        : dispatch(saveTimeSheetData(restProps));
    }
    setIsAddButtonClicked(false);
    setRowToBeUpdated({});
    validProjectAllocationData && setNewRowAdded(initialData(headingName, selectedPeriodWeek));
    resetSearchData();
  };

  const closeButtonHandler = () => {
    // setbillRateAmount(0);
    if (!isEditButtonClicked) {
      setIsAddButtonClicked(false);
    } else {
      setIsEditButtonClicked(false);
    }
    setRowToBeUpdated({});
    setFilterProjectDropdownData(null);
    setSelectedClient(null);
    setSelectedProject(null);
    setNewRowAdded(initialData(headingName, selectedPeriodWeek));
    setTeamMembers(allUserDetails);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (headingName === Modules.CLIENT_ADMIN) {
      dispatch(
        getClientAdminData({
          pageNo: newPage + 1,
          pageSize: rowsPerPage,
          sortBy: sortBy,
          sortDir: "ASC",
          searchData: searchData,
        })
      );
    } else if (headingName === Modules.PROJECT_ADMIN) {
      dispatch(
        getProjectAdminData({
          pageNo: newPage + 1,
          pageSize: rowsPerPage,
          sortBy: sortBy,
          sortDir: "ASC",
          searchData: searchData,
          expiredAllocations: expiredAllocations,
        })
      );
    } else if (headingName === Modules.PROJECT_ALLOCATION) {
      dispatch(
        getProjectAllocationData({
          pageNo: newPage + 1,
          pageSize: rowsPerPage,
          sortBy: sortBy,
          sortDir: "ASC",
          searchData: searchData,
          status: projectStatus,
          employeeID: "",
        })
      );
    } else if (headingName === Modules.PROJECT_MANAGEMENT) {
      dispatch(
        getProjectManagementData({
          projectId: projectId,
          pageNo: newPage + 1,
          pageSize: rowsPerPage,
          sortBy: sortBy,
          sortDir: "ASC",
          searchData: searchData,
          billStatus: billStatus,
          expiredAllocations: expiredAllocations,
        })
      );
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    if (headingName === Modules.CLIENT_ADMIN) {
      dispatch(
        getClientAdminData({
          pageNo: 1,
          pageSize: event.target.value,
          sortBy: sortBy,
          sortDir: "ASC",
          searchData: searchData,
        })
      );
    } else if (headingName === Modules.PROJECT_ADMIN) {
      dispatch(
        getProjectAdminData({
          pageNo: 1,
          pageSize: event.target.value,
          sortBy: sortBy,
          sortDir: "ASC",
          searchData: searchData,
          expiredAllocations: expiredAllocations,
        })
      );
    } else if (headingName === Modules.PROJECT_ALLOCATION) {
      dispatch(
        getProjectAllocationData({
          pageNo: 1,
          pageSize: event.target.value,
          sortBy: sortBy,
          sortDir: "ASC",
          searchData: searchData,
          status: projectStatus,
          employeeID: "",
        })
      );
    } else if (headingName === Modules.PROJECT_MANAGEMENT) {
      dispatch(
        getProjectManagementData({
          projectId: projectId,
          pageNo: 1,
          pageSize: event.target.value,
          sortBy: sortBy,
          sortDir: "ASC",
          searchData: searchData,
          billStatus: billStatus,
          expiredAllocations: expiredAllocations,
        })
      );
    }
  };
  const inputFieldHandler = (event, col, subcol = null) => {
    let valuesArray = event.target.value.split(".");
    if (valuesArray.length !== 1 && valuesArray[1] >= 100) {
      toast.info(`You can not enter values after 2 decimal places`);
    } else if (
      event.target.value !== "" &&
      (event.target.id === "time" || col === "billAllocation") &&
      (parseFloat(event.target.value) < parseFloat(event.target.min) ||
        parseFloat(event.target.value) > parseFloat(event.target.max))
    ) {
      toast.info(
        `Please Enter values between ${event.target.min}-${event.target.max}`
      );
    } else {
      setNewRowAdded({ ...newRowAdded, [col]: event.target.value });
    }
  };

  const handleSortBy = (colName) => {
    setSortBy(colName);
    let sortDirection;
    let idx = columns.findIndex((col) => col.id === colName);

    if (columns[idx].sortDir === "ASC") {
      columns[idx].sortDir = "DESC";
      sortDirection = "DESC";
    } else {
      columns[idx].sortDir = "ASC";
      sortDirection = "ASC";
    }
    if (headingName === Modules.CLIENT_ADMIN) {
      dispatch(
        getClientAdminData({
          pageNo: page + 1,
          pageSize: rowsPerPage,
          sortBy: colName,
          sortDir: sortDirection,
          searchData: searchData,
        })
      );
    } else if (headingName === Modules.PROJECT_ADMIN) {
      dispatch(
        getProjectAdminData({
          pageNo: page + 1,
          pageSize: rowsPerPage,
          sortBy: colName,
          sortDir: sortDirection,
          searchData: searchData,
          expiredAllocations: expiredAllocations,
        })
      );
    } else if (headingName === Modules.PROJECT_ALLOCATION) {
      dispatch(
        getProjectAllocationData({
          pageNo: page + 1,
          pageSize: rowsPerPage,
          sortBy: colName,
          sortDir: sortDirection,
          searchData: searchData,
          status: projectStatus,
          employeeID: "",
        })
      );
    } else if (headingName === Modules.PROJECT_MANAGEMENT) {
      dispatch(
        getProjectManagementData({
          projectId: projectId,
          pageNo: page + 1,
          pageSize: rowsPerPage,
          sortBy: colName,
          sortDir: sortDirection,
          searchData: searchData,
          expiredAllocations: expiredAllocations,
          billStatus: billStatus,
        })
      );
    } else if (headingName === Modules.SKILLS) {
      if (sortAsc) {
        {
          rows &&
            rows.length &&
            setRows(
              rows.sort((a, b) =>
                a[`${colName}`].toLowerCase() > b[`${colName}`].toLowerCase()
                  ? 1
                  : -1
              )
            );
        }
        setSortAsc(!sortAsc);
      } else {
        {
          rows &&
            rows.length &&
            setRows(
              rows.sort((a, b) =>
                a[`${colName}`].toLowerCase() < b[`${colName}`].toLowerCase()
                  ? 1
                  : -1
              )
            );
        }
        setSortAsc(!sortAsc);
      }
    }
  };

  const handleChange = (event, col) => {
    let tempList = event.target.value;

    if (col.id === "employeeName") {
      let employeeIds = [];
      tempList.forEach((emp) => {
        employeeIds.push(emp["employeeId"]);
      });
      let idx = tempList.findIndex(
        (ele) => ele.employeeId === tempList[tempList.length - 1].employeeId
      );
      if (idx !== tempList.length - 1) {
        tempList.pop();
        tempList.splice(idx, 1);
      }
      setNewRowAdded({
        ...newRowAdded,
        employeeName: tempList,
        employeeId: employeeIds,
      });
    } else if (col.id === "approvers") {
      let idx = tempList.findIndex(
        (ele) => ele.approverId === tempList[tempList.length - 1].approverId
      );
      if (idx !== tempList.length - 1) {
        tempList.pop();
        tempList.splice(idx, 1);
      }
      setNewRowAdded({ ...newRowAdded, approvers: tempList });
    }
  };
  const handleColumnDisplay = (id) => {
    if(tabName==="TRAINING AND SESSION"&&userData?.data?.roleName==="Employee"&&id==='actions'){
      return true;
    }
    return false;
  }

  const displayMenuItem = (col) => {
    if (headingName === Modules.INVOICE_TRACKER) {
      if (col === "billCurrency" || col === "revenueCurrency") {
        return (
          listItems &&
          listItems["billCurrency"].map((option) => (
            <MenuItem
              key={option.id}
              value={option.longCodeValue}
              onClick={() =>
                setNewRowAdded({
                  ...newRowAdded,
                  [col]: option.longCodeValue,
                })
              }
            >
              {option.longCodeValue}
            </MenuItem>
          ))
        );
      }
      if (col === "clientName") {
        return (
          filterClientDropdownData &&
          filterClientDropdownData.map((option) => (
            <MenuItem
              key={option.id}
              value={option.name}
              onClick={() => {
                setSelectedClient(option.id);
                setNewRowAdded({
                  ...newRowAdded,
                  [col]: option.name,
                  clientId: option.id,
                });
              }}
            >
              {option.name}
            </MenuItem>
          ))
        );
      } else if (selectedClient && col === "projectName") {
        return (
          filterProjectDropdownData &&
          filterProjectDropdownData.map((option) => (
            <MenuItem
              key={option.id}
              value={option.name}
              onClick={() => {
                setSelectedProject(option.id);
                setNewRowAdded({
                  ...newRowAdded,
                  [col]: option.name,
                  projectId: option.id,
                });
              }}
            >
              {option.name}
            </MenuItem>
          ))
        );
      }
    } else {
      if (col === "clientName") {
        return (
          clientsData &&
          clientsData.map((option) => (
            <MenuItem
              key={option.id}
              value={option.name}
              onClick={() =>
                setNewRowAdded({
                  ...newRowAdded,
                  [col]: option.name,
                  clientId: option.id,
                })
              }
            >
              {option.name}
            </MenuItem>
          ))
        );
      } else if (
        col === "currency" ||
        col === "paymentTerms" ||
        col === "location" ||
        col === "status" ||
        col === "employeeRole"
      ) {
        return (
          listItems &&
          listItems[col].map((option) => (
            <MenuItem
              key={option.id}
              value={option.longCodeValue}
              onClick={() =>
                setNewRowAdded({
                  ...newRowAdded,
                  [col]: option.longCodeValue,
                  [`${col}Id`]: option.id,
                })
              }
            >
              {option.longCodeValue}
            </MenuItem>
          ))
        );
      } else if (col === "billFrequency" || col === "billCurrency") {
        return (
          listItems &&
          listItems[col].map((option) => (
            <MenuItem
              key={option.id}
              value={option.longCodeValue}
              onClick={() =>
                setNewRowAdded({
                  ...newRowAdded,
                  [col]: option.longCodeValue,
                })
              }
            >
              {option.longCodeValue}
            </MenuItem>
          ))
        );
      } else if (col === "type") {
        return (
          listItems &&
          listItems.type &&
          listItems.type.map((option) => (
            <MenuItem
              key={option.id}
              value={option.shortCodeValue}
              onClick={() =>
                setNewRowAdded({
                  ...newRowAdded,
                  [col]: option.shortCodeValue,
                  typeId: option.id,
                })
              }
            >
              {option.shortCodeValue}
            </MenuItem>
          ))
        );
      } else if (col === "projectManagerName") {
        return (
          managerTeam &&
          managerTeam.data.map((option) => (
            <MenuItem
              key={option.id}
              value={`${getFullName(option.firstName, option.lastName)} (${
                option.email
              })`}
              onClick={() => {
                setManagerTeam(allUserDetails);
                setNewRowAdded({
                  ...newRowAdded,
                  [col]: `${getFullName(option.firstName, option.lastName)} (${
                    option.email
                  })`,
                  projectManagerId: option.id,
                });
              }}
            >
              {`${getFullName(option.firstName, option.lastName)} (${
                option.email
              })`}
            </MenuItem>
          ))
        );
      } else if (col === "businessOwner") {
        return (
          ownerTeam &&
          ownerTeam.data.map((option) => (
            <MenuItem
              key={option.id}
              value={`${getFullName(option.firstName, option.lastName)} (${
                option.email
              })`}
              onClick={() => {
                setOwnerTeam(allUserDetails);
                setNewRowAdded({
                  ...newRowAdded,
                  [col]: `${getFullName(option.firstName, option.lastName)} (${
                    option.email
                  })`,
                  [`${col}Id`]: option.id,
                });
              }}
            >
              {`${getFullName(option.firstName, option.lastName)} (${
                option.email
              })`}
            </MenuItem>
          ))
        );
      } else if (col === "deliveryOfficer") {
        return (
          deliveryOfficerTeam &&
          deliveryOfficerTeam.data.map((option) => (
            <MenuItem
              key={option.id}
              value={`${getFullName(option.firstName, option.lastName)} (${
                option.email
              })`}
              onClick={() => {
                setDeliveryOfficerTeam(allUserDetails);
                setNewRowAdded({
                  ...newRowAdded,
                  [col]: `${getFullName(option.firstName, option.lastName)} (${
                    option.email
                  })`,
                  [`${col}Id`]: option.id,
                });
              }}
            >
              {`${getFullName(option.firstName, option.lastName)} (${
                option.email
              })`}
            </MenuItem>
          ))
        );
      } else if (col === "employeeName") {
        return (
          teamMembers &&
          teamMembers.data.map((option) => (
            <MenuItem
              key={option.id}
              value={getFullName(option.firstName, option.lastName)}
              onClick={() => {
                setTeamMembers(allUserDetails);
                setNewRowAdded({
                  ...newRowAdded,
                  [col]: `${getFullName(option.firstName, option.lastName)} (${
                    option.email
                  })`,
                  employeeId: option.id,
                });
              }}
            >
              {`${getFullName(option.firstName, option.lastName)} (${
                option.email
              })`}
            </MenuItem>
          ))
        );
      } else if (col === "projectName") {
        if (tabName === "MY TIMESHEET") {
          return (
            assignedProjects &&
            Array.from(
              new Set(assignedProjects.map((option) => option.projectId))
            ).map((projectId) => {
              const option = assignedProjects.find(
                (option) => option.projectId === projectId
              );
              return (
                <MenuItem
                  key={option.projectId}
                  value={option.projectName}
                  required={col.isRequired}
                  onClick={() =>
                    setNewRowAdded({
                      ...newRowAdded,
                      [col]: option.projectName,
                      projectId: option.projectId,
                    })
                  }
                >
                  {option.projectName}
                </MenuItem>
              );
            })
          );
        } else
          return (
            assignedProjects &&
            assignedProjects.map((option) => (
              <MenuItem
                key={option.projectId}
                value={option.projectName}
                required={col.isRequired}
                onClick={() =>
                  setNewRowAdded({
                    ...newRowAdded,
                    [col]: option.projectName,
                    projectId: option.projectId,
                  })
                }
              >
                {option.projectName}
              </MenuItem>
            ))
          );
      } else if (col === "task") {
        return allTasks
          ? allTasks.map((option, index) => (
              <MenuItem
                key={index}
                value={option.taskName}
                onClick={() =>
                  setNewRowAdded({
                    ...newRowAdded,
                    [col]: option.taskName,
                    taskId: option.taskID,
                  })
                }
              >
                {option.taskName}
              </MenuItem>
            ))
          : null;
      } else {
        return (
          dropDownMockData &&
          dropDownMockData[col].map((option) => (
            <MenuItem
              key={option}
              value={option}
              onClick={() => {
                if (col === "billStatus" && option === "Non Billable") {
                  setBillRateDisable(true);
                } else if (col === "billStatus") {
                  setBillRateDisable(false);
                }
                setNewRowAdded({ ...newRowAdded, [col]: option });
              }}
            >
              {option}
            </MenuItem>
          ))
        );
      }
    }
  };

  const createInputField = (col) => {
    if (col.id === "paymentTerms") {
      return (
        <TableCell key={col.id}>
          <TextField
            className="numberInput"
            type="number"
            id="outlined-required"
            label={getLabel(col.id, headingName)}
            placeholder=""
            value={newRowAdded[col.id]}
            required={col.isRequired}
            onChange={(e) => inputFieldHandler(e, col.id)}
            sx={{
              "& label": {
                lineHeight: "0.8rem",
              },
            }}
          />
        </TableCell>
      );
    } else if (getTypeofColumn(col.id, headingName) === "textfield") {
      return (
        <TableCell
          key={col.id}
          style={{ maxWidth: col.maxWidth ? col.maxWidth : "auto" }}
        >
          <TextField
            style={{ width: "100%", maxWidth: "auto" }}
            id="outlined-required"
            inputProps={{
              maxLength: 100,
              min: col.min === 0 ? col.min : null,
              max: col.max ? col.max : null,
            }}
            type={col.fieldType}
            label={getLabel(col.id, headingName)}
            placeholder=""
            value={
              headingName !== Modules.INVOICE_TRACKER
                ? newRowAdded[col.id]
                : newRowAdded[col.id]
            }
            required={col.isRequired}
            sx={{
              "& label": {
                lineHeight: "0.8rem",
              },
            }}
            disabled={
              (col.id === "costAllocation" ? true : false) ||
              (col.disabled ? true : false)
            } //"|| col.id=='billRate' && billRateDisable"
            onChange={(e) => inputFieldHandler(e, col.id)}
          />
        </TableCell>
      );
    } else if (getTypeofColumn(col.id, headingName) === "multi-select") {
      let values = null;
      if (typeof newRowAdded[col.id] === typeof "") {
        newRowAdded["employeeName"] = [
          {
            employeeId: newRowAdded["employeeId"],
            employeeName: newRowAdded["employeeName"],
          },
        ];
        newRowAdded["employeeId"] = [newRowAdded["employeeId"]];
        values = [newRowAdded["employeeName"]];
      } else {
        values = newRowAdded[col.id];
      }

      return (
        <TableCell
          key={col.id}
          style={{ maxWidth: col.maxWidth ? col.maxWidth : "auto" }}
        >
          <FormControl
            sx={{ m: 1, width: 120, margin: "0" }}
            required={col.isRequired}
          >
            <InputLabel id="demo-multiple-checkbox-label">
              {col.label}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={values}
              onChange={(e) => handleChange(e, col)}
              required={col.isRequired}
              input={<OutlinedInput label={col.label} />}
              renderValue={() =>
                col.id === "hosts"
                  ? getHosts(values, col.id)
                  : getApprovers(values, col.id)
              }
              MenuProps={MenuProps}
              disabled={
                isEditButtonClicked &&
                (col.id === "employeeName" ? true : false)
              }
            >
              {(col.id === "approvers" ||
                col.id === "employeeName" ||
                col.id === "hosts") && (
                <ListSubheader className="subheader">
                  <TextField
                    placeholder="Search Here..."
                    className="subheader-field"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    autoFocus={true}
                    onChange={(e) => filterNamesHandler(e.target.value, col.id)}
                  />
                </ListSubheader>
              )}
              {col.id === "approvers"
                ? approversTeam &&
                  approversTeam.data
                    .map((user) => ({
                      approverId: user.id,
                      approverName: getFullName(user.firstName, user.lastName),
                      approverEmail: user.email,
                    }))
                    .map((approver, index) => (
                      <MenuItem
                        key={index}
                        value={approver}
                        className="no-left-margin"
                      >
                        <Checkbox
                          checked={
                            values.findIndex(
                              (app) => app.approverId === approver.approverId
                            ) > -1
                          }
                        />
                        <ListItemText
                          primary={`${approver.approverName} (${approver.approverEmail})`}
                        />
                      </MenuItem>
                    ))
                : teamMembers &&
                  teamMembers.data
                    .map((user) => ({
                      employeeId: user.id,
                      employeeName: getFullName(user.firstName, user.lastName),
                      employeeEmail: user.email,
                    }))
                    .map((employee, index) => (
                      <MenuItem
                        key={index}
                        value={employee}
                        className="no-left-margin"
                      >
                        <Checkbox
                          checked={
                            newRowAdded["employeeName"].findIndex(
                              (app) => app.employeeId === employee.employeeId
                            ) > -1
                          }
                        />
                        <ListItemText
                          primary={`${employee.employeeName} (${employee.employeeEmail})`}
                        />
                      </MenuItem>
                    ))}
            </Select>
          </FormControl>
        </TableCell>
      );
    } else if (getTypeofColumn(col.id, headingName) === "select") {
      const value = getValueOfSelect(col.id);
      const selectedValue = value ? value : newRowAdded[col.id];
      return (
        <TableCell
          key={col.id}
          style={{ maxWidth: col.maxWidth ? col.maxWidth : "auto" }}
        >
          <FormControl
            fullWidth
            required={getisRequiredofColumn(col.id, headingName)}
          >
            <InputLabel className="select-label" id={`label-for-${col.id}`}>
              {getLabel(col.id, headingName)}
            </InputLabel>
            <Select
              id="outlined-select-currency"
              labelId={`label-for-${col.id}`}
              label={getLabel(col.id, headingName)}
              value={selectedValue}
              required={col.isRequired}
              className={"select-input"}
              autoComplete={false}
              autoFocus={false}
              MenuProps={{
                autoFocus: false,
                PaperProps: { sx: { maxHeight: 300 } },
              }}
              disabled={
                (isEditButtonClicked && col.id === "employeeName") ||
                (headingName === Modules.INVOICE_TRACKER &&
                  col.id === "projectName" &&
                  filterProjectDropdownData === null) ||
                (headingName === Modules.INVOICE_TRACKER &&
                  col.id === "invoiceStatus")
              }
            >
              {(col.id === "employeeName" ||
                col.id === "projectManagerName" ||
                col.id === "businessOwner" ||
                col.id === "deliveryOfficer") && (
                <ListSubheader className="subheader">
                  <TextField
                    placeholder="Search Here..."
                    className="subheader-field"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    autoFocus={true}
                    onChange={(e) => filterNamesHandler(e.target.value, col.id)}
                  />
                </ListSubheader>
              )}
              {displayMenuItem(col.id)}
            </Select>
          </FormControl>
        </TableCell>
      );
    } else if (getTypeofColumn(col.id, headingName) === "date") {
      let minDate;
      let maxDate;
      if (headingName === Modules.INVOICE_TRACKER && selectedProjectData) {
        minDate = new Date(selectedProjectData.startDate);
        maxDate = new Date(selectedProjectData.endDate);
      }
      return (
        <TableCell key={col.id} style={{ width: "8rem", maxWidth: "7rem" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label=""
              minDate={minDate}
              maxDate={maxDate}
              onChange={(newValue) => {
                let formatedDate = dateCalc(newValue, col.id);
                setNewRowAdded({ ...newRowAdded, [col.id]: formatedDate });
              }}
              value={newRowAdded[col.id]}
              placeholder="Date"
              required={col.isRequired}
              disabled={col.disabled ? true : false}
              renderInput={(params) => <TextField {...params} error={false} />}
            />
          </LocalizationProvider>
        </TableCell>
      );
    } else if (col.id === "actions") {
      return (
        <TableCell key={col.id}>
          <div className="attachmentContainer">
            {(headingName === Modules.PROJECT_ADMIN ||
              headingName === Modules.CLIENT_ADMIN) &&
              isEditButtonClicked && (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  className="attachmentIcon"
                >
                  <input
                    hidden
                    accept="*"
                    type="file"
                    onChange={(e) => setFileState(e.target.files[0])}
                    required={col.isRequired}
                  />
                  <AttachFileIcon />
                </IconButton>
              )}
            {newRowAdded.clientName === "" ||
            (headingName === Modules.TIMESHEET &&
              (newRowAdded.projectName === "" ||
                newRowAdded.task === "" ||
                !isDateAdded())) ||
            (headingName === Modules.INVOICE_TRACKER &&
              (newRowAdded.projectName === "" ||
                newRowAdded.billStartDate === "" ||
                newRowAdded.billEndDate === "" ||
                newRowAdded.billAmount === "")) ? (
              <img src={AddDisableIcon} className="editDeleteIcon" alt="" />
            ) : (
              <img
                src={AddEnableIcon}
                onClick={saveDataHandler}
                className="cursorPointer editDeleteIcon"
                alt=""
              />
            )}

            {/* {(headingName === Modules.TRAINING_AND_SESSION) && (
              <img
                src={AddEnableIcon}
                onClick={saveDataHandler}
                className="cursorPointer editDeleteIcon"
                alt=""
              />)
            } */}
            <img
              src={crossIcon}
              className="cursorPointer editDeleteIcon"
              onClick={closeButtonHandler}
              alt=""
            />
          </div>
        </TableCell>
      );
    } else if (col.isDate) {
      let date = Object.keys(newRowAdded).find(
        (i) =>
          moment(i).isValid() &&
          moment(i).format("DD") === moment(col.date).format("DD")
      );
      const selctedProjectForUser =
        newRowAdded.projectId !== undefined
          ? newRowAdded.projectId
          : newRowAdded.projectID;
      let startDate = null;
      let endDate = null;
      if (assignedProjects) {
        for (let i = 0; i < assignedProjects.length; i++) {
          if (assignedProjects[i].projectId === selctedProjectForUser) {
            startDate = assignedProjects[i].sowStartDate;
            endDate = assignedProjects[i].sowEndDate;

            if (startDate && endDate) {
              if (date >= startDate && date <= endDate) {
                break;
              }
            }
          }
        }
      }

      if (date >= startDate && date <= endDate) {
        return (
          <TableCell key={col.id} className="timeField">
            <TextField
              label={"Time"}
              id="time"
              type="number"
              value={newRowAdded[date] === "-" ? 0 : newRowAdded[date]}
              style={{ maxWidth: "6rem" }}
              InputProps={{ inputProps: { min: 0, max: 24, step: "2" } }}
              required={col.isRequired}
              sx={{
                "& label": {
                  lineHeight: "0.8rem",
                },
              }}
              onChange={(e) => inputFieldHandler(e, date)}
            />
          </TableCell>
        );
      } else {
        return (
          <TableCell key={col.id} className="timeField">
            <TextField
              label={"Time"}
              id="time"
              type="number"
              disabled
              value={0}
              style={{ maxWidth: "6rem" }}
              required={col.isRequired}
              sx={{
                "& label": {
                  lineHeight: "0.8rem",
                },
              }}
              onChange={(e) => inputFieldHandler(e, date)}
            />
          </TableCell>
        );
      }
    } else if (getTypeofColumn(col.id, headingName) === "empty") {
      return <TableCell key={col.id} />;
    }
  };

  const handleCopy = (id) => {
    let idx = rows.findIndex(
      (row) => row[UniqueIds[headingName.replace(" ", "")]] === id
    );
    if (rows[idx].employeeId) {
      setNewRowAdded({ ...rows[idx], employeeName: [], employeeId: [] });
    } else {
      setNewRowAdded({ ...rows[idx], approvers: [] });
    }
    setIsAddButtonClicked(true);
  };
  const editButtonClicked = (id) => {
    let uniqueId = UniqueIds[headingName.replace(" ", "")];
    if (tabName === "CREATE INVOICE") {
      uniqueId = "billMonth";
    }
    let idx = rows.findIndex((row) => row[uniqueId] === id);
    if (headingName === Modules.TIMESHEET) {
      let rowData = initialData(headingName, selectedPeriodWeek);
      Object.keys(rows[idx]).forEach((key) => {
        if (Object.keys(rowData).includes(key)) {
          rowData[key] = rows[idx][key];
        } else {
          let keys = Object.keys(rowData).filter((i) => moment(i).isValid());
          if (
            keys.map((i) => moment(i).format("DD")).includes(key.slice(-2)) &&
            rows[idx][key] !== "-"
          ) {
            rowData[
              keys.find((i) => moment(i).format("DD") === key.slice(-2))
            ] = rows[idx][key].toString();
          } else if (
            !keys.map((i) => moment(i).format("DD")).includes(key.slice(-2))
          ) {
            rowData[key] = rows[idx][key];
          }
        }
      });
      rowData["timesheetDetailID"] = rows[idx]["timesheetDetailID"];
      setRowToBeUpdated(rowData);
      setNewRowAdded(rowData);
    } else {
      setRowToBeUpdated(rows[idx]);
      if (rows[idx].employeeId) {
        setNewRowAdded({
          ...rows[idx],
          employeeName: getEmployeeName(rows[idx].employeeId),
        });
      } else {
        setNewRowAdded(rows[idx]);
      }
    }
    setIsEditButtonClicked(true);
    setDeliveryOfficerTeam(allUserDetails);
    setOwnerTeam(allUserDetails);
    setManagerTeam(allUserDetails);
    setApproversTeam(allUserDetails);
    setTeamMembers(allUserDetails);
  };

  const deleteButtonClicked = (id) => {
    if (headingName === Modules.CLIENT_ADMIN) {
      dispatch(deleteClientAdminData(id));
    } else if (headingName === Modules.PROJECT_ADMIN) {
      dispatch(
        deleteProjectAdminData({
          id: id,
          expiredAllocations: expiredAllocations,
        })
      );
    } else if (headingName === Modules.TIMESHEET) {
      dispatch(deleteTimeSheetData(id));
    } else if (headingName === Modules.PROJECT_MANAGEMENT) {
      dispatch(
        deleteProjectManagementData({
          projectAllocationId: id,
          projectId: projectId,
          pageNo: page + 1,
          pageSize: rowsPerPage,
        })
      );
    } else if (headingName === Modules.INVOICE_TRACKER) {
      dispatch(deleteInvoice(id));
    }
    else if (headingName === Modules.TRAINING_AND_SESSION) {
        dispatch(deleteSessionData(id));
    }
    setDialogDeleteButtonClicked(false);
  };

  const getEmployeeName = (id) => {
    let employeeName = "";
    if (!id || id === null) {
      return;
    }

    if (allUserDetailsDict) {
      let user = allUserDetailsDict[id.toLowerCase()];
      if (user) {
        employeeName = getFullName(user.firstName, user.lastName);
      }
    }

    if (employeeName.length === 0) {
      return null;
    }
    return employeeName;
  };

  const getNameAndEmail = (id) => {
    let managerNameAndEmail = "";

    if (allUserDetailsDict) {
      let user = allUserDetailsDict[id];
      if (user) {
        managerNameAndEmail = `${user.firstName} ${user.lastName} (${user.email})`;
      }
    }

    return managerNameAndEmail;
  };

  const dialogBoxHandler = (rowData) => {
    setDeleteRow(rowData);
    setShowDialogBox(true);
  };

  const isDateAdded = () => {
    let isAdded = false;
    Object.keys(newRowAdded).forEach((key) => {
      if (moment(key).isValid() && newRowAdded[key] !== "") isAdded = true;
    });
    return isAdded;
  };

  const handleViewDetails = (Id) => {
    if (headingName === Modules.INVOICE_TRACKER) {
      setSelectedInvoiceId(Id);
      setFormViewDetails(true);
    } else if (
      headingName === Modules.PROJECT_ALLOCATION &&
      tabName === "RESOURCE ALLOCATION"
    ) {
      setSelectedProjectAllocationId(Id);
      let employeeId = "";
      rows.forEach((row) => {
        if (row.projectAllocationId === Id) {
          employeeId = row.employeeId;
        }
      });
      setSelectedEmpId(employeeId);
      setViewDetails(true);
    } else {
      setSelectedEmpId(Id);
      setViewDetails(true);
    }
    setVtrackLoader(true);
  };

  React.useEffect(() => {
    setRowToBeUpdated({});
    setNewRowAdded(initialData(headingName, selectedPeriodWeek));
    setSortBy(initialSort[headingName]);
    setPage(0);
    setRowsPerPage(10);
    setRows([]);
  }, [headingName]);

  React.useEffect(() => {
    if (dialogDeleteButtonClicked) {
      deleteButtonClicked(deleteRow);
      setDialogDeleteButtonClicked(false);
      resetSearchData();
    }
  }, [dialogDeleteButtonClicked]);

  useEffect(() => {
    // For Download Button
    if (clientAdminData) {
      setColumns(clientAdminData);
      setRows(clientAdminData);
    } else if (projectAdminData) {
      setColumns(projectAdminData);
      setRows(projectAdminData);
    }
  }, [clientAdminData, projectAdminData]);

  React.useEffect(() => {
    if (selectedPeriodWeek) {
      setNewRowAdded(initialData(headingName, selectedPeriodWeek));
    }
  }, [selectedPeriodWeek]);

  useEffect(() => {
    if (mappedProjectManagementData && mappedProjectManagementData.length) {
      const dropdownClients = mappedProjectManagementData.map((data) => {
        return {
          name: data.clientName,
          id: data.clientId,
        };
      });

      setFilterClientDropdownData(dropdownClients);
    }
  }, [mappedProjectManagementData]);

  useEffect(() => {
    if (selectedClient) {
      const dropdownProjects = mappedProjectManagementData
        .filter((data) => data.clientId === selectedClient)[0]
        .projects?.map((project) => {
          return {
            name: project.projectName,
            id: project.projectId,
          };
        });
      setFilterProjectDropdownData(dropdownProjects);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedProject) {
      dispatch(getProjectById({ projectId: selectedProject }));
    }
  }, [selectedProject]);


  const useRowStyles = makeStyles({
    root: {
      "& > *": {
        borderBottom: "unset",
      },
    },
  });

  function createData(empData) {
    return {
      name: empData.empName,
      history: empData.allocationChangeRecords,
    };
  }
  function createDataForAllocationChangeByEmployee(empData) {
    return {
      name: empData.empName,
      history: empData.allocationChangeRecordByEmployees,
    };
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
      <React.Fragment>
        <TableRow className={classes.root} align="left">
          <TableCell sx={{ width: "20px" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell sx={{ fontWeight: "medium", fontSize: "1.6rem" }}>
            {row.name}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                ></Typography>
                <div className="padded-table">
                  <Table
                    className={classes.paddedtable}
                    size="small"
                    aria-label="purchases"
                  >
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#f3f8fc" }}>
                        <TableCell
                          sx={{
                            fontWeight: "medium",
                            fontSize: "1.4rem",
                            color: "#1773bc",
                          }}
                          align="left"
                        >
                          Project Name
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "medium",
                            fontSize: "1.4rem",
                            color: "#1773bc",
                          }}
                          align="left"
                        >
                          Attribute Changed
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "medium",
                            fontSize: "1.4rem",
                            color: "#1773bc",
                          }}
                          align="left"
                        >
                          Old Value
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "medium",
                            fontSize: "1.4rem",
                            color: "#1773bc",
                          }}
                          align="left"
                        >
                          New Value
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "medium",
                            fontSize: "1.4rem",
                            color: "#1773bc",
                          }}
                          align="left"
                        >
                          Updated By
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "medium",
                            fontSize: "1.4rem",
                            color: "#1773bc",
                          }}
                          align="left"
                        >
                          Update Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.history.map((historyRow) => (
                        <TableRow key={historyRow.columnName}>
                          <TableCell
                            sx={{ fontWeight: "medium", fontSize: "1.1rem" }}
                            align="left"
                          >
                            {historyRow.projectName}
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "medium", fontSize: "1.1rem" }}
                            align="left"
                          >
                            {historyRow.columnName}
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "medium", fontSize: "1.1rem" }}
                            align="left"
                          >
                            {historyRow.oldValue}
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "medium", fontSize: "1.1rem" }}
                            align="left"
                          >
                            {historyRow.newValue}
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "medium", fontSize: "1.1rem" }}
                            align="left"
                          >
                            {historyRow.updateBy}
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "medium", fontSize: "1.1rem" }}
                            align="left"
                          >
                            {historyRow.updatedDT}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      calories: PropTypes.number.isRequired,
      carbs: PropTypes.number.isRequired,
      fat: PropTypes.number.isRequired,
      history: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          customerId: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired,
        })
      ).isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      protein: PropTypes.number.isRequired,
    }).isRequired,
  };

  const rows1 = allocationChangeReportData.map((value) => {
    return createData(value);
  });
  const rows2 =
    allocationChangeReportDataByEmployee &&
    allocationChangeReportDataByEmployee.data
      ? allocationChangeReportDataByEmployee.data.map((value) =>
          createDataForAllocationChangeByEmployee(value)
        )
      : [];
  function CollapsibleTable() {
    return (
      <div>
        <div>
          <TableContainer
            component={Paper}
            sx={{
              height: 600,
              "&::-webkit-scrollbar": { width: 10 },
              "&::-webkit-scrollbar-thumb": { backgroundColor: "white" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#1773bc",
                borderRadius: 2,
              },
            }}
          >
            <Table aria-label="collapsible table">
              <TableHead></TableHead>
              <TableBody>
                {rows1.map((row) => (
                  <Row key={row.name} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <ExportExcel
            props={{
              startDate: startDate?.format(DATE_TIME_FORMAT),
              endDate: endDate?.format(DATE_TIME_FORMAT),
            }}
            excelData={allocationChangeReportDataForExcel.data}
            headingName={Modules.REPORTING}
            tabName={"ALLOCATION CHANGE REPORT BY PROJECT"}
            fileName={"Allocation Change Report"}
          />
          {/* {allocationChangeReportDataForExcel? customHeadingsForReporting(allocationChangeReportDataForExcel): customHeadingsForReporting(allocationChangeReportData)} */}
        </div>
      </div>
    );
  }

  function AllocationChangeByEmployeeTable() {
    return (
      <div>
        <div>
          <TableContainer
            component={Paper}
            sx={{
              height: 600,
              "&::-webkit-scrollbar": { width: 10 },
              "&::-webkit-scrollbar-thumb": { backgroundColor: "white" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#1773bc",
                borderRadius: 2,
              },
            }}
          >
            <Table aria-label="collapsible table">
              <TableHead></TableHead>
              <TableBody>
                {rows2.map((row) => (
                  <Row key={row.name} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <ExportExcel
            props={{
              startDate: startDate?.format(DATE_TIME_FORMAT),
              endDate: endDate?.format(DATE_TIME_FORMAT),
              employeeIds: selectedUserIds,
            }}
            excelData={allocationChangeReportByEmployeeDataForExcel.data}
            headingName={Modules.REPORTING}
            tabName={"ALLOCATION CHANGE REPORT BY EMPLOYEE"}
            fileName={"Allocation Change Report"}
          />
          {/* {allocationChangeReportDataForExcel? customHeadingsForReporting(allocationChangeReportDataForExcel): customHeadingsForReporting(allocationChangeReportData)} */}
        </div>
      </div>
    );
  }
  if (
    headingName === Modules.VIEW_ATTENDANCE &&
    viewAttendanceData &&
    (tabName === "VIEW ATTENDANCE" ||
      (tabName === "VIEW EMPLOYEE ATTENDANCE" &&
        selectedUserID &&
        viewAttendanceClicked))
  ) {
    return <CreateCalendar selectedUserID={selectedUserID} tabName={tabName} />;
  }
  if (
    headingName === Modules.REPORTING &&
    tabName === "ALLOCATION CHANGE REPORT BY PROJECT" &&
    allocationChangeReportData.length
  ) {
    return CollapsibleTable();
  } else if (
    headingName === Modules.REPORTING &&
    tabName === "ALLOCATION CHANGE REPORT BY EMPLOYEE" &&
    allocationChangeReportDataByEmployee &&
    allocationChangeReportDataByEmployee.data &&
    allocationChangeReportDataByEmployee.data.length
  ) {
    return AllocationChangeByEmployeeTable();
  } else {
    return (
      <>
        {
          <EmployeeView
            viewEmployeeDetails={viewEmployeeDetails}
            setViewEmployeeDetails={setViewEmployeeDetails}
            selectedEmployeeId={selectedEmpId}
          />
        }
        {vTrackLoader && <Loader />}
        <FormView
          formViewDetails={formViewDetails}
          setFormViewDetails={setFormViewDetails}
          selectedInvoiceId={selectedInvoiceId}
          headingName={headingName}
        />
        <DetailView
          viewDetails={viewDetails}
          setViewDetails={setViewDetails}
          selectedEmpId={selectedEmpId}
          selectedPeriodWeek={selectedPeriodWeek}
          headingName={headingName}
          selectedProjectAllocationId={selectedProjectAllocationId}
          selectedProjectId={selectedProject}
        />
        {showDialogBox && (
          <DialogBox
            setShowDialogBox={setShowDialogBox}
            setDialogDeleteButtonClicked={setDialogDeleteButtonClicked}
            header={setDialogBoxText()}
          />
        )}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "48rem" }}>
            <Table aria-label="sticky table" size="small">
              {columns && columns.length !== 0 && (
                <TableHead style={{ position: "sticky", top: "0", zIndex: 2 }}>
                  <TableRow style={{ backgroundColor: "white" }}>
                    {columns &&
                      columns.length &&
                      columns.map((column) => {
                        if (!column.id) return null;
                        return (
                          column.id !==
                            UniqueIds[headingName.replace(" ", "")] && (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                position: "relative",
                                maxWidth: column.maxWidth
                                  ? column.maxWidth
                                  : "auto",
                              }}
                              sx={{
                                backgroundColor: "#1773bc0d",
                                color: "#1773bc",
                                fontWeight: 700,
                              }}
                              className={column.isDate ? "dateHeading" : ""}
                            >
                              <div className="table-header-cell">

                                {(tabName==="PENDING APPROVAL"&&column.id==='actions')?(
                                  <div style={{display:"flex",flexDirection:"row"}}>
                                    <div style={{marginTop:"0.85rem",marginRight:"0.73rem"}}>{column.label}</div>
                                    <div>
                                    <Tooltip title={(rows&&rows.length&&selectedTimesheetRows.length===rows.length)?(<h2>Deselect All</h2>):(<h2>Select All</h2>)}>
                                    <Checkbox disabled={!rows.length} checked={rows&&rows.length&&selectedTimesheetRows.length===rows.length}
                                      onClick={handleSelectAllForPendingApproval}
                                    />
                                    </Tooltip>
                                    </div>
                                  </div>

                                ):(<span>{handleColumnDisplay(column.id)===true?(""):(column.label)}</span>)}

                                {!column.isDate &&
                                  headingName !== Modules.TIMESHEET &&
                                  column.id !== "actions" &&
                                  column.isSort && (
                                    <img
                                      src={TableArrows}
                                      alt=""
                                      className="tableArrows"
                                      onClick={() => handleSortBy(column.id)}
                                    />
                                  )}
                                {column.day && (
                                  <div className="month">{column.day}</div>
                                )}
                              </div>
                            </TableCell>
                          )
                        );
                      })}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {/* {(!rows.length) &&  (
                <TableRow>
                  <TableCell colspan="100%">
                    <TableLoader/>
                  </TableCell>
                </TableRow>
              )} */}
                {headingName === Modules.INVOICE_TRACKER &&
                  tabName === "CREATE INVOICE" &&
                  isAddButtonClicked &&
                  rows &&
                  rows.length === 0 && (
                    <TableRow>
                      <TableCell style={{ textAlign: "center" }} colSpan="100%">
                        <h1 style={{ color: "#1773bc" }}>All Invoices Done!</h1>
                      </TableCell>
                    </TableRow>
                  )}
                {isAddButtonClicked &&
                  headingName !== Modules.INVOICE_TRACKER &&
                  tabName !== "CREATE INVOICE" && (
                    <TableRow id="new_row">
                      {columns.map((col) => {
                        return createInputField(col);
                      })}
                    </TableRow>
                  )}
                {rows &&
                  rows.map((row, rowIdx) => {
                    if (
                      headingName !== Modules.CLIENT_ADMIN &&
                      headingName !== Modules.PROJECT_ADMIN &&
                      (row["employeeId"]
                        ? getEmployeeName(row["employeeId"])
                        : getEmployeeName(row["employeeID"])) === null
                    ) {
                      return null;
                    }
                    if (
                      rowToBeUpdated[tabName !== "CREATE INVOICE" ? UniqueIds[headingName.replace(" ", "")] : "billMonth"] ===
                      row[tabName !== "CREATE INVOICE" ? UniqueIds[headingName.replace(" ", "")] : "billMonth"] && tabName !== "REPORTEES" && tabName !== "TRAINING AND SESSION" && tabName !== "SESSION HISTORY" 
                    ) {
                      return (
                        <TableRow id="new_row" key={rowIdx}>
                          {columns && columns.map((col) => {
                            return createInputField(col);
                          })}
                        </TableRow>
                      );
                    } else {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowIdx}
                        >
                          {columns &&
                            columns.map((col) => {
                              if (!col.id) return null;
                              if (col.id === "actions") {
                                return (
                                  <TableCell key={col.id}>
                                    <div className="attachmentContainer">
                                      {(headingName ===
                                        Modules.PROJECT_MANAGEMENT ||
                                        (headingName ===
                                          Modules.INVOICE_TRACKER &&
                                          tabName !== "CREATE INVOICE") ||
                                        headingName ===
                                          Modules.PROJECT_ALLOCATION) && (
                                        <Tooltip title={<h2>View Details</h2>}>
                                          <IconButton
                                            color="primary"
                                            onClick={() =>
                                              handleViewDetails(
                                                headingName ===
                                                  Modules.INVOICE_TRACKER
                                                  ? row["invoiceId"]
                                                  : headingName ===
                                                      Modules.PROJECT_ALLOCATION &&
                                                    tabName ===
                                                      "RESOURCE ALLOCATION"
                                                  ? row["projectAllocationId"]
                                                  : row["employeeId"]
                                              )
                                            }
                                          >
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      {
                                        headingName===Modules.REPORTING&&tabName==="MISSING TIMESHEET"&&notificationsList&&(notificationsList.findIndex((item) =>item.enabled ===true && item.notificationType==="MissingTimesheetReminderManual")>-1)&&extractDatesForTimesheetReminder(row.periodWeek)&&( 
                                        <Button onClick={() =>
                                         dispatch( 
                                          sendMissingTimesheetReminderManually({ employeeName: row.employeeName, employeeId: row.employeeID, periodWeek: row.periodWeek})
                                           )} 
                                           variant="contained"  color="primary" style={{ width:"15rem",height:"2.5rem" }}>Send Reminder Email</Button>
                                      )}
                                      { headingName === Modules.PROJECT_ADMIN && (
                                        <div>
                                          {rowIdx === rowIdResourcingDetailsPopup &&
                                            openResourcingDetailsPopup && (
                                              <Dialog
                                                open={openResourcingDetailsPopup}
                                                onClose={handleClose}
                                                maxWidth={"false"}
                                                PaperProps={{
                                                  style: {
                                                    backgroundColor: "white",
                                                    height: "60rem"
                                                  },
                                                }}
                                              >
                                                {headingName ===
                                                  Modules.PROJECT_ADMIN && (
                                                  <ResourcingDetailsPopup
                                                    onResponse={
                                                      handleResourcingDetails
                                                    }
                                                    row={row}
                                                  />
                                                )}
                                              </Dialog>
                                            )}
                                          {headingName ===
                                            Modules.PROJECT_ADMIN && (
                                            <button
                                              onClick={() =>{
                                                setOpenResourcingDetailsPopup(true);
                                                setRowIdResourcingDetailsPopup(rowIdx);
                                                dispatch(
                                                  getResourcingData({
                                                    projectId: row.projectId
                                                  })
                                                )
                                              }}
                                              className="buttonBackgroundBorder cursorPointer"
                                            >
                                              <Tooltip
                                                title={<h2>Resourcing Details</h2>}
                                              >
                                                <GroupsIcon className="cursorPointer editDeleteIcon "
                                                  style={{color:'#1773bc',cursor:'pointer'}}
                                                  fontSize="large"
                                                />
                                              </Tooltip>
                                            </button>
                                          )}
                                        </div>
                                      )}
                                      {(headingName === Modules.PROJECT_ADMIN ||
                                        headingName ===
                                          Modules.PROJECT_MANAGEMENT) && (
                                        <Tooltip title={<h2>Clone</h2>}>
                                          <ContentCopyIcon
                                            style={{
                                              color: "#1976d2",
                                              cursor: "pointer",
                                              margin: "0 0.5rem 0 0.5rem",
                                            }}
                                            onClick={() =>
                                              handleCopy(
                                                row[
                                                  UniqueIds[
                                                    headingName.replace(" ", "")
                                                  ]
                                                ]
                                              )
                                            }
                                          />
                                        </Tooltip>
                                      )}
                                      {headingName === Modules.CLIENT_ADMIN ||
                                      headingName === Modules.PROJECT_ADMIN ? (
                                        <div>
                                          <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            className="attachmentIcon"
                                          >
                                            {headingName ===
                                              Modules.CLIENT_ADMIN && (
                                              <input
                                                hidden
                                                accept="*"
                                                type="file"
                                                onChange={(e) => {
                                                  getClientAdminORProjectAdminData(
                                                    e.target.files,
                                                    row.clientId,
                                                    row.clientName,
                                                    headingName
                                                  );
                                                }}
                                              />
                                            )}
                                            {headingName ===
                                              Modules.PROJECT_ADMIN && (
                                              <input
                                                hidden
                                                accept="*"
                                                type="file"
                                                onChange={(e) => {
                                                  getClientAdminORProjectAdminData(
                                                    e.target.files,
                                                    row.projectId,
                                                    row.projectName,
                                                    headingName
                                                  );
                                                }}
                                              />
                                            )}
                                            <Tooltip
                                              title={<h2>Attachment</h2>}
                                            >
                                              <AttachFileIcon />
                                            </Tooltip>
                                          </IconButton>
                                          {rowIdx === rowIDMultipleViewPopup &&
                                            openMultipleFileView && (
                                              <Dialog
                                                open={openMultipleFileView}
                                                onClose={handleClose}
                                                maxWidth={"false"}
                                                PaperProps={{
                                                  style: {
                                                    backgroundColor: "white",
                                                    // boxShadow: 'none',
                                                  },
                                                }}
                                              >
                                                {headingName ===
                                                  Modules.PROJECT_ADMIN && (
                                                  <MultipleFileViewPopup
                                                    onResponse={
                                                      handleMultipleFileView
                                                    }
                                                    type={"sow"}
                                                    Id={row.projectId}
                                                  />
                                                )}
                                                {headingName ===
                                                  Modules.CLIENT_ADMIN && (
                                                  <MultipleFileViewPopup
                                                    onResponse={
                                                      handleMultipleFileView
                                                    }
                                                    type={"msa"}
                                                    Id={row.clientId}
                                                  />
                                                )}
                                              </Dialog>
                                            )}
                                          {headingName ===
                                            Modules.PROJECT_ADMIN && (
                                            <button
                                              onClick={() =>
                                                dispatch(
                                                  getMultipleFileView({
                                                    type: "sow",
                                                    Id: row.projectId,
                                                  })
                                                )
                                              }
                                              className="buttonBackgroundBorder cursorPointer"
                                            >
                                              <Tooltip
                                                title={<h2>View Documents</h2>}
                                              >
                                                <IconButton
                                                  color="primary"
                                                  onClick={() => {
                                                    setMultipleFileView(true);
                                                    setRowIDMultipleViewPopup(
                                                      rowIdx
                                                    );
                                                  }}
                                                >
                                                  <VisibilityIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </button>
                                          )}
                                          {headingName ===
                                            Modules.CLIENT_ADMIN && (
                                            <button
                                              onClick={() =>
                                                dispatch(
                                                  getMultipleFileView({
                                                    type: "msa",
                                                    Id: row.clientId,
                                                  })
                                                )
                                              }
                                              className="buttonBackgroundBorder cursorPointer"
                                            >
                                              <Tooltip
                                                title={<h2>View Documents</h2>}
                                              >
                                                <IconButton
                                                  color="primary"
                                                  onClick={() => {
                                                    setMultipleFileView(true);
                                                    setRowIDMultipleViewPopup(
                                                      rowIdx
                                                    );
                                                  }}
                                                >
                                                  <VisibilityIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </button>
                                          )}
                                        </div>
                                      ) : null}
                                      {rowIdx === rowIDViewAndNominateSession &&
                                            openViewAndNominateSession && (
                                              <Dialog
                                                open={openViewAndNominateSession}
                                                onClose={handleClose}
                                                maxWidth= {"false"}
                                                PaperProps={{
                                                  style: {
                                                    backgroundColor: "white",
                                                    padding: "3rem",
                                                    marginLeft: "20rem",
                                                    width:"125rem"
                                                  },
                                                }}
                                              >
                                                {headingName ===Modules.TRAINING_AND_SESSION  && (
                                                  <ViewAndNominate
                                                    onResponse={
                                                      handleViewAndNominateSessionPopup
                                                    }
                                                    sessionData = {row}
                                                  />
                                                )}
                                              </Dialog>
                                            )}

                                            {row.sessionID === rowIDSessionFeedbackPopup &&
                                            openSessionFeedbackPopup && (
                                              <Dialog
                                                open={openSessionFeedbackPopup}
                                                onClose={handleClose}
                                                maxWidth= {"false"}
                                                PaperProps={{
                                                  style: {
                                                    backgroundColor: "white",
                                                    padding: "3rem",
                                                    marginLeft: "20rem",
                                                    width:"125rem",
                                                    height:"60rem",
                                                  },
                                                }}
                                              >
                                                {headingName ===Modules.TRAINING_AND_SESSION  && (
                                                  <SessionFeedback
                                                    onResponse={
                                                      handleSessionFeedbackView
                                                    }
                                                    sessionData = {row}
                                                  />
                                                )}
                                              </Dialog>
                                            )}
                                            
                                      {headingName === Modules.TRAINING_AND_SESSION && tabName === "TRAINING AND SESSION" &&(
                                        <>
                                          {userData?.data?.roleName === ROLES.ADMIN && <Tooltip title={<h2>Participant List</h2>}>
                                          
                                            <GroupsIcon className="cursorPointer editDeleteIcon "
                                              style={{color:'#1773bc',cursor:'pointer'}}
                                              fontSize="large"
                                              onClick={() => {
                                                dispatch(getParticipantsData(row.sessionId));
                                                setParticipantsListView(true);
                                                setRowIDEditSession(row.sessionId);
                                              }}
                                            />
                                          </Tooltip>}
                                          {userData?.data?.roleName === ROLES.ADMIN  ?
                                          <Tooltip
                                            title={<h2>View and Nominate</h2>}
                                          >
                                            <IconButton
                                              color="primary"
                                              style={{ marginLeft: "2rem" }}
                                              onClick={() => {
                                                setViewAndNominateSession(true);
                                                setRowIDViewAndNominateSession(rowIdx);
                                                dispatch(getUserDetailsForTrainingAndSession({sessionId:row.sessionId}));
                                                dispatch(getReportees(null));
                                              }}
                                            >
                                              <VisibilityIcon />
                                            </IconButton>
                                          </Tooltip>
                                          :
                                          <Button className="addBtn" variant="contained" style={{width:"15rem"}}
                                          onClick={() => {
                                            setViewAndNominateSession(true);
                                            setRowIDViewAndNominateSession(rowIdx);
                                            dispatch(getUserDetailsForTrainingAndSession({sessionId:row.sessionId}));
                                            dispatch(getReportees(null));
                                          }}>
                                            {userData?.data?.roleName === ROLES.PROJECT_MANAGER?('View and Nominate'):('View Details')}
                                          </Button>}
                                        </>
                                      )}
                                    
                                      {headingName === Modules.TRAINING_AND_SESSION && tabName === "SESSION HISTORY" &&(
                                        <>
                                          {row.attended === 1 ? (
                                            row.isFeedBackSubmitted === 1 ? (
                                              <div>Feedback Submitted</div>
                                            ) : (
                                              <Button
                                                className="addBtn"
                                                variant="contained"
                                                style={{ width: "15rem" }}
                                                onClick={() => {
                                                  // Handle button click logic here
                                                  //dispatch(getSessionFeedbackQuestionsList());
                                                  dispatch(getUserDetails());
                                                  routeChange(row.sessionID);
                                                }}
                                              >
                                                Submit Feedback
                                              </Button>
                                            )
                                          ) : null}
                                        </>
                                      )}
                                      {tabName !== "PENDING APPROVAL" &&
                                        tabName !== "TRAINING AND SESSION"&&
                                        tabName !== "MISSING TIMESHEET" &&
                                        headingName!== Modules.TRAINING_AND_SESSION&&
                                        tabName !== "REPORTEES" &&
                                        row.status !== "Approved" &&
                                        row.status !== "Submitted" &&
                                        headingName !==Modules.PROJECT_ALLOCATION && (
                                          <button
                                            onClick={() => {
                                              headingName ===
                                                Modules.INVOICE_TRACKER &&
                                              tabName !== "CREATE INVOICE"
                                                ? handleViewDetails(
                                                    headingName ===
                                                      Modules.INVOICE_TRACKER
                                                      ? row["invoiceId"]
                                                      : headingName ===
                                                          Modules.PROJECT_ALLOCATION &&
                                                        tabName ===
                                                          "RESOURCE ALLOCATION"
                                                      ? row[
                                                          "projectAllocationId"
                                                        ]
                                                      : row["employeeId"]
                                                  )
                                                : editButtonClicked(
                                                    tabName === "CREATE INVOICE"
                                                      ? row["billMonth"]
                                                      : row[
                                                          UniqueIds[
                                                            headingName.replace(
                                                              " ",
                                                              ""
                                                            )
                                                          ]
                                                        ]
                                                  );
                                            }}
                                            className="buttonBackgroundBorder cursorPointer"
                                            disabled={
                                              tabName !== "CREATE INVOICE"
                                                ? isAddButtonClicked
                                                : isEditButtonClicked
                                            }
                                          >
                                            <Tooltip title={<h2>Edit</h2>}>
                                              <img
                                                src={editIcon}
                                                className="editDeleteIcon"
                                                alt=""
                                              />
                                            </Tooltip>
                                          </button>
                                        )}
                                      {
                                        headingName=== Modules.TRAINING_AND_SESSION && tabName === "TRAINING AND SESSION" && userData?.data?.roleName === ROLES.ADMIN && row.status!=="Completed"&&(
                                          <button
                                            onClick={() => {
                                              setOpenEditSessionPopup(true)
                                              setRowIDEditSession(row.sessionId)
                                            }}
                                            className="buttonBackgroundBorder cursorPointer"
                                          >
                                            <Tooltip title={<h2>Edit</h2>}>
                                              <img
                                                src={editIcon}
                                                className="editDeleteIcon"
                                                alt=""
                                              />
                                            </Tooltip>
                                          </button>
                                        )}
                                      {(headingName !==
                                        Modules.PROJECT_ALLOCATION &&
                                        headingName!== Modules.TRAINING_AND_SESSION&&
                                        tabName!== "MISSING TIMESHEET"&&
                                        //row.status !== "Approved" &&
                                        //row.status !== "Submitted" &&
                                        (row.periodStatus?row.periodStatus==="Open":true) &&
                                        tabName !== "CREATE INVOICE" &&
                                        tabName !== "PENDING APPROVAL" &&
                                        tabName !== "REPORTEES") && (
                                          <Tooltip title={<h2>Delete</h2>}>
                                            <img
                                              src={deleteIcon}
                                              className="editDeleteIcon cursorPointer deleteIcon"
                                              onClick={() =>
                                                dialogBoxHandler(
                                                  row[
                                                    UniqueIds[
                                                      headingName.replace(
                                                        " ",
                                                        ""
                                                      )
                                                    ]
                                                  ]
                                                )
                                              }
                                              alt=""
                                            />
                                          </Tooltip>
                                        )}
                                        {rowIDEditSession === row.sessionId && openEditSessionPopup && (<Dialog open={openEditSessionPopup} onClose={handleCloseEditSessionPopup} maxWidth={"false"} PaperProps={{ style: { backgroundColor: "white", padding: "2.3rem",marginLeft:"20rem" } }} >
                                          {headingName === Modules.TRAINING_AND_SESSION && ( <SessionUpdate
                                                sessionId = {row.sessionId}
                                                onResponse={
                                                  handleOpenEditSessionPopup
                                                }
                                              />)}
                                          </Dialog>
                                        )}
                                        {rowIDEditSession === row.sessionId && openParticipantsListView && (<Dialog open={openParticipantsListView} onClose={handleCloseParticipantsListViewPopup} maxWidth={"false"} PaperProps={{ style: { backgroundColor: "white", padding: "2.3rem" } }} >
                                          {headingName === Modules.TRAINING_AND_SESSION && ( <ParticipantListPopUp
                                            sessionId = {row.sessionId}
                                            sessionDate = {row.sessionDate}
                                            totalSeats = {row.seatsAlloted}
                                            sessionName = {row.sessionName}
                                            sessionStatus = {row.status}
                                            onResponse={
                                              handleOpenParticipantsListViewPopup
                                            }
                                          />)}
                                          </Dialog>
                                        )}
                                        {headingName === Modules.TRAINING_AND_SESSION && tabName === "TRAINING AND SESSION" && userData?.data?.roleName === ROLES.ADMIN && row.status==="Draft"&&(
                                          <Tooltip title={<h2>Delete</h2>}>
                                            <img
                                              src={deleteIcon}
                                              className="editDeleteIcon cursorPointer deleteIcon"
                                              onClick={() =>
                                                dialogBoxHandler(
                                                  row.sessionId
                                                )
                                              }
                                              alt=""
                                            />
                                          </Tooltip>
                                        )}
                                        {
                                          headingName===Modules.TRAINING_AND_SESSION && tabName === "TRAINING AND SESSION" && userData?.data?.roleName === ROLES.ADMIN && (row.status!=="Draft")&&(
                                            <ExportExcel
                                                props={{sessionId:row.sessionId}}
                                                excelData={sessionsDataForExcel}
                                                headingName={Modules.TRAINING_AND_SESSION}
                                                fileName={row.sessionName}
                                              />
                                          )}
                                      {tabName === "PENDING APPROVAL" && (
                                        <>
                                          <Tooltip title={<h2>Approve</h2>}>
                                            <img
                                              src={approveIcon}
                                              className="approveRejectIcon"
                                              onClick={() =>{
                                                dispatch(
                                                  updateTimeSheetStatus({
                                                    timesheetDetailIDs:
                                                      row[
                                                        UniqueIds[
                                                          headingName.replace(
                                                            " ",
                                                            ""
                                                          )
                                                        ]
                                                      ].toString(),
                                                    timesheetStatus: "Approved",
                                                  })
                                                )
                                              }}
                                              alt=""
                                            />
                                          </Tooltip>

                                          {rowIdx ===
                                            rowIDRejectTimesheetPopup &&
                                            openDialogBox && (
                                              <Dialog
                                                open={openDialogBox}
                                                onClose={handleClose}
                                                fullWidth={true}
                                                maxWidth={"sm"}
                                              >
                                                <MyPopupComponent
                                                  onResponse={handleDialogBox}
                                                  timesheetDetailID={
                                                    row[
                                                      UniqueIds[
                                                        headingName.replace(
                                                          " ",
                                                          ""
                                                        )
                                                      ]
                                                    ]
                                                  }
                                                />
                                              </Dialog>
                                            )}
                                          <Tooltip title={<h2>Reject</h2>}>
                                            <img
                                              src={rejectIcon}
                                              className="approveRejectIcon"
                                              onClick={() => {
                                                setOpenDialogBox(true);
                                                setRowIDRejectTimesheetPopup(
                                                  rowIdx
                                                );
                                              }}
                                              alt=""
                                            />
                                          </Tooltip>
                                          <Checkbox
                                          onChange = {(e) => {
                                            if (e.target.checked) {
                                              setSelectedTimesheetRows(selectedRows => [...selectedRows, row.timesheetDetailID]);
                                          } else {
                                            setSelectedTimesheetRows(selectedRows => selectedRows.filter(id => id !== row.timesheetDetailID));
                                          }
                                         
                                          }}
                                          checked = {selectedTimesheetRows.includes(row.timesheetDetailID)}
                                          />

                                          
                                          
                                        </>
                                      )}
                                    </div>
                                  </TableCell>
                                );
                              } else if (col.id?.includes("Date")) {
                                return (
                                  <TableCell
                                    key={col.id}
                                    style={{
                                      maxWidth: col.maxWidth
                                        ? col.maxWidth
                                        : "auto",
                                    }}
                                  >
                                    {convertDateToDDMYYYY(row[col.id])}
                                  </TableCell>
                                );
                              } else if (
                                tabName === "PENDING APPROVAL" &&
                                tabName === "REPORTEES" &&
                                headingName !== Modules.REPORTING &&
                                col.id === "status"
                              ) {
                                return null;
                              }
                              return col.id !==
                                UniqueIds[headingName.replace(" ", "")] ? (
                                <TableCell
                                  key={col.id}
                                  style={{
                                    textAlign:
                                      col.isDate || col.id === "totalHrs"
                                        ? "left"
                                        : "auto",
                                    maxWidth: col.maxWidth
                                      ? col.maxWidth
                                      : "auto",
                                    whiteSpace: col.maxWidth
                                      ? "nowrap"
                                      : "normal",
                                    color:
                                      col.id === "employeeName" &&
                                      headingName !== Modules.TIMESHEET
                                        ? "#1773bc"
                                        : "black",
                                    cursor:
                                      col.id === "employeeName" &&
                                      headingName !== Modules.TIMESHEET
                                        ? "pointer"
                                        : "",
                                  }}
                                  onClick={(event) => {
                                    if (
                                      col.id === "employeeName" &&
                                      headingName !== Modules.TIMESHEET
                                    ) {
                                      setSelectedEmpId(
                                        row["employeeId"]
                                          ? row["employeeId"]
                                          : row["employeeID"]
                                      );
                                      setViewEmployeeDetails(true);
                                    }
                                  }}
                                >
                                  {col.id === "employeeName" &&
                                  headingName !== Modules.SKILLS ? (
                                    row["zohoEmployeeId"] === undefined ? (
                                      row["employeeId"] ? (
                                        getEmployeeName(row["employeeId"])
                                      ) : (
                                        getEmployeeName(row["employeeID"])
                                      )
                                    ) : row["employeeId"] ? (
                                      getEmployeeName(row["employeeId"]) +
                                      " ( " +
                                      row["zohoEmployeeId"] +
                                      ") "
                                    ) : (
                                      getEmployeeName(row["employeeID"])
                                    )
                                  ) : col.id === "projectManagerName" &&
                                    headingName === Modules.REPORTING ? (
                                    getEmployeeName(row["projectManagerID"])
                                  ) 
                                    :col.id === "attendance" &&
                                    headingName === Modules.TRAINING_AND_SESSION &&
                                    tabName === "SESSION HISTORY"&&
                                    userData.data.roleName==="Admin"?
                                  //   (row["presentCount"] + " / " + row["acceptedCount"] ) && (<ExportExcel
                                  //   props={{sessionId:row.sessionId}}
                                  //   excelData={sessionsDataForExcel}
                                  //   headingName={Modules.TRAINING_AND_SESSION}
                                  //   fileName={"Session Details"}
                                  // />)
                                  (<div style={{ display: 'flex', alignItems:'center',justifyContent:"space-between",maxWidth:"70%" }}>
                                      <div style={{paddingRight:"3rem"}}>{row["presentCount"]} present / {row["acceptedCount"]} accepted</div>
                                      <ExportExcel
                                        props={{ sessionId: row.sessionID }}
                                        excelData={sessionsDataForExcel}
                                        headingName={Modules.TRAINING_AND_SESSION}
                                        fileName={row.sessionName}
                                      />
                                    </div>)
                                    : col.id
                                      .toLowerCase()
                                      .includes("allocation") && row[col.id] ? (
                                    <div className="allocation">
                                      <Box sx={{ position: "relative" }}>
                                        <CircularProgress
                                          variant="determinate"
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.grey[
                                                theme.palette.mode === "light"
                                                  ? 200
                                                  : 800
                                              ],
                                          }}
                                          size={20}
                                          thickness={4}
                                          value={100}
                                        />
                                        <CircularProgress
                                          variant="determinate"
                                          value={row[col.id]}
                                          thickness={4}
                                          style={getCircularProgressColor(
                                            row[col.id]
                                          )}
                                          size={20}
                                        />
                                      </Box>
                                      <div>{row[col.id]}%</div>
                                    </div>
                                  ) : col.id === "approvers" ? (
                                    <HtmlTooltip
                                      title={getApproversWithIds(row[col.id])}
                                    >
                                      <div
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {getApprovers(row[col.id], col.id)}
                                      </div>
                                    </HtmlTooltip>
                                  ) : col.id === "hosts" ? (
                                    <HtmlTooltip
                                      title={getHostsWithIds(row[col.id])}
                                    >
                                      <div
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {getHosts(row[col.id], col.id)}
                                      </div>
                                    </HtmlTooltip>
                                  ) 
                                    :col.id === "targetedSkill"?(
                                    row[col.id]&&
                                    <HtmlTooltip
                                      title = {getSkillsForTooltip(row[col.id])}
                                    >
                                      <div
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth:"28rem",
                                          whiteSpace:"nowrap"
                                        }}
                                      >
                                        {row[col.id]}
                                      </div>
                                    </HtmlTooltip>
                                  )
                                    : col.id === "viewDetails" ? (
                                    <IconButton
                                      color="primary"
                                      onClick={() =>
                                        handleViewDetails(row["employeeId"])
                                      }
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  ) : col.id === "billAllocation" ? (
                                    row[col.id] === 0 ? (
                                      "-"
                                    ) : (
                                      row[col.id]
                                    )
                                  ) : (
                                    row[col.id]
                                  )}
                                </TableCell>
                              ) : null;
                            })}
                        </TableRow>
                      );
                    }
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="button-flex" style={{paddingTop:"1rem"}}>
          {headingName===Modules.TIMESHEET&&tabName==="MY TIMESHEET"&&timeSheetData&&!timeSheetData.length&&
          <button className = "addBtn"
           onClick={handleclonePreviousWeekTimesheet}
            >
              Clone Previous Week Data
            </button>
          }
        </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <div style={{ display: 'flex', alignItems: 'center', gap: "1rem" }}>
              {(headingName === Modules.PROJECT_MANAGEMENT || (headingName === Modules.SKILLS && rows.length) || (headingName === Modules.PROJECT_ALLOCATION && tabName === 'RESOURCE ALLOCATION' && rows.length > 0)) 
              && <ExportExcel data={headingName === Modules.PROJECT_MANAGEMENT || headingName === Modules.PROJECT_ALLOCATION || headingName === Modules.SKILLS ? dataForExcel : []} headingName={headingName} projectId={projectId} pageNo={page} pageSize={rowsPerPage} billStatus={billStatus} expiredAllocations={expiredAllocations} />}
              {(userData?.data?.roleName === ROLES.ADMIN && (headingName === Modules.PROJECT_MANAGEMENT)) && <ExportExcel data={dataForExcel} headingName={headingName} pageNo={page} pageSize={rowsPerPage} all={true} />}
            </div> */}

            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              {headingName === Modules.PROJECT_MANAGEMENT && (
                <ExportExcel
                  props={{
                    projectId: projectId,
                    pageNo: page,
                    pageSize: rowsPerPage,
                    billStatus: billStatus,
                    expiredAllocations: expiredAllocations,
                  }}
                  excelData={exportProjectManagementData.data}
                  headingName={Modules.PROJECT_MANAGEMENT}
                  tabName={""}
                  fileName={"Project Management Data"}
                />
              )}
              {headingName === Modules.PROJECT_ADMIN && (
                <ExportExcel
                  props={{
                    pageNo: page,
                    pageSize: rowsPerPage,
                    expiredAllocations: expiredAllocations,
                  }}
                  excelData={projectAdminData?projectAdminData.data:[]}
                  headingName={Modules.PROJECT_ADMIN}
                  tabName={""}
                  fileName={"Project Admin Data"}
                />
              )}
              {headingName === Modules.PROJECT_ALLOCATION &&
                tabName === "RESOURCE ALLOCATION" && (
                  <ExportExcel
                    props={{
                      selectedUserIds: selectedUserIds,
                      isHistory: isHistory,
                    }}
                    excelData={projectAllocationData.data}
                    headingName={Modules.PROJECT_ALLOCATION}
                    tabName={"RESOURCE ALLOCATION"}
                    fileName={"Project allocations Data"}
                  />
                )}
              {userData?.data?.roleName === ROLES.ADMIN &&
                headingName === Modules.PROJECT_MANAGEMENT && (
                  <ExportExcel
                    props={{
                      projectId: projectId,
                      pageNo: page,
                      pageSize: rowsPerPage,
                      billStatus: billStatus,
                      expiredAllocations: expiredAllocations,
                    }}
                    excelData={allProjectManagementData.data}
                    headingName={Modules.PROJECT_MANAGEMENT}
                    fileName={"All Project Management Data"}
                    all={true}
                  />
                )}
              {userData?.data?.roleName === ROLES.ADMIN &&
                headingName === Modules.REPORTING &&
                tabName === "MISSING TIMESHEET" && (
                  <ExportExcel
                    props={{}}
                    excelData={reportingData}
                    headingName={Modules.REPORTING}
                    tabName={"MISSING TIMESHEET"}
                    fileName={"Missing Timesheet Data"}
                  />
                )}
              {(userData?.data?.roleName === ROLES.ADMIN ||
                userData?.data?.roleName === ROLES.PROJECT_MANAGER) &&
                headingName === Modules.SKILLS && (
                  <ExportExcel
                    props={{}}
                    excelData={skillsList}
                    headingName={Modules.SKILLS}
                    fileName={"Skills Tracker"}
                  />
                )}
            </div>
            {headingName !== Modules.TIMESHEET &&
              headingName !== Modules.VIEW_ATTENDANCE &&
              headingName !== Modules.TRAINING_AND_SESSION &&
              headingName !== Modules.REPORTING &&
              headingName !== Modules.SKILLS &&
              tabName !== "RESOURCE ALLOCATION" &&
              tabName !== "CREATE INVOICE" && (
                <TablePagination
                  rowsPerPageOptions={
                    headingName === Modules.INVOICE_TRACKER ||
                    headingName === Modules.VIEW_ATTENDANCE
                      ? []
                      : [10, 25, 100]
                  }
                  component="div"
                  count={totalRecord}
                  rowsPerPage={
                    headingName === Modules.INVOICE_TRACKER
                      ? totalRecord
                      : rowsPerPage
                  }
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
          </div>
        </Paper>
        {warningPopup && (<Dialog open={warningPopup} onClose={handleClose}><WarningPopup onResponse={handleWarningPopup}/></Dialog>)}
      </>
    );
  }
};
