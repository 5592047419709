import { call, put, takeLatest } from "redux-saga/effects";
import { getZohoPeopleDetails } from "../http/requests/settings";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";
import { SettingDataType, setVtrackLoader, setZohoPeopleDetails } from "../redux/actions";


function* workerGetZohoPeopleDetails({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const zohoPeopleDetails = yield call(getZohoPeopleDetails,
      payload.employeeId
    );

    yield put(setZohoPeopleDetails(zohoPeopleDetails));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    toast.error(err.data.errorMessage, toastOptions);
    yield put(setVtrackLoader(false));
  }
};

export function* getZohoPeopleDetailsSaga() {
  yield takeLatest(
    SettingDataType.GET_ZOHO_PEOPLE_DETAILS,
    workerGetZohoPeopleDetails
  );
};
