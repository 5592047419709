import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationColumns } from "../../common/utils/settings";
import { updateNotification } from "../../redux/actions";
import Loader from "../Loaders/Loader";
import TableLoader from "../Loaders/TableLoader";
import './notificationTable.css'
import { AddEnableIcon, crossIcon, deleteIcon, editIcon} from '../../common/icons';

export const NotificationTable = () => {
  const { notificationsList } = useSelector(({ USER }) => USER);
  const { vTrackLoader, tableLoader } = useSelector(({ APP_STATE }) => APP_STATE);
  const [notificationData, setNotificationData] = useState([]);

  const [editNotificationId, setEditNotificationId] =useState(false);
  const [optionalEmailData,setOptionalEmailData]=useState('')
  const [sendToggleStatus,setSendToggleStatus]=useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    if(notificationsList){
      setNotificationData(notificationsList);
    
    }
  }, [notificationsList])
 
  const handleEdit = (id,enabled,optionalEmail="")=>{
    setEditNotificationId(id);
    setOptionalEmailData(optionalEmail);
    setSendToggleStatus(enabled);
  }
  const handleCancel= ()=>{
    setEditNotificationId('')
    setOptionalEmailData('');
    setSendToggleStatus(false)

  }
  const handleUpdate = ()=>{
   
    dispatch(
      updateNotification({
        id:editNotificationId,
        sendToggle:sendToggleStatus,
        optionalEmail:optionalEmailData
      })
    )
    setEditNotificationId('')
    
    setSendToggleStatus(false)
  }



  return(
    <>
    {/* {vTrackLoader && <Loader />} */}
    <div className="settingsWrapper">
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "48rem"}}>
          <Table  style={{marginTop: '1rem'}} aria-label="sticky table" size="small">
            <TableHead style={{position:"sticky", top:"0", zIndex: 1}}>
              <TableRow style={{backgroundColor:"white" }} className='settingTableHeader manageNotifications'>
              {notificationColumns['MANAGE NOTIFICATIONS'].map(
                (column) =>
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{width: column.width}}
                    sx={{
                        backgroundColor: "#1773bc0d",
                        color: "#1773bc",
                        fontWeight: 700,
                    }}
                  >
                    {column.label}
                  {/* <img
                      src={TableArrows}
                      alt=""
                      className="tableArrows"

                  /> */}
                  </TableCell>)}
              </TableRow>
            </TableHead>
            {(tableLoader || vTrackLoader) && <TableLoader />}
            {notificationData && <TableBody className='settingTableBody manageNotifications'>
                        {notificationData.map((data) =>

                            <TableRow key={data.notificationId} className='settingTableHeader'>
                                <TableCell align="left" style={{ width:"35rem"}}>{data.notificationName}</TableCell>
                                <TableCell align="left" style={{ width: "10rem"}}>
                                  <Switch 
                                    checked={editNotificationId===data.notificationId?sendToggleStatus:data.enabled}
                                    onChange={()=>{if(editNotificationId===data.notificationId)
                                      setSendToggleStatus(!sendToggleStatus);}}
                                    disabled={false}
                                    value={data.notificationId}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </TableCell>
                                {/* <TableCell align="left">
                                  <Switch 
                                    checked={data.enabled} 
                                    onChange={handleChange} 
                                    disabled={changeNotification}
                                    value={data.notificationId}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </TableCell> */}
                                {editNotificationId===data.notificationId?
                                  (<TableCell align="left " style={{width:"50rem" }}>
                                    <TextField
                                    style={{width:"45rem"}}
                                    
                                    value={optionalEmailData}
                                    sx={{
                                      "& label": {
                                        lineHeight: '0.8rem'
                                      }
                                    }}
                                    onChange={(e)=>{
                                      setOptionalEmailData(e.target.value)
                                    }}

                                    />
                                  </TableCell>)
                                :
                                <TableCell align="left" style={{width:"50rem",marginRight:"3rem"}}>{data.optionalEmail}</TableCell>
                                }
                                <TableCell align="center" style={{width: "5rem" }}>
                                  {editNotificationId===data.notificationId?
                                      <div className="actions">
                                          <Tooltip title="update">
                                            <img
                                                src={AddEnableIcon}
                                                className="editDeleteIcon cursorPointer"
                                                
                                                onClick={()=>{
                                                  handleUpdate()
                                                }}
                                                alt=""
                                            />    
                                          </Tooltip>
                                          <Tooltip title="cancel">
                                            <img
                                                src={crossIcon}
                                                className="editDeleteIcon"
                                                
                                                onClick={handleCancel}
                                            />
                                          </Tooltip>
                                      </div>
                                      :
                                      <div className="actions">
                                          <Tooltip title="Edit">
                                                <button
                                                    onClick={()=>
                                                      handleEdit(data.notificationId,data?.enabled,data?.optionalEmail) 
                                                    }
                                                    
                                                    className="buttonBackgroundBorder cursorPointer"
                                                >
                                                    <img src={editIcon} className = "editDeleteIcon" alt=""/>
                                                </button>    
                                          </Tooltip>
                                      </div>
                                  }
                                </TableCell>
                            </TableRow>)}
                    </TableBody>}
          </Table>
        </TableContainer>
      </Paper>
    </div>
    </>
  )
}