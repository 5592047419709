import { call, put, takeLatest } from "redux-saga/effects";
import {getCustomWfo} from "../http/requests/setupWFODays";
import {
    setVtrackLoader,
  } from "../redux/actions";

  import { SettingDataType } from "../redux/actions";
  import{setCustomWfo} from "../redux/actions/settings"

  function* workergetCustomWfoDaysSaga({payload}) {
    try {
      yield put(setVtrackLoader(true));
      const wfoList = yield call(getCustomWfo,payload.year)
      yield put(setCustomWfo(wfoList));
      yield put(setVtrackLoader(false));
    } catch (err) {
      console.log(err);
      yield put(setVtrackLoader(false));
    }
  };
  
  export function* getCustomWfoDaysSaga() {
    yield takeLatest(
      SettingDataType.GET_CUSTOM_WFO,
      workergetCustomWfoDaysSaga
    );
  };
  
