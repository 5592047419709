export const SessionType = {
    ADD_SESSION:"ADD_SESSION",
    UPDATE_SESSION:"UPDATE_SESSION",
    GET_ALL_SESSIONS_DATA:"GET_ALL_SESSIONS_DATA",
    SET_ALL_SESSIONS_DATA:"SET_ALL_SESSIONS_DATA",
    DELETE_SESSION:"DELETE_SESSION",
    GET_PARTICIPANTS_DATA: "GET_PARTICIPANTS_DATA",
    SET_PARTICIPANTS_DATA: "SET_PARTICIPANTS_DATA",
    UPDATE_PARTICIPANT_REQUEST : "UPDATE_PARTICIPANT_REQUEST",
    UPDATE_ATTENDANCE_REQUEST:"UPDATE_ATTENDANCE_REQUEST",
    SET_ALL_SESSIONS_DATA:"SET_ALL_SESSIONS_DATA",
    REGISTER_USER_SESSION_DATA:"REGISTER_USER_SESSION_DATA",
    SUBMIT_SESSION_FEEDBACK_DETAILS:"SUBMIT_SESSION_FEEDBACK_DETAILS",
    GET_USER_DETAILS_FOR_TRAINING_AND_SESSION:"GET_USER_DETAILS_FOR_TRAINING_AND_SESSION",
    SET_USER_DETAILS_FOR_TRAINING_AND_SESSION:"SET_USER_DETAILS_FOR_TRAINING_AND_SESSION",
    UPDATE_USER_REGISTRATION:"UPDATE_USER_REGISTRATION",
    GET_SESSIONS_USER_HISTORY:"GET_SESSIONS_USER_HISTORY",
    SET_SESSIONS_USER_HISTORY: "SET_SESSIONS_USER_HISTORY",
    GET_SESSION_FEEDBACK_QUESTIONS_LIST: "GET_SESSION_FEEDBACK_QUESTIONS_LIST",
    SET_SESSION_FEEDBACK_QUESTIONS_LIST: "SET_SESSION_FEEDBACK_QUESTIONS_LIST",
    GET_SESSION_FEEDBACK_HEADER: "GET_SESSION_FEEDBACK_HEADER",
    SET_SESSION_FEEDBACK_HEADER: "SET_SESSION_FEEDBACK_HEADER",
    GET_SESSION_FOR_EXCEL: "GET_SESSION_FOR_EXCEL",
    SET_SESSION_FOR_EXCEL:"SET_SESSION_FOR_EXCEL",
    SET_SESSION_FEEDBACK_URL:"SET_SESSION_FEEDBACK_URL",
}

export const addSession = (data)=>({
    type: SessionType.ADD_SESSION,
    payload: {data}
}
);

export const updateSession = (data)=>({
  type: SessionType.UPDATE_SESSION,
  payload: {data}
}
);

export const getAllSessionsData = (data) => 
({
  type: SessionType.GET_ALL_SESSIONS_DATA,
  payload: data,
});

export const setAllSessionsData = (data) =>
({
    type: SessionType.SET_ALL_SESSIONS_DATA,
    payload:data,
});
export const registerUserSessionData = (data)=>({
    type: SessionType.REGISTER_USER_SESSION_DATA,
    payload: {data}
});
export const submitSessionFeedbackDetails = (data)=>({
  type: SessionType.SUBMIT_SESSION_FEEDBACK_DETAILS,
  payload: {data}
});

export const getUserDetailsForTrainingAndSession = (data) => ({
    type: SessionType.GET_USER_DETAILS_FOR_TRAINING_AND_SESSION,
    payload: data
});

export const setUserDetailsForTrainingAndSession = (data) =>({
    type: SessionType.SET_USER_DETAILS_FOR_TRAINING_AND_SESSION,
    payload:data,
});

export const updateUserRegistration = (data) => ({
    type: SessionType.UPDATE_USER_REGISTRATION,
    payload: {data}
  });

export const deleteSessionData = (data) => 
  ({
    type: SessionType.DELETE_SESSION,
    payload: data
  });

export const getParticipantsData = (data) =>
({
  type: SessionType.GET_PARTICIPANTS_DATA,
  payload: data
});
export const setParticipantsData = (data) =>
({
  type: SessionType.SET_PARTICIPANTS_DATA,
  payload: data
});
export const updateParticipantRequest = (data)=>({
  type: SessionType.UPDATE_PARTICIPANT_REQUEST,
  payload: {data}
});
export const getSessionUserHistory = ()=>
({
  type: SessionType.GET_SESSIONS_USER_HISTORY,
});
export const setSessionUserHistory = (data)=>
({
  type: SessionType.SET_SESSIONS_USER_HISTORY,
  payload:data
});

export const getSessionFeedbackQuestionsList = ()=>
({
  type: SessionType.GET_SESSION_FEEDBACK_QUESTIONS_LIST,
});
export const getSessionFeedbackHeader = (data) => 
({
  type: SessionType.GET_SESSION_FEEDBACK_HEADER,
  payload:data
})
export const setSessionFeedbackHeader = (data) => 
({
  type: SessionType.SET_SESSION_FEEDBACK_HEADER,
  payload:data
})
export const setSessionFeedbackquestionsList = (data)=>
({
  type: SessionType.SET_SESSION_FEEDBACK_QUESTIONS_LIST,
  payload:data
});

export const updateAttendance = (data)=>({
  type:SessionType.UPDATE_ATTENDANCE_REQUEST,
  payload:{data}
})

export const setAttendanceData = (data) => ({
  type: SessionType.SET_ATTENDANCE_DATA,
  payload: data
})
export const getSessionDataForExcel=(data)=>({
  type : SessionType.GET_SESSION_FOR_EXCEL ,
  payload : data
})
export const setSessionDataForExcel = (data) =>({
  type : SessionType.SET_SESSION_FOR_EXCEL,
  payload:data
})

export const setSessionFeedbackUrl = (data) => ({
  type: SessionType.SET_SESSION_FEEDBACK_URL,
  payload: data
})