import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { updateHoliday } from "../http/requests/holiday-list";
import { getListItems } from "../http/requests/dropdown";
import {
  SettingDataType,
  getHolidayList,
  setVtrackLoader,
  setListItems,
  setHolidayList
} from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerUpdateHolidaySaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));    
    yield call(updateHoliday, payload.updateData);
    toast.success("Data Saved", toastOptions)
    // const listItems = yield call(getListItems,'holiday')
    // yield put(setListItems(listItems));
    // const holidayList = yield call(getHolidayList,
    //   payload.year);
    // yield put(setHolidayList(holidayList));
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
}

export function* updateHolidaySaga() {
  yield takeLatest(
    SettingDataType.UPDATE_HOLIDAY,
    workerUpdateHolidaySaga
  );
}
