export const ViewAttendanceType = {
    GET_VIEW_ATTENDANCE_DATA: "GET_VIEW_ATTENDANCE_DATA",
    SET_VIEW_ATTENDANCE_DATA: "SET_VIEW_ATTENDANCE_DATA",
    GET_LEAVE_DETAILS: "GET_LEAVE_DETAILS",
    SET_LEAVE_DETAILS: "SET_LEAVE_DETAILS",
    GET_ATTENDANCE_FOR_EXCEL: "GET_ATTENDANCE_FOR_EXCEL",
    SET_ATTENDANCE_FOR_EXCEL: "SET_ATTENDANCE_FOR_EXCEL" 
}

export const getViewAttendanceData = (data) => 
({
  type: ViewAttendanceType.GET_VIEW_ATTENDANCE_DATA,
  payload: data,
});

export const setViewAttendanceData = (data) =>
({
    type: ViewAttendanceType.SET_VIEW_ATTENDANCE_DATA,
    payload:data,
})

export const getLeaveDetails = (data) =>
({
    type: ViewAttendanceType.GET_LEAVE_DETAILS,
    payload:data,
})

export const setLeaveDetails = (data) =>
({
    type: ViewAttendanceType.SET_LEAVE_DETAILS,
    payload:data,
})
export const getAttendanceForExcel = (data) =>
({
    type: ViewAttendanceType.GET_ATTENDANCE_FOR_EXCEL,
    payload:data,
})

export const setAttendanceForExcel = (data) =>
({
    type: ViewAttendanceType.SET_ATTENDANCE_FOR_EXCEL,
    payload:data,
})