import { call, put, takeLatest } from "redux-saga/effects";
import { getProjectAllocationDetails, getUsersProjectAllocationDetails } from "../http/requests/project-allocation";
import {
  ProjectAllocationType,
  setProjectAllocationData,
  setProjectAllocationDataForExcel,
  setVtrackLoader,
} from "../redux/actions";


function* workerUsersProjectAllocationSaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    yield put(setProjectAllocationData([]));
    const projectAllocationDetails = yield call(
      getUsersProjectAllocationDetails,
      {
        employeeIds: payload.employeeIds,
        isHistory: payload.isHistory
      }
    );   
      yield put(setProjectAllocationData(projectAllocationDetails));
    
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
}

export function* usersProjectAllocationSaga() {
  yield takeLatest(
    ProjectAllocationType.GET_USERS_PROJECT_ALLOCATION,
    workerUsersProjectAllocationSaga
  );
}
