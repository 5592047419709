import axios from "axios";
import { ACCESS_TOKEN } from "../constants/local-storage-keys";
import { Modules } from "../constants/sidebar";
import { getLocalStorageItem } from "./local-storage";
import Cookies from 'universal-cookie';

export const UniqueIds = {
  ProjectAdmin: 'projectId',
  ClientAdmin: 'clientId',
  ProjectAllocation: 'projectAllocationId',
  Timesheet: 'timesheetDetailID',
  ProjectManagement: 'projectAllocationId',
  Reporting: 'employeeID',
  InvoiceTracker: 'invoiceId',
  CreateInvoice: 'billMonth',
  SkillTracker: 'employeeID',
  Training_And_Session: 'sessionId'
};

const cookies = new Cookies();

export const tableColumnsData = {
  'ProjectAdmin': [
    { id: "projectName", label: "Project Name", minWidth: 100, type: 'textfield', sortDir: '', align: 'left', isRequired: true, isSort: true },
    { id: "clientName", label: "Client Name", minWidth: 100, type: 'select', sortDir: '', align: 'left', isRequired: true, isSort: true },
    { id: "type", label: "Type", minWidth: 80, type: 'select', sortDir: '', align: 'left', isSort: true },
    { id: "sowStartDate", label: "SOW Start Date", minWidth: 100, type: 'date', sortDir: '', align: 'left', isSort: true },
    { id: "sowEndDate", label: "SOW End Date", minWidth: 110, type: 'date', sortDir: '', align: 'left', isSort: true },
    { id: 'projectManagerName', label: 'Veersa Project Manager', minWidth: 100, type: 'select', sortDir: '', align: 'left', isSort: false },
    { id: 'status', label: 'Status', minWidth: 100, type: 'select', align: 'left', isSort: false },
    { id: 'approvers', label: 'Approvers', minWidth: 100, maxWidth: 120, type: 'multi-select', sortDir: '', align: 'left', isSort: false },
    { id: 'actions', label: 'Actions', minWidth: 100, type: 'action', sortDir: '', align: 'left', isSort: false }
  ],
  'ProjectAdminManager': [
    { id: "projectName", label: "Project Name", minWidth: 100, type: 'textfield', sortDir: '', align: 'left', isRequired: true, isSort: true },
    { id: "clientName", label: "Client Name", minWidth: 100, type: 'select', sortDir: '', align: 'left', isRequired: true, isSort: true },
    { id: "type", label: "Type", minWidth: 80, type: 'select', sortDir: '', align: 'left', isSort: true },
    { id: "sowStartDate", label: "SOW Start Date", minWidth: 100, type: 'date', sortDir: '', align: 'left', isSort: true },
    { id: "sowEndDate", label: "SOW End Date", minWidth: 110, type: 'date', sortDir: '', align: 'left', isSort: true },
    { id: 'projectManagerName', label: 'Veersa Project Manager', minWidth: 100, type: 'select', sortDir: '', align: 'left', isSort: false },
    { id: 'status', label: 'Status', minWidth: 100, type: 'select', align: 'left', isSort: false },
    { id: 'approvers', label: 'Approvers', minWidth: 100, maxWidth: 120, type: 'multi-select', sortDir: '', align: 'left', isSort: false }
  ],
  'ClientAdmin': [
    { id: "clientName", label: "Client Name", minWidth: 100, type: 'textfield', sortDir: '', align: 'left', isRequired: true, isSort: true },
    { id: "location", label: "Client Location", minWidth: 80, type: 'select', sortDir: '', align: 'left', isSort: true },
    { id: "currency", label: "Currency", minWidth: 80, type: 'select', sortDir: '', align: 'left', isSort: true },
    { id: "msaStartDate", label: "MSA Start Date", minWidth: 100, type: 'date', sortDir: '', align: 'left', isSort: true },
    { id: "msaEndDate", label: "MSA End Date", minWidth: 110, type: 'date', sortDir: '', align: 'left', isSort: true },
    { id: "businessOwner", label: "Veersa Business Owner", minWidth: 100, type: 'select', sortDir: '', align: 'left', isSort: false },
    { id: "paymentTerms", label: "Payment Terms", minWidth: 80, type: 'textfield', sortDir: '', align: 'left', isSort: true },
    { id: "deliveryOfficer", label: "Veersa Delivery Officer", minWidth: 100, type: 'select', sortDir: '', align: 'left', isSort: false },
    { id: 'actions', label: 'Actions', minWidth: 100, type: 'action', sortDir: '', align: 'left', isSort: false }
  ],
  'ProjectAllocation': [
    { id: "employeeName", label: "Employee Name", minWidth: 120, type: 'select', sortDir: '', align: 'left', isSort: false },
    { id: "projectName", label: "Project Name", minWidth: 100, type: 'select', sortDir: '', align: 'left', isSort: true },
    { id: "projectManagerName", label: "Project Manager", minWidth: 80, type: 'select', sortDir: '', align: 'left', isSort: false },
    { id: "startDate", label: "Start Date", minWidth: 100, type: 'date', sortDir: '', align: 'left', isSort: true },
    { id: "endDate", label: "End Date", minWidth: 110, type: 'date', sortDir: '', align: 'left', isSort: true },
    { id: 'billStatus', label: 'Bill Status', minWidth: 100, type: 'textfield', sortDir: '', align: 'left', isSort: false },
    { id: 'billAllocation', label: 'Allocation', minWidth: 100, type: 'textfield', sortDir: '', align: 'left', isSort: false },
    // { id: 'allocationStatus', label: 'Allocation Status', minWidth: 100, type: 'textfield', sortDir: '', align: 'left', isSort: false },
    // { id: 'status', label: 'Allocation Status', minWidth: 100, type: 'textfield' }
  ],
  'ProjectManagement': [
    { id: "employeeName", label: "Employee Name", minWidth: 120, type: 'multi-select', sortDir: '', isRequired: true, align: 'left', isSort: true },
    { id: "employeeRole", label: "Project Role", minWidth: 120, type: 'select', sortDir: '', isRequired: false, align: 'left', isSort: false },
    { id: "site", label: "Offshore/Onshore", minWidth: 110, type: 'select', sortDir: '', align: 'left', isSort: false },
    { id: 'startDate', label: 'Start Date', minWidth: 90, type: 'date', sortDir: '', isRequired: true, align: 'left', isSort: true },
    { id: "endDate", label: "End Date", minWidth: 90, type: 'date', sortDir: '', isRequired: true, align: 'left', isSort: true },
    { id: 'billCurrency', label: 'Bill Currency', minWidth: 80, isRequired: true, type: 'select', sortDir: '', align: 'left', isSort: false },
    { id: 'billRate', label: 'Bill Rate', minWidth: 80, min: 0, isRequired: true, type: 'textfield', fieldType: 'number', sortDir: '', align: 'left', isSort: false },
    { id: 'billFrequency', label: 'Bill Frequency', minWidth: 80, isRequired: true, type: 'select', sortDir: '', align: 'left', isSort: false },
    { id: 'billAllocation', label: 'Allocation', minWidth: 100, min: 0, max: 100, isRequired: true, type: 'textfield', fieldType: 'number', sortDir: '', align: 'left', isSort: false },
    { id: 'billStatus', label: 'Bill Status', minWidth: 80, type: 'select', sortDir: '', align: 'left', isSort: false },
    { id: 'costAllocation', label: 'Cost Allocation', minWidth: 100, type: 'textfield', sortDir: '', align: 'left', isSort: false },
    { id: 'actions', label: 'Actions', minWidth: 100, type: 'action', sortDir: '', align: 'left', isSort: false }
  ],
  'Timesheet': [
    { id: "projectName", label: "Project Name", minWidth: 110, type: 'select', isRequired: true },
    { id: "task", label: "Task", minWidth: 100, maxWidth: 150, type: 'select', isRequired: true },
    { id: "notes", label: "Notes", minWidth: 100, maxWidth: 150, type: 'textfield', isRequired: false },
    { id: "totalHrs", label: "Total", minWidth: 60, type: 'empty' },
    { id: "status", label: "Status", minWidth: 60, type: 'empty' }
  ],
  'MyTimeSheet': [
    { id: "projectName", label: "Project Name", minWidth: 110, type: 'select', isRequired: true },
    { id: "task", label: "Task", minWidth: 100, maxWidth: 150, type: 'select', isRequired: true },
    { id: "notes", label: "Notes", minWidth: 100, maxWidth: 150, type: 'textfield', isRequired: false },
    { id: "totalHrs", label: "Total", minWidth: 60, type: 'empty' },
    { id: "status", label: "Status", minWidth: 60, type: 'empty' },
  ],
  'Reportees': [
    { id: "projectName", label: "Project Name", minWidth: 110, type: 'select' },
    { id: "task", label: "Task", minWidth: 100, type: 'textfield' },
    { id: "viewDetails", label: "View Details", minWidth: 80, type: 'action' },
    { id: "totalHrs", label: "Total", minWidth: 60, type: 'empty' },
    { id: "status", label: "Status", minWidth: 60, type: 'empty' }
  ],
  'SkillsReport': [
    { id: "employeeName", label: "Employee Name", minWidth: 200, type: 'textfield', sortDir: '', isSort: true },
    { id: "projectName", label: "Current Project Allocation", minWidth: 200, type: 'textfield', sortDir: '', isSort: true },
    { id: "skillBucket", label: "Skill Bucket", minWidth: 300, type: 'textfield', sortDir: '', isSort: true },
    { id: "primarySecondarySkills", label: "Primary & Secondary Skills", minWidth: 60, type: 'textfield', sortDir: '', isSort: true },
  ],
  'PendingApproval': [
    { id: "projectName", label: "Project Name", minWidth: 110, type: 'select', isRequired: true },
    { id: "employeeName", label: "Employee Name", minWidth: 100, type: 'select' },
    { id: "periodWeek", label: "Period Week", minWidth: 100, type: 'textfield' },
    { id: "task", label: "Task", minWidth: 100, maxWidth: 150, type: 'select', isRequired: true },
    { id: "notes", label: "Notes", minWidth: 100, maxWidth: 150, type: 'textfield', isRequired: false },
    { id: "totalHrs", label: "Total", minWidth: 60, type: 'empty' },
    { id: 'actions', label: 'Actions', minWidth: 100, type: 'action', align: 'left' }
  ],
  'MissingTimesheet': [
    { id: "employeeName", label: "Employee Name", minWidth: 300, type: 'textfield' },
    { id: "periodWeek", label: "Period Week", minWidth: 300, type: 'textfield' },
    { id: "status", label: "Status", minWidth: 200, type: 'textfield' },
    { id: "actions", label: 'Actions', minWidth:60, type:'action',align: 'left'}
  ],
  'TimesheetReports': [
    { id: "employeeName", label: "Employee Name", minWidth: 120, type: 'textfield' },
    { id: "projectManagerName", label: "Manager Name", minWidth: 100, type: 'select' },
    { id: "projectName", label: "Project Name", minWidth: 110, type: 'select' },
    { id: "periodWeek", label: "Period Week", minWidth: 110, type: 'textfield' },
    { id: "status", label: "Status", minWidth: 60, type: 'textfield' },
    { id:"approverName", label:"Approved By", minWidth:60, type: 'textfield'}
  ],
  'InvoiceTracker': [
    { id: "clientName", label: "Client Name", minWidth: 100, type: 'textfield', isRequired: true, disabled: true },
    { id: "projectName", label: "Project Name", minWidth: 100, type: 'textfield', isRequired: true, disabled: true },
    // { id: "createdDate", label: "Current Date", minWidth: 120, type: 'date', sortDir: '',isRequired: true, align: 'left', disabled: true },
    { id: "billMonth", label: "Invoice Month", minWidth: 100, type: 'textfield', fieldType: 'text', isRequired: true, disabled: true },
    { id: "billStartDate", label: "Invoice Start Date", type: "textfield", minWidth: 100, isRequired: true, align: 'left', disabled: true },
    { id: "billEndDate", label: "Invoice End Date", minWidth: 100, type: 'textfield', isRequired: true, disabled: true },
    { id: "billCurrency", label: "Invoice Currency", minWidth:100, type: 'select', isRequired: true, disabled: true },
    { id: "billAmount", label: "Invoice Amount", minWidth: 100, type: 'textfield', fieldType: 'text', isRequired: true },
    { id: "revenueCurrency", label: "Revenue Currency", minWidth:100, type: 'select', isRequired: true, disabled: true },
    { id: "revenueAmount", label: "Revenue Amount", minWidth:100, type: 'textfield', fieldType: 'text',isRequired: true },
    // { id: "soWamount", label: "SOW Amount", minWidth: 100, type: 'textfield', fieldType: 'text', isRequired: true },
    { id: "invoiceStatus", label: "Invoice Status", minWidth: 100, type: 'textfield', disabled: true },
    { id: 'actions', label: 'Actions', minWidth: 100, type: 'action', align: 'left' },
  ],
  "InvoiceTrackerEdit": [
    { id: "clientName", label: "Client Name", minWidth: 110, type: 'textfield', disabled: true, align: "left" },
    { id: "projectName", label: "Project Name", minWidth: 110, type: 'textfield', disabled: true, align: "left" },
    { id: "createdDate", label: "Created Date", minWidth: 110, type: 'date', isSort: true, disabled: true, align: "left" },
    { id: "billMonth", label: "Invoice Month", minWidth: 110, type: 'textfield', fieldType: 'text', disabled: true, align: "left" },
    { id: "billStartDate", label: "Invoice Start Date", minWidth: 110, type: "date", disabled: false, align: "left" },
    { id: "billEndDate", label: "Invoice End Date", minWidth: 110, type: 'date', disabled: false, align: "left" },
    { id: "billAmount", label: "Invoice Amount", minWidth: 110, type: 'textfield', disabled: false, fieldType: 'text', align: "left" },
    { id: "qbInvoiceReference", label: "QuickBooks Invoice", minWidth: 110, type: 'textfield', fieldType: 'text', disabled: false, align: "left" },
    // { id: "soWamount", label: "SOW Amount", minWidth: 110, type: 'textfield', disabled: true, fieldType: 'text', align: "left" },
    { id: "billCurrency", label: "Invoice Currency", minWidth:110, type: 'select', disabled: false, fieldType: 'select', align:"left" },
    { id: "revenueCurrency", label: "Revenue Currency", minWidth:110, type: 'select', disabled: false,fieldType: 'select', align:"left" },
    { id: "revenueAmount", label: "Revenue Amount", minWidth:110, type: 'textfield', disabled: false, fieldType: 'text', align: "left" },
    { id: "billAmountReceived", label: "Invoice Amount Received", minWidth: 110, type: 'textfield', fieldType: 'text', disabled: false, align: "left" },
    { id: "clientReminderDate", label: "Client Reminder Date", minWidth: 110, type: 'date', fieldType: 'text', disabled: false, align: "left" },
    { id: "invoiceStatus", label: "Invoice Status", minWidth: 110, type: 'select', disabled: false, align: "left" },
    { id: "remark", label: "Remark", minWidth: 110, type: 'multiline-textfield', fieldType: 'text', isRequired: false, align: "left" },
  ],
  "TrainingAndSession": [
    { id: "sessionName", label: "Session Name", minWidth: 100, type: 'textfield', sortDir: '', align: 'left'},
    { id: "targetedSkill", label: "Targeted Skills", minWidth: 100, type: 'textfield', sortDir: '', align: 'left'},
    { id: 'hosts', label: 'Hosts', minWidth: 100, maxWidth: 100, type: 'multi-select', sortDir: '', align: 'left', isSort: false },
    { id: "sessionDate", label: "Session Date", minWidth: 100, type: 'textfield', sortDir: '', align: 'left'},
    // { id: "duration", label: "Duration", minWidth: 100, type: 'textfield', sortDir: '', align: 'left' },
    { id: "status", label: "Session Status", minWidth: 100, type: 'textfield', sortDir: '', align: 'left' },
    { id: "registrationStatus", label: "Registration Status", minWidth: 100, type: 'textfield', sortDir: '', align: 'left' },
    { id: 'actions', label: 'Actions', minWidth: 100, type: 'action', align: 'left' },
    
  ],
  "SessionHistory": [
    { id: "sessionName", label: "Session Name", minWidth: 100, type: 'textfield', sortDir: '', align: 'left'},
    { id: "sessionDate", label: "Session Date", minWidth: 100, type: 'textfield', sortDir: '', align: 'left'},
    { id: "registrationType", label: "Registration Type", minWidth: 100, type: 'textfield', sortDir: '', align: 'left' },
    { id: "registrationStatus", label: "Registration Status", minWidth: 100, type: 'textfield', sortDir: '', align: 'left' },
    { id: "sessionStatus", label: "Session Status", minWidth: 100, type: 'textfield', sortDir: '', align: 'left' },
    { id: "attendance", label: "Attendance", minWidth: 100, type: 'textfield', align: 'left' },
    { id: 'actions', label: 'Feedback', minWidth: 100, type: 'action', align: 'left' },
  ],
  "SessionHistoryAdmin": [
    { id: "sessionName", label: "Session Name", minWidth: 100, type: 'textfield', sortDir: '', align: 'left'},
    { id: "sessionDate", label: "Session Date", minWidth: 100, type: 'textfield', sortDir: '', align: 'left'},
    { id: "sessionStatus", label: "Session Status", minWidth: 100, type: 'textfield', sortDir: '', align: 'left' },
    { id: "attendance", label: "Attendance", minWidth: 100, type: 'textfield', align: 'left' },
    { id: 'actions', label: 'Feedback', minWidth: 100, type: 'action', align: 'left' },
  ],
  "ResourcingDetailsProjectAdmin": [
    { id: "year", label: "Year", minWidth: 100, type: 'textfield',disabled:true, sortDir: '', align: 'left'},
    { id: "month", label: "Month", minWidth: 100, type: 'textfield',disabled:true, sortDir: '', align: 'left'},
    { id: "billableResourceCount", label: "Billable Count", minWidth: 100, type: 'textfield', sortDir: '', align: 'left' },
    { id: "nonBillableResourceCount", label: "Non-Billable Count", minWidth: 100, type: 'textfield', align: 'left' },
    { id: "shadowResourceCount", label: "Shadow Count", minWidth: 100, type: 'textfield', align: 'left' },
  ],
  "AllocationHistory": [
    { id: "tableName", label: "Table Name", minWidth: 110, type: 'textfield', disabled: true, align: "left" },
    { id: "columnName", label: "Column Name", minWidth: 110, type: 'textfield', disabled: true, align: "left" },
    { id: "oldValue", label: "Old Value", minWidth: 110, type: 'textfield', disabled: true, align: "left" },
    { id: "newValue", label: "New Value", minWidth: 110, type: 'textfield', disabled: true, align: "left" },
    { id: "updateBy", label: "Updated By", minWidth: 110, type: 'textfield', disabled: true, align: "left" },
    { id: "updatedDate", label: "Updated Date", minWidth: 110, type: 'date', disabled: true, align: "left" },
  ],
  "ZohoDetails": {
    "Employee Details": [
      { id: "employeeID", label: "Employee ID", type: "textfield" },
      { id: "employeeName", label: "Employee Name", type: "textfield" },
      { id: "email", label: "Email", type: "textfield" },
      { id: "department", label: "Department", type: "textfield" },
      { id: "jobTitle", label: "Job Title", type: "textfield" },
      { id: "stream", label: "Stream", type: "textfield" },
      { id: "locationName", label: "Location Name", type: "textfield" },
      { id: "designation", label: "Designation", type: "textfield" },
      { id: "dateOfJoining", label: "Date Of Joining", type: "date" },
    ],
    "Skills Details": [
      { id: "skillBucket", label: "Skill Bucket", type: "textfield" },
      { id: "skillBucketIfOthersSelected", label: "Skill Bucket (If Others Selected)", type: "textfield" },
      { id: "primarySkill", label: "Primary Skill", type: "textfield" },
      { id: "primarySkillIfOthersSelected", label: "Primary Skill (If Others Selected)", type: "textfield" },
      { id: "secondarySkill", label: "Secondary Skill", type: "textfield" },
      { id: "secondarySkillIfOthersSelected", label: "Secondary Skill (If Others Selected)", type: "textfield" },
      { id: "additionalSkills", label: "Additional Skills", type: "textfield" },
    ],
    'Project Allocation': [
      { id: "projectName", label: "Project Name", type: 'texxtfield' },
      { id: "projectManagerName", label: "Project Manager", type: 'textfield' },
      { id: "startDate", label: "Start Date", type: 'date' },
      { id: "endDate", label: "End Date", type: 'date' },
      { id: 'billStatus', label: 'Bill Status', type: 'textfield' },
      { id: 'billAllocation', label: 'Allocation', type: 'textfield' },
    ]
  }

};

export function titleCase(st) {
  return st.toLowerCase().split(" ").reduce((s, c) =>
    s + "" + (c.charAt(0).toUpperCase() + c.slice(1)), '');
}
export const getTypeofColumn = (col, moduleName) => {
  if (tableColumnsData[moduleName.replace(' ', '')] && tableColumnsData[moduleName.replace(' ', '')].length) {
    for (let column of tableColumnsData[moduleName.replace(' ', '')]) {
      if (column.id === col) {
        return column.type;
      }
    }
  }
};

export const getisRequiredofColumn = (col, moduleName) => {
  if (tableColumnsData[moduleName.replace(' ', '')] && tableColumnsData[moduleName.replace(' ', '')].length) {
    for (let column of tableColumnsData[moduleName.replace(' ', '')]) {
      if (column.id === col) {
        return column.isRequired;
      }
    }
  }
}

export const getLabel = (col, moduleName) => {
  if (col.id === "billMonth") {
    return '';
  }
  if (tableColumnsData[moduleName.replace(' ', '')] && tableColumnsData[moduleName.replace(' ', '')].length) {
    for (let column of tableColumnsData[moduleName.replace(' ', '')]) {
      if (column.id === col) {
        return column.label;
      }
    }
  }
  return '';
};

export const getMinWidth = (col, moduleName) => {
  if (tableColumnsData[moduleName.replace(' ', '')] && tableColumnsData[moduleName.replace(' ', '')].length) {
    for (let column of tableColumnsData[moduleName.replace(' ', '')]) {
      if (column.id === col) {
        return column.minWidth;
      }
    }
  }
};

export const fileHandler = (file, id, name, headingName) => {
  const accessToken = getLocalStorageItem(ACCESS_TOKEN);
  // const accessToken = cookies.get('userInformation');

  if (file) {
    const formData = new FormData();
    formData.append("file", file);
    let URL = "";
    if (headingName === Modules.CLIENT_ADMIN)
      URL = `https://vtrack-dev-api.azurewebsites.net/Client/upload-msa?clientId=${id}&clientName=${name}`;
    else if (headingName === Modules.PROJECT_ADMIN)
      URL = `https://vtrack-dev-api.azurewebsites.net/ProjectAdmin/upload-sow?projectId=${id}&projectName=${name}`;
    axios.post(URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

};

export const convertDateToDDMYYYY = (data) => {
  if (!data) return '';
  let date = new Date(data.split("T")[0]);
  let MM = date.toLocaleString("default", {
    month: "short",
  });
  let YYYY = date.getFullYear();
  let DD = date.getDate();
  return DD + "-" + MM + "-" + YYYY
};

export const initialSort = {
  'Client Admin': 'clientName',
  'Project Admin': 'projectName',
  'Project Allocation': 'projectName',
  'Project Management': 'projectName',
};

export const getFullName = (firstName, lastName) => {
  let fullName = "";
  if (firstName) {
    fullName += firstName;
  }
  if (lastName) {
    fullName += " " + lastName
  }
  return fullName;
};

export const getApprovers = (renderValues, id) => {
  let approverList = '';
  for (let i = 0; i < renderValues.length; i++) {
    approverList += id === "approvers" ? renderValues[i].approverName : renderValues[i].employeeName;
    if (i !== renderValues.length - 1) {
      approverList += ', ';
    }
  }
  return approverList;
};

export const getHosts = (renderValues,id) => {
  let hostList = '';
  for (let i = 0; i < renderValues.length; i++) {
    hostList += renderValues[i].hostName;
    if (i !== renderValues.length - 1) {
      hostList += ', ';
    }
  }
  return hostList;
}

export const getApproversIds = (approvers) => {
  let approverIds = '';
  for (let i = 0; i < approvers.length; i++) {
    approverIds += approvers[i].approverId;
    if (i !== approvers.length - 1) {
      approverIds += ',';
    }
  }
  return approverIds;
};

export const getApproversWithIds = (approvers) => {
  return (<div>{approvers.map((approver) => (<div>{`${approver.approverName} (${approver.approverEmail})`}</div>))}</div>);
};
export const getHostsWithIds = (approvers) => {
  return (<div>{approvers.map((approver) => (<div>{`${approver.hostName} (${approver.hostEmail})`}</div>))}</div>);
};
export const getSkillsForTooltip = (skills) => {
  return (
    <div>
      {skills.split(',').map((skill, index) => (
        <div key={index}>{skill}</div>
      ))}
    </div>
  );
};


export const getTotalHrs = (timesheetData) => {
  let totalHrs = 0;
  for (const data of timesheetData) {
    totalHrs += parseFloat(data.totalHrs);
  }
  let finalHours = totalHrs.toFixed(2);
  if (finalHours.split('.')[1] === "00") finalHours = parseInt(totalHrs);
  return finalHours;
};

export const dateCalc = (newValue, col) => {
  try {
    let condition = col === "sowEndDate" || col === "msaEndDate" || col === "endDate" ? true : false;
    let value = newValue.toISOString();
    let date = new Date(value);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let time = condition ? "T23:59:59" : "T00:00:00";
    month = month <= 9 ? '0' + month : month;
    day = day <= 9 ? '0' + day : day;
    return year + '-' + month + '-' + day + time;
  }
  catch {
    return newValue;
  }
}

export const startWeek = (newValue) => {
  // Sunday = 0, Saturday = 6
  const correction = {
    0: 1, 1: 0, 2: -1, 3: -2, 4: -3, 5: -4, 6: -5
  }
  let value = newValue.toISOString();
  let date = new Date(value);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let curDate = date.getDate();
  let day = date.getDay();
  month = month <= 9 ? '0' + month : month;
  curDate = curDate + correction[day];
  curDate = curDate <= 9 ? '0' + curDate : curDate;
  return year + '-' + month + '-' + curDate + "T00:00:00";
}