import { HttpMethod } from "../../common/constants/http-requests";
import { httpRequest } from "../../common/utils/http-request";
import { INVOICE_TRACKER_API } from "../api";

export const getAllClientsInvoice = (clientId,projectId,startDate,endDate) => 
httpRequest({
  url: `${INVOICE_TRACKER_API}/all-clients-invoice?clientId=${clientId}&projectId=${projectId}&startDate=${startDate}&endDate=${endDate}`,
  method: HttpMethod.GET,
})

export const createInvoice = (payload) =>
httpRequest({
  url: `${INVOICE_TRACKER_API}/create-invoice`,
  method: HttpMethod.POST,
  data: payload
})

export const getInvoiceByInvoiceId = (invoiceId) =>
httpRequest({
  url: `${INVOICE_TRACKER_API}/Invoice/${invoiceId}`,
  method: HttpMethod.GET,
})

export const updateInvoice = ({invoiceId, payload}) =>
  httpRequest({
    url: `${INVOICE_TRACKER_API}/Invoice/${invoiceId}`,
    method: HttpMethod.PUT,
    data: payload
  })

export const deleteInvoice = (invoiceId) => 
httpRequest({
  url: `${INVOICE_TRACKER_API}/Invoice/${invoiceId}`,
  method: HttpMethod.DELETE,
})

export const getInvoicedMonths = (projectId) =>
httpRequest({
  url: `${INVOICE_TRACKER_API}/Invoice/${projectId}/Project`,
  method: HttpMethod.GET,
})