import {toast} from "react-toastify";
import {call, put, takeLatest} from "redux-saga/effects";
import {updateAttendanceRequest, getParticipantData} from "../http/requests/session";
import {SessionType, setAttendanceData, setVtrackLoader} from "../redux/actions";
import {toastOptions} from "../common/utils/toasterOptions";

function* workerUpdateAttendanceRequestSaga({payload}) {    
    try {
        yield put(setVtrackLoader(true));
        yield call(updateAttendanceRequest, payload.data.updateData);
        toast.success("Attendance Updated", toastOptions);
        // const updatedAttendanceList = yield call(getParticipantData, payload.data.sessionId);
        // yield put(setAttendanceData(updatedAttendanceList));
        yield put(setVtrackLoader(false));
    } catch (err) {
        toast.error(err.data.errorMessage, toastOptions);
        yield put(setVtrackLoader(false));
        toast.error(err.data.errorMessage, toastOptions);
    }
}

export function* updateAttendanceRequestSaga() {
    yield takeLatest(
        SessionType.UPDATE_ATTENDANCE_REQUEST,
        workerUpdateAttendanceRequestSaga
    );
}