import { call, put, takeLatest } from "redux-saga/effects";
import {
  InvoiceTrackerType,
  getInvoiceDetails,
  getInvoiceTrackerData,
  setVtrackLoader
} from "../redux/actions";
import { updateInvoice } from "../http/requests/invoice-tracker";
import { toast } from "react-toastify";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerUpdateInvoiceSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    yield call(updateInvoice,{
      invoiceId: payload.invoiceId,
      payload: payload.payload
    });
    toast.success("Data updated Successfully", toastOptions);

    yield put(getInvoiceDetails(payload));
    yield put(getInvoiceTrackerData());
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions);
    yield put(setVtrackLoader(false));
  }
};

export function* updateInvoiceSaga() {
  yield takeLatest(
    InvoiceTrackerType.UPDATE_INVOICE,
    workerUpdateInvoiceSaga
  );
};
