import { useMsal } from "@azure/msal-react";
import { loginRequest, logoutRequest } from "./authConfig";
import { useCallback ,useEffect} from "react";
import { json, useNavigate } from "react-router-dom";
import { removeLocalStorageItem, setLocalStorageItem } from "../common/utils/local-storage";
import { ACCESS_TOKEN, ACCOUNT, EXPIRES_ON, REFRESH_TOKEN,ID_TOKEN,TIME_OF_AUTO_LOGOUT } from "../common/constants/local-storage-keys";
import { dashboardURL, indexURL} from "../routes/routes";
import Cookies from 'universal-cookie';
import { useSelector } from "react-redux";
import { setSessionFeedbackUrl } from "../redux/actions";


export const useAzureADAuth = () => {
  const { instance } = useMsal();
  const {sessionFeedbackURL}  = useSelector(({ MODULES }) => MODULES);

  const navigate = useNavigate();
  const cookies = new Cookies();

  // useEffect(() => {
  //     const userInformation = sessionStorage.getItem('msal.token.keys.' + process.env.REACT_APP_CLIENT_ID);
  //     if (userInformation) {
  //       const userInfoObject = JSON.parse(userInformation);
  //       const refreshToken = userInfoObject.refreshToken ? userInfoObject.refreshToken[0] : null;
  //       const idToken = userInfoObject.idToken ? userInfoObject.idToken[0] : null;
  //       if (refreshToken) {
  //         setLocalStorageItem(REFRESH_TOKEN, refreshToken);
  //       } else {
  //         console.error('Refresh token not found in user information:', userInfoObject);
  //       }

  //       if(idToken){
  //         setLocalStorageItem(ID_TOKEN, idToken);
  //       }
  //       else{
  //         console.error('Id token not found in user information:', userInfoObject);
  //       }
  //     } else {
  //       console.error('User information not found in sessionStorage');
  //     }

    
  //}, []);


  const loginAzureADPopup = useCallback(() => {
    instance
      .loginPopup(loginRequest)
      .then((e) => {
        setLocalStorageItem(ACCESS_TOKEN, e.accessToken);
        // handleSessionStorageChange()
        sessionStorage.setItem("userInformation", JSON.stringify(e));
        setLocalStorageItem("userInformation", JSON.stringify(e));
        
        if(sessionFeedbackURL){
          navigate(sessionFeedbackURL);
          setSessionFeedbackUrl(null)
        }else{
        navigate(dashboardURL);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [instance]);


  const logoutAzureAD = useCallback(() => {
    instance
    .logoutPopup(logoutRequest)
    .then(() => {
      navigate(indexURL);
      localStorage.clear();
      })
      .catch((e) => {
        console.log(e);
      });
  }, [instance]);

  return {
    loginAzureADPopup,
    logoutAzureAD,
  };
};
