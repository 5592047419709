import { call, put, select, takeLatest } from "redux-saga/effects";
import { clonePreviousWeekTimesheet,getMyTimeSheetDetails } from "../http/requests/timesheet";
import { TimeSheetType, setVtrackLoader, setTimeSheetData } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workerClonePreviousWeekTimesheetSaga({ payload }) {
    try {
        yield put(setVtrackLoader(true));
        yield call(clonePreviousWeekTimesheet,{
            selectedPeriodWeek: payload.selectedPeriodWeek,
            previousPeriodWeek: payload.previousPeriodWeek
        })
        const timeSheetDetails = yield call(
            getMyTimeSheetDetails,
            payload.selectedPeriodWeek,
        );
        yield put(setTimeSheetData(timeSheetDetails));
        toast.success("Timesheet Cloned Successfully", toastOptions)
        yield put(setVtrackLoader(false));
      } catch (err) {
        toast.error(err.data.errorMessage, toastOptions)
        yield put(setVtrackLoader(false));
      }
};

export function* clonePreviousWeekTimesheetSaga() {
  yield takeLatest(
    TimeSheetType.CLONE_PREVIOUS_WEEK_TIMESHEET,
    workerClonePreviousWeekTimesheetSaga
  );
};
