import { call, put, takeLatest } from "redux-saga/effects";
import { postProjectManagementDetails } from "../http/requests/project-management";
import {
  setVtrackLoader,
} from "../redux/actions";
import { getProjectManagementData, ProjectManagementType } from "../redux/actions/project-management";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workerSaveProjectManagementSaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    const projectManagementResponse = yield call(postProjectManagementDetails, payload.data);
    let page=payload.data.pageNo;page+=1;
    let rows=payload.data.rows;
    if(projectManagementResponse?.id.length === 0){
      toast.success("All Data is Saved Successfully!", toastOptions);
    }else{
      toast.info(`${projectManagementResponse?.id} are not added because it has allocation greater than 100.`, toastOptions)
    }
    yield put(
        getProjectManagementData({
        projectId: payload.data.projectId,
        pageNo: page,
        pageSize: rows,
        sortDir: "ASC",
        sortBy: "projectName",
        searchData: "",
        billStatus:"",
        expiredAllocations: false
      })
    );
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
}

export function* saveProjectManagementSaga() {
  yield takeLatest(
    ProjectManagementType.SAVE_PROJECT_MANAGEMENT_DATA,
    workerSaveProjectManagementSaga
  );
}
