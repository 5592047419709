import { call, put, takeLatest } from "redux-saga/effects";
import {addCustomWfo, getWFODays} from "../http/requests/setupWFODays";
import {
    setVtrackLoader,
  } from "../redux/actions";

  import { SettingDataType } from "../redux/actions";
  import{getCustomWfo, setCustomWfo} from "../redux/actions/settings";
  import { toast } from 'react-toastify';
import { toastOptions } from "../common/utils/toasterOptions";

  function* workeraddCustomWfoDaysSaga({payload}) {
    try {
      yield put(setVtrackLoader(true));
      const wfoList = yield call(addCustomWfo,payload)
        if (wfoList.id === -2) {
        // If the response contains id -2, it means there was an error
        toast.error(wfoList.message); // Display an error toast with the message
        yield put(getCustomWfo(payload));
        yield put(getWFODays(payload.year));
        yield put(setCustomWfo(wfoList));
      }
      else if(wfoList.id>=1){
        toast.success('Custom WFO Date added successfully', toastOptions);
        yield put(getCustomWfo(payload));
        yield put(getWFODays(payload.year));
        yield put(setCustomWfo(wfoList));
      }
      else if(wfoList.id===-1){
        toast.error(wfoList.message);
        yield put(getCustomWfo(payload));
        yield put(getWFODays(payload.year));
        yield put(setCustomWfo(wfoList));
      } 
      // else {
      //   // Update the state with the response data
      //   toast.success('Custom Date added', toastOptions); // Display a success toast for adding the custom date
      // }
      yield put(setVtrackLoader(false));
    }
    //   yield put(getCustomWfo(payload));
    //   yield put(getWFODays(payload.year))
    //   yield put(setCustomWfo(wfoList));
    //   yield put(setVtrackLoader(false));
    // }
     catch (err) {
      console.log(err);
      yield put(setVtrackLoader(false));
    }
  };
  
  export function* addCustomWfoDaysSaga() {
    yield takeLatest(
      SettingDataType.ADD_CUSTOM_WFO,
      workeraddCustomWfoDaysSaga
    );
  };
  
