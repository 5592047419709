import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, Slide, TextField } from "@mui/material"
import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import { forwardRef, useState } from "react";
import { Modules } from "../../common/constants/sidebar";
import { getInvoiceDetails, getListItems, updateInvoice } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { initialData } from "../../mock-data/TableData";
import { dateCalc, tableColumnsData } from "../../common/utils/datatable";
import "./formView.css"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CircularLoader from "../Loaders/CircularLoader";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FormView = ({
  formViewDetails, 
  setFormViewDetails, 
  selectedInvoiceId, 
  headingName,
  isEdit}) =>{

  const { invoiceDetails, listItems } = useSelector(({ MODULES }) => MODULES);
  const { vTrackLoader } = useSelector(({ APP_STATE }) => APP_STATE);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [newRowAdded, setNewRowAdded] = useState([]);
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState('');
  const [selectedbillCurrency, setSelectedBillCurrency] = useState('');
  const [selectedRevenueCurrency,setSelectedRevenueCurrency] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  
  const dispatch = useDispatch();
  const handleClose = () => {
    setFormViewDetails(false);
    setRows([]);
    setColumns([]);
    setNewRowAdded([]);
  };

  useEffect(() => {
    if(formViewDetails && selectedInvoiceId !== '' && headingName === Modules.INVOICE_TRACKER){
      dispatch(getInvoiceDetails({
        invoiceId: selectedInvoiceId
      }));
      dispatch(getListItems({
        headingName : headingName
      }));
    }
  }, [formViewDetails, selectedInvoiceId, headingName])

  useEffect(() => {
    if(invoiceDetails){
      setColumns(tableColumnsData["InvoiceTrackerEdit"])
      setRows(invoiceDetails);
      setNewRowAdded(invoiceDetails);
      setSelectedInvoiceStatus(invoiceDetails['invoiceStatus']);
      setIsDisabled(invoiceDetails?.invoiceStatus === 'Completed' ? true : false);
      setSelectedBillCurrency(invoiceDetails['billCurrency']);
      setSelectedRevenueCurrency(invoiceDetails['revenueCurrency']);
    }
  }, [invoiceDetails]);

  
  const updateRowValue = (event, col,row) => {
    setNewRowAdded(prevState => {
      const updatedRowAdded = { ...prevState };
      if (event.target.value === null) {
        updatedRowAdded[col.id] = null;
      } else {
        updatedRowAdded[col.id] = event.target.value;
      }
      return updatedRowAdded;
    });
  }

  const handleSave = () => {
    dispatch(updateInvoice({
      invoiceId: selectedInvoiceId,
      payload: {
        ...newRowAdded
      }
    }));
    setNewRowAdded([]);
  }

  const displayMenuItem = (id, col) => {
    if(headingName === Modules.INVOICE_TRACKER && id === "invoiceStatus"){
      return listItems && listItems[id] && listItems[id].map((option) => (
          <MenuItem
            key={option.id}
            value={option.longCodeValue}
            onClick={() =>{
                setSelectedInvoiceStatus(option.longCodeValue);
                setNewRowAdded({
                  ...newRowAdded,
                  [id]: option.longCodeValue
                });
              }
            }
          >
            {option.longCodeValue}
          </MenuItem>
        ));
    }
    if (headingName === Modules.INVOICE_TRACKER && (id  === 'billCurrency'|| id === 'revenueCurrency')) {
      return listItems && listItems['billCurrency'] && listItems['billCurrency'].map((option) => (
        <MenuItem
          key={option.id}
          value={option.longCodeValue}
          onClick={() =>{
            id ==='billCurrency'? setSelectedBillCurrency(option.longCodeValue):setSelectedRevenueCurrency(option.longCodeValue)
            setNewRowAdded({
              ...newRowAdded, 
              [id]: option.longCodeValue
            });
          }
          }
        >
          {option.longCodeValue}
        </MenuItem>
      ))
    }
  }

  const createInvoiceEditForm = (row, col, index) => {
    switch(col.type){
      case 'textfield':
        return(
          <TextField
            id="outlined-required"
            inputProps={{ maxLength: 100, min:(col.min == 0 ? col.min : null), max:(col.max ? col.max : null )}}
            type={col.fieldType}
            label={col.label}
            value={newRowAdded[col.id]?newRowAdded[col.id]:''}
            disabled={col.disabled || isDisabled}
            onChange={(e) => updateRowValue(e, col)}
          />
        )
      case 'select':
        return(
          <FormControl sx={{maxLength: 100, m: "0.5rem 0.7rem 0 0.7rem"}}>
            <InputLabel className="select-label" id={`label-for-${col.id}`}>{col.label}</InputLabel>
            <Select
              id="outlined-select-currency"
              labelId={`label-for-${col.id}`}
              label={col.label}
              value={col.id==="invoiceStatus"?selectedInvoiceStatus:(col.id==="billCurrency"?selectedbillCurrency:selectedRevenueCurrency)}
              required={col.isRequired}
              className={"select-input"}
              disabled={col.disabled || isDisabled}
            >
              {displayMenuItem(col.id, col)}
            </Select>
          </FormControl>
        )  
      case 'multiline-textfield':
        return(
           <TextField
              id="outlined-multiline-static"
              label={col.label}
              multiline
              rows={4}
              disabled={isDisabled}
              value={newRowAdded[col.id]?newRowAdded[col.id]:''}
              onChange={(e) => updateRowValue(e, col)}
            />
        )
      case 'date':
        return(
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={col.label}
                onChange={(newValue) => {
                  let formatedDate = dateCalc(newValue, col.id);
                  setNewRowAdded({ ...newRowAdded, [col.id]: formatedDate });
                }}
                value={newRowAdded[col.id]}
                placeholder="Date"
                required={col.isRequired}
                disabled = {(col.disabled ? true : false) || isDisabled}
                renderInput={(params) => <TextField {...params} error={false} />}
              />
            </LocalizationProvider>
        )
    }
  }
  return(
    <>
    {
      <Dialog
        open={formViewDetails}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          style: {
            height: '65vh', // Adjust the value as needed
          },
        }}
      >
        <DialogTitle style={{color:"#1773bc", fontSize: "3rem"}}>Invoice Details</DialogTitle>
        { vTrackLoader && <CircularLoader />}
        {!vTrackLoader && columns.length !== 0 && rows.length !== 0 && 
        <DialogContent>
          <Box
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
      sx={{
        '& .MuiTextField-root': { m: 1}
      }}
      noValidate
      autoComplete="off"
          >
            {
              columns.map((col, index) => {
                return (
                  rows[col.id] ? createInvoiceEditForm(rows[col.id], col, index) : createInvoiceEditForm(initialData(Modules.INVOICE_TRACKER, null)[col.id], col, index)
                  )
              }) 
            }
          </Box>
        </DialogContent>}
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Close</Button>
          <Button variant="contained" disabled={isDisabled} onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    }
    </>
  )
}