import { call, put, takeLatest } from "redux-saga/effects";
import { downloadMultipleFileView } from "../http/requests/multiple-file-view";
import { ProjectAdminType, setVtrackLoader } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";
import { MultipleFileViewType } from "../redux/actions/multiple-file-view";

function* workerDownloadMultipleFileViewSaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    const response = yield call(downloadMultipleFileView, payload.ID);
    yield downloadBase64(response.data, response.filename);
    downloadBase64(response.data, response.filename);
    toast.success("Data Downloaded", toastOptions)
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* downloadMultipleFileViewSaga() {
  yield takeLatest(
    MultipleFileViewType.DOWNLOAD_MULTIPLE_FILE_VIEW,
    workerDownloadMultipleFileViewSaga
  );
};

function* downloadBase64(base64Data, fileName) {
  const linkSource = `data:application/octet-stream;base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}