import { call, put, takeLatest } from "redux-saga/effects";
import { getListItems } from "../http/requests/dropdown";
import {
  DropdownType,
  setListItems,
  setVtrackLoader
} from "../redux/actions";
import { Modules } from "../common/constants/sidebar";

function* workerListItemsSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    let listItems = {};
    if(payload.headingName === Modules.INVOICE_TRACKER){
      const invoiceStatus = yield call(getListItems, 'invoiceStatusType');
      const billCurrency= yield call(getListItems,'billCurrency');
      listItems = {
        'invoiceStatus': invoiceStatus,
        'billCurrency':billCurrency
        
      };
    } else if(payload.headingName === Modules.PROJECT_MANAGEMENT){
      const employeeRole = yield call(getListItems, 'employeeRoleType');
      const billFrequency= yield call(getListItems,'billFrequency')
      const billCurrency= yield call(getListItems,'billCurrency')
      listItems = {
        'employeeRole': employeeRole,
        'billFrequency':billFrequency,
        'billCurrency':billCurrency
      }
    } 
    else if(payload.headingName === Modules.SKILLS) {
        const streamList= yield call(getListItems, 'stream');
        const skillList = yield call(getListItems, 'skillBucket');
        const primarySecSkillList = yield call(getListItems, 'primarySecondarySkills')
        listItems = {
          'Streams' : streamList,
          'Skills': skillList, 
          'PriSecSkills': primarySecSkillList
        }
    }
     else{
      const currency = yield call(getListItems, 'currency');
      const location = yield call(getListItems, 'clientLocation');
      const type = yield call(getListItems, 'projectType');
      const status = yield call(getListItems, 'projectStatus');
      
      listItems = {
        'currency': currency,
        'location': location,
        'type': type,
        'status': status,
      };
    }
    yield put(setListItems(listItems));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* listItemsSaga() {
  yield takeLatest(
    DropdownType.GET_LIST_ITEMS,
    workerListItemsSaga
  );
};
