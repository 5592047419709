import { call, put, takeLatest } from "redux-saga/effects";
import { getAttendanceForExcel } from "../http/requests/view-attendance";
import {
  setVtrackLoader,
  ViewAttendanceType,
  setAttendanceForExcel
} from "../redux/actions";

function* workerGetAttendanceForExcelSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const attendanceForExcel = yield call(getAttendanceForExcel,payload.month,payload.year);
    yield put(setAttendanceForExcel(attendanceForExcel));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* getAttendanceForExcelSaga() {
  yield takeLatest(
    ViewAttendanceType.GET_ATTENDANCE_FOR_EXCEL,
    workerGetAttendanceForExcelSaga
  );
};
