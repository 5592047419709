import { call, put, takeLatest } from "redux-saga/effects";
import { getSessionDataForExcel } from "../http/requests/session";
import {
  setVtrackLoader
} from "../redux/actions";
import { SessionType,setSessionDataForExcel } from "../redux/actions/session";

function* workerParticipantsDetailsBySession({payload}) {
  try {
    yield put(setVtrackLoader(true));
    
    const participantDetailsBySession = yield call(getSessionDataForExcel,payload.sessionId);
    yield put(setSessionDataForExcel(participantDetailsBySession));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* participantsDetailsBySessionSaga() {
  yield takeLatest(
    SessionType.GET_SESSION_FOR_EXCEL,
    workerParticipantsDetailsBySession
  );
};
