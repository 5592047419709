import { call, put, takeLatest } from "redux-saga/effects";
import { getAllocationChangeReportDataByEmployeeForExcel } from "../http/requests/reporting";
import { setVtrackLoader } from "../redux/actions";
import { ReportingType, setAllocationChangeReportByEmployeeDataForExcel, setReportingData,setExportData } from "../redux/actions/reporting";



function* workerGetAllocationChangeReportByEmployeeDataForExcel({ payload }){
  try{
    yield put(setVtrackLoader(true));
    
    let allocationChangeReport = yield call(
        getAllocationChangeReportDataByEmployeeForExcel,
        {
            employeeIds: payload.employeeIds,
            endDate: payload.endDate,
            startDate: payload.startDate
        }
    );

      yield put(setAllocationChangeReportByEmployeeDataForExcel(allocationChangeReport));
      yield put(setVtrackLoader(false));
  } catch(err){
    console.log(err);
    yield put(setVtrackLoader(false));
  }
}

export function* getAllocationChangeReportByEmployeeDataForExcelSaga() {
  yield takeLatest(
    ReportingType.GET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE_DATA_FOR_EXCEL,
    workerGetAllocationChangeReportByEmployeeDataForExcel
  );
};