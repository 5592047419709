import { HttpMethod } from "../../common/constants/http-requests";
import { httpRequest } from "../../common/utils/http-request";
import { REPORTING_API } from "../api";


export const getTimesheetReports = (startDate, endDate, projectId) =>{
  return httpRequest({
    url: `${REPORTING_API}/get-timesheet-reports?startDate=${startDate}&endDate=${endDate}&projectId=${projectId}`,
    method: HttpMethod.GET,
  });
};
export const getMissingTimesheet = (startDate, endDate, projectId) =>
  httpRequest({
    url: `${REPORTING_API}/get-missing-timesheet?startDate=${startDate}&endDate=${endDate}&projectId=${projectId ? projectId : ""}`,
    method: HttpMethod.GET,
  })

export const sendMissingTimesheetReminderManually = (employeeName, employeeId, periodWeek) =>
  httpRequest({
    url: `${REPORTING_API}/missing-timesheet-reminder-manual?employeeName=${employeeName}&employeeId=${employeeId}&periodWeek=${periodWeek}`,
    method: HttpMethod.GET,
  })

export const getAllocationChangeReport = (startDate, endDate, projectId) =>
  httpRequest({
    url: `${REPORTING_API}/get-allocation-change-report?startDate=${startDate}&endDate=${endDate}&projectId=${projectId}`,
    method: HttpMethod.GET,
})

export const getAllocationChangeReportData = (startDate, endDate, projectId)=>
httpRequest({
  url: `${REPORTING_API}/get-allocation-change-report-data?startDate=${startDate}&endDate=${endDate}&projectId=${projectId}`,
  method:HttpMethod.GET,
})

export const getAllocationChangeReportByEmployee = (payload) =>
  httpRequest({
    url: `${REPORTING_API}/get-allocation-change-report-by-employee`,
    method: HttpMethod.POST,
    data:payload
})

export const getAllocationChangeReportDataByEmployeeForExcel= (payload)=>
httpRequest({
  url:`${REPORTING_API}/get-allocation-change-report-by-employee-data-for-excel`,
  method:HttpMethod.POST,
  data:payload
})