import { call, put, takeLatest } from "redux-saga/effects";
import { getAllocationChangeReportByEmployee } from "../http/requests/reporting";
import { setVtrackLoader } from "../redux/actions";
import { ReportingType, setAllocationChangeReportByEmployee, setReportingData,setExportData } from "../redux/actions/reporting";

function* workerGetAllocationChangeReportByEmployee({ payload }){
  try{
    yield put(setVtrackLoader(true));
    const allocationChangeReport = yield call(
        getAllocationChangeReportByEmployee,
        {
            employeeIds: payload.employeeIds,
            endDate: payload.endDate,
            startDate: payload.startDate,
      }
    );
      if(payload.isExport){
        yield put(setExportData(allocationChangeReport));
       }
      else{
       yield put(setAllocationChangeReportByEmployee(allocationChangeReport));
      }
      yield put(setVtrackLoader(false));
  } catch(err){
    console.log(err);
    yield put(setVtrackLoader(false));
  }
}

export function* getAllocationChangeReportByEmployeeSaga() {
  yield takeLatest(
    ReportingType.GET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE,
    workerGetAllocationChangeReportByEmployee
  );
};