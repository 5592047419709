import { HttpMethod } from "../../common/constants/http-requests";
import { httpRequest } from "../../common/utils/http-request";
import { PROJECT_ADMIN_API } from "../api";
import { CLIENT_API } from "../api";

export const getMultipleFileView = (type, Id) => {
  let url;

  if (type === 'sow') {
    url = `${PROJECT_ADMIN_API}/get-sow?projectId=${Id}&type=${type}`;
  }
  else if (type === 'msa') {
    url = `${CLIENT_API}/get-msa?type=${type}&clientId=${Id}`;
  }

  return httpRequest({
    url,
    method: HttpMethod.GET,
  });
};

export const downloadMultipleFileView = (ID) => {
  return httpRequest({
    url: `${PROJECT_ADMIN_API}/${ID}/download-sow`,
    method: HttpMethod.GET,
  });
}
export const deleteMultipleFileView = (type,ID) => {
  let url;
  if (type === 'sow') {
    url = `${PROJECT_ADMIN_API}/${ID}/delete-sow`;
  }
  else if (type === 'msa') {
    url = `${CLIENT_API}/${ID}/delete-msa`;
  }
  return httpRequest({
    url,
    method: HttpMethod.POST,
  });
}
