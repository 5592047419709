import { HttpMethod } from "../../common/constants/http-requests";
import { httpRequest } from "../../common/utils/http-request";
import { HOLIDAY_LIST_API } from "../api";

export const getHolidayList = (year) =>
  httpRequest({
    url: `${HOLIDAY_LIST_API}/${year}/get-holiday-list`,
    method: HttpMethod.GET,
  });

export const postHoliday = (data) =>
  httpRequest({
    url: `${HOLIDAY_LIST_API}/add-holiday`,
    method: HttpMethod.POST,
    data: data,
  });

export const cloneHoliday = (data) =>
  httpRequest({
    url: `${HOLIDAY_LIST_API}/clone-holiday`,
    method: HttpMethod.POST,
    data: data,
  });

export const updateHoliday = (data) =>
  httpRequest({
    url: `${HOLIDAY_LIST_API}/${data.id}/update-holiday`,
    method: HttpMethod.PUT,
    data: data,
  });

export const deleteHoliday = (data) =>
  httpRequest({
    url: `${HOLIDAY_LIST_API}/${data.id}/delete-holiday`,
    method: HttpMethod.DELETE,
  });
  