export const Modules = {
    PROJECT_ALLOCATION: 'Project Allocation',
    TIMESHEET: 'Timesheet',
    PROJECT_MANAGEMENT: 'Project Management',
    CLIENT_ADMIN: 'Client Admin',
    PROJECT_ADMIN: 'Project Admin',
    VENDOR_ADMIN: 'Vendor Admin',
    VENDOR_SOW_ADMIN: 'Vendor SOW Admin',
    REPORTING: 'Reporting',
    SETTINGS: 'Settings',
    INVOICE_TRACKER: 'Invoice Tracker',
    SKILLS: 'Skill Tracker',
    VIEW_ATTENDANCE: 'View Attendance',
    ATTENDANCE_FILE_UPLOAD: 'Attendance File Upload',
    TRAINING_AND_SESSION: 'Training and Session',
    SESSION_FEEDBACK: 'Session Feedback',
};
