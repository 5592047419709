import { call, put, takeLatest } from "redux-saga/effects";
import { registerUserSessionData,getUserDetailsForTrainingAndSession,getAllSessionsData } from "../http/requests/session";
import { SessionType,setVtrackLoader,setUserDetailsForTrainingAndSession,setAllSessionsData } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workerRegisterUserSessionSaga({ payload }) {
    try {
        yield put(setVtrackLoader(true));
        
        yield call(registerUserSessionData, payload.data);
        toast.success("Registration Successfull", toastOptions)
        let sessionId=payload.data.sessionId;
        let userSessionDetail = yield call(getUserDetailsForTrainingAndSession,sessionId)
        yield put(setUserDetailsForTrainingAndSession(userSessionDetail));
        const sessionDetail = yield call(getAllSessionsData,"");
        yield put(setAllSessionsData(sessionDetail));
        yield put(setVtrackLoader(false));
    } catch (err) {
        toast.error(err.data.errorMessage, toastOptions)
        yield put(setVtrackLoader(false));
    }
}

export function* registerUserSessionSaga() {
    yield takeLatest(
        SessionType.REGISTER_USER_SESSION_DATA,
        workerRegisterUserSessionSaga
    );
};