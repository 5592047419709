import { call, put, takeLatest } from "redux-saga/effects";
import {cloneWfoDays} from "../http/requests/setupWFODays";
import { toast } from 'react-toastify';
import { toastOptions } from "../common/utils/toasterOptions";
import {
    setVtrackLoader,
  } from "../redux/actions";

  import { SettingDataType } from "../redux/actions";

  function* workercloneWfoDaysSaga({payload}) {
    try {
      yield put(setVtrackLoader(true));
        const cloneResult = yield call(cloneWfoDays,payload)
        if(cloneResult.id===-1){
          toast.error(cloneResult.message, toastOptions);
        }
        else if(cloneResult.id ===-2){
          toast.error(cloneResult.message, toastOptions)
        }else{
          toast.success(cloneResult.message, toastOptions)
        }
      yield put(setVtrackLoader(false));
    } catch (err) {
      console.log(err);
      yield put(setVtrackLoader(false));
    }
  };
  
  export function* cloneWfoDaysSaga() {
    yield takeLatest(
      SettingDataType.CLONE_WFO_DAYS,
      workercloneWfoDaysSaga
    );
  };
  
