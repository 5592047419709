import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { updateParticipantRequest,getParticipantData } from "../http/requests/session";
import {
  SessionType,
  setParticipantsData,
  setVtrackLoader
} from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerUpdateParticipantRequestSaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    yield call(updateParticipantRequest, payload.data.updateData);    
    toast.success(payload.data.updateData.status, toastOptions)
    const updatedParticipantList = yield call(getParticipantData,payload.data.sessionId);
    yield put(setParticipantsData(updatedParticipantList));
    yield put(setVtrackLoader(false));
    
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
    toast.error(err.data.errorMessage, toastOptions)
  }
}

export function* updateParticipantRequestSaga() {
  yield takeLatest(
    SessionType.UPDATE_PARTICIPANT_REQUEST,
    workerUpdateParticipantRequestSaga
  );
}
