import { HttpMethod } from "../../common/constants/http-requests";
import { httpRequest, httpRequestForFile } from "../../common/utils/http-request";
import { PROJECT_ADMIN_API } from "../api";

export const getProjectAdminDetails = (pageNo, pageSize, sortDir, sortBy, searchData, expiredAllocations) =>
  httpRequest({
    url: `${PROJECT_ADMIN_API}/get-projects?page=${pageNo}&pagesize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchKey=${searchData}&expiredAllocations=${expiredAllocations}`,
    method: HttpMethod.GET,
  });

export const postProjectAdminDetails = (data) =>
  httpRequest({
    url: `${PROJECT_ADMIN_API}/add-project`,
    method: HttpMethod.POST,
    data: data,
  });

export const updateProjectAdminDetails = (data) =>
  httpRequest({
    url: `${PROJECT_ADMIN_API}/${data.projectId}/update-project`,
    method: HttpMethod.PUT,
    data: data,
  });

export const deleteProjectAdminDetails = (projectId) =>
  httpRequest({
    url: `${PROJECT_ADMIN_API}/${projectId}/delete-project`,
    method: HttpMethod.DELETE,
  });

export const postProjectAdminFile = (data) =>
  httpRequestForFile({
    url: `${PROJECT_ADMIN_API}/upload-sow?projectId=${data.id}&projectName=${data.name}`,
    method: HttpMethod.POST,
    data: data.data
  });

export const getProjectById = (projectId) => 
  httpRequest({
    url: `${PROJECT_ADMIN_API}/${projectId}`,
    method: HttpMethod.GET,
  })

  
export const downloadProjectAdminDetails = (projectId) => 
httpRequest({
  url: `${PROJECT_ADMIN_API}/${projectId}/download-sow`,
  method: HttpMethod.GET,
}); 

export const getResourcingData = (projectId) => 
  httpRequest({
    url: `${PROJECT_ADMIN_API}/get-resourcing-detail/${projectId}`,
    method: HttpMethod.GET,
  })

export const updateResourcingData = (data) =>
  httpRequest({
    url: `${PROJECT_ADMIN_API}/update-resourcing-detail`,
    method: HttpMethod.POST,
    data: data,
  });
export const updateBillableLeaves = (data) =>
  httpRequest({
    url: `${PROJECT_ADMIN_API}/update-billable-leaves`,
    method: HttpMethod.POST,
    data: data,
  });
export const getBillableLeaves = (projectId) =>
  httpRequest({
    url: `${PROJECT_ADMIN_API}/get-billable-leaves/${projectId}`,
    method: HttpMethod.GET,
  });