import { call, put, takeLatest } from "redux-saga/effects";
import { getHolidayList } from "../http/requests/holiday-list";
import { getListItems } from "../http/requests/dropdown";
import {
  DropdownType,
  SettingDataType,
  setAllProjectsData,
  setHolidayList,
  setVtrackLoader,
  setListItems
} from "../redux/actions";

function* workerHolidayListSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const listItems = yield call(getListItems,'holiday')
    yield put(setListItems(listItems));
    const holidayList = yield call(getHolidayList,
      payload.year);
    yield put(setHolidayList(holidayList));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* holidayListSaga() {
  yield takeLatest(
    SettingDataType.GET_HOLIDAY_LIST,
    workerHolidayListSaga
  );
};
