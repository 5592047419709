import { call, put, takeLatest } from "redux-saga/effects";
import { downloadProjectAdminDetails } from "../http/requests/project-admin";
import { ProjectAdminType, setVtrackLoader } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workerDownloadProjectAdminSaga({ payload }) {
  try { 
    yield put(setVtrackLoader(true));
    const response = yield call(downloadProjectAdminDetails, payload.projectId);
    downloadBase64(response.data, response.filename);
    toast.success("Data Downloaded", toastOptions)
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* downloadProjectAdminSaga() {
  yield takeLatest(
    ProjectAdminType.DOWNLOAD_PROJECT_ADMIN_DATA,
    workerDownloadProjectAdminSaga
  );
};

function downloadBase64(base64Data, fileName){
  const linkSource = `data:application/octet-stream;base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

