import { call, put, takeLatest } from "redux-saga/effects";
import { getAllocationChangeReportData } from "../http/requests/reporting";
import { setVtrackLoader } from "../redux/actions";
import { ReportingType, setAllocationChangeReportDataForExcel, setReportingData,setExportData } from "../redux/actions/reporting";



function* workerGetAllocationChangeReportData({ payload }){
  try{
    yield put(setVtrackLoader(true));
    let allocationChangeReport = yield call(
      getAllocationChangeReportData,
      payload.startDate,
      payload.endDate,
      payload.projectId);
      allocationChangeReport = {
        data: allocationChangeReport,
        totalCount: allocationChangeReport.length
      }

      yield put(setAllocationChangeReportDataForExcel(allocationChangeReport));
      yield put(setVtrackLoader(false));
  } catch(err){
    console.log(err);
    yield put(setVtrackLoader(false));
  }
}

export function* getAllocationChangeReportDataSaga() {
  yield takeLatest(
    ReportingType.GET_ALLOCATION_CHANGE_REPORT_DATA,
    workerGetAllocationChangeReportData
  );
};