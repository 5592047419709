import { call, put, takeLatest } from "redux-saga/effects";
import {
  ProjectAdminType,
  setSelectedProjectData,
  setVtrackLoader
} from "../redux/actions";
import { getProjectById } from "../http/requests/project-admin";
import { toast } from "react-toastify";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerGetProjectByIdSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const projectData = yield call(getProjectById, payload.projectId);
    yield put(setSelectedProjectData(projectData));
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* getProjectByIdSaga() {
  yield takeLatest(
    ProjectAdminType.GET_PROJECT_DATA,
    workerGetProjectByIdSaga
  );
};
