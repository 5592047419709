import { call, put, takeLatest } from "redux-saga/effects";
import {
  SettingDataType,
  setHolidayList,
  setVtrackLoader
} from "../redux/actions";
import { deleteHoliday,getHolidayList } from "../http/requests/holiday-list";
import { toast } from "react-toastify";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerDeleteHolidaySaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    yield call(deleteHoliday,payload);
    toast.success("Data deleted Successfully", toastOptions);
    let holidayList = yield call(getHolidayList, payload.year);
    yield put(setHolidayList(holidayList));
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions);
    yield put(setVtrackLoader(false));
  }
};

export function* deleteHolidaySaga() {
  yield takeLatest(
    SettingDataType.DELETE_HOLIDAY,
    workerDeleteHolidaySaga
  );
};
