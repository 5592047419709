import { HttpMethod } from "../../common/constants/http-requests";
import { httpRequest } from "../../common/utils/http-request";
import { SESSION_API} from "../api";

export const addSession = (data) =>
    httpRequest({
        url: `${SESSION_API}/add-session`,
        method: HttpMethod.POST,
        data: data,
    });

export const updateSession = (data) =>
httpRequest({
    url: `${SESSION_API}/${data.sessionId}/update-session`,
    method: HttpMethod.PUT,
    data: data.updateData,
});


export const deleteSessionData = (sessionId) =>
httpRequest({
    url: `${SESSION_API}/${sessionId}/delete-session`,
    method: HttpMethod.DELETE,
});

export const getParticipantData = (sessionId) =>
httpRequest({
    url: `${SESSION_API}/${sessionId}/get-participant-data`,
    method: HttpMethod.GET,
});
export const updateParticipantRequest = (payload) =>
httpRequest({
    url: `${SESSION_API}/approve-participant`,
    method: HttpMethod.PUT,
    data: payload,
});
export const getAllSessionsData = (searchData) =>
    httpRequest({
        url: `${SESSION_API}/get-all-sessions?searchData=${searchData}`,
        method: HttpMethod.GET,
    });
export const registerUserSessionData = (data) =>
    httpRequest({
        url: `${SESSION_API}/register-user`,
        method: HttpMethod.POST,
        data: data,
    });
export const submitSessionFeedbackDetails = (data) =>
    httpRequest({
        url: `${SESSION_API}/submit-session-feedback?sessionId=${data.SessionId}`,
        method: HttpMethod.POST,
        data: data.Data,
    })
export const updateUserRegistration = (data) =>
    httpRequest({
        url: `${SESSION_API}/update-user-registration`,
        method: HttpMethod.PUT,
        data: data,
    });
export const getUserDetailsForTrainingAndSession = (sessionId) =>
    httpRequest({
        url: `${SESSION_API}/get-user-details-training-and-session?sessionId=${sessionId}`,
        method: HttpMethod.GET,
    });
export const getSessionUserHistory = () =>
    httpRequest({
        url: `${SESSION_API}/get-session-history`,
        method: HttpMethod.GET,
    })
export const getSessionFeedbackQuestionsList = () =>
    httpRequest({
        url: `${SESSION_API}/get-feedback-questions`,
        method: HttpMethod.GET,
    })
export const getSessionFeedbackHeader = (sessionId) => 
    httpRequest({
        url: `${SESSION_API}/${sessionId}/get-session-feedback-header`,
        method: HttpMethod.GET
    })
export const updateAttendanceRequest = (payload) =>
httpRequest({
    url: `${SESSION_API}/update-attendance`,
    method: HttpMethod.PUT,
    data: payload,
});
export const getSessionDataForExcel = (sessionId) =>
httpRequest({
    url: `${SESSION_API}/${sessionId}/get-session-data-for-excel`,
    method: HttpMethod.GET
})