import { call, put, takeLatest } from "redux-saga/effects";
import {
  InvoiceTrackerType,
  setInvoiceTrackerData,
  setVtrackLoader
} from "../redux/actions";
import { createInvoice, getAllClientsInvoice } from "../http/requests/invoice-tracker";
import { toast } from "react-toastify";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerSaveInvoiceTrackerDataSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const createResponse = yield call(createInvoice, payload);
    toast.success("Successfully Added", toastOptions);
    // const invoiceTrackerData = yield call(getAllClientsInvoice);
    // yield put(setInvoiceTrackerData(invoiceTrackerData));
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* saveInvoiceTrackerDataSaga() {
  yield takeLatest(
    InvoiceTrackerType.SAVE_INVOICE_TRACKER_DATA,
    workerSaveInvoiceTrackerDataSaga
  );
};
