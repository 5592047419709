import { call, put, takeLatest } from "redux-saga/effects";
import { getLeaveDetails } from "../http/requests/view-attendance";
import {
  DropdownType,
  setLeaveDetails,
  setVtrackLoader
} from "../redux/actions";
import { ViewAttendanceType } from "../redux/actions/view-attendance";

function* workerAllLeaveDetailsSaga(payload) {
  try {
    yield put(setVtrackLoader(true));
    const leaveDetails = yield call(getLeaveDetails,payload.payload.leaveId);
    yield put(setLeaveDetails(leaveDetails));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* allLeaveDetailsSaga() {
  yield takeLatest(
    ViewAttendanceType.GET_LEAVE_DETAILS,
    workerAllLeaveDetailsSaga
  );
};
