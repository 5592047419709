import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { SelectedModule } from "../../components/SelectedModule/selected-module";
import Sidebar from "../../components/Sidebar/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TopBar } from "../../components/TopBar/TopBar";
import TabsComponent from "../../components/Tabs/tabs";
import { ModuleList } from "../../mock-data/Sidebar";
import "./VTrack.css";
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from "../../common/utils/local-storage";
import { ACCESS_TOKEN } from "../../common/constants/local-storage-keys";
import { indexURL, VTrackURL } from "../../routes/routes";
import { Modules } from "../../common/constants/sidebar";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../common/constants/extra-constants";
import SessionFeedback from "../../components/Session/SessionFeedback";
import { setSessionFeedbackUrl } from "../../redux/actions";

export const VTrack = () => {
  const [headingName, setHeadingName] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const cookies = new Cookies();
  const [tabIndex, setTabIndex] = useState(0);
  const [periodWeek, setPeriodWeek] = useState(null);
  const [sessionFeedbackId,setSessionFeedbackId]= useState(null);

  const { userData } = useSelector(({ USER }) => USER);
  const search = useLocation().search;
  const dispatch = useDispatch();
  useEffect(() => {
    const tabIndexQuery = new URLSearchParams(search).get('tabIndex');
    const id = new URLSearchParams(window.location.search).get('id');
    const periodWeekQuery = new URLSearchParams(search).get('periodWeek');
    if(id!==null){
      setSessionFeedbackId(id);
    }
    if(tabIndexQuery !== null && periodWeekQuery !== null){
      setTabIndex(parseInt(tabIndexQuery));
      setPeriodWeek({
        startDate:  moment(moment(periodWeekQuery.split("-")[0]).format(DATE_TIME_FORMAT)).startOf('isoweek'),
        endDate: moment(moment(periodWeekQuery.split("-")[1]).format(DATE_TIME_FORMAT)).endOf('isoweek')
      });
    }
    else{
      setTabIndex(0);
      setPeriodWeek(null);
    }
  }, []);

  const changePage = (headingName) => {
    setHeadingName(headingName);
    if(headingName === Modules.SESSION_FEEDBACK){
      setSessionFeedbackId(null);
    }
    if(headingName === Modules.PROJECT_ALLOCATION){
      setValue(1);
    }else{
      setValue(0);
    }
  };

  useEffect(()=>{
    const id = new URLSearchParams(window.location.search).get('id');
    if(id!==null){
      setSessionFeedbackId(id);
    }
    let routeName = window.location.pathname.split("/").at(-1);
    if(userData){
      let head = null;
      Object.keys(userData.data.tabs).forEach((ele) => {
        if(ele.toLowerCase() === routeName.toLowerCase()){
          head = ele;
        }
      })
      if(head !== null){
        setHeadingName(ModuleList.find(e=>e.key===head).name);
      }else{
        setHeadingName(ModuleList.find(e=>e.key===Object.keys(userData.data.tabs)[1]).name);
      }
    }
  },[userData]);

  useEffect(() => {
    
    if(!getLocalStorageItem(ACCESS_TOKEN)){
      if(window.location.pathname === '/vTrack/SessionFeedback'){
        const urlParams = new URLSearchParams(window.location.search);
        const fullUrl = window.location.pathname + "?id=" + urlParams.get('id');
        dispatch(setSessionFeedbackUrl(fullUrl));
      }
      else if(window.location.pathname === '/vTrack/TrainingandSession')
        dispatch(setSessionFeedbackUrl(window.location.pathname));
      
      navigate(indexURL);
    }
  }, []);

  useEffect(() => {
    document.title = "vTrack";
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      <div className={`mainDiv ${sidebarVisible ? "sidebar-open" : ""}`}>
      {/* <TopBar headingName={headingName} toggleSidebar={toggleSidebar} /> */}
      {sidebarVisible && <div className="overlay" onClick={toggleSidebar}></div>}
        <div className={`sidebarContainer ${sidebarVisible ? "visible" : ""}`}>
          <Sidebar changePage={changePage} />
        </div>
        <button className="toggleButton" onClick={toggleSidebar}>
            ☰
          </button>
        <div className="rightContainer">
        
          <div className="selectedModule">
            <SelectedModule headingName={headingName} value={value} setValue={setValue} tabIndex={tabIndex} periodWeek={periodWeek} setTabIndex={setTabIndex} setPeriodWeek={setPeriodWeek} feedbackId={sessionFeedbackId} />
          </div>
        </div>

      </div>
    </>
  );
};
