import { call, put, takeLatest } from "redux-saga/effects";
import { getParticipantData } from "../http/requests/session";
import { setVtrackLoader } from "../redux/actions";
import {
    SessionType,setParticipantsData
} from "../redux/actions/session";

function* workerParticipantsDataSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const participantsData = yield call(getParticipantData,
      payload);
      
    yield put(setParticipantsData(participantsData));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* participantsDataSaga() {
  yield takeLatest(
    SessionType.GET_PARTICIPANTS_DATA,
    workerParticipantsDataSaga
  );
};
