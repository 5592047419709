import { call, put, takeLatest } from "redux-saga/effects";
import {
  InvoiceTrackerType,
  setInvoiceDetails,
  setVtrackLoader
} from "../redux/actions";
import { getInvoiceByInvoiceId } from "../http/requests/invoice-tracker";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workerGetInvoiceSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const invoiceTrackerData = yield call(getInvoiceByInvoiceId,payload.invoiceId);
    yield put(setInvoiceDetails(invoiceTrackerData));
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions);
    yield put(setVtrackLoader(false));
  }
};

export function* getInvoiceSaga() {
  yield takeLatest(
    InvoiceTrackerType.GET_INVOICE_DETAILS,
    workerGetInvoiceSaga
  );
};
