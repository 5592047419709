import { call, put, takeLatest } from "redux-saga/effects";
import { getMultipleFileView } from "../http/requests/multiple-file-view";
import { setVtrackLoader } from "../redux/actions";
import { MultipleFileViewType, setMultipleFileViewData} from "../redux/actions/multiple-file-view";

function* workerGetMultipleFileView({ payload }){
  try{
    yield put(setVtrackLoader(true));
    const multipleFileView = yield call(
      getMultipleFileView,
      payload.type,
      payload.Id);
      yield put(setMultipleFileViewData(multipleFileView));
      yield put(setVtrackLoader(false));
  } catch(err){
    console.log(err);
    yield put(setVtrackLoader(false));
  }
}

export function* getMultipleFileViewSaga() {
  yield takeLatest(
    MultipleFileViewType.GET_MULTIPLE_FILE_VIEW,
    workerGetMultipleFileView
  );
};