import { call, put, takeLatest } from "redux-saga/effects";
import { cloneHoliday } from "../http/requests/holiday-list";
import { SettingDataType,setVtrackLoader } from "../redux/actions";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";

function* workerCloneHolidaySaga({ payload }) {
  try {
    yield put(setVtrackLoader(true));
    yield call(cloneHoliday, payload);
    toast.success("Data Cloned", toastOptions)
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* cloneHolidaySaga() {
  yield takeLatest(
    SettingDataType.CLONE_HOLIDAY,
    workerCloneHolidaySaga
  );
};
