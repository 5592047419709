import React ,{ useEffect, useState }  from "react";
import { Button } from "@mui/material";
import FileSaver, * as fileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { Modules } from '../common/constants/sidebar';
 import FileDownloadIcon from '@mui/icons-material/FileDownload';
 import { excelIcon, excelAllIcon } from '../common/icons';
 import { toast } from 'react-toastify';
 import { toastOptions } from '../common/utils/toasterOptions';
 import moment from 'moment';
 import { getProjectManagementData, setExportProjectManagementData,resetExportProjectManagementData, setAllProjectManagementData } from '../redux/actions/project-management';
import { getAllocationChangeReportData, setAllocationChangeReportDataForExcel,getAllocationChangeReportByEmployeeDataForExcel,setAllocationChangeReportByEmployeeDataForExcel } from '../redux/actions/reporting';
import { getAllProjectManagementData } from '../redux/actions/project-management';
import {getUsersProjectAllocation, setProjectAllocationData} from '../redux/actions/project-allocation';
import {getSessionDataForExcel,setSessionDataForExcel,getSessionFeedbackQuestionsList,setSessionFeedbackquestionsList} from  '../redux/actions/session'
import { file } from "@babel/types";
import { getFullName, tableColumnsData } from '../common/utils/datatable';
import { getAttendanceForExcel, setAttendanceForExcel, setVtrackLoader } from "../redux/actions";
import {getProjectAdminData} from "../redux/actions";
import dayjs from 'dayjs';


 export default function ExportExcel({props,excelData,headingName,tabName,fileName, all=false}){
      const {
      allProjectManagementData,
      exportProjectManagementData,
      mappedProjectManagementData,
      exportData,
      selectedProjectId,
      sessionsDataForExcel,
      projectAdminData,
      sessionFeedbackQuestions
    } = useSelector(({ MODULES }) => MODULES);
    const { allUserDetails } = useSelector(({ USER }) => USER);
    const [flag, setFlag]=useState(false);
    const[exporting, setExporting] = useState(false);
    const dispatch = useDispatch();
  
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
  const fileExtension = '.xlsx';
  let projectTab=[];
      if (mappedProjectManagementData){
        mappedProjectManagementData.forEach((client) => {
          client.projects.forEach((project)=>{
            projectTab.push({
              projectName : project.projectName,
              projectId: project.projectId
            })
          })
        });
    }

  const getProjectName = (projectId) => {
    let projectName = "";
    projectTab &&
    projectTab.length &&
    projectTab.forEach((project) => {
        if (project.projectId === projectId) {
          projectName = project.projectName;
        }
      });
      return projectName
};

    const getEmployeeName = (id) => {
        let employeeName = "";
        allUserDetails &&
          allUserDetails.data.length &&
          allUserDetails.data.forEach((user) => {
            if (user.id === id) {
              employeeName = getFullName(user.firstName, user.lastName);
            }
          });
        return employeeName;
    };

    const getApprovers = (id) => {
      let approverList = '';
      for (let i = 0; i < id.length; i++) {
        approverList += id[i].approverName;
        if (i !== id.length - 1) {
          approverList += ', ';
        }
      }
      return approverList;
  }

        
    const exportToExcel = async () => {
      let ws;
      let wb;
      // const headerCellStyle = {
      //   font: { bold: true, color: { rgb: "1773bc" } },
      //   fill: { fgColor: { rgb: "F3F8FC" } } ,
      //   alignment: { horizontal: "center", vertical: "center",wrapText:true }
      // };
      if (headingName === Modules.PROJECT_ALLOCATION && tabName === "RESOURCE ALLOCATION") {
        let projectAllocationExcelData = excelData.map((item) => ({
          employeeName: getEmployeeName(item.employeeId),
          projectName: item.projectName,
          projectManagerName: item.projectManagerName,
          startDate: { t: 'd', v: moment(item.startDate).toDate() },
          endDate: { t: 'd', v: moment(item.endDate).toDate() },
          billStatus: item.billStatus,
          billAllocation: item.billAllocation,
        }));
        
        ws = XLSX.utils.json_to_sheet(projectAllocationExcelData);
        ws['!cols'] = [{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 10},{wch: 10}];
        
        XLSX.utils.sheet_add_aoa(ws, [["Employee Name", "Project Name", "Project Manager Name", "Start Date", "End Date", "Bill Status", "Bill Allocation"]], { origin: "A1" });
        wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      } else if (headingName === Modules.REPORTING && tabName === "ALLOCATION CHANGE REPORT BY PROJECT") {
        let allocationChangeReportData = excelData.map((item)=>({
          empID: item.empID,
          empName: item.empName,
          projectName: item.projectName,
          columnName: item.columnName,
          oldValue: item.oldValue,
          newValue: item.newValue,
          updatedDT: { t: 'd', v: moment(item.updatedDT).toDate() },
          updateBy: item.updateBy,

        }))
        ws = XLSX.utils.json_to_sheet(allocationChangeReportData);
        ws['!cols'] = [{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch:15}];
        XLSX.utils.sheet_add_aoa(ws, [["Employee ID","Employee Name","Project Name", "Attribute Name", "Old Value", "New Value", "Updated Date","Updated By"]], { origin: "A1" });
        wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      }
      else if (headingName === Modules.REPORTING && tabName === "ALLOCATION CHANGE REPORT BY EMPLOYEE") {
        let allocationChangeReportDataByEmployee = excelData.map((item)=>({
          empID: item.empID,
          empName: item.empName,
          projectName: item.projectName,
          columnName: item.columnName,
          oldValue: item.oldValue,
          newValue: item.newValue,
          updatedDT: { t: 'd', v: moment(item.updatedDT).toDate() },
          updateBy: item.updateBy,

        }))
        ws = XLSX.utils.json_to_sheet(allocationChangeReportDataByEmployee);
        ws['!cols'] = [{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch:15}];
        XLSX.utils.sheet_add_aoa(ws, [["Employee ID","Employee Name","Project Name", "Attribute Name", "Old Value", "New Value","Updated Date","Updated By"]], { origin: "A1" });
        wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      }
      else if(headingName=== Modules.REPORTING && tabName ==="MISSING TIMESHEET"){
        let missingTimesheetExcelData = excelData.map((item)=>({
          employeeName: item.employeeName,
          periodWeek: item.periodWeek,
          status:item.status
        }));

        ws = XLSX.utils.json_to_sheet(missingTimesheetExcelData);

        XLSX.utils.sheet_add_aoa(ws,[["Employee Name","Period Week","Status"]], {origin: "A1"});
        wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      }
      
      else if (headingName === Modules.PROJECT_MANAGEMENT) {
        let projectManagementExcelData = excelData.map((item) => ({
          zohoEmployeeId: item.zohoEmployeeId,
          employeeName: [item?.firstName, item?.lastName].filter(Boolean).join(" "),
          employeeRole : item.employeeRole,
          clientName: item.clientName,
          projectName: item.projectName,
          projectManagerName: item.projectManagerName,
          site: item.site,
          startDate:{ t: 'd', v: moment(item.startDate).toDate() },
          endDate:{ t: 'd', v: moment(item.endDate).toDate() },
          billStatus: item.billStatus,
          billAllocation: item.billAllocation,
          costAllocation: item.costAllocation,
          department: item.department,
          jobTitle: item.jobTitle,
          stream: item.stream,
          designation: item.designation,
          skillBucket: item.skillBucket,
          primarySkill: item.primarySkill,
          secondarySkill: item.secondarySkill
          // billDetails: item.billDetails,
        }));
        ws = XLSX.utils.json_to_sheet(projectManagementExcelData);

        ws['!cols'] = [{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 10},{wch: 10},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15},{wch: 15}];
        
        XLSX.utils.sheet_add_aoa(ws, [["Employee ID", "Employee Name", "Project Role", "Client Name","Project Name", "Project Manager Name", "Offshore/Onshore", "Start Date", "End Date", "Bill Status", "Allocation", "Cost Allocation", "Department", "Job Title", "Stream", "Designation", "Skill Bucket", "Primary Skill", "Secondary Skill"]], { origin: "A1" });
        wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      }
      else if(headingName=== Modules.SKILLS){
        let skillsTrackerExcelData = excelData.map((item)=>({
          employeeName: item.employeeName,
          projectName:item.projectName,
          skillBucket:item.skillBucket,
          primarySecondarySkills:item.primarySecondarySkills
        }));
        ws = XLSX.utils.json_to_sheet(skillsTrackerExcelData);

        XLSX.utils.sheet_add_aoa(ws, [["Employee Name","Project Name","Skill Bucket","Primary Secondary Skils"]]);
        wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      }
      else if(headingName===Modules.PROJECT_ADMIN){
        let projectAdminExcelData = excelData.map((item)=>({
          projectName: item.projectName,
          clientName: item.clientName,
          type: item.type,
          sowStartDate:dayjs(item.sowStartDate)?.format( "DD-MMM-YYYY"),
          sowEndDate:dayjs(item.sowEndDate)?.format( "DD-MMM-YYYY"),
          projectManagerName:item.projectManagerName,
          status:item.status,
          approvers: getApprovers(item.approvers)
        }));
        ws = XLSX.utils.json_to_sheet(projectAdminExcelData);
        ws['!cols'] = [{wch: 15},{wch: 15},{wch: 7},{wch: 15},{wch: 15},{wch: 20},{wch: 15},{wch: 15}];
        XLSX.utils.sheet_add_aoa(ws, [["Project Name","Client Name","Type","SOW Start Date","SOW End Date","Project Manager Name","Status","Approvers"]]);
        wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      }
      else if(headingName=== Modules.TRAINING_AND_SESSION){
        // particpants details by session data for excel
        let participantsDetailsBySession = excelData.participantsDetailsBySessionDataForExcelResponse.map((item)=>({
          sessionName:item.sessionName,
          registrationDate: item.registrationDate,
          employeeEmail:item.employeeEmail,
          employeeID:item.employeeID,
          employeeName:item.employeeName,
          sessionStatus:item.sessionStatus,
          attendance: item.attendance
        }));
        let ws1 = XLSX.utils.json_to_sheet(participantsDetailsBySession);
        ws1['!cols'] = [{wch: 15},{wch: 17},{wch: 26},{wch: 15},{wch: 15},{wch: 24},{wch: 10}];
        const headers1 = [
          ["Session Name", "Date of Nomination", "Employee Email", "Employee Code", "Employee Name", "Confirmation for the Session", "Attendance"]
        ];
        XLSX.utils.sheet_add_aoa(ws1,headers1,{origin: "A1"});
        const headerCellStyle1 = {
          font: { bold: true, color: { rgb: "1773bc" } }, 
          fill: { fgColor: { rgb: "F3F8FC" } } 
        };
        for (let col = 0; col < headers1[0].length; col++) {
          const cellRef = XLSX.utils.encode_cell({ r: 0, c: col });
          ws1[cellRef].s = headerCellStyle1;
        }

        // ============================================================================================================================================================

        // session feedback data for excel
        let feedbackDetails = excelData.feedbackDetailsForExcelResponse.map((item) => {
          let feedbackObject = {
            employeeName: item.employeeName,
            employeeEmail: item.employeeEmail,
        };
        
        // Dynamically add feedback responses to the object
        item.feedbackResponses.forEach((response, index) => {
            feedbackObject[`q${index + 1}`] = response.response;
        });
        
        return feedbackObject;
      });
      let ws2 = XLSX.utils.json_to_sheet(feedbackDetails);

      const numQuestions = Math.max(...excelData.feedbackDetailsForExcelResponse.map(item => item.feedbackResponses.length));

      ws2['!cols'] = [ 
        {wch:15},
        {wch:26},
        ...Array.from({ length: numQuestions }, () => ({ wch: 26.57 }))];
      ws2['!rows'] = [{ hpx: 152.25 }, {}, {}, {}, {}, {}, {}, {}, {}, {}];
      const headers = [
          ["Employee Name",
          "Employee Email",
          ...(sessionFeedbackQuestions?.map(question => question.question)??[])]
      ];
      XLSX.utils.sheet_add_aoa(ws2, headers, { origin: "A1" });
      const headerCellStyle = {
          font: { bold: true, color: { rgb: "1773bc" } },
          fill: { fgColor: { rgb: "F3F8FC" } } ,
          alignment: { horizontal: "center", vertical: "center",wrapText:true }
      };
      for (let col = 0; col < headers[0].length; col++) {
        const cellRef = XLSX.utils.encode_cell({ r: 0, c: col });
        ws2[cellRef].s = headerCellStyle;
      }

       wb = {
        Sheets: {
          'Participants Details': ws1,
          'Feedback Details': ws2
        },
        SheetNames: ['Participants Details', 'Feedback Details']
      };
      }
      else if(tabName === "VIEW EMPLOYEE ATTENDANCE"){
        let attendanceData = excelData.map((item)=>{
          let returnObject = {}
          returnObject["Emp ID"] = item.employeeID
          returnObject["Emp Name"] = item.employeeName
          returnObject["Total Working Days"] = item.totalWorkingDays
          returnObject["Present"] = item.totalPresentDays
          returnObject["Absent"] = item.totalAbsentDays
          returnObject["WFH"] = item.totalWfhDays
          returnObject["Leaves"] = item.totalLeaveDays
          returnObject["Month"] = `${props.month}-${props.year}`
          for (let index = 1; index <= excelData[0].status.length; index++) {
            //returnObject[index+" "] = leaveTypeMappings[item.status[index-1]]?leaveTypeMappings[item.status[index-1]]:item.status[index-1]
            returnObject[index+" "] = item.status[index-1]
          }
          return returnObject
          
        });
     
        ws = XLSX.utils.json_to_sheet(attendanceData)
        let columnWidths = [
          { wch: 7 }, // "Employee ID"
          { wch: 16 }, // "Employee Name"
          { wch: 5}, //"Total Working Days"
          { wch: 4}, //"Total Present Days"
          { wch: 4}, //"Total Absent Days"
          { wch: 4}, //"Total WFH Days"
          { wch: 4}, //"Total Leave Days"
          { wch: props.month.length + 5 }, // "Month"
          ...Array.from({ length: excelData[0].status.length }, () => ({ wch: 4 }))
        ];
        ws['!cols'] = columnWidths;
        wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      }
    
       
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      dispatch(setVtrackLoader(true));
      dispatch(setAllocationChangeReportDataForExcel([]));
      dispatch(resetExportProjectManagementData());
      dispatch(setSessionDataForExcel(null));
      dispatch(setAllocationChangeReportByEmployeeDataForExcel([]));
      dispatch(setAttendanceForExcel(null));
      // dispatch(setSessionFeedbackquestionsList(null))
      dispatch(setAllProjectManagementData([]))
      excelData = null;
      dispatch(setVtrackLoader(false));
    }

   const  handleClick = async() => { 
        setExporting(true);
        if(headingName === Modules.REPORTING && tabName=== "ALLOCATION CHANGE REPORT BY PROJECT" ){
          dispatch(
           getAllocationChangeReportData({
             startDate: props.startDate,
             endDate: props.endDate,
             projectId: selectedProjectId,
             headingName: headingName,
           })
         );
         setFlag(true);
       }
       else if(headingName=== Modules.REPORTING && tabName === "ALLOCATION CHANGE REPORT BY EMPLOYEE"){
        dispatch(
          getAllocationChangeReportByEmployeeDataForExcel({
            employeeIds: props.employeeIds,
            endDate: props.endDate,
            startDate: props.startDate
          })
        )
       }
       else if(headingName=== Modules.PROJECT_ADMIN){
        dispatch(
          getProjectAdminData({
            page:props.page,
            pageSize:props.rowsPerPage,
            expiredAllocations: props.expiredAllocations,
          })
        )
       }
       else if(headingName === Modules.PROJECT_MANAGEMENT){
         if(!all){
                dispatch(
                 getProjectManagementData({
                   projectId: props.projectId,
                   pageNo:1,
                   pageSize: props.pageSize,
                   sortBy: "projectName",
                   sortDir: "ASC",
                   searchData: "",
                   isExport: true,
                   expiredAllocations: props.expiredAllocations,
                   billStatus: props.billStatus,
                   headingName: headingName
                 })
           ); 
           }
       else{
                       dispatch(
                         getAllProjectManagementData({
                             sortBy: "projectName",
                             sortDir: "ASC",
                             searchData: "",
                           })
                       );
       }
       setFlag(true);
     }
     else if(headingName=== Modules.PROJECT_ALLOCATION){
      dispatch(
        getUsersProjectAllocation({
          employeeIds: props.selectedUserIds,
          isHistory: props.isHistory,
          headingName:headingName,
          isExport:true
        })
      );
     }
     else if(headingName===Modules.TRAINING_AND_SESSION){
      
      dispatch(
        getSessionDataForExcel({
          sessionId:props.sessionId
        })
      );

   
     }
     else if(tabName==="VIEW EMPLOYEE ATTENDANCE"){
      dispatch(
        getAttendanceForExcel({
          month: props.month,
          year: props.year
        })
      );
     }
    }

    useEffect(()=>{
      if(exporting && (excelData)){
        if(headingName === Modules.PROJECT_MANAGEMENT){
          fileName = all ? fileName : getProjectName(props.projectId) + "-" + headingName;
        }
        if(excelData){
          exportToExcel(fileName);
        }
        setExporting(false);
      }
    },[excelData,exporting]) 
    
  

  return (
    tabName==="VIEW EMPLOYEE ATTENDANCE"?
    (<Button onClick={(e) => handleClick()} variant='contained' sx={{width:"10rem"}}>Download</Button>)
    :(
      <div style={headingName === Modules.SKILLS || headingName === Modules.PROJECT_ALLOCATION || headingName === Modules.REPORTING ? ({marginTop: '2rem'}): ({marginTop: 0})}>
        <a onClick={(e) => handleClick()} style={{cursor: "pointer"}}>      
        <Tooltip title={all?(headingName===Modules.PROJECT_MANAGEMENT || headingName === Modules.SKILLS)?<h1>Export All Project's Data xls</h1>:<h1>Export All Data xls</h1>:<h2>Export Data xls</h2>}>
          {(headingName === Modules.REPORTING && 
            (tabName === "MISSING TIMESHEET" || tabName=== "ALLOCATION CHANGE REPORT BY PROJECT"))||(headingName===Modules.PROJECT_ADMIN)
            ||(headingName===Modules.TRAINING_AND_SESSION&&(tabName==="TRAINING AND SESSION"))?<img src={excelIcon} height={'40px'} />:(headingName === Modules.SKILLS || headingName === Modules.PROJECT_ALLOCATION) ? <img src={excelIcon} height={'30px'} /> : <img src={all?excelAllIcon:excelIcon}/>}
        </Tooltip>
        </a>
      </div>
    )
  )
}