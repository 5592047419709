import { call, put, takeLatest } from "redux-saga/effects";
import {
  SessionType,
  setVtrackLoader,
  setAllSessionsData
} from "../redux/actions";
import { deleteSessionData,getAllSessionsData } from "../http/requests/session";
import { toast } from "react-toastify";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerDeleteSessionSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    yield call(deleteSessionData,payload);
    toast.success("Session deleted Successfully", toastOptions);
    const sessionDetail = yield call(getAllSessionsData,"");
    yield put(setAllSessionsData(sessionDetail));
    yield put(setVtrackLoader(false));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions);
    yield put(setVtrackLoader(false));
  }
};

export function* deleteSessionSaga() {
  yield takeLatest(
    SessionType.DELETE_SESSION,
    workerDeleteSessionSaga
  );
};