import { call, put, takeLatest } from "redux-saga/effects";
import { getSessionFeedbackQuestionsList } from "../http/requests/session";
import {
  setVtrackLoader
} from "../redux/actions";
import { SessionType,setSessionFeedbackquestionsList } from "../redux/actions/session";

function* workergetSessionFeedbackQuestionsListSaga({payload}) {
  try {
    yield put(setVtrackLoader(true));
    const sessionQuestionList = yield call(getSessionFeedbackQuestionsList);
    yield put(setSessionFeedbackquestionsList(sessionQuestionList));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    yield put(setVtrackLoader(false));
  }
};

export function* getSessionFeedbackQuestionsListSaga() {
  yield takeLatest(
    SessionType.GET_SESSION_FEEDBACK_QUESTIONS_LIST,
    workergetSessionFeedbackQuestionsListSaga
  );
};