import { call, put, takeLatest } from "redux-saga/effects";
import { syncZohoPeople, getSyncDetails } from "../http/requests/settings";
import { toastOptions } from "../common/utils/toasterOptions";
import { toast } from "react-toastify";
import { setSyncDetails, SettingDataType, setVtrackLoader } from "../redux/actions";
 

function* workerSyncZohoPeople() {
  try {
    yield put(setVtrackLoader(true));
    yield call(syncZohoPeople);
    toast.success("Successfully Synced Data With Zoho People.", toastOptions)
    const syncDetails = yield call (getSyncDetails);
    yield put(setSyncDetails(syncDetails));
    yield put(setVtrackLoader(false));
  } catch (err) {
    console.log(err);
    toast.error(err.data.errorMessage, toastOptions)
    yield put(setVtrackLoader(false));
  }
};

export function* syncZohoPeopleSaga() {
  yield takeLatest(
    SettingDataType.SYNC_ZOHO_PEOPLE,
    workerSyncZohoPeople
  );
};
