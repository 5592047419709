export const ReportingType = {
  GET_TIMESHEET_REPORTS: 'GET_TIMESHEET_REPORTS',
  GET_MISSING_TIMESHEET: 'GET_MISSING_TIMESHEET',
  SEND_MISSING_TIMESHEET_REMINDER_MANUALLY: 'SEND_MISSING_TIMESHEET_REMINDER_MANUALLY',
  SET_REPORTING_DATA: 'SET_REPORTING_DATA',
  GET_ALLOCATION_CHANGE_REPORT: 'GET_ALLOCATION_CHANGE_REPORT', 
  // RESET_REPORTING_DATA_STATE: 'RESET_REPORTING_DATA_STATE',
  SET_ALLOCATION_CHANGE_REPORT_DATA: 'SET_ALLOCATION_CHANGE_REPORT_DATA',
  GET_ALLOCATION_CHANGE_REPORT_DATA: 'GET_ALLOCATION_CHANGE_REPORT_DATA',
  SET_ALLOCATION_CHANGE_REPORT_DATA_FOR_EXCEL: 'SET_ALLOCATION_CHANGE_REPORT_DATA_FOR_EXCEL',
  SET_EXPORT_DATA: 'SET_EXPORT_DATA',
  GET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE: 'GET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE',
  SET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE: 'SET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE',
  GET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE_DATA_FOR_EXCEL: 'GET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE_DATA_FOR_EXCEL',
  SET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE_DATA_FOR_EXCEL: 'SET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE_DATA_FOR_EXCEL'
}

export const getTimesheetReports = (data) => 
({
  type: ReportingType.GET_TIMESHEET_REPORTS,
  payload: data,
})

export const getMissingTimesheet = (data) =>
({
  type: ReportingType.GET_MISSING_TIMESHEET,
  payload: data,
})

export const sendMissingTimesheetReminderManually = (data) => ({
  type:ReportingType.SEND_MISSING_TIMESHEET_REMINDER_MANUALLY,
  payload:data,
})

export const setReportingData = (data) => 
({
  type: ReportingType.SET_REPORTING_DATA,
  payload: data,
})

export const getAllocationChangeReport = (data)=>
({
  type: ReportingType.GET_ALLOCATION_CHANGE_REPORT,
  payload: data,
})

export const getAllocationChangeReportData =(data)=>
({
  type: ReportingType.GET_ALLOCATION_CHANGE_REPORT_DATA,
  payload:data,
})
export const setAllocationChangeReportDataForExcel =(data)=>
({
  type: ReportingType.SET_ALLOCATION_CHANGE_REPORT_DATA_FOR_EXCEL,
  payload:data,
})

export const setAllocationChangeReportData = (data)=>
({
  type: ReportingType.SET_ALLOCATION_CHANGE_REPORT_DATA,
  payload: data,
})

export const setExportData = (data)=> 
({
  type: ReportingType.SET_EXPORT_DATA,
  payload: data,
})

export const getAllocationChangeReportByEmployee =(data)=>
({
  type: ReportingType.GET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE,
  payload:data,
})
export const setAllocationChangeReportByEmployee = (data)=>
({
  type: ReportingType.SET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE,
  payload: data,
})

export const getAllocationChangeReportByEmployeeDataForExcel =(data)=>
({
  type: ReportingType.GET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE_DATA_FOR_EXCEL,
  payload:data,
})
export const setAllocationChangeReportByEmployeeDataForExcel =(data)=>
({
  type: ReportingType.SET_ALLOCATION_CHANGE_REPORT_BY_EMPLOYEE_DATA_FOR_EXCEL,
  payload:data,
})