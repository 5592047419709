// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading-font-color{
    color: #1773bc;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    height: 3rem
}`, "",{"version":3,"sources":["webpack://./src/components/FormView/formView.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI;AACJ","sourcesContent":[".heading-font-color{\r\n    color: #1773bc;\r\n}\r\n\r\n.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{\r\n    height: 3rem\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
