import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  ButtonBase,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tableColumnsData } from "../../common/utils/datatable";
import {
  getHolidayList,
  addHoliday,
  updateHoliday,
  deleteHoliday,
  setHolidayList,
  cloneHoliday,
} from "../../redux/actions";
import Loader from "../Loaders/Loader";
import TableLoader from "../Loaders/TableLoader";
import "./holidayListTable.css";
import {
  AddEnableIcon,
  crossIcon,
  deleteIcon,
  editIcon,
} from "../../common/icons";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DialogBox from "../DialogBox/dialogBox";
import { AddDisableIcon } from "../../common/icons";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

export const HolidayListTable = () => {
  const { holidayList } = useSelector(({ MODULES }) => MODULES);
  const [holidayData, setHolidayData] = useState([]);
  const { vTrackLoader, tableLoader } = useSelector(
    ({ APP_STATE }) => APP_STATE
  );
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [deleteHolidayId, setDeleteHolidayId] = useState(false);
  const [editHolidayId, setEditHolidayId] = useState(false);
  const [editYear, setEditYear] = useState(false);
  const [editDate, setEditDate] = useState(false);
  const [editDay, setEditDay] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const { listItems } = useSelector(({ MODULES }) => MODULES);
  const [listItemsData, setListItemsData] = useState([]);
  const [showDialogBox, setShowDialogBox] = useState(false);
  const [dialogDeleteButtonClicked, setDialogDeleteButtonClicked] = useState(false);
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
  const [showClonePopup, setShowClonePopup] = useState(false);
  const [sourceYear, setSourceYear] = useState(false);
  const [targetYear, setTargetYear] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (holidayList) {
      setHolidayData(holidayList);
    }
  }, [holidayList]);

  useEffect(() => {
    setListItemsData(listItems);
  }, [listItems]);

  useEffect(() => {
    if (dialogDeleteButtonClicked) {
      dispatch(deleteHoliday({ id: deleteHolidayId, year: selectedYear }));
      setDialogDeleteButtonClicked(false);
      setDeleteHolidayId(false);
    }
  }, [dialogDeleteButtonClicked]);

  const handleCancel = () => {
    setEditHolidayId(null);
    setEditYear(null);
    setEditDate(null);
    setEditDay(null);
    setEditTitle(null);
    setIsAddButtonClicked(false);
  };

  const handleChange = (event) => {
    setEditTitle(event.target.value);
  };

  useEffect(() => {
    dispatch(getHolidayList({ year: selectedYear }));
  }, [selectedYear]);

  const handleUpdate = (data) => {
    dispatch(
      updateHoliday({
        updateData: {
          id: editHolidayId,
          title: editTitle ? editTitle : data.title,
          holidayDay: editDay ? editDay : data.holidayDay,
          holidayDate: editDate ? editDate : data.holidayDate,
          holidayYear: editYear ? editYear : data.holidayYear,
        },
        year: selectedYear,
      })
    );
    dispatch(getHolidayList({ year: selectedYear }));
    handleCancel();
  };

  const handleAdd = () => {
    dispatch(
      addHoliday({
        id: -10,
        title: editTitle,
        holidayDay: editDay,
        holidayDate: editDate,
        holidayYear: editYear,
      })
    );
    dispatch(getHolidayList({ year: selectedYear }))
    handleCancel()
  };

  const years = [];
  let i;
  for (i = 0; i < 5; i++) {
    years.push(currentYear - 2 + i);
  }

  const handleClone = () => {
    dispatch(cloneHoliday({
      sourceYear:sourceYear,
      targetYear:targetYear
    }))
    setTargetYear(false)
    setSourceYear(false)
    dispatch(getHolidayList({ year: selectedYear }))
    setShowClonePopup(false)
  };

  const ClonePopUp = () => {
    
    return (
      <Dialog open={showClonePopup} onClose={() => setShowClonePopup(false)} sx={{border:"2px solid #1773bc"}}>
        <DialogTitle sx={{color:"#1773bc",fontFamily:"Montserrat", fontWeight:600,fontSize:15, margin:2,textAlign:"center"}}>SET UP HOLIDAYS FOR NEW YEAR</DialogTitle>
        <DialogContent sx={{minHeight:150}} >
          <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{color:"#1773bc",fontSize:12}}>Select Source</div>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10  }}>{yearDropdown("sourceYear")}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: '#1773bc', fontSize: '1.5rem' }}>TO</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{color:"#1773bc",fontSize:12}}>Select Target</div>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10  }}>{yearDropdown("targetYear")}</div>
            </div>
          </div>
        
        </DialogContent>
        <div style={{ display: "flex", justifyContent: "flex-end", padding: 10 }} className="popup-actions">
          <Button onClick={() => {setShowClonePopup(false); setTargetYear(false); setSourceYear(false)}} autoFocus style={{ marginLeft: "0.5rem"}} variant="outlined"  size="large" >
            Cancel
          </Button>
          <Button onClick={()=>sourceYear&&targetYear&&handleClone()} autoFocus style={{ marginLeft: "0.5rem"}} variant="contained"  size="large" >
            Clone
          </Button>
        </div>
      </Dialog>
    );
  };


  const yearDropdown = (yearType) =>{
    return(<>
    <FormControl sx={{ minWidth: 100, marginTop:1}} size="small">
              <InputLabel id="demo-select-small-label" sx={{ minwidth: 100 }}>
                Year<span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={yearType==="selectedYear"?selectedYear:(yearType==="sourceYear"?sourceYear:targetYear)}
                label="Year"
              >
                {years.map((year) => (
                  <MenuItem
                    key={year}
                    onClick={() => {
                      if(yearType==="selectedYear")
                      setSelectedYear(year)
                      else if(yearType==="sourceYear")
                      setSourceYear(year)
                      else
                      setTargetYear(year)
                    }}
                    value={year}
                  >
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
    </>)
    
  }

  return (
    <>
      {/* {vTrackLoader && <Loader />} */}
      <div className="settingsWrapper" style={{ maxWidth: "100%" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems:"flex-end" }}>
            {yearDropdown("selectedYear")}
            <ButtonBase
                style={{ marginRight: 10}}
                disabled={isAddButtonClicked || editHolidayId}
                className={
                  isAddButtonClicked || editHolidayId
                    ? "holidayDisableAddButton"
                    : "holidayAddBtn"
                }
                onClick={() => setIsAddButtonClicked(true)}
              >
                Add Holiday
              </ButtonBase>
          </div>

          <TableContainer sx={{ maxHeight: "45rem" }}>
            <Table
              style={{ marginTop: "1rem" }}
              aria-label="sticky table"
              size="small"
            >
              <TableHead>
                <TableRow className="settingTableHeader manageNotifications">
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#1773bc0d",
                      color: "#1773bc",
                      width: "30rem",
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      fontFamily:"Montserrat"
                    }}
                  >
                    {"Title"}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#1773bc0d",
                      color: "#1773bc",
                      width: "20rem",
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      fontFamily:"Montserrat"
                    }}
                  >
                    {"Day"}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#1773bc0d",
                      color: "#1773bc",
                      width: "30rem",
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      fontFamily:"Montserrat"
                    }}
                  >
                    {"Date"}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#1773bc0d",
                      color: "#1773bc",
                      width: "18rem",
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      fontFamily:"Montserrat" 
                    }}
                  >
                    {"Action"}
                  </TableCell>
                </TableRow>
              </TableHead>

              {(tableLoader || vTrackLoader) && <TableLoader />}
              {holidayData && (
                <TableBody className="settingTableBody holidayListTable">
                  {isAddButtonClicked ? (
                    <TableRow key={1} className="settingTableHeader">
                      <TableCell
                        align="left "
                        style={{ width: "30rem", color: "black !important" }}
                      >
                        <FormControl sx={{ minWidth: 150 }} size="small">
                          <InputLabel id="demo-select-small-label">
                            Title<span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Select
                            required
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={editTitle}
                            label="Title"
                            onChange={handleChange}
                          >
                            {listItemsData.map((items) => (
                              <MenuItem
                                key={items.id}
                                value={items.longCodeValue}
                                onClick={() => {
                                  setEditTitle(items.longCodeValue);
                                }}
                              >
                                {items.longCodeValue}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="left " style={{ width: "20rem" }}>
                        <TextField
                          style={{ width: "10rem" }}
                          value={editDay ? editDay : "Select Date"}
                          sx={{ "& label": { lineHeight: "0.8rem" } }}
                          disabled
                        />
                      </TableCell>
                      <TableCell align="left " style={{ width: "30rem" }}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          sx={{
                            "& label": {
                              lineHeight: "0.8rem",
                              display: "flex",
                              alignItems: "center",
                            },
                          }}
                        >
                          <DatePicker
                            required
                            label={
                              <div>
                                Select Date{" "}
                                <span style={{ color: "red" }}>*</span>
                              </div>
                            }
                            value={
                              editDate
                                ? editDate
                                : new Date(selectedYear, new Date().getMonth(), new Date().getDate())
                            }
                            onChange={(newValue) => {
                              const formattedDate = newValue
                                ? newValue.format("DD-MMM-YYYY")
                                : null;
                              setEditDate(formattedDate);
                              const selectedDate = new Date(newValue);
                              const dayIndex = selectedDate.getDay();
                              const daysOfWeek = [
                                "Sunday",
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                              ];
                              const selectedDay = daysOfWeek[dayIndex];
                              const selectedYear = selectedDate.getFullYear();
                              setEditDay(selectedDay);
                              setEditYear(selectedYear);
                            }}
                            renderInput={(params) => (
                              <TextField
                                style={{
                                  width: "15rem",
                                }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell align="center" style={{ width: "5rem" }}>
                        <div className="actions">
                          <Tooltip
                            title={
                              editTitle && editDate
                                ? "Add"
                                : "Fill Required Fields"
                            }
                            style={{ marginRight: "2.5rem" }}
                          >
                            {editTitle && editDate ? (
                              <img
                                src={AddEnableIcon}
                                className="editDeleteIcon cursorPointer"
                                onClick={() => {
                                  handleAdd();
                                }}
                                alt=""
                              />
                            ) : (
                              <img
                                src={AddDisableIcon}
                                className="editDeleteIcon disabledButton"
                                alt=""
                              />
                            )}
                          </Tooltip>
                          <Tooltip title="cancel">
                            <img
                              src={crossIcon}
                              className="editDeleteIcon cursorPointer"
                              onClick={handleCancel}
                              alt=""
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}

                  {holidayData.map((data) => (
                    <TableRow key={data.id} className="settingTableHeader">
                      {editHolidayId === data.id ? (
                        <TableCell
                          align="left "
                          style={{ width: "30rem", color: "black !important" }}
                        >
                          <FormControl sx={{ minWidth: 150 }} size="small">
                            <InputLabel id="demo-select-small-label">
                              Title
                            </InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={editTitle}
                              label="Title"
                              onChange={handleChange}
                            >
                              {listItemsData.map((items) => (
                                <MenuItem
                                  key={items.id}
                                  value={items.longCodeValue}
                                  onClick={() => {
                                    setEditTitle(items.longCodeValue);
                                  }}
                                >
                                  {items.longCodeValue}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      ) : (
                        <TableCell align="left" style={{ width: "30rem" }}>
                          {editHolidayId === data.id
                            ? editTitle
                              ? editTitle
                              : data.title
                            : data.title}
                        </TableCell>
                      )}

                      <TableCell align="left" style={{ width: "20rem" }}>
                        {editHolidayId === data.id
                          ? editDay
                            ? editDay
                            : data.holidayDay
                          : data.holidayDay}
                      </TableCell>

                      {editHolidayId === data.id ? (
                        <TableCell align="left " style={{ width: "30rem" }}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            sx={{
                              "& label": {
                                lineHeight: "0.8rem",
                              },
                            }}
                          >
                            <DatePicker
                              label="Select Date"
                              value={editDate ? editDate : data.holidayDate}
                              onChange={(newValue) => {
                                const formattedDate = newValue
                                  ? newValue.format("YYYY-MM-DD")
                                  : null;
                                setEditDate(formattedDate);
                                const selectedDate = new Date(newValue);
                                const dayIndex = selectedDate.getDay();
                                const daysOfWeek = [
                                  "Sunday",
                                  "Monday",
                                  "Tuesday",
                                  "Wednesday",
                                  "Thursday",
                                  "Friday",
                                  "Saturday",
                                ];
                                const selectedDay = daysOfWeek[dayIndex];
                                const selectedYear = selectedDate.getFullYear();
                                setEditDay(selectedDay);
                                setEditYear(selectedYear);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  style={{
                                    width: "15rem",
                                  }}
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </TableCell>
                      ) : (
                        <TableCell align="left" style={{ width: "30rem" }}>
                          {new Date(data.holidayDate).toLocaleDateString(
                            "en-US"
                          )}
                        </TableCell>
                      )}

                      <TableCell align="center" style={{ width: "5rem" }}>
                        {editHolidayId === data.id ? (
                          <div className="actions">
                            <Tooltip
                              title="update"
                              style={{ marginRight: "2.5rem" }}
                            >
                              <img
                                src={AddEnableIcon}
                                className="editDeleteIcon cursorPointer"
                                onClick={() => {
                                  handleUpdate(data);
                                }}
                                alt=""
                              />
                            </Tooltip>
                            <Tooltip title="cancel">
                              <img
                                src={crossIcon}
                                className="editDeleteIcon"
                                onClick={handleCancel}
                                alt=""
                              />
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="actions">
                            <div style={{ marginRight: "2.5rem" }}>
                              <Tooltip title="Edit">
                                <button
                                  disabled={isAddButtonClicked}
                                  onClick={() => setEditHolidayId(data.id)}
                                  className={`buttonBackgroundBorder ${
                                    isAddButtonClicked
                                      ? "disabledButton"
                                      : "cursorPointer"
                                  }`}
                                >
                                  <img
                                    src={editIcon}
                                    className="editDeleteIcon"
                                    alt=""
                                  />
                                </button>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip title="Delete">
                                <button
                                  disabled={isAddButtonClicked}
                                  onClick={() => {
                                    setDeleteHolidayId(data.id);
                                    setShowDialogBox(true);
                                  }}
                                  className={`buttonBackgroundBorder ${
                                    isAddButtonClicked
                                      ? "disabledButton"
                                      : "cursorPointer"
                                  }`}
                                >
                                  <img
                                    src={deleteIcon}
                                    className="editDeleteIcon"
                                    alt=""
                                  />
                                </button>
                                {showDialogBox && deleteHolidayId===data.id && (
                                  <DialogBox
                                    setShowDialogBox={setShowDialogBox}
                                    setDialogDeleteButtonClicked={
                                      setDialogDeleteButtonClicked
                                    }
                                    header="Are you sure you want to Delete?"
                                  />
                                )}
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <div style={{paddingTop:"2rem"}}>
          <ButtonBase
            className={"cursorPointer holidayCloneBtn"}
            autoFocus
            variant="contained"
            onClick={() => {
              setShowClonePopup(true);
            }}
          >
            Set Up Holidays For New Year
          </ButtonBase>{ClonePopUp()}
          </div>
        </Paper>
      </div>
    </>
  );
};
