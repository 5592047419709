
export const ProjectAllocationType = {
    GET_PROJECT_ALLOCATION_DATA: 'GET_PROJECT_ALLOCATION_DATA',
    SET_PROJECT_ALLOCATION_DATA: 'SET_PROJECT_ALLOCATION_DATA',
    GET_USERS_PROJECT_ALLOCATION: 'GET_USERS_PROJECT_ALLOCATION',
    SET_EMPLOYEE_VIEW_DATA:'SET_EMPLOYEE_VIEW_DATA',
    SET_PROJECT_ALLOCATION_DATA_FOR_EXCEL: 'SET_PROJECT_ALLOCATION_DATA_FOR_EXCEL'
  }
  
  export const getProjectAllocationData = (data) => 
  ({
    type: ProjectAllocationType.GET_PROJECT_ALLOCATION_DATA,
    payload: data,
  });
  
  export const setProjectAllocationData = (data) => 
  ({
    type: ProjectAllocationType.SET_PROJECT_ALLOCATION_DATA,
    payload: data,
  });
  export const setEmployeeViewData = (data) => 
  ({
    type: ProjectAllocationType.SET_EMPLOYEE_VIEW_DATA,
    payload: data,
  });

  export const getUsersProjectAllocation = (data) =>
  ({
    type: ProjectAllocationType.GET_USERS_PROJECT_ALLOCATION,
    payload: data
  })
  export const setProjectAllocationDataForExcel =(data)=>
  ({
    type: ProjectAllocationType.SET_PROJECT_ALLOCATION_DATA_FOR_EXCEL,
    payload:data
  })