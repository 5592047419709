import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    DialogTitle,
    DialogContent,
    Button,
    DialogContentText,
    DialogActions,
    FormControl,
    Select,
    MenuItem,
  } from "@mui/material";
  import {
    getViewAttendanceData
  } from "../../redux/actions";
  import moment from "moment";
  import ExportExcel from "../ExportExcel";
  import { Modules } from "../../common/constants/sidebar";
  import Loader from "../Loaders/Loader";
  import CircularLoader from "../Loaders/CircularLoader";

const WarningPopup= (props) => {

    const handleCancel = () => {
      props.onResponse(false);
    }
    const handleDispatch = () => {
      props.onResponse(true);
    }
    return (
      <>
      <React.Fragment>
          <DialogTitle
          sx={{
            fontSize: '1.5rem',
            fontWeight: '600',
            color: '#1773bc',
            margin: '1rem 0',
            fontFamily: 'Montserrat',
          }}>
            Resource Allocation WILL Exceed Allowed Amount
          </DialogTitle>
          <DialogContent sx={{width:"26rem"}}>
            <div>Billable allocation for the following months will exceed the alloted amount</div>
          </DialogContent>
          <DialogActions >
            <Button onClick={handleDispatch} variant='filled'  sx={{width:"7rem"}}>Proceed</Button>
            <Button onClick={handleCancel} variant='outlined'  sx={{width:"7rem"}}>Cancel</Button>
          </DialogActions>
      </React.Fragment>
      </>
      
    );
}

export default WarningPopup;