const BASE_URL = process.env.REACT_APP_BASE_URL;

export const CLIENT_API = `${BASE_URL}/Client`;
export const TIMESHEET_API = `${BASE_URL}/Timesheet`;
export const PROJECT_ADMIN_API = `${BASE_URL}/ProjectAdmin`;
export const PROJECT_ALLOCATION_API = `${BASE_URL}/ProjectAllocation`;
export const PROJECT_MANAGEMENT_API = `${BASE_URL}/ProjectManagement`;
export const USERS_API = `${BASE_URL}/Users`;
export const ROLES_API = `${BASE_URL}/UserRoles`;
export const DROPDOWN = `${BASE_URL}/Dropdown`;
export const USER_DETAILS = `${BASE_URL}/UserDetails`;
export const REPORTING_API = `${BASE_URL}/Reporting`;
export const INVOICE_TRACKER_API = `${BASE_URL}/InvoiceTracker`;
export const HISTORY_API = `${BASE_URL}/History`;
export const ZOHO_API = `${BASE_URL}/ZohoPeople`;
export const SKILLS_API = `${BASE_URL}/UserSkills`;
export const HOLIDAY_LIST_API = `${BASE_URL}/HolidayList`;
export const SETUP_WFO_DAYS_API = `${BASE_URL}/SetupWFODays`;
export const VIEW_ATTENDANCE_API = `${BASE_URL}/ViewAttendance`;
export const SESSION_API = `${BASE_URL}/Session`;
export const FeedBack_API = `${BASE_URL}/SessionFeedback`;