import { call, put, takeLatest } from "redux-saga/effects";
import {
  SettingDataType,
  setVtrackLoader, setCustomWfo
} from "../redux/actions";
import { deleteCustomWfoDay, getCustomWfo } from "../http/requests/setupWFODays";
import { toast } from "react-toastify";
import { toastOptions } from "../common/utils/toasterOptions";

function* workerDeleteCustomWfoDaySaga({payload}) {
  try {
    yield call(deleteCustomWfoDay,payload);
    toast.success("Data deleted Successfully", toastOptions);
    let getCustomdata = yield call(getCustomWfo, payload.year);
    yield put(setCustomWfo(getCustomdata));
  } catch (err) {
    toast.error(err.data.errorMessage, toastOptions);
  }
};

export function* deleteCustomWfoDaySaga() {
  yield takeLatest(
    SettingDataType.DELETE_CUSTOM_WFO_DAY,
    workerDeleteCustomWfoDaySaga
  );
};