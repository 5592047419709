import { useEffect, useState, useRef } from "react";
import CircularLoader from "../Loaders/CircularLoader";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogContentText,
  DialogActions,
  Dialog,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useDispatch, useSelector } from "react-redux";
import {
  getViewAttendanceData,
  getLeaveDetails,
  setVtrackLoader,
} from "../../redux/actions";
import moment from "moment";
import "./Calendar.css";
import { Modules } from "../../common/constants/sidebar";

export const CreateCalendar = (props) => {
  const calendarRef = useRef(null);
  const { leaveDetails, viewAttendanceData } = useSelector(
    ({ MODULES }) => MODULES
  );
  const { vTrackLoader } = useSelector(({ APP_STATE }) => APP_STATE);
  const { userData, allUserDetails } = useSelector(({ USER }) => USER);
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showLeavePopup, setShowLeavePopup] = useState(false);

  const [events, setEvents] = useState([]);

  useEffect(()=>{
    if(props.tabName==="VIEW EMPLOYEE ATTENDANCE"){
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      setSelectedMonth(currentMonth);
      setSelectedYear(currentYear);
      handleGoToDate(currentMonth,currentYear);
    }
  },[props.selectedUserID])

  useEffect(() => {
    var WFOList = [];
    var HolidayList = [];
    var employeeAttendanceList = [];
    var WFHPresent = [];
    var WFOAbsent = [];
    var WFOAbsentApplyLeave = [];
    var Intime = [];

    if (viewAttendanceData["viewAttendanceWFOResponses"]) {
      WFOList = viewAttendanceData["viewAttendanceWFOResponses"].map(
        (item) => ({
          title: item.status,
          start: moment(item.date).format("YYYY-MM-DD"),
        })
      );
    }

    if (viewAttendanceData["viewAttendanceHolidayResponses"]) {
      HolidayList = viewAttendanceData["viewAttendanceHolidayResponses"].map(
        (item) => ({
          title: item.title,
          start: moment(item.holidayDate).format("YYYY-MM-DD"),
          type: "H",
          priority: "a"
        })
      );
    }

    if (viewAttendanceData["viewEmployeeAttendances"]) {
      employeeAttendanceList = viewAttendanceData[
        "viewEmployeeAttendances"
      ].map((item) => {
        let title = "";
        let priority = "";
        if (item.type === "O") {
          title = `P `;
          priority = "b"
        } else if (item.type === "R") {
          title = leaveTypeMappings[item.leaveType] || "";
          priority = "e"
        }

        return {
          title: title,
          start: moment(item.attendanceDate).format("YYYY-MM-DD"),
          intime: moment(item.inTime).format("hh:mm A"),
          leaveId: item.leaveID,
          type: item.type,
          priority: priority
        };
      });

      Intime = employeeAttendanceList
        .filter((detail) => detail.type === "O")
        .map((detail) => ({ 
          ...detail,
           title: "In Time: "+ detail.intime,
           priority:"d"
          }));
    }

    if (viewAttendanceData["wfhPresent"]) {
      WFHPresent = viewAttendanceData["wfhPresent"].map((item) => ({
        title: "P",
        start: moment(item).format("YYYY-MM-DD"),
        priority:"b"
      }));
    }

    if (viewAttendanceData["wfoAbsent"]) {
      WFOAbsent = viewAttendanceData["wfoAbsent"].map((item) => ({
        title: "A",
        start: moment(item).format("YYYY-MM-DD"),
        priority:"b"
      }));
      if (props.tabName === "VIEW ATTENDANCE")
        WFOAbsentApplyLeave = WFOAbsent.map((item) => ({
          title: "Apply Leave",
          start: item.start,
          priority:"d",
        }));
    }

    setEvents([
      ...WFOList,
      ...HolidayList,
      ...employeeAttendanceList,
      ...WFHPresent,
      ...WFOAbsent,
      ...WFOAbsentApplyLeave,
      ...Intime,
    ]);
  }, [viewAttendanceData, selectedMonth, selectedYear]);

  function renderEventContent(eventInfo) {
    return (
      <>
        <span
          style={{
            color:
              eventInfo.event.title === "Apply Leave"
                ? "white"
                : eventInfo.event.extendedProps.type === "R"
                ? "#1773bc"
                : "black",
            fontSize:
            eventInfo.event.title === "Apply Leave" ? (window.matchMedia('(max-width: 768px)').matches?"0.58rem":"0.8rem"): (eventInfo.event.title.startsWith("In Time")?"0.8rem":"1.25rem"),
            padding: eventInfo.event.title === "Apply Leave" ? "0.5rem" : "",
          }}
        >
          {eventInfo.event.title}
        </span>
      </>
    );
  }

  const leaveTypeMappings = {
    "Casual Leave (C)": "CL",
    "Casual Leave": "CL",
    "Leave Without Pay": "LWP",
    "Work From Home (WFH)": "WFH",
    "Work From Home (Interns)": "WFH",
    "Earned Leave": "EL",
    "Earned Leave 2021": "EL",
    "Bereavement Leave": "BL",
    "Paternity Leave": "PL",
    "Sick Leave": "SL",
  };
  const monthOptions = Array.from({ length: 12 }, (_, index) => ({
    value: index + 1,
    label: moment().month(index).format("MMMM"),
  }));
  const year = new Date().getFullYear() - 1;
  const yearOptions = Array.from({ length: 3 }, (_, index) => ({
    value: year + index,
    label: year + index,
  }));

  const user = allUserDetails && allUserDetails.data.find(
    (user) => user.id === props.selectedUserID
  );
  let selectedUserName = "";
  if (user) {
    selectedUserName = user.firstName + " " + user.lastName;
  }
  const LeavePopup = () => {
    return (
      <Dialog
        fullWidth={true}
        open={showLeavePopup}
        maxWidth="lg"
        onClose={() => setShowLeavePopup(false)}
      >
        <DialogTitle>
          <div className="leave-popup-heading">Leave Detail</div>
        </DialogTitle>
        <DialogContent>
          <div></div>
          {vTrackLoader && <CircularLoader />}
          {!vTrackLoader && leaveDetails && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="leave-popup-header">Leave Type</TableCell>
                    <TableCell className="leave-popup-header">From</TableCell>
                    <TableCell className="leave-popup-header">To</TableCell>
                    <TableCell className="leave-popup-header">Days Taken</TableCell>
                    <TableCell className="leave-popup-header">Reason for Leave</TableCell>
                    <TableCell className="leave-popup-header">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="leave-popup-body">{leaveDetails.leaveType}</TableCell>
                    <TableCell className="leave-popup-body">{leaveDetails.from}</TableCell>
                    <TableCell className="leave-popup-body">{leaveDetails.to}</TableCell>
                    <TableCell className="leave-popup-body">{leaveDetails.daysTaken}</TableCell>
                    <TableCell className="leave-popup-body">{leaveDetails.reasonForLeave}</TableCell>
                    <TableCell className="leave-popup-body">{leaveDetails.approvalStatus}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowLeavePopup(false);
            }}
            style={{ margin: "2rem", fontSize: "1.5rem" }}
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleDateClick = (arg) => {
    const clickedDate = arg.date;
    const isREventOnDate = events.find(
      (event) =>
        moment(event.start).isSame(clickedDate, "day") && event.type === "R"
    );
    if (isREventOnDate) {
      setShowLeavePopup(true);
      setVtrackLoader(true);
      dispatch(getLeaveDetails({ leaveId: isREventOnDate.leaveId }));
      setVtrackLoader(false);
    }
  };

  const handleEventClick = (arg) => {
    const clickedEvent = arg.event.extendedProps;
    if (clickedEvent.type === "R") {
      setShowLeavePopup(true);
      setVtrackLoader(true);
      dispatch(getLeaveDetails({ leaveId: clickedEvent.leaveId }));
      setVtrackLoader(false);
    }
    if (arg.event.title === "Apply Leave") {
      window.open(
        "https://people.zoho.in/veersa/zp#leavetracker/applyleave",
        "_blank"
      );
    }
  };

  const handleGoToDate = (month, year) => {
    dispatch(
      getViewAttendanceData({
        month: month,
        year: year,
        employeeId: props.selectedUserID,
      })
    );
    if (calendarRef.current && !vTrackLoader) {
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-01`;
      calendarRef.current.getApi().gotoDate(formattedDate);
    }
  };

  const handleMonthChange = (event) => {
    const newMonth = event.target.value;
    setSelectedMonth(newMonth);
    handleGoToDate(newMonth, selectedYear);
  };

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setSelectedYear(newYear);
    handleGoToDate(selectedMonth, newYear);
  };

  // useEffect(() => {
  //   handleGoToDate()
  // }, [selectedMonth, selectedYear]);

  function getEventClassNames(event) {
    const classNames = [];
    if (
      (event.title === "P" ||
        event.title === "A" ||
        event.title === "Apply Leave") &&
      moment(event.start).format("YYYY-MM-DD") <
        moment(
          props.selectedUserID
            ? viewAttendanceData["dateOfJoining"][0]
            : userData && userData.data.joinDate
        ).format("YYYY-MM-DD")
    ) {
      event.remove();
    }

    if (
      event.title === "H" ||
      event.title === "WFO" ||
      event.title === "WFHDAY" ||
      event.title === "SAT & SUN"
    ) {
      event.remove();
    }

    const evenStartDate = moment(event.start);
    if (
      evenStartDate.isSameOrAfter(moment(), "day") &&
      (event.title === "A" || event.title === "P")
    ) {
      event.remove();
    }
    if (event.type === "R") {
      classNames.push("cursorPointer");
    }
    if (event.title === "Apply Leave") {
      classNames.push("apply-leave");
      classNames.push("cursorPointer");
    }
    return classNames.join(" ");
  }

  const dayCellClassNames = (arg) => {
    const classNames = [];

    for (let i = 0; i < events.length; i++) {
      if (
        events[i].type === "H" &&
        moment(arg.date).format("YYYY-MM-DD") ===
          moment(events[i].start).format("YYYY-MM-DD")
      ) {
        classNames.push("holiday-background");
      } else if (
        events[i].title === "WFO" &&
        moment(arg.date).format("YYYY-MM-DD") ===
          moment(events[i].start).format("YYYY-MM-DD")
      ) {
        classNames.push("WFO-background");
      } else if (
        events[i].title === "WFHDAY" &&
        moment(arg.date).format("YYYY-MM-DD") ===
          moment(events[i].start).format("YYYY-MM-DD")
      ) {
        classNames.push("WFH-background");
      } else if (
        events[i].title === "SAT & SUN" &&
        moment(arg.date).format("YYYY-MM-DD") ===
          moment(events[i].start).format("YYYY-MM-DD")
      ) {
        classNames.push("sat-sun-background");
      } else if (
        events[i].type === "R" &&
        moment(arg.date).format("YYYY-MM-DD") ===
          moment(events[i].start).format("YYYY-MM-DD")
      ) {
        classNames.push("cursorPointer");
      }
    }

    if (moment(arg.date).isSame(moment(), "day")) {
      const todayCell = document.querySelector(".fc-day-today");
      if (todayCell) {
        todayCell.classList.remove("fc-day-today");
        todayCell.classList.add("today-cell");
      }
    }

    return classNames.join(" ");
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        paddingRight: 1,
        height: "100%",
        width: "100%",
        "&::-webkit-scrollbar": { width: 10 },
        "&::-webkit-scrollbar-thumb": { backgroundColor: "white" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#1773bc",
          borderRadius: 2,
        },
        boxShadow: "none",
      }}
    >
      <div>
        <div className="attendance-top-text">
          <span style={{ color: "black" }}>Hello</span>{" "}
          {userData && userData.data.activeUsers.firstName}{" "}
          {userData && userData.data.activeUsers.lastName}
          {props.selectedUserID ? (
            <span style={{ color: "black" }}>
              , you are viewing{" "}
              <span className="attendance-top-text">
                {selectedUserName}
              </span>
              's attendance records. Use the dropdowns to view the previous
              month records.
            </span>
          ) : (
            <span style={{ color: "black" }}>
              , view your attendance records below and use the dropdowns to
              view the previous month records.
            </span>
          )}
        </div>

        <div style={{ fontFamily: "sans-serif", textAlign: "center" }}>
          <div className="calendar-header">
            <div
              style={{
                paddingBottom: "1rem",
                paddingTop: "0rem",
                display: "flex",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "600",
                    color: "grey",
                    marginRight: "1rem",
                    whiteSpace: "nowrap",
                    fontFamily:"Montserrat"
                  }}
                >
                  Select Month :{" "}
                </div>
                  <FormControl fullWidth>
                    {/* <InputLabel id="select-month-label">Month</InputLabel> */}
                    <Select
                      style={{ height: "3rem", fontSize: "1.25rem" }}
                      labelId="select-month-label"
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      disabled = {vTrackLoader}
                    >
                      {monthOptions.map((month) => (
                        <MenuItem key={month.value} value={month.value}>
                          {month.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    paddingLeft: "2rem",
                    fontSize: "1.25rem",
                    fontWeight: "600",
                    color: "grey",
                    marginRight: "1rem",
                    whiteSpace: "nowrap",
                    fontFamily:"Montserrat"
                  }}
                >
                  {" "}
                  Select Year :{" "}
                </div>
                <FormControl fullWidth>
                  {/* <InputLabel id="select-year-label">Year</InputLabel> */}
                  <Select
                    style={{ height: "3rem", fontSize: "1.25rem" }}
                    labelId="select-year-label"
                    value={selectedYear}
                    onChange={handleYearChange}
                    disabled = {vTrackLoader}
                  >
                    {yearOptions.map((year) => (
                      <MenuItem key={year.value} value={year.value}>
                        {year.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div>
              <div className="count-text" style={{ textAlign: "right" }}>
                Total working days :{" "}
                {viewAttendanceData["viewAttendanceCount"] &&
                  viewAttendanceData["viewAttendanceCount"][0]}
              </div>

              <div className="count-text" style={{ textAlign: "right" }}>
                Number of days present:{" "}
                {viewAttendanceData["viewAttendanceCount"] &&
                  viewAttendanceData["viewAttendanceCount"][1]}
              </div>
            </div>
          </div>
          
          {vTrackLoader ? <CircularLoader></CircularLoader> : ""}
          <TableContainer
            component={Paper}
            sx={{
              height: "47vh",
              display: vTrackLoader ? "none" : "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <FullCalendar
              headerToolbar={false}
              ref={calendarRef}
              height="100vh"
              widht="100%"
              overflowX="hidden"
              overflowY="hidden"
              style={{ flexGrow: 1 }}
              defaultView="dayGridMonth"
              themeSystem="Simplex"
              firstDay={1}
              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
              dateClick={handleDateClick}
              eventClick={handleEventClick}
              events={events}
              initialView="dayGridMonth"
              eventContent={renderEventContent}
              eventClassNames={(arg) => getEventClassNames(arg.event)}
              dayCellClassNames={dayCellClassNames}
              eventOrder="priority"
            />
            {showLeavePopup && LeavePopup()}
          </TableContainer>
          <div style={{ paddingTop: "2rem" }}>
            <head>
              <title>Circle Legend</title>
            </head>
            <body>
              <div className="calendar-footer">
                <div className="legend-container">
                  <div className="legend">
                    <div className="circle wfo">
                      <span className="legend-label">WFO DAY</span>
                    </div>
                    <div className="circle wfh">
                      <span className="legend-label">WFH DAY</span>
                    </div>
                    <div className="circle hol">
                      <span className="legend-label">HOLIDAY</span>
                    </div>
                  </div>
                  <div className="note-legend">
                    <span style={{ color: "black", fontSize: "1rem", fontFamily:"Montserrat"}}>
                      Note : Above data is 1 day old and Next data refresh happens
                      tonight.
                    </span>
                  </div>
                </div>
                <ul className="leave-codes-list">
                  <li>
                    <strong>A</strong>
                    <span className="description">ABSENT</span>
                  </li>
                  <li>
                    <strong>P</strong>{" "}
                    <span className="description">PRESENT</span>
                  </li>
                  <li>
                    <strong>WFH</strong>
                    <span className="description">WORK FROM HOME</span>
                  </li>
                  <li>
                    <strong>CL</strong>
                    <span className="description">CASUAL LEAVE</span>
                  </li>
                  <li>
                    <strong>LWP</strong>
                    <span className="description">LEAVE WITHOUT PAY</span>
                  </li>
                  <li>
                    <strong>SL</strong>
                    <span className="description">SICK LEAVE</span>
                  </li>
                  <li>
                    <strong>EL</strong>
                    <span className="description">EARNED LEAVE</span>
                  </li>
                </ul>
              </div>
            </body>
          </div>
        </div>
      </div>
    </TableContainer>
  );
};
