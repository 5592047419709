// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./common/fonts/Montserrat/static/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./common/fonts/Montserrat/static/Montserrat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./common/fonts/Montserrat/static/Montserrat-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./common/fonts/Montserrat/static/Montserrat-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./common/fonts/Montserrat/static/Montserrat-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./common/fonts/Kumbh_Sans/static/KumbhSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Montserrat";   /*Can be any text*/
  src: local("Montserrat-Regular"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";   /*Can be any text*/
  src: local("Montserrat-Bold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";   /*Can be any text*/
  src: local("Montserrat-Light"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";   /*Can be any text*/
  src: local("Montserrat-Medium"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}

@font-face {
  font-family: "Montserrat-Thin";   /*Can be any text*/
  src: local("Montserrat-Thin"),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
}

@font-face {
  font-family: "Kumbh Sans";   /*Can be any text*/
  src: local("KumbhSans-Regular"),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype");
}

*{
  margin: 0;
  padding: 0;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,IAAI,kBAAkB;EAC/C;8DACmF;AACrF;;AAEA;EACE,8BAA8B,IAAI,kBAAkB;EACpD;8DACgF;AAClF;;AAEA;EACE,+BAA+B,IAAI,kBAAkB;EACrD;8DACiF;AACnF;;AAEA;EACE,gCAAgC,IAAI,kBAAkB;EACtD;8DACkF;AACpF;;AAEA;EACE,8BAA8B,IAAI,kBAAkB;EACpD;8DACgF;AAClF;;AAEA;EACE,yBAAyB,IAAI,kBAAkB;EAC/C;8DACkF;AACpF;;AAEA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":["@font-face {\r\n  font-family: \"Montserrat\";   /*Can be any text*/\r\n  src: local(\"Montserrat-Regular\"),\r\n    url(\"./common/fonts/Montserrat/static/Montserrat-Regular.ttf\") format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Montserrat-Bold\";   /*Can be any text*/\r\n  src: local(\"Montserrat-Bold\"),\r\n    url(\"./common/fonts/Montserrat/static/Montserrat-Bold.ttf\") format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Montserrat-Light\";   /*Can be any text*/\r\n  src: local(\"Montserrat-Light\"),\r\n    url(\"./common/fonts/Montserrat/static/Montserrat-Light.ttf\") format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Montserrat-Medium\";   /*Can be any text*/\r\n  src: local(\"Montserrat-Medium\"),\r\n    url(\"./common/fonts/Montserrat/static/Montserrat-Medium.ttf\") format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Montserrat-Thin\";   /*Can be any text*/\r\n  src: local(\"Montserrat-Thin\"),\r\n    url(\"./common/fonts/Montserrat/static/Montserrat-Thin.ttf\") format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Kumbh Sans\";   /*Can be any text*/\r\n  src: local(\"KumbhSans-Regular\"),\r\n    url(\"./common/fonts/Kumbh_Sans/static/KumbhSans-Regular.ttf\") format(\"truetype\");\r\n}\r\n\r\n*{\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
