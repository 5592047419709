import { Component, useEffect } from 'react';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { toast } from 'react-toastify';
import DialogContentText from '@mui/material/DialogContentText';
import { useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getMultipleFileView } from "../../redux/actions/multiple-file-view";
import { useSelector } from "react-redux";
import { Modules } from "../../common/constants/sidebar";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    AddDisableIcon,
    AddEnableIcon, approveIcon, crossIcon, deleteIcon, downloadIcon, editIcon, rejectIcon, TableArrows
} from "../../common/icons";
import { deleteMultipleFileView } from "../../redux/actions/multiple-file-view";
import { downloadMultipleFileView } from "../../redux/actions/multiple-file-view";
import { tableColumnsData } from "../../common/utils/datatable";
import { updateResourcingData,updateBillableLeaves } from '../../redux/actions';
import { Padding } from '@mui/icons-material';
import CircularLoader from "../Loaders/CircularLoader";
import { getResourcingData } from '../../redux/actions';
import { toastOptions } from '../../common/utils/toasterOptions';

const ResourcingDetailsPopup = (props) => {
    const resourcingData = useSelector(({ MODULES }) => MODULES.resourcingData)
    const billableLeavesData = useSelector(({MODULES}) => MODULES.billableLeavesData)
    const dispatch = useDispatch();
    const [newRowAdded, setNewRowAdded] = useState({});
    const [rows,setRows] = useState([]);
    const [billableLeave, setBillableLeave] = useState("");
    const [isBillableLeaveChanged, setIsBillableLeaveChanged] = useState(false);

    const handleClose = () => {
        props.onResponse(true);
      }

    const handleUpdateDetails = () =>{
        dispatch(updateResourcingData(rows));
    }

    const handleUpdateBillableLeaves = () =>{
        dispatch(updateBillableLeaves({
            projectId : props.row.projectId,
            billableLeaves: billableLeave
        }));
        setIsBillableLeaveChanged(false);
    }

    const columns=tableColumnsData["ResourcingDetailsProjectAdmin"];

    const inputFieldHandler = (event, key) => {
        // Split the key into month, year, and field
        let keyArray = key.split("-");
        
        // Ensure the keyArray contains the correct elements
        if (keyArray.length !== 3) return; // Early return if key format is incorrect
        let valuesArray = event.target.value.split(".");
        if (valuesArray.length !== 1 && valuesArray[1] >= 100) {
        toast.info(`You can not enter values after 2 decimal places`,toastOptions);
        return;
        }
        // Validate the input
         if (isNaN(event.target.value)) {
            toast.info("Please Enter Numbers Only");
            return;
        } 
    
        // Update the newRowAdded state
        setNewRowAdded((prevState) => ({
            ...prevState,  // Spread the previous state
            [key]: event.target.value  // Use computed property name to set the dynamic key
        }));
    
        // Update the rows state
        setRows(prevRows => {
            const updatedRows = prevRows.map(row =>
                row.month == keyArray[0] && row.year == keyArray[1] 
                    ? { ...row, [keyArray[2]]: event.target.value } 
                    : row
            );
            return updatedRows;
        });
    };
    

    const createInputField = (col, row) => {
        const key = `${row.month}-${row.year}-${col.id}`;
        return (
            <TableCell
                key={key}
                style={{ maxWidth: col.maxWidth ? col.maxWidth : "auto" }}
            >
                <TextField
                    style={{ width: "100%", maxWidth: "auto" }}
                    id="standard-basic"
                    variant='standard'
                    inputProps={{
                        maxLength: 100,
                        min: col.min === 0 ? col.min : null,
                        max: col.max ? col.max : null,
                        style: {
                            fontSize: '1.5rem', // Increase font size
                        },
                    }}
                    type={col.fieldType}
                    placeholder={col.label}
                    value={newRowAdded[key] ?? row[col.id]}  // Use nullish coalescing operator to handle undefined
                    required={col.isRequired}
                    sx={{
                        "& label": {
                            lineHeight: "1rem",
                        },
                    }}
                    disabled={col.disabled ? true : false}
                    onChange={(e) => inputFieldHandler(e, key)}
                />
            </TableCell>
        );
    };
    
      const generateMonths = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const months = [];
        const years = [];
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        
        let current = new Date(start);
        const rowsGenerated=[];
        while (current <= end) {
            const monthName = monthNames[current.getMonth()];
            const year = current.getFullYear();
            const row={projectId:props.row.projectId,month:monthName,year:year,billableResourceCount:'',nonBillableResourceCount:'',shadowResourceCount:''};
            current.setMonth(current.getMonth() + 1);
            rowsGenerated.push(row);
        }
        return rowsGenerated
        };
    
        const rowsGenerated=generateMonths(props.row.sowStartDate, props.row.sowEndDate);

        const extractDate = (datetime) => {
            const dateTimeString = datetime; 
            const formattedDate = dateTimeString.slice(0, 10);
            return formattedDate;
        }

        useEffect(()=>{
           rowsGenerated && rowsGenerated.length && rowsGenerated.map((row)=>{
                resourcingData && resourcingData.length && resourcingData.map((data)=>{
                    if(row.month==data.month&&row.year==data.year){
                        row.billableResourceCount=data.billableResourceCount
                        row.nonBillableResourceCount=data.nonBillableResourceCount
                        row.shadowResourceCount=data.shadowResourceCount
                    }
                })
            })
            setRows(rowsGenerated);
        },[resourcingData])
        
        useEffect(()=>{
            if(billableLeavesData){
                setBillableLeave(billableLeavesData);
            }
         },[billableLeavesData])
    
    return (
        <React.Fragment>
            <DialogContent>
            <div>
                <div style={{ marginBottom: '1rem',marginTop:"-1rem", fontWeight:'bold', fontSize: '1.8rem', color:'#1773BC', display:'flex', justifyContent:'space-between'}}>
                    <label>Project Details </label>
                </div>
                <div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ flex: "1", marginBottom: "1rem" }}>
                    <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
                        <label style={{  marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem",color: "rgb(23, 115, 188)" }}>
                        Project Name 
                        </label>
                        <label style={{ fontFamily: "Montserrat", fontSize: "1.7rem" }}>
                        {props.row.projectName}
                        </label>
                        </div>
                    </div>

                    <div style={{ flex: "1", marginBottom: "1rem" }}>
                    <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
                        <label style={{ marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem" ,color: "rgb(23, 115, 188)"}}>
                        Client Name
                        </label>
                        <label style={{  fontFamily: "Montserrat", fontSize: "1.7rem"}}>
                        {props.row.clientName}
                        </label>
                        </div>
                    </div>
                    </div>

                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ flex: "1", marginBottom: "1rem" }}>
                        <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
                        <label style={{ marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem" ,color: "rgb(23, 115, 188)"}}>
                        Project Manager Name 
                        </label>
                        <label style={{ fontFamily: "Montserrat", fontSize: "1.7rem" }}>
                        {props.row.projectManagerName}
                        </label>
                        </div>
                    </div>
                    <div style={{display:"flex",flexWrap:"wrap",flex: "1", marginBottom: "1rem", flexDirection:"row" }}>
                        <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column"}}>
                        <label style={{  marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem",color: "rgb(23, 115, 188)"}}>
                        SOW Start Date 
                        </label>
                        
                        <label style={{  fontFamily: "Montserrat", fontSize: "1.7rem"}}>
                        {extractDate(props.row.sowStartDate)}
                        </label>
                        </div>
                        <div style={{display:"flex",flexWrap:"wrap", flexDirection:"column",marginLeft:"2rem"}}>
                        <label style={{ marginRight:"1rem",fontFamily: "Montserrat", fontSize: "1.5rem" ,color: "rgb(23, 115, 188)"}}>
                        SOW End Date 
                        </label>
                        <label style={{ fontFamily: "Montserrat", fontSize: "1.7rem" }}>
                        {extractDate(props.row.sowEndDate)}
                        </label>
                        </div>
                    </div>
                    </div>
                </div>
                <div style={{ marginBottom: '1.5rem',marginTop:"1rem", fontSize: '1.8rem', color:'#1773BC', display:'flex'}}>
                    <label style={{marginTop:"0.5rem"}}>Billable Leaves </label>
                    <TextField
                        style={{marginLeft:"2rem",width:"4rem", fontFamily: "Montserrat", fontSize: "1.7rem"}}
                        id="standard"
                        variant='standard'
                        inputProps={{
                            style: {
                                fontSize: '1.5rem', // Increase font size
                            },
                        }}
                        value={billableLeave}  // Use nullish coalescing operator to handle undefined
                        onChange={(e) => {setBillableLeave(e.target.value);setIsBillableLeaveChanged(true);}}
                    />
                    <Button disabled={!isBillableLeaveChanged} variant="contained" style={{ maxWidth: '12rem',marginLeft: '5rem',marginTop:"0.1rem"}} onClick={handleUpdateBillableLeaves}>Update</Button>
                </div>
                <div style={{ marginBottom: '1rem',marginTop:"1rem", fontWeight:'bold', fontSize: '1.8rem', color:'#1773BC', display:'flex', justifyContent:'space-between'}}>
                    <label>Resource Allocation Details </label>
                </div>
                <TableContainer sx={{height: "30rem"}} component={Paper} >
                    <Table sx={{ minWidth: "100rem"}} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{bgcolor:'#f3f8fc'}}>
                                {columns.map((col) => (
                                    <TableCell align="left"sx={{color:'#3a75c2',fontSize:'1.5rem'}} key={col.id}>{col.label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {rows&&rows.length&&rows.map((row)=>(
                                <TableRow>
                                    {columns&&columns.map((col)=>(
                                        col.id === 'month' || col.id === 'year' ? (
                                            <TableCell key={col.id} style={{ fontSize: '1.5rem' }}> 
                                            {row[col.id]}
                                            </TableCell>
                                        ) : (
                                            createInputField(col, row)
                                        )
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            </DialogContent>
            <DialogActions>
            <Button variant="contained" style={{ maxWidth: '12rem',marginRight: '1rem'}} onClick={handleUpdateDetails}>Update Details</Button>
            <Button variant="contained" style={{ maxWidth: '12rem' }} onClick={handleClose}>Close</Button>
            </DialogActions>
        </React.Fragment>

    );
}

export default ResourcingDetailsPopup;