import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    DialogTitle,
    DialogContent,
    Button,
    DialogContentText,
    DialogActions,
    FormControl,
    Select,
    MenuItem,
  } from "@mui/material";
  import {
    getViewAttendanceData
  } from "../../redux/actions";
  import moment from "moment";
  import ExportExcel from "../ExportExcel";
  import { Modules } from "../../common/constants/sidebar";
  import Loader from "../Loaders/Loader";
  import CircularLoader from "../Loaders/CircularLoader";

const MonthYearPopup= (props) => {
    const [month, setMonth] = useState(moment().format("MMMM"));
    const [year, setYear] = useState(new Date().getFullYear());
    const dispatch = useDispatch();
    const { vTrackLoader } = useSelector(({ APP_STATE }) => APP_STATE);
    const {attendanceForExcel} = useSelector(({ MODULES }) => MODULES);

    const monthOptions = Array.from({ length: 12 }, (_, index) => ({
        value: index + 1,
        label: moment().month(index).format("MMMM"),
      }));
    const curryear = new Date().getFullYear() - 1;
    const yearOptions = Array.from({ length: 2 }, (_, index) => ({
      value: curryear + index,
      label: curryear + index,
    }));

    const handleMonthChange = (event) => {
      const newMonth = event.target.value;
      setMonth(newMonth);
    };
  
    const handleYearChange = (event) => {
      const newYear = event.target.value;
      setYear(newYear);
    };
    
    // const handleSubmit = () => {
    //     dispatch(
    //         getViewAttendanceData({
    //           month: month,
    //           year: year
    //         })
    //       );
    //   props.onResponse(true)
    // };

    const handleCancel = () => {
      props.onResponse(true);
    }
    return (
      <>
      {vTrackLoader && <Loader />}
      <React.Fragment>
          <DialogTitle
          sx={{
            fontSize: '1.5rem',
            fontWeight: '600',
            color: '#1773bc',
            margin: '1rem 0',
            fontFamily: 'Montserrat',
          }}>
            Download Attendance Report
          </DialogTitle>
          <DialogContent sx={{width:"26rem"}}>
            {/* <DialogContentText
            sx={{
                fontSize: '1.25rem',
                fontWeight: '600',
                color: '#1773bc',
                margin: '1rem 0',
                fontFamily: 'Montserrat',
              }}>
              Download All Reportees' Attendance Data
            </DialogContentText> */}
            {/* <div
              style={{
                paddingBottom: "1rem",
                paddingTop: "0rem",
                display: "flex",
                justifyContent:"space-between"
              }}
            > */}
            <div style={{ display: "flex", alignItems: "center",maxWidth:"25rem",paddingBottom:"1rem" }}>
              <div
                style={{
                  fontSize: "1.25rem",
                  fontWeight: "600",
                  color: "grey",
                  marginRight: "2.45rem",
                  whiteSpace: "nowrap",
                  fontFamily:"Montserrat"
                }}
              >
                {" "}
                Select Year :{" "}
              </div>
              <FormControl fullWidth>
                {/* <InputLabel id="select-year-label">Year</InputLabel> */}
                <Select
                  style={{ height: "3rem", fontSize: "1.25rem" }}
                  labelId="select-year-label"
                  value={year}
                  onChange={handleYearChange}
                  disabled = {vTrackLoader}
                >
                  {yearOptions.map((year) => (
                    <MenuItem key={year.value} value={year.value}>
                      {year.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: "flex", alignItems: "center",maxWidth:"25rem" }}>
              <div
                style={{
                  fontSize: "1.25rem",
                  fontWeight: "600",
                  color: "grey",
                  marginRight: "1rem",
                  whiteSpace: "nowrap",
                  fontFamily:"Montserrat"
                }}
              >
                Select Month :{" "}
              </div>
                <FormControl fullWidth>
                  {/* <InputLabel id="select-month-label">Month</InputLabel> */}
                  <Select
                    style={{ height: "3rem", fontSize: "1.25rem"}}
                    labelId="select-month-label"
                    value={month}
                    onChange={handleMonthChange}
                    disabled = {vTrackLoader}
                  >
                    {monthOptions.map((month) => (
                      <MenuItem key={month.value} value={month.label}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
            </div>
            {/* </div> */}
          </DialogContent>
          <DialogActions >
            {/* <Button onClick={handleSubmit} variant='contained' sx={{width:"10rem"}}>Download</Button> */}
            <ExportExcel
              props={{month:month,year:year}}
              excelData={attendanceForExcel}
              tabName={"VIEW EMPLOYEE ATTENDANCE"}
              fileName={`${month} ${year} Employee Attendance`}
            />
            <Button onClick={handleCancel} variant='outlined'  sx={{width:"7rem"}}>Close</Button>
          </DialogActions>
      </React.Fragment>
      </>
      
    );
}

export default MonthYearPopup;